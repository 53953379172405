<div class="container-fluid">
  <div class="tabContentBlock">
    <div class="row pageHeader">
      HO Limit Details
    </div>
    <div class="row"  style="margin-top: 12px;">
      <div class="col-md-6 pl-0">
        <mat-form-field class="cusWidth">
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
          <button mat-icon-button matPrefix>
            <mat-icon class="primColor">search</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="col-md-6 buttonAlignRight tabBtnAlign">
        <button class="mat-raised-button mat-button-color mr-10 exportBtn" (click)="exportAsXLSX()"> 
          <fa-icon [icon]="['fas', 'download']" style="margin-right: 10px;"></fa-icon>Export Excel</button>
        <button class="mat-raised-button mat-primary exportBtn" (click)="addHoLimit()"> Add Record</button>
      </div>
    </div>
    <div class="row" style="padding: 20px 0px 20px;">
      <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
      <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
        <ng-container matColumnDef="upper_limit">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Upper Limit </mat-header-cell>
          <mat-cell *matCellDef="let element" class="w-200"> {{element.upper_limit}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Type </mat-header-cell>
          <mat-cell *matCellDef="let element" class="w-200"> {{element.type}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="year">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200">Year </mat-header-cell>
          <mat-cell *matCellDef="let element" class="w-200"> {{element.year}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="month">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200">Month </mat-header-cell>
          <mat-cell *matCellDef="let element" class="w-200"> {{element.month}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="is_active">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200">Active </mat-header-cell>
          <mat-cell *matCellDef="let element" class="w-200"> {{element.is_active}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Action</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="actionRowBlock">
              <a class="mr-10" (click)="editHoLimit(element)">
                Edit
              </a>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayColumns;"></mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="dataSource.data.length > 50 ? [10, 20, 50, dataSource.data.length] : [10, 20, 50]" (pageChange)="onPageChange(currentPage)"
        [(pageIndex)]="currentPage" showFirstLastButtons></mat-paginator>
    </div>
    <div *ngIf="dataSource.data.length == 0" class="noData">
      No Data Found
    </div>
  </div>
  <simple-notifications [options]="notificationOptions"></simple-notifications>
</div>