<h2 class="dialog-heading" *ngIf="title" mat-dialog-title>{{title}}</h2>
<mat-dialog-content style="width:500px">
    <form class="example-full-width" [formGroup]="deliveryForm">
        <mat-form-field class="example-full-width">
            <input formControlName="delivery_type" placeholder="Delivery type" matInput type='text' required>
            <mat-error>
                <span *ngIf="deliveryForm.get('delivery_type').hasError('required')">District Name is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="delivery_name" placeholder="Delivery Name" matInput type='text' required>
            <mat-error>
                <span *ngIf="deliveryForm.get('delivery_name').hasError('required')">Delivery Name is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="short_name" placeholder="Short Name" matInput type='text' required>
            <mat-error>
                <span *ngIf="deliveryForm.get('short_name').hasError('required')">Short Name is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="address_1" placeholder="Address 1" matInput type='text' required>
            <mat-error>
                <span *ngIf="deliveryForm.get('address_1').hasError('required')">Address 1 is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="pincode" placeholder="Pincode" matInput type='text' required>
            <mat-error>
                <span *ngIf="deliveryForm.get('pincode').hasError('required')">Pincode is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="city" placeholder="City" matInput type='text' required>
            <mat-error>
                <span *ngIf="deliveryForm.get('city').hasError('required')">City is required</span>
            </mat-error>
        </mat-form-field>     
        <mat-form-field class="example-full-width">
            <input formControlName="district" placeholder="District" matInput type='text' required>
            <mat-error>
                <span *ngIf="deliveryForm.get('district').hasError('required')">District is required</span>
            </mat-error>
        </mat-form-field>        
        <mat-form-field class="example-full-width">
            <mat-label>State</mat-label>
            <mat-select class="matSelect" formControlName="state" required>
                <mat-option *ngFor="let state of states" [value]="state.state_name">
                    {{state.state_name}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="deliveryForm.get('state').hasError('required')">State is required</span>
            </mat-error>
    </mat-form-field>

        <mat-form-field class="example-full-width">
            <input formControlName="email_1" placeholder="Email 1" matInput type='text'>
            <mat-error>
                <span *ngIf="deliveryForm.get('email_1').hasError('pattern')">Invalid Email Id</span>
            </mat-error>
        </mat-form-field> 
        <mat-form-field class="example-full-width">
            <input formControlName="email_2" placeholder="Email 2" matInput type='text'>
            <mat-error>
                <span *ngIf="deliveryForm.get('email_2').hasError('pattern')">Invalid Email Id</span>
            </mat-error>
        </mat-form-field> 

        <mat-form-field class="example-full-width">
            <input formControlName="address_2" placeholder="Address 2" matInput type='text'>
        </mat-form-field> 

        <mat-form-field class="example-full-width">
            <input formControlName="mobile_1" placeholder="Mobile 1" matInput type='text'>
            <mat-error>
                <span *ngIf="deliveryForm.get('mobile_1').hasError('pattern')">Invalid Mobile Number</span>
            </mat-error>
        </mat-form-field> 

        <mat-form-field class="example-full-width">
            <input formControlName="mobile_2" placeholder="Mobile 2" matInput type='text'>
            <mat-error>
                <span *ngIf="deliveryForm.get('mobile_1').hasError('pattern')">Invalid Mobile Number</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <input formControlName="gstin" placeholder="GSTIN" matInput type='text'>
        </mat-form-field>

    </form>
    <mat-dialog-actions>
        <button class="mat-raised-button mr-10" (click)="close()">Close</button>
        <button class="mat-raised-button mat-primary" (click)="saveDelivery()">Save</button>
    </mat-dialog-actions>
</mat-dialog-content>