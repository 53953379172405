<h2 class="dialog-heading" *ngIf="title" mat-dialog-title>{{title}}</h2>
<mat-dialog-content style="width:500px">
    <form class="example-full-width" [formGroup]="forFreightForm">
        <mat-form-field class="example-full-width">
            <mat-label required>Select Cluster</mat-label>
            <mat-select class="matSelect" formControlName="cluster_id" required>
                <mat-option *ngFor="let cluster of clusterList" [value]="cluster.id">
                    {{cluster.name}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!forFreightForm.get('cluster_id').valid || forFreightForm.get('cluster_id').touched">Cluster is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label required>Delivery Location</mat-label>
            <mat-select class="matSelect" formControlName="delivery_id" required>
                <mat-option *ngFor="let location of deliveryLocationList" [value]="location.id">
                    {{location.delivery_name}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!forFreightForm.get('delivery_id').valid || forFreightForm.get('delivery_id').touched">Delivery Location is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="group_id" placeholder="Group ID" matInput type='text' required>
            <mat-error>
                <span *ngIf="forFreightForm.get('group_id').hasError('required')">Group ID is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="freight_value" placeholder="Freight Value" matInput type='number' required>
            <mat-error>
                <span *ngIf="forFreightForm.get('freight_value').hasError('required')">Freight Value is required</span>
            </mat-error>
            <mat-error>
                <span *ngIf="forFreightForm.get('freight_value').hasError('pattern')">Freight Value should be greater than 0 and upto 2 decimal places</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label [class.active]="forFreightForm.get('valid_from').value">Valid From</mat-label>
            <input formControlName="valid_from" matInput  [matDatepicker]="picker1" [max]="forFreightForm.value.valid_to">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1 color="primary"></mat-datepicker>
            <mat-error>
                <span *ngIf="forFreightForm.get('valid_from').hasError('required')">Valid From is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label [class.active]="forFreightForm.get('valid_to').value">Valid To</mat-label>
            <input formControlName="valid_to" matInput  [matDatepicker]="picker2" [min]="forFreightForm.value.valid_from">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2 color="primary"></mat-datepicker>
            <mat-error>
                <span *ngIf="forFreightForm.get('valid_to').hasError('required')">Valid To is required</span>
            </mat-error>
        </mat-form-field>
    </form>
    <mat-dialog-actions >
        <button class="mat-raised-button mr-10" (click)="close()">Close</button>
        <button class="mat-raised-button mat-primary" (click)="saveForFreight()">Save</button>
    </mat-dialog-actions>
</mat-dialog-content>
