import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MenuService } from '../services/menu.service';
import { UbService } from '../services/ub.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ExcelService } from '../services/excel.service';
import { NotificationsService } from 'angular2-notifications';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';

import * as moment from 'moment';
import { ManageOfferDialogComponent } from '../widgets/manage-offer-dialog/manage-offer-dialog.component';
import { ConfirmationDialogComponent } from '../widgets/confirmation-dialog/confirmation-dialog.component';
import { OfferAction, OfferToggleText, UserRole } from '../models/schema';

const initialSelection = [];
const allowMultiSelect = true;

@Component({
  selector: 'app-manage-offer',
  templateUrl: './manage-offer.component.html',
  styleUrls: ['./manage-offer.component.scss']
})
export class ManageOfferComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  OfferAction = OfferAction;
  OfferToggleText = OfferToggleText;
  permission: any;
  plantCodeList = [];
  documentDate = moment(new Date()).format('YYYY-MM-DD');
  avilableStatus = ['ALL', 'SUBMITTED', 'APPROVED', 'REJECTED'];
  statusSelected = this.avilableStatus[0];
  startDate = moment().subtract(1, "days").format("YYYY-MM-DD") ;
  endDate = moment().format("YYYY-MM-DD");

  public daterange: any = {
   start: this.startDate,
    end: this.endDate,
  };
  loggedInUser: any;
  activeSeason = ''
  loading = false;
  public options: any = {
    locale: { format: 'D MMMM YYYY' },
    alwaysShowCalendars: false,
    maxDate: new Date(),
    startDate:moment().subtract(1, "days").format("D MMMM YYYY") ,
    endDate: moment().format("D MMMM YYYY") ,
  };
  public notificationOptions: any = {
    position: ['bottom', 'right'],
    timeOut: 5000,
    lastOnBottom: true,
    pauseOnHover: true,
    clickToClose: true,
    animate: 'fromRight'
  };
  showOffer: any;
  dataSource = new MatTableDataSource<any>();
  stateList: any;
  editRow: any;
  selection: any;
  isFrozen = false;
  displayed6RowColumns = [] // ['select', 'mandi_name', 'delivery_location', 'pakka_arthiya', 'order_number', 'reference_no', 'offer_reference_no', 'document_date', 'previous_year_price', 'base_price',
  // 'quantity', 'for_price', 'suggested_price', 'ceiling_price', 'created_by', 'plant_code', 'counter_offer_price', 'action'];
  seasonType = 'Season';
  allOffers = [];

  public selectedDate(value: any, datepicker?: any) {
    datepicker.start = value.start;
    datepicker.end = value.end;
    this.daterange.start = moment(value.start).format('YYYY-MM-DD');
    this.daterange.end = moment(value.end).format('YYYY-MM-DD');
    this.daterange.label = value.label;
    this.getOffers();
  }

  constructor(
    public menuService: MenuService,
    private router: Router,
    private service: UbService,
    private dialog: MatDialog,
    private excelService: ExcelService,
    private notificationService: NotificationsService,
  ) {
    this.getplantCode();
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      return data.destinationObj.delivery_name.toLocaleLowerCase().includes(filter) ||
        data.mandiObj.mandi_name.toLocaleLowerCase().includes(filter) ||
        data.userObj.user_id.toLocaleLowerCase().includes(filter) ||
        data.base_price.toString().includes(filter) ||
        data.createdByObj.user_id.toString().includes(filter) ||
        data.offer_number.toString().includes(filter);
    };
    this.selection = new SelectionModel<any>(allowMultiSelect, initialSelection);
  }

  applyFilter(filterValue) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected == numRows;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.service.currentOffer.subscribe(status => {
      console.log('Current offer status--->', status)
    });

    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    if (this.loggedInUser.role === UserRole.Facilitator || this.loggedInUser.role === UserRole.PukkaArthiya) {
      this.displayed6RowColumns = ['select', 'mandi_name', 'delivery_location', 'pakka_arthiya', 'order_number', 'reference_no', 'offer_reference_no', 'document_date', 'base_price',
        'quantity', 'for_price', 'ceiling_price', 'created_by', 'counter_offer_price', 'action'];
    } else {
      this.displayed6RowColumns = ['select', 'mandi_name', 'delivery_location', 'pakka_arthiya', 'order_number', 'reference_no', 'offer_reference_no', 'document_date', 'previous_year_price', 'base_price',
        'quantity', 'for_price', 'suggested_price', 'ceiling_price', 'created_by', 'plant_code', 'counter_offer_price', 'action'];
    }
    const menu = this.menuService.getMenu(this.router.url);
    this.permission = menu.actions;
    this.getOffers();
    if (this.checkPermission(OfferAction.view_season_offer) && this.checkPermission(OfferAction.view_offseason_offer)) {
      this.activeSeason = OfferToggleText.Season
    } else if (this.checkPermission(OfferAction.view_offseason_offer)) {
      this.activeSeason = OfferToggleText.Offseason
      console.log('this.seasonType-------->', this.seasonType)
    } if (this.checkPermission(OfferAction.view_season_offer)) {
      this.activeSeason = OfferToggleText.Season
      console.log('this.Season-------->', this.seasonType)
    }
  }

  getOffers() {
    let season = true;
    if (this.seasonType === 'Season') {
      season = true;
    } else {
      season = false
    }
    let params = {};
    if (this.loggedInUser.role.toLowerCase() === 'facilitator' || this.loggedInUser.role.toLowerCase() === 'pakka arthiya') {
      params = {
        startDate: this.daterange.start,
        endDate: this.daterange.end,
        season: season,
        created_by: this.loggedInUser.id
      }
    } else {
      params = {
        startDate: this.daterange.start,
        endDate: this.daterange.end,
        season: season
      }
    }
    this.loading = true;
    this.service.getOffers(params).subscribe(
      (ordersResponse: any) => {
        if (ordersResponse.status === true) {
          this.allOffers = [...ordersResponse.result]

          if (this.loggedInUser.role === UserRole.Facilitator || this.loggedInUser.role === UserRole.PukkaArthiya) {
            this.showOffer = this.allOffers;
          } else {
            this.showOffer = this.allOffers.filter((item) => item.status !== 'SAVED');
          }

          if (this.statusSelected != 'ALL') {
            this.showOffer = this.showOffer.filter((itm) => itm.status === this.statusSelected);
          }

          this.dataSource.data = this.showOffer;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        } else {
          console.log('error')
        }
        this.loading = false;
      }, (error) => {
        this.loading = false;
        this.notificationService.error('Error', error.error.message);
      }
      
    )
  }

  checkPermission(access: string): boolean {
    return this.permission.includes(access);
  }

  addNewOffer() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Add Offer Details',
      height: '800px',
      width: '300px',
      panelClass: 'addnew-custom-dialog',
      type: 'add',
      season: this.seasonType === 'Season' ? true : false
    };
    const dialogRef = this.dialog.open(ManageOfferDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.ngOnInit();
    })
  }

  manageOfferDialog(data) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Edit Offer Details',
      height: '800px',
      width: '300px',
      panelClass: 'addnew-custom-dialog',
      type: 'add',
      offer_id: data.id,
      editeItem: data,
      season: this.seasonType === OfferToggleText.Season ? true : false
    };
    const dialogRef = this.dialog.open(ManageOfferDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.ngOnInit();
    })
  }

  updateCounterPrice(data, status) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Edit Offer Details',
      height: '800px',
      width: '300px',
      panelClass: 'addnew-custom-dialog',
      type: 'update',
      offer_id: data.id,
      status: status,
      season: this.seasonType === OfferToggleText.Season ? true : false
    };
    const dialogRef = this.dialog.open(ManageOfferDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.ngOnInit();
    })
  }

  updateOfferPriceStatus(state, row) {
    console.log(state);
    console.log(row);
  }

  exportAsXLSX(): void {
    if (this.loggedInUser.role === UserRole.Facilitator) {
      const exportData = this.dataSource.data.map((val) => (
        {
          id: val.id,
          'Mandi Name': val.mandiObj.mandi_name,
          'Document Date': val.document_date,
          'Deliver Location': val.destinationObj?.delivery_name,
          'Pakka Arthiya': val.userObj?.user_id,
          'Reference Number': val.ref_num,
          'Offer Reference Number': val.offer_number,
          'Action': val.status,
          'Base Price': val.base_price,
          'Quantity': val.quantity,
          'For Price': val.order_for_price,
          'Suggested Price': val.suggested_price,
          'Ceiling Price': val.ceiling_price,
          'Created By': val.createdByObj.user_id,
          'Counter Offer Price': val.counter_offer_price
        }
      ));
      this.excelService.exportAsExcelFile(exportData, 'Offer');
    }

    if (this.loggedInUser.role === UserRole.UBP) {
      const exportData = this.dataSource.data.map((val) => (
        {
          id: val.id,
          'Mandi Name': val.mandiObj?.mandi_name,
          'Document Date': val.document_date,
          'Deliver Location': val.destinationObj?.delivery_name,
          'Pakka Arthiya': val.userObj?.user_id,
          'Reference Number': val.ref_num,
          'Offer Reference Number': val.offer_number,
          'Action': val.status,
          'Base Price': val.base_price,
          'Quantity': val.quantity,
          'For Price': val.order_for_price,
          'Suggested Price': val.suggested_price,
          'Ceiling Price': val.ceiling_price,
          'Created By': val.createdByObj.user_id,
          'Counter Offer Price': val.counter_offer_price
        }
      ));
      this.excelService.exportAsExcelFile(exportData, 'Offer');
    }

  }

  seasonTypeChange(event) {
    console.log('Event------>', event)
    this.seasonType = event.checked ? OfferToggleText.Offseason : OfferToggleText.Season;
    this.getOffers();
  }

  // Open dialog from the Confirmation dialog component
  openDialogConfirmation(item, type) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Confirmation',
      buttonText: {
        ok: 'Add',
        cancel: 'Cancel',
      },
      content: type,
      data: item,
      height: "600px",
      width: '300px',
      panelClass: 'addnew-custom-dialog'
    };
    const confirmationRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);

    confirmationRef.afterClosed().subscribe(
      (dialogResponse: any) => {
        if (dialogResponse.value === 'confirm') {
          let obj = {};
          let orderIds = []
          if (item === 'all') {
            if (this.selection.selected.length > 0) {
              for (const order of this.selection.selected) {
                if (order.status !== "SUBMITTED")
                  orderIds.push(order.id)
              }
            } else {
              this.notificationService.error('Error', 'Please select the offers to Submit');
              return;
            }
          } else {
            orderIds.push(item.id);
          }
          obj = {
            ids: orderIds
          }
          this.service.submitOrder(obj).subscribe(
            (submitResonse: any) => {
              if (submitResonse.status === true) {
                this.notificationService.success('Success', 'Successfully Submitted');
                this.getOffers();
              }
            }, (error) => {
              this.notificationService.error('Error', error.error.message);
            }
          )
        }
      }
    )
  }

  acceptAll(type, item) {
    if (this.selection.selected.length === 0) {
      this.notificationService.error('Error', 'No Offers selected to Approve');
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Confirmation',
      buttonText: {
        ok: 'Add',
        cancel: 'Cancel',
      },
      content: type,
      data: item,
      height: "600px",
      width: '300px',
      panelClass: 'addnew-custom-dialog'
    };
    const confirmationRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);

    confirmationRef.afterClosed().subscribe(
      (dialogResponse: any) => {
        if (dialogResponse.value === 'confirm') {
          const payload = {
            ids: [],
            status: 'APPROVED',
            reviewed_by: this.loggedInUser.id
          }
          for (const data of this.selection.selected) {
            if (!data.plant_master_id) {
              this.notificationService.error('Error', 'Please Select the Plant Code');
              return;
            }
            if (data.status === 'SUBMITTED' && data.plant_master_id) {
              payload.ids.push(data.id);
            }
          }
          this.service.reviewOrder(payload).subscribe(
            (response: any) => {
              if (response.status === true) {
                this.getOffers();
                this.notificationService.success('Success', 'Successfully Approved');
              }
            }, (error) => {
              this.notificationService.error('Error', error.error.message);
            }
          )
        }
      }
    );
  }

  rejectAll(type, item) {
    if (this.selection.selected.length === 0) {
      this.notificationService.error('Error', 'No Offers selected to Reject');
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Confirmation',
      buttonText: {
        ok: 'Add',
        cancel: 'Cancel',
      },
      content: type,
      data: item,
      height: "600px",
      width: '300px',
      panelClass: 'addnew-custom-dialog'
    };
    const confirmationRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);

    confirmationRef.afterClosed().subscribe(
      (dialogResponse: any) => {
        if (dialogResponse.value === 'confirm') {
          const payload = {
            ids: [],
            status: 'REJECTED',
            reviewed_by: this.loggedInUser.id
          }
          for (const data of this.selection.selected) {
            if (data.status === 'SUBMITTED') {
              payload.ids.push(data.id);
            }
          }
          this.service.reviewOrder(payload).subscribe(
            (response: any) => {
              if (response.status === true) {
                this.getOffers();
                this.notificationService.success('Success', 'Successfully Rejected');
              }
            }, (error) => {
              this.notificationService.error('Error', error.error.message);
            }
          )
        }
      }
    );
  }

  reviewOrder(element, status) {
    if (!element.plant_master_id && status === 'APPROVED') {
      this.notificationService.error('Error', 'Please Select the Plant Code');
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Confirmation',
      buttonText: {
        ok: 'Add',
        cancel: 'Cancel',
      },
      content: status === 'APPROVED' ? 'Approve Orders' : 'Reject Orders',
      data: status,
      height: "600px",
      width: '300px',
      panelClass: 'addnew-custom-dialog'
    };
    const confirmationRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);

    confirmationRef.afterClosed().subscribe(
      (dialogResponse: any) => {
        if (dialogResponse.value === 'confirm') {
          const payload = {
            ids: [element.id],
            status: status,
            reviewed_by: this.loggedInUser.id
          }
          this.service.reviewOrder(payload).subscribe((res: any) => {
            if (res.status === true) {
              this.getOffers();
              this.notificationService.success('Success', status + ' Successfully');
            }
          }, error => {
            this.notificationService.error('Error', error.error.message);
          })
        }
      }
    )
  }

  changeStatus(event) {
    let showOrder = []
    if (event.value === 'ALL') {
      showOrder = this.allOffers;
    } else {
      showOrder = this.allOffers.filter((itm) => itm.status === event.value);
    }
    this.dataSource.data = showOrder;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.statusSelected = event.value;
  }

  getplantCode() {
    this.plantCodeList = [];
    this.service.getAllPlantCode().subscribe((res: any) => {
      if (res.status) {
        this.plantCodeList = res.result;
      }
    }, error => {
      this.notificationService.error('Error', error.error.message);
    })
  }

  changePlaneCode(code, element) {
    console.log('code, elemt------------->', code, element)
    const payload = {
      plant_master_id: code
    }
    this.service.updateOrderPlantCode(payload, element.id).subscribe((res: any) => {
      if (res.status === true) {
        this.getOffers();
      }
    }, err => {
      this.notificationService.error('Error', err?.error?.message);
    })

  }
}
