<div class="container-fluid">
  
  <div class="pageHeader">
    Agmarknet Data
  </div>
  <div class="dateRangeBlock" style="padding: 20px 0px 0px;">
    <div class="col-md-6">
      <div class="input-wrapper">
        <input type="text" class="daterangeInput input-field" name="daterangeInput" daterangepicker [options]="options"
          (selected)="selectedDate($event, daterange)" /> &nbsp;
        <fa-icon [icon]="['fas', 'calendar']" class="icon-favIcon primColor"></fa-icon>
      </div>
    </div>
    <div class="col-md-6 buttonAlignRight">
      <div class="mr-10 sampleFileWrapper" *ngIf="loggedInUser.role == 'UBP'">
        <a class="sampleFile" href="assets/template/Agmark sample data.xlsx">
          <fa-icon [icon]="['fas', 'download']"></fa-icon> Sample Excel
        </a>
      </div>
      <div *ngIf="loggedInUser.role == 'UBP'">
        <label (change)="onFileChange($event)" for="file-upload" class="custom-file-upload">
          Upload File
        </label>
        <input (change)="onFileChange($event)" #uploadExcel style="display:none" id="file-upload" type="file" />
      </div>
    </div>
  </div>

  <div class="tabsBlock">
    <div class="tabContentBlock">
      <div class="row">
        <div class="col-md-6 pl-0">
          <mat-form-field class="cusWidth">
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
            <button mat-icon-button matPrefix>
              <mat-icon class="primColor">search</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="col-md-6 buttonAlignRight tabBtnAlign">
          <button class="mat-raised-button mat-primary exportBtn" (click)="exportAsXLSX()">
            <fa-icon [icon]="['fas', 'download']"></fa-icon> Export Excel
          </button>
        </div>
      </div>
      <div class="row" style="padding: 20px 0px 0px;">
        <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

        <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
          <ng-container matColumnDef="market_place_name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Market Place Name</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.market_center_name}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="state">
            <mat-header-cell *matHeaderCellDef mat-sort-header>State</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.state_name}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="district">
            <mat-header-cell *matHeaderCellDef mat-sort-header>District</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.district_name}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="commodity">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Commodity</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.commodity}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="variety">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Variety</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.variety}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="grade">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Grade</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.grade}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="min">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Minimum</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.ag_min}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="max">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Maximum</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.ag_max}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="modal">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Modal</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.modal}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.date_arrival}} </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="dataSource.data.length > 50 ? [10, 20, 50, dataSource.data.length] : [10, 20, 50]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </div>
  <simple-notifications [options]="notificationOptions"></simple-notifications>
</div>