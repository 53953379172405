import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { ExcelService } from '../services/excel.service';
import { MenuService } from '../services/menu.service';
import { UbService } from '../services/ub.service';
import { StatesMasterDialogComponent } from '../widgets/states-master-dialog/states-master-dialog.component';

@Component({
  selector: 'app-states-master',
  templateUrl: './states-master.component.html',
  styleUrls: ['./states-master.component.scss']
})
export class StatesMasterComponent implements OnInit {
  loggedInUser;
  isActive = [
    { value: '1', name: 'Active' },
    { value: '0', name: 'InActive' }
  ];
  public notificationOptions: any = {
    position: ['bottom', 'right'],
    timeOut: 5000,
    lastOnBottom: true,
    pauseOnHover: true,
    clickToClose: true,
    animate: 'fromRight'
  };
  loading = false;
  displayedColumns_data = ['state_name', 'sort_order', 'short_name', 'status', 'action'];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private service: UbService,
    private _service: NotificationsService,
    private excelService: ExcelService,
    private dialog: MatDialog,
    private menuService: MenuService,
    private router: Router) {
  }

  ngOnInit(): void {
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    this.getTableData();
  }
  applyFilter(filterValue) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  isDataActive() {
    for (const data of this.dataSource.data) {
      if (data.status == '1') {
        data.status = 'Active';
      }
      else {
        data.status = 'Inactive';
      }
    }
  }

  getTableData() {
    this.loading = true;
    this.service.getStates().subscribe((response: any) => {
      if (response['status']) {
        this.dataSource.data = response.result;
        this.isDataActive();
        
      }
      this.dataSource.filterPredicate = (data: any, filter: string) => {
        return data.state_name?.toLocaleLowerCase().includes(filter) ||
          data.short_name?.toLocaleLowerCase().includes(filter) ||
          data.sort_order?.toString().toLocaleLowerCase().includes(filter) ||
          data.status?.toLocaleLowerCase().includes(filter);
      };
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loading = false;
    }, err => {
      this._service.error('Error', err?.error?.message);
      this.loading = false;
    });
  }

  exportAsXLSX(): void {
    const exportData = this.dataSource.data.map((val) => ({
      id: val.id,
      "State Name": val.state_name,
      "Sort Order": val.sort_order,
      "Short Name": val.short_name,
      "Status": val.status,
    }));
    this.excelService.exportAsExcelFile(exportData, 'State-Master-Data');
  }

  addState() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Add State',
      height: '800px',
      width: '300px',
      panelClass: 'addnew-custom-dialog',
      type: 'add'
    };
    const dialogRef = this.dialog.open(StatesMasterDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.ngOnInit();
    })
  }

  editState(data) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Edit State',
      height: '800px',
      width: '300px',
      panelClass: 'addnew-custom-dialog',
      type: 'edit',
      state_id: data.id
    };
    const dialogRef = this.dialog.open(StatesMasterDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.ngOnInit();
    })
  }

}
