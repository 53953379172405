import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { ExcelService } from '../../services/excel.service';
import { MenuService } from '../../services/menu.service';
import { UbService } from '../../services/ub.service';
import { ReportType, Period } from '../../models/schema'
import * as moment from 'moment';

enum UserRole {
  Facilitator = 'Facilitator',
  UBP = 'UBP'
}

@Component({
  selector: 'app-mis-report',
  templateUrl: './mis-report.component.html',
  styleUrls: ['./mis-report.component.scss']
})
export class MisReportComponent implements OnInit {
  documentDate = moment(new Date()).format('YYYY-MM-DD');
  loggedInUser: any;

  public notificationOptions: any = {
    position: ['bottom', 'right'],
    timeOut: 5000,
    lastOnBottom: true,
    pauseOnHover: true,
    clickToClose: true,
    animate: 'fromRight'
  };
  displayedColumns_data = ['item', 'Today', 'PrvDay', 'CurWeek', 'PrvWeek', 'MTD', 'PrvMonth', 'YTD', 'PrvYear'];

  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private service: UbService,
    private _service: NotificationsService,
    private excelService: ExcelService,
    private menuService: MenuService,
    private router: Router
  ) { }

  applyFilter(filterValue) {
  }
  ngOnInit(): void {
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    this.getTableData(this.loggedInUser.id);
  }

  getTableData(id) {

    this.service.getMIS().subscribe((response: any) => {
      if (response['status']) {
        const data = response.otherRowResult;
        const tableRows = [{ title: 'Arrival Min Price', type: ReportType.ArrivalMinPrice },
        { title: 'Arrival Max Price', type: ReportType.ArrivalMaxPrice },
        { title: 'Suggested Price', type: ReportType.SuggestedPrice },
        { title: 'Purchase Price - Base', type: ReportType.PurchaseBasePrice },
        { title: 'Purchase Price - FOR', type: ReportType.PurchaseFORPrice },
        { title: 'Purchase Quantity (Quintal)', type: ReportType.PurchaseQuantity },
        ];

        console.log('data----------->', data)

        for (let i = 0; i < tableRows.length; i++) {
          const row = tableRows[i];
          console.log('row----------->', row)
          this.dataSource.data.push({
            item: row.title,
            Today: this.getData(data, row.type, Period.Today),
            PrvDay: this.getData(data, row.type, Period.PrvDay),
            CurWeek: this.getData(data, row.type, Period.CurWeek),
            PrvWeek: this.getData(data, row.type, Period.PrvWeek),
            MTD: this.getData(data, row.type, Period.MTD),
            PrvMonth: this.getData(data, row.type, Period.PrvMonth),
            YTD: this.getData(data, row.type, Period.YTD),
            PrvYear: this.getData(data, row.type, Period.PrvYear),
          })
        }
        console.log(this.dataSource.data);
      }
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, err => { });
  }

  getData(data, reportType, period) {
    const result = data.find(val => val.type == reportType && val.period == period);
    if (result) {
      return result.sum_total_amount
    }

    return 0
  }

  exportAsXLSX(): void {
    const exportData = this.dataSource.data.map((val) => ({
      "":val.item,
      "Today":val.Today,
      "Prv Day":val.PrvDay,
      "Current week":val.CurWeek,
      "Prv week":val.PrvWeek,
      "MTD":val.MTD,
      "Prev Month":val.PrvMonth,
      "YTD":val.YTD,
      "Prev Year":val.PrvYear,
    }));
    this.excelService.exportAsExcelFile(exportData, 'MIS-report-Data');
  }

}
