<div class="container-fluid">
    <div class="tabContentBlock">
        <div class="row pageHeader">
            Custer Freight Details
        </div>

        <div class="row">
            <div class="col-md-6 pl-0">
                <mat-form-field class="cusWidth">
                    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
                    <button mat-icon-button matPrefix>
                        <mat-icon class="primColor">search</mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <div class="col-md-6 buttonAlignRight tabBtnAlign">
                <button class="mat-raised-button mat-button-color mr-10 exportBtn" (click)="exportAsXLSX()"> 
                    <fa-icon [icon]="['fas', 'download']" style="margin-right: 10px;"></fa-icon>Export Excel</button>
                <button class="mat-raised-button mat-primary exportBtn" (click)="addClusterFreight()"> Add
                    Record</button>
            </div>
        </div>
        <div class="row" style="padding: 20px 0px 0px;">
            <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
            <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
                <ng-container matColumnDef="cluster_name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Cluster Name </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.cluster_name}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="delivery_name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Delivery Name </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.delivery_name}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="gid">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Group Id </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.gid}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="freight_value">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Freight Value </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.freight_value}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="valid_from">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Valid From </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.valid_from}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="valid_to">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Valid To </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.valid_to}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="action">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Action</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="actionRowBlock">
                            <a class="mr-10" (click)="editClusterFreight(element)">
                                Edit
                            </a>
                        </div>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayColumns;"></mat-row>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No Data Found </td>
                </tr>
            </mat-table>
            <mat-paginator [pageSizeOptions]="[ 10, 20, 50]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
    <simple-notifications [options]="notificationOptions"></simple-notifications>
</div>