import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MenuService } from '../services/menu.service';
import { UbService } from '../services/ub.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogComponent } from '../widgets/dialog/dialog.component';
import { ExcelService } from '../services/excel.service';
import { Daterangepicker } from 'ng2-daterangepicker';
import { NotificationsService } from 'angular2-notifications';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { StorageInterestMasterDialogComponent } from '../widgets/storage-interest-master-dialog/storage-interest-master-dialog.component';


@Component({
  selector: 'app-storage-interest-master',
  templateUrl: './storage-interest-master.component.html',
  styleUrls: ['./storage-interest-master.component.scss']
})
export class StorageInterestMasterComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  permission: any;
  loading = false;
  documentDate = moment(new Date()).format('YYYY-MM-DD');
  loggedInUser: any;
  public notificationOptions: any = {
    position: ['bottom', 'right'],
    timeOut: 5000,
    lastOnBottom: true,
    pauseOnHover: true,
    clickToClose: true,
    animate: 'fromRight'
  };
  dataSource = new MatTableDataSource<any>();
  displayedRowColumns: any;

  constructor(
    public menuService: MenuService,
    private router: Router,
    private service: UbService,
    private dialog: MatDialog,
    private excelService: ExcelService,
    private _service: NotificationsService
  ) {
    let Season = 'Season';
    let OffSeason = 'OffSeason';
  }

  applyFilter(filterValue) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnInit(): void {
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    const menu = this.menuService.getMenu(this.router.url);
    this.permission = menu.actions;
    this.displayedRowColumns = ['type', 'month', 'year', 'cost', 'rate', 'season', 'action'];
    this.getStorageIntrest();
  }

  getStorageIntrest() {
    this.loading = true;
    this.service.getAllStorageInterest().subscribe((response: any) => {
      this.dataSource.data = response.result;
      this.dataSource.filterPredicate = (data: any, filter: string) => {
        if(data.season == true) {
          data['season_type'] = 'Season';
        } else {
          data['season_type'] = 'OffSeason';
        }
        return  data.type?.toLocaleLowerCase().includes(filter) ||
          data.month?.toString().includes(filter) ||
          data.year?.toString().includes(filter) ||
          data.cost?.toString().includes(filter) ||
          data.rate?.toString().includes(filter) ||
          data.season_type?.toLocaleLowerCase().includes(filter)
      };
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loading = false;
    }, error => {
      this._service.error('Error', error.error.message);
      this.loading = false;
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  addStorageInterest() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Add Storage Interest',
      height: '800px',
      width: '300px',
      panelClass: 'addnew-custom-dialog',
      type: 'add'
    };
    const dialogRef = this.dialog.open(StorageInterestMasterDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.getStorageIntrest();
    });
  }

  editStorage(rowData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Update Storage Interest',
      height: '800px',
      width: '300px',
      panelClass: 'addnew-custom-dialog',
      type: 'edit',
      storageInterestData: rowData
    };
    const dialogRef = this.dialog.open(StorageInterestMasterDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.getStorageIntrest();
    });
  }

  exportAsXLSX(): void {
    const exportData = this.dataSource.data.map((val) => (
      {
        'Type': val.type,
        'Month': val.month,
        'Year' : val.year,
        'Cost' : val.cost,
        'Rate' : val.rate,
        'Season': (val.season === true) ? 'Season' : 'OffSeason',
      }
    ));
    this.excelService.exportAsExcelFile(exportData, 'storage-interest-master');
  }

}
