import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MenuService } from '../services/menu.service';
import { UbService } from '../services/ub.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ExcelService } from '../services/excel.service';
import { NotificationsService } from 'angular2-notifications';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import * as moment from 'moment';
import { MandiUserMappingMasterDialogComponent } from '../widgets/mandi-user-mapping-master-dialog/mandi-user-mapping-master-dialog.component';

@Component({
  selector: 'app-mandi-user-mapping-master',
  templateUrl: './mandi-user-mapping-master.component.html',
  styleUrls: ['./mandi-user-mapping-master.component.scss']
})
export class MandiUserMappingMasterComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  permission: any;
  documentDate = moment(new Date()).format('YYYY-MM-DD');
  loggedInUser: any;
  public notificationOptions: any = {
    position: ['bottom', 'right'],
    timeOut: 5000,
    lastOnBottom: true,
    pauseOnHover: true,
    clickToClose: true,
    animate: 'fromRight'
  };
  dataSource = new MatTableDataSource<any>();
  displayedRowColumns: any;
  loading = false;

  constructor(
    public menuService: MenuService,
    private router: Router,
    private service: UbService,
    private dialog: MatDialog,
    private excelService: ExcelService,
    private _service: NotificationsService
  ) {
  }

  applyFilter(filterValue) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnInit(): void {
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    const menu = this.menuService.getMenu(this.router.url);
    this.permission = menu.actions;
    this.displayedRowColumns = ['mandi_name', 'user_name', 'status', 'action'];
    this.getAllMandiUser();
  }

  getAllMandiUser() {
    this.loading = true;
    this.service.getAllMandiUser().subscribe((response: any) => {
      this.dataSource.data = response.result;
      this.dataSource.filterPredicate = (data: any, filter: string) => {
        if(data.active == '1') {
          data['status'] == 'active'
        } else {
          data['status'] == 'inactive'
        }
        return data.mandiObj?.mandi_name?.toLocaleLowerCase().includes(filter) ||
          data.userObj?.first_name?.toLocaleLowerCase().includes(filter) ||
          data.userObj?.last_name?.toLocaleLowerCase().includes(filter) ||
          data.status?.toLocaleLowerCase().includes(filter)
      };
      this.dataSource.paginator = this.paginator;
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch(property) {
          case 'user_name': return item.userObj?.first_name;
          case 'status': return item.status;
          default: return item[property];
        }
      }
      this.dataSource.sort = this.sort;
      this.loading = false;
      for (const data of this.dataSource.data) {
        data.mandi_name = data.mandiObj.mandi_name
        data.user_name = data.mandiObj.first_name,
        data.status = data.active == '1' ? 'Active' : 'Inactive'
      }
    }, error => {
      this._service.error('Error', error.error.message);
      this.loading = false;
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  checkPermission(access: string): boolean {
    return this.permission.includes(access);
  }

  addMandiUser() {
    console.log('Add Mandi');
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Add Mandi User mapping',
      height: '800px',
      width: '300px',
      panelClass: 'addnew-custom-dialog',
      type: 'add'
    };
    const dialogRef = this.dialog.open(MandiUserMappingMasterDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.getAllMandiUser();
    });
  }

  editMandiUser(rowData) {
    console.log(rowData);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Edit Mandi User mapping',
      height: '800px',
      width: '300px',
      panelClass: 'addnew-custom-dialog',
      type: 'edit',
      mandiRowData: rowData
    };
    const dialogRef = this.dialog.open(MandiUserMappingMasterDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.getAllMandiUser();
    });
  }

  exportAsXLSX(): void {
    const exportData = this.dataSource.data.map((val) => (
      {
        'User Name': val.userObj.first_name + ' ' + val.userObj.last_name ,
        'Mandi Name': val.mandiObj.mandi_name,
        'Status': (val.active == 1) ? 'Active' : 'InActive'
      }
    ));
    this.excelService.exportAsExcelFile(exportData, 'user-mandi-master');
  }
}
