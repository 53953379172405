import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuService } from '../services/menu.service';
import { UbService } from '../services/ub.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ExcelService } from '../services/excel.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PremiumMasterDialogComponent } from '../widgets/premium-master-dialog/premium-master-dialog.component';
import { NotificationsService } from 'angular2-notifications';
@Component({
  selector: 'app-premium-master',
  templateUrl: './premium-master.component.html',
  styleUrls: ['./premium-master.component.scss']
})
export class PremiumMasterComponent implements OnInit {
  loggedInUser;
  public notificationOptions: any = {
    position: ['bottom', 'right'],
    timeOut: 5000,
    lastOnBottom: true,
    pauseOnHover: true,
    clickToClose: true,
    animate: 'fromRight'
  };
  permission;
  loading = false;
  displayedColumns_data=['product_name','state_name','premium','year','month','action'];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(public menuService: MenuService,
    private service: UbService,
    private _service: NotificationsService,
    private excelService: ExcelService,
    private router: Router,
    public dialog: MatDialog) { 
    }

  ngOnInit(): void {    
    const menu = this.menuService.getMenu(this.router.url);
    this.permission = menu.actions;
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    this.getTableData();
  }

  checkPermission(access: string): boolean {
    return this.permission.includes(access);
  }

  addPremium() {
    const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
     dialogConfig.data = {
        title: 'Add Premium',
        height: '800px',
        width: '300px',
        panelClass: 'addnew-custom-dialog',
        type : 'add'
      };
      const dialogRef = this.dialog.open(PremiumMasterDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(data => {
        this.ngOnInit();
      })
    }

    editPremium(data) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
        title: 'Update Premium',
       height: '800px',
        width: '300px',
       panelClass: 'addnew-custom-dialog',
        type : 'edit',
        premium_id : data.id
      };
      const dialogRef = this.dialog.open(PremiumMasterDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(data => {
        this.ngOnInit();
      })
    }
  getTableData(){
    this.loading = true;
    this.service.getPremium().subscribe((response: any) => {
      if (response['status']) {
        this.dataSource.data = response.result;       
      }
      this.dataSource.filterPredicate = (data: any, filter: string) => {
        return data.productObj?.name?.toString().includes(filter) ||
          data.stateObj?.state_name?.toLocaleLowerCase().includes(filter) ||
          data.premium?.toString().toLocaleLowerCase().includes(filter) ||
          data.year?.toString().toLocaleLowerCase().includes(filter) ||
          data.month?.toString().toLocaleLowerCase().includes(filter)
      };
      this.dataSource.paginator = this.paginator;
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch(property) {
          case 'product_name': return item.productObj?.name;
          case 'state_name': return item.stateObj?.state_name;
          default: return item[property];
        }
      }
      this.dataSource.sort = this.sort;
      this.loading = false;
    }, err => {
      this.loading = false;
      this._service.error('Error', err?.error?.message);
    });
  }

  applyFilter(filterValue) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  exportAsXLSX(): void {
    const exportData = this.dataSource.data.map((val) => ({
      'Id': val.id,
      "Product Name": val.productObj?.name,
      "State":val.stateObj?.state_name,
      "Premium": val.premium,
      "Year": val.year,
      "Month":val.month,
    }));    
    this.excelService.exportAsExcelFile(exportData, 'Premium-Master-Data');
  }


}
