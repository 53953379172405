<h2 class="dialog-heading" *ngIf="title" mat-dialog-title>{{title}}</h2>
<mat-dialog-content style="width:500px">
    <form class="example-full-width" [formGroup]="locationForm">
        <mat-form-field class="example-full-width">
            <mat-label required>State</mat-label>
            <mat-select class="matSelect" formControlName="state" required>
                <mat-option *ngFor="let state of stateList" [value]="state.id">
                    {{state.state_name}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!locationForm.get('state').valid || locationForm.get('state').touched">Location ID is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="district" placeholder="district" matInput type='text' required>
            <mat-error>
                <span *ngIf="locationForm.get('district').hasError('required')">District Name is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="town_city" placeholder="City" matInput type='text' required>
            <mat-error>
                <span *ngIf="locationForm.get('town_city').hasError('required')">City Name is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="pincode" placeholder="Pin Code" matInput type='text' required>
            <mat-error>
                <span *ngIf="locationForm.get('pincode').hasError('required')">Pincode is required</span>
            </mat-error>
        </mat-form-field>
       
        <mat-form-field class="example-full-width">
            <mat-label required>Active</mat-label>
            <mat-select class="matSelect" formControlName="activity" required>
                <mat-option *ngFor="let stat of status" [value]="stat.id">
                    {{stat.name}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!locationForm.get('activity').valid || locationForm.get('activity').touched">Location ID is required</span>
            </mat-error>
        </mat-form-field>       
    </form>
    <mat-dialog-actions>
        <button class="mat-raised-button mr-10" (click)="close()">Close</button>
        <button class="mat-raised-button mat-primary" (click)="saveLocations()">Save</button>
    </mat-dialog-actions>
</mat-dialog-content>