import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationsService } from 'angular2-notifications';
import { UbService } from 'src/app/services/ub.service';

@Component({
  selector: 'app-delivery-master-dialog',
  templateUrl: './delivery-master-dialog.component.html',
  styleUrls: ['./delivery-master-dialog.component.scss']
})
export class DeliveryMasterDialogComponent implements OnInit {

  dialogData: any;
  title: string;
  deliveryForm: FormGroup;
  deliveryData: any;
  deliveryType: any;
  loggedInUser: any;
  stateList: any;
  type: any;
  states:any;

  constructor(
    private service: UbService,
    private fb: FormBuilder,
    private notificationService: NotificationsService,
    private dialogRef: MatDialogRef<DeliveryMasterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.title = data.title;
    this.deliveryData = data.deliveryRowData;
    this.type = data.type;
    this.deliveryType = ['2 row', '6 row'];
  }

  ngOnInit(): void {
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    this.deliveryForm = this.fb.group({
      delivery_type: ['', [Validators.required]],
      delivery_name: ['', [Validators.required]],
      short_name: ['', [Validators.required]],
      address_1: ['', [Validators.required]],
      pincode: ['', [Validators.required]],
      city: ['', [Validators.required]],
      district: ['', [Validators.required]],
      address_2: [''],
      state: ['', [Validators.required]],
      mobile_1: ['' , [Validators.pattern('[0-9]{10}')]],
      mobile_2: ['' , [Validators.pattern('[0-9]{10}')]],
      email_1: ['',[ Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      email_2: ['',[ Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      gstin: [''],
    });

    if (this.deliveryData) {
      this.deliveryForm.controls['delivery_type'].setValue(this.deliveryData.delivery_type);
      this.deliveryForm.controls['delivery_name'].setValue(this.deliveryData.delivery_name);
      this.deliveryForm.controls['short_name'].setValue(this.deliveryData.short_name);
      this.deliveryForm.controls['address_1'].setValue(this.deliveryData.address_1);
      this.deliveryForm.controls['pincode'].setValue(this.deliveryData.pincode);
      this.deliveryForm.controls['city'].setValue(this.deliveryData.city);
      this.deliveryForm.controls['address_2'].setValue(this.deliveryData.address_2);
      this.deliveryForm.controls['district'].setValue(this.deliveryData.district);
      this.deliveryForm.controls['state'].setValue(this.deliveryData.state);
      this.deliveryForm.controls['mobile_1'].setValue(this.deliveryData.mobile_1);
      this.deliveryForm.controls['mobile_2'].setValue(this.deliveryData.mobile_2);
      this.deliveryForm.controls['email_1'].setValue(this.deliveryData.email_1);
      this.deliveryForm.controls['email_2'].setValue(this.deliveryData.email_2);
      this.deliveryForm.controls['gstin'].setValue(this.deliveryData.gstin);
      console.log(this.deliveryData);
    }
    this.getStates();
  }

  close() {
    this.dialogRef.close();
  }

  getStates() {
    this.states = [];
    this.service.getState().subscribe((res) => {
      if (res['status']) {
        this.states = res['result'];
      }
    }, err => {});
  }
  saveDelivery() {
    for (const c in this.deliveryForm.controls) {
      if (this.deliveryForm.controls.hasOwnProperty(c)) {
        this.deliveryForm.controls[c].markAsTouched();
      }
    }
    if (this.deliveryForm.valid) {
      const payload = {
        delivery_type: this.deliveryForm.value.delivery_type,
        delivery_name: this.deliveryForm.value.delivery_name,
        short_name: this.deliveryForm.value.short_name,
        address_1: this.deliveryForm.value.address_1,
        pincode: this.deliveryForm.value.pincode,
        city: this.deliveryForm.value.city,
        address_2: this.deliveryForm.value.address_2,
        state: this.deliveryForm.value.state,
        district: this.deliveryForm.value.district,
        email_1: this.deliveryForm.value.email_1,
        email_2: this.deliveryForm.value.email_2,
        mobile_1: this.deliveryForm.value.mobile_1,
        mobile_2: this.deliveryForm.value.mobile_2,
        gstin: this.deliveryForm.value.gstin
      };
      if (this.type === 'add') {
        this.service.createDelivery(payload).subscribe((res) => {
          if (res['status']) {
            this.notificationService.success('Success', res['message']);
            this.dialogRef.close();
          }
        }, error => {
          this.notificationService.error('Error', error.error.message);
        });
      } else if (this.type === 'edit') {

        this.service.updateDelivery(this.deliveryData.id, payload).subscribe((res) => {
          if (res['status']) {
            this.notificationService.success('Success', res['message']);
            this.dialogRef.close();
          }
        }, error => {
          this.notificationService.error('Error', error.error.message);
        });

      }
    }
  }

}
