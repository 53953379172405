<div class="container-fluid">

    <div class="report-header">
        <div class="row pageHeader">
            Arrival Report
        </div>
    </div>

    <div class="report-body">
        <form [formGroup]="filterForm">
            <div class="row">
                <div class="col-md-12">
                    <p class="body-text">Fill Mandatory Details to execute report</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Select State</mat-label>
                        <mat-select #matRefState formControlName="state" (openedChange)="stateChange($event)" multiple required>

                            <mat-option (selectionChange)="stateSelChange($event)" value="All">All</mat-option>
                            <mat-option (selectionChange)="stateSelChange($event)" *ngFor="let state of stateList"
                                [value]="state.state_id">{{state.state }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="filterForm.get('state').hasError('required')"> State Value Required </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-md-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Discrict</mat-label>
                        <mat-select #matRefDistrict formControlName="district" (openedChange)="districtChange($event)"
                            multiple required>

                            <mat-option (selectionChange)="districtSelChange($event)" value="All">All</mat-option>
                            <mat-option (selectionChange)="districtSelChange($event)"
                                *ngFor="let district of districtList" [value]="district.d">{{district.district}}
                                - {{district.state}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="filterForm.get('district').hasError('required')"> District Value Required </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-md-3">
                    <mat-form-field appearance="fill">
                        <mat-label>City</mat-label>
                        <mat-select #matRefCity formControlName="city" (openedChange)="cityChange($event)" multiple required>
                            <mat-option (selectionChange)="citySelChange($event)" value="All">All</mat-option>
                            <mat-option (selectionChange)="citySelChange($event)" *ngFor="let city of cityList"
                                [value]="city.id">{{city.town_city}} - {{city.district}} - {{city.state}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="filterForm.get('city').hasError('required')"> City Value Required </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-md-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Mandi</mat-label>
                        <mat-select #matRefMandi formControlName="mandi" (openedChange)="mandiChange($event)" multiple required>

                            <mat-option (selectionChange)="mandiSelChange($event)" value="All">All</mat-option>
                            <mat-option (selectionChange)="mandiSelChange($event)" *ngFor="let mandi of mandiList"
                                [value]="mandi.id">{{mandi.mandi_name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="filterForm.get('mandi').hasError('required')"> Mandi Value Required </mat-error>
                    </mat-form-field>
                </div>

            </div>

            <div class="row">
                <div class="col-md-12">
                    <p class="body-text">Fill Arrival details</p>
                </div>
            </div>

            <div class="row">

                <div class="col-md-3">
                    <mat-form-field>
                        <input formControlName="min_price_from" placeholder="Min Price from" matInput type='number'>
                    </mat-form-field>
                </div>

                <div class="col-md-1 body-to-text">
                    To
                </div>

                <div class="col-md-3">
                    <mat-form-field>
                        <input formControlName="min_price_to" placeholder="Min Price to" matInput type='number'>
                    </mat-form-field>
                </div>
                <div class="col-md-1">

                </div>
                <div class="col-md-4">
                    <mat-form-field>
                        <mat-label required>Status</mat-label>
                        <mat-select class="matSelect" formControlName="status">
                            <mat-option *ngFor="let status of statusList" [value]="status.value">
                                {{status.title}}
                            </mat-option>
                        </mat-select>

                    </mat-form-field>
                </div>

            </div>

            <div class="row">
                <div class="col-md-3">
                    <mat-form-field>
                        <input formControlName="max_price_from" placeholder="Max Price from" matInput type='number'>
                    </mat-form-field>
                </div>

                <div class="col-md-1 body-to-text">
                    To
                </div>

                <div class="col-md-3">
                    <mat-form-field>
                        <input formControlName="max_price_to" placeholder="Max Price to" matInput type='number'>
                    </mat-form-field>
                </div>

                <div class="col-md-1">

                </div>

                <div class="col-md-4">
                    <div class="input-wrapper">
                        <input type="text" class="daterangeInput input-field" formControlName="document_date" name="daterangeInput" daterangepicker
                            [options]="options" (selected)="selectedDate($event, daterange)" /> &nbsp;
                        <fa-icon [icon]="['fas', 'calendar']" class="icon-favIcon primColor"></fa-icon>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-3">

                </div>
                <div class="col-md-3">

                </div>
             
                <div class="col-md-2">
                    <button class="mat-raised-button mat-primary mr-10 ml-10" (click)="execute()">
                        Execute
                    </button>
                </div>

                <div class="col-md-2">
                    <button class="mat-raised-button mat-primary mr-10 ml-10" (click)="reset()">
                        Reset
                    </button>
                </div>
                <div class="col-md-2">
                    <button [disabled] = "!dataSource.data[0]" class="mat-raised-button mat-primary exportBtn" (click)="exportAsXLSX()">
                        <fa-icon [icon]="['fas', 'download']"></fa-icon> Export Excel
                      </button>
                </div>
            </div>
        </form>


        <div class="row" style="margin-top: 20px;">
            <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

            <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
                <ng-container matColumnDef="state">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> State</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.mandiObj.state}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="district">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> District </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.mandiObj.district}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="city">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> City </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.mandiObj.city}}</mat-cell>
                </ng-container>


                <ng-container matColumnDef="mandi_name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Mandi Name </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.mandiObj.mandi_name}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="document_date">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Date </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.document_date}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="min_price">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Minimum Price <br> (&#x20b9;) </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.min_price}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="max_price">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Maximum Price <br> (&#x20b9;)</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.max_price}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="quantity">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Quantity <br> (In Quintal) </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.quantity}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="is_submitted">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <span class="submittedRow" *ngIf="element.is_submitted == true">Submitted</span>
                        <span class="notSubmittedRow" *ngIf="element.is_submitted == false">NotSubmitted</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="created_by">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Created By</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.createdByObj?.first_name}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="created_on">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Created On </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.createdAt | date: 'dd/MM/yyyy h:mm:ss'}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="updated_by">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Updated By</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.updated_by}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="updated_on">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Updated On </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.updatedAt | date: 'dd/MM/yyyy h:mm:ss'}} </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No Data Found on {{documentDate}}</td>
                </tr>
            </mat-table>
            <mat-paginator [pageSizeOptions]="dataSource.data.length > 50 ? [10, 20, 50, dataSource.data.length] : [10, 20, 50]" showFirstLastButtons></mat-paginator>

        </div>
    </div>
</div>