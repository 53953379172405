<div class="container-fluid">
  <div class="pageHeader">
    Arrival Data
  </div>
  <div class="dateRangeBlock" style="padding: 20px 0px 0px;">
    <div class="col-md-6">
      <div class="input-wrapper">
        <input type="text" class="daterangeInput input-field" name="daterangeInput" daterangepicker [options]="options"
          (selected)="selectedDate($event, daterange)" /> &nbsp;
        <fa-icon [icon]="['fas', 'calendar']" class="icon-favIcon primColor"></fa-icon>
      </div>
    </div>
    <div class="col-md-6 buttonAlignRight" *ngIf="isFrozen && checkPermission('create')">
      <button class="mat-raised-button mat-primary mr-10 ml-10" style="background-color: red;border: none;">Ceiling
        Price is Frozen</button>
    </div>
    <div class="col-md-6 buttonAlignRight" *ngIf="!isFrozen">
      <div class="mr-10 sampleFileWrapper"
        *ngIf="checkPermission(ArrivalAction.create_2_row)  && activeLink == barleyTabText.Barley_2_Row">
        <a class="sampleFile" href="assets/template/2RowArrivalData.xlsx">
          <fa-icon [icon]="['fas', 'download']"></fa-icon> Sample Excel
        </a>
      </div>
      <div class="mr-10 sampleFileWrapper"
        *ngIf="checkPermission(ArrivalAction.create_6_row) && activeLink == barleyTabText.Barley_6_Row">
        <a class="sampleFile" href="assets/template/6RowArrivalData.xlsx">
          <fa-icon [icon]="['fas', 'download']"></fa-icon> Sample Excel
        </a>
      </div>
      <div *ngIf="checkPermission(ArrivalAction.create_6_row) && activeLink == barleyTabText.Barley_6_Row">
        <label (change)="onFileChange($event)" for="file-upload" class="custom-file-upload">
          Upload File
        </label>
        <input (change)="onFileChange($event)" #uploadExcel style="display:none" id="file-upload" type="file" />
      </div>

      <div *ngIf="checkPermission(ArrivalAction.create_2_row) && activeLink == barleyTabText.Barley_2_Row">
        <label (change)="onFileChange($event)" for="file-upload" class="custom-file-upload">
          Upload File
        </label>
        <input (change)="onFileChange($event)" #uploadExcel style="display:none" id="file-upload" type="file" />
      </div>

      <div *ngIf="activeLink == barleyTabText.Barley_2_Row && checkPermission(ArrivalAction.create_2_row)">
        <button class="mat-raised-button mat-primary mr-10 ml-10" (click)="openDialog()">
          <fa-icon [icon]="['fas', 'plus']"></fa-icon> Add New
        </button>
      </div>

      <div *ngIf="activeLink == barleyTabText.Barley_6_Row && checkPermission(ArrivalAction.create_6_row) ">
        <button class="mat-raised-button mat-primary mr-10 ml-10" (click)="openDialog()">
          <fa-icon [icon]="['fas', 'plus']"></fa-icon> Add New
        </button>
      </div>

    </div>
    <div class="col-md-6 buttonAlignRight" *ngIf="isFrozen">
      <div >
        <button class="mat-raised-button mat-danger mr-10 ml-10"  style="background-color: red; border: none; color: white;" >
          Ceiling Price is frozen
        </button>
      </div>

    </div>
  </div>

  <div class="tabsBlock">
    <nav mat-tab-nav-bar>
      <a mat-tab-link *ngFor="let link of links" (click)="activeLink = link;getTableData(link);"
        [active]="activeLink == link"> {{link}}
      </a>
    </nav>
    <div *ngIf="activeLink == barleyTabText.Barley_6_Row" class="tabContentBlock">
      <div class="row">
        <div class="col-md-6 pl-0">
          <mat-form-field class="cusWidth">
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
            <button mat-icon-button matPrefix>
              <mat-icon class="primColor">search</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="col-md-6 buttonAlignRight tabBtnAlign">
          <button class="mat-raised-button mat-primary mr-10" (click)="submitAll()"
            *ngIf="checkPermission(ArrivalAction.submit_6_row) && !isFrozen">Submit All</button>

          <button class="mat-raised-button mat-primary exportBtn" (click)="exportAsXLSX()">
            <fa-icon [icon]="['fas', 'download']"></fa-icon> Export Excel
          </button>
        </div>
      </div>
      <div class="row" style="padding: 20px 0px 0px;">
        <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

        <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
          <ng-container matColumnDef="mandi_name">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Mandi Name </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.mandiObj.mandi_name}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="min_price">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Minimum Price <br> (&#x20b9;) </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.min_price}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="max_price">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Maximum Price <br> (&#x20b9;)</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.max_price}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="quantity">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Quantity <br> (In Quintal) </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.quantity}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="is_submitted">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Is Submitted </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="submittedRow" *ngIf="element.is_submitted == true">Submitted</span>
              <span class="notSubmittedRow" *ngIf="element.is_submitted == false">NotSubmitted</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="document_date">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Document Date </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.document_date}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="created_on">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Created On </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.document_date}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Actions</mat-header-cell>
            <mat-cell *matCellDef="let element" ><span class="action-btn" (click)="edit(element)"
                *ngIf="checkPermission(ArrivalAction.update_6_row) && !isFrozen && !element.is_ceiling_set && element.document_date == documentDate">Edit |</span> <span class="action-btn submitActionBtn"
                (click)="submit(element.id)" *ngIf="checkPermission(ArrivalAction.update_6_row) && element.is_submitted == false && !isFrozen">Submit</span></mat-cell>
            |
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayed6RowColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayed6RowColumns;"></mat-row>


          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No Data Found on {{documentDate}}</td>
          </tr>
        </mat-table>
        <mat-paginator [pageSizeOptions]="dataSource.data.length > 50 ? [10, 20, 50, dataSource.data.length] : [10, 20, 50]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
    <div *ngIf="activeLink == barleyTabText.Barley_2_Row" class="tabContentBlock">

      <div class="row">
        <div class="col-md-6 pl-0">
          <mat-form-field class="cusWidth">
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
            <button mat-icon-button matPrefix>
              <mat-icon class="primColor">search</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="col-md-6 buttonAlignRight tabBtnAlign">
          <button class="mat-raised-button mat-primary mr-10" (click)="submitAll()"
            *ngIf="checkPermission(ArrivalAction.submit_2_row) && !isFrozen">Submit All</button>

          <button class="mat-raised-button mat-primary exportBtn" (click)="exportAsXLSX()">
            <fa-icon [icon]="['fas', 'download']"></fa-icon> Export Excel
          </button>
        </div>
      </div>
      <div class="row" style="padding: 20px 0px 0px;">

        <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
          <ng-container matColumnDef="mandi_name">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Mandi Name </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.mandiObj.mandi_name}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="state">
            <mat-header-cell *matHeaderCellDef mat-sort-header>State </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.stateObj.state_name}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="one_price">
            <mat-header-cell *matHeaderCellDef mat-sort-header> One Price <br> (&#x20b9;) </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.one_price}} </mat-cell>
          </ng-container>

          <!-- <ng-container matColumnDef="base_price">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Base Price <br> (&#x20b9;)</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.avg_buying_price}} </mat-cell>
          </ng-container> -->

          <ng-container matColumnDef="quantity">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Quantity <br> (In Quintal) </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.quantity}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="is_submitted">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Is Submitted </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="submittedRow" *ngIf="element.is_submitted == true">Submitted</span>
              <span class="notSubmittedRow" *ngIf="element.is_submitted == false">NotSubmitted</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="document_date">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Document Date </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.document_date}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="created_on">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Created On </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.document_date}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Action</mat-header-cell>
            <mat-cell *matCellDef="let element"><span class="action-btn" (click)="edit(element)"
                *ngIf="checkPermission(ArrivalAction.update_2_row) && !isFrozen && !element.is_ceiling_set && element.document_date == documentDate">Edit |</span> <span class="action-btn submitActionBtn"
                (click)="submit2Row(element.id)" *ngIf="checkPermission(ArrivalAction.submit_2_row) && element.is_submitted == false && !isFrozen">Submit</span>
            </mat-cell> |
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayed2RowColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayed2RowColumns;"></mat-row>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No Data Found on ;</td>
          </tr>
        </mat-table>
        <mat-paginator [pageSizeOptions]="dataSource.data.length > 50 ? [10, 20, 50, dataSource.data.length] : [10, 20, 50]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </div>
  <simple-notifications [options]="notificationOptions"></simple-notifications>
</div>