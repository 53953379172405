import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { UbService } from '../services/ub.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MenuService } from '../services/menu.service';
import { Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { ExcelService } from '../services/excel.service';
import { ConfirmationDialogComponent } from '../widgets/confirmation-dialog/confirmation-dialog.component'
// import { DialogComponent } from '../widgets/dialog/dialog.component';
import { ManageOrderDialogComponent } from '../widgets/manage-order-dialog/manage-order-dialog.component';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { NotificationsService } from 'angular2-notifications';
import { UserRole, Products, OrderAction, orderTabText } from '../models/schema';

const initialSelection = [];
const allowMultiSelect = true;

@Component({
  selector: 'app-manage-order',
  templateUrl: './manage-order.component.html',
  styleUrls: ['./manage-order.component.scss']
})

export class ManageOrderComponent implements OnInit {
  OrderAction = OrderAction;
  orderTabText = orderTabText;
  selectedIndex;
  rowSelected: any;
  selectedStatus = new FormControl();
  avilableStatus = ['ALL', 'SUBMITTED', 'APPROVED', 'REJECTED']
  statusSelected = this.avilableStatus[0];
  documentDate = new Date().toISOString().slice(0, 10).replace('T', ' ');
  options: any = {
    locale: { format: 'D MMMM YYYY' },
    alwaysShowCalendars: false,
    maxDate: new Date(),
    startDate: moment().subtract(1, "days").format("D MMMM YYYY"),
    endDate: moment().format("D MMMM YYYY"),
  };
  plantCodeList = [];
  public notificationOptions: any = {
    position: ['bottom', 'right'],
    timeOut: 5000,
    lastOnBottom: true,
    pauseOnHover: true,
    clickToClose: true,
    animate: 'fromRight'
  };

  displayFacilitator: any;
  displayUbFlow: any;
  links = [];
  activeLink = '';
  startDate = moment().subtract(1, "days").format("YYYY-MM-DD");
  endDate = moment().format("YYYY-MM-DD");
  loading = false
  public daterange: any = {
    start: this.startDate,
    end: this.endDate,
  };

  displayedColumns = ['order_number', 'order_type', 'order_created', 'mandi_name', 'pakka_arthiya', 'reference_no', 'delivery_location', 'base_price', 'quantity', 'for_price', 'createdBy', 'plant_code', 'action'];
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(allowMultiSelect, initialSelection);
  availableProducts: any = [];
  orders: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  loggedInUser: any;
  permission: any;
  allOrders: any;
  allMandis: any;
  mandiList: any;
  productId = 1;
  showOrder: any;
  constructor(
    private service: UbService,
    private dialog: MatDialog,
    public menuService: MenuService,
    private router: Router,
    private excelService: ExcelService,
    private notificationService: NotificationsService
  ) {
    this.getplantCode();
    this.getAllMandi();
    this.getProduct();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.service.currentOrder.subscribe(status => {
      console.log('Current order status--->', status)
    });

    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);   // Get user from localhost to identify the role
    this.getUserMandi(this.loggedInUser.id) //Get Mandi data based on user id
    const menu = this.menuService.getMenu(this.router.url);
    this.permission = menu.actions;

    // Showing table data based on the user role
    if (this.loggedInUser.role === UserRole.Facilitator || this.loggedInUser.role === UserRole.PukkaArthiya) {
      // Facilitator role table
      this.displayFacilitator = ['select', 'order_number', 'order_type', 'order_created', 'mandi_name', 'pakka_arthiya', 'reference_no', 'delivery_location', 'base_price', 'quantity', 'for_price', 'createdBy', 'action'];
    } else {
      // UB Flow role table
      this.displayUbFlow = ['select', 'order_number', 'order_type', 'document_date', 'mandi_name', 'pakka_arthiya', 'reference_no', 'delivery_location', 'plant_code', 'base_price', 'quantity', 'for_price', 'previous_year_price', 'suggested_price', 'ceiling_price', 'createdBy', 'action'];
    }

    if (this.checkPermission(OrderAction.view_6_row)) {
      this.links.push(orderTabText.Barley_6_Row);
    }

    if (this.checkPermission(OrderAction.view_2_row)) {
      this.links.push(orderTabText.Barley_2_Row);
      this.activeLink = orderTabText.Barley_2_Row;
    }

    if (this.checkPermission(OrderAction.view_6_row)) {
      this.activeLink = orderTabText.Barley_6_Row;
    }

    // this.getProduct()
  }

  changeStatus(event) {
    if (event.value === 'ALL') {
      this.showOrder = this.allOrders.filter((item) => item.status !== 'SAVED');
    } else {
      this.showOrder = this.allOrders.filter((itm) => itm.status === event.value);
    }
    this.dataSource.data = this.showOrder;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.statusSelected = event.value
  }

  checkPermission(access: string): boolean {
    return this.permission.includes(access);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  submitOrder(item) {
  }


  getTableData(link) {
    this.dataSource = new MatTableDataSource([]);
    if (link === orderTabText.Barley_2_Row) {
      this.productId = 2;
      this.getAllOrders(2);
    } else if (link === orderTabText.Barley_6_Row) {
      this.productId = 1;
      this.getAllOrders(1);
    }
  }

  getProduct() {
    this.service.getProduct().subscribe(
      (productResponse: any) => {
        if (productResponse.status === true) {

          if (this.loggedInUser.role == UserRole.UBP) {
            this.availableProducts = productResponse.result;
          } else {

            if (this.checkPermission(OrderAction.create_6_row)) {
              this.availableProducts.push(productResponse.result[0]);
            }

            if (this.checkPermission(OrderAction.create_2_row)) {
              this.availableProducts.push(productResponse.result[1]);
            }
          }
        }
        else {
          // err
        }
      }
    )
  }

  /*
    Get All orders:
    Based on role - Facilitator,
    Based on Date range - Start date and End Date,
    Based on Product - 6 Row Barle and 2 Row Barley
  */
  getAllOrders(productId) {
    let filter = ''
    if (this.loggedInUser.role === UserRole.Facilitator || this.loggedInUser.role === UserRole.PukkaArthiya) {
      filter = `product_id=${productId}&from_date=${this.daterange.start}&to_date=${this.daterange.end}&type=Auction&created_by=${this.loggedInUser.id}`
    } else {
      filter = `product_id=${productId}&from_date=${this.daterange.start}&to_date=${this.daterange.end}&type=Auction`
    }
    this.loading = true;
    this.service.getOrders(filter).subscribe(
      (ordersResponse: any) => {
        this.selection.clear();
        if (ordersResponse.status === true) {
          this.allOrders = ordersResponse.result;

          if (this.loggedInUser.role === UserRole.Facilitator || this.loggedInUser.role === UserRole.PukkaArthiya) {
            this.showOrder = this.allOrders;
          } else {
            this.showOrder = this.allOrders.filter((item) => item.status !== 'SAVED');
          }

          if (this.statusSelected != 'ALL') {
            this.showOrder = this.showOrder.filter((itm) => itm.status === this.statusSelected);
          }

          this.dataSource.data = this.showOrder;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;

        } else {
          console.log('error')
        }
        this.loading = false;
      }, (err) => {
        this.loading = false;
        console.log(err);
      }
    )
  }


  // Fetch all Mandis
  getAllMandi() {
    this.service.getAllMandi().subscribe(
      (mandiResponse: any) => {
        if (mandiResponse.status === true) {
          this.allMandis = mandiResponse.result;
          this.getAllOrders(1);
        } else {
          console.log('error')
        }
      }, (err) => {
        console.log(err);
      }
    )
  }


  // Fetch Mandis based on user id
  getUserMandi(mandiId) {
    this.service.getUserMandi(mandiId).subscribe(
      (mandiResponse: any) => {
        if (mandiResponse.status === true) {
          this.mandiList = mandiResponse.result;
          return this.mandiList
        } else {
          console.log("error");
        }
      }, (err) => {
        console.log(err)
      });
  }


  // Open dialog from the Confirmation dialog component
  openDialogConfirmation(item, type) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      products: this.availableProducts,
      title: 'Confirmation',
      buttonText: {
        ok: 'Add',
        cancel: 'Cancel',
      },
      content: type,
      data: item,
      height: "600px",
      width: '300px',
      panelClass: 'addnew-custom-dialog'
    };
    const confirmationRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);

    confirmationRef.afterClosed().subscribe(
      (dialogResponse: any) => {
        if (dialogResponse.value === 'confirm') {
          let obj = {};
          let orderIds = []
          if (item === 'all') {
            for (const order of this.dataSource.data) {
              orderIds.push(order.id)
            }
          } else {
            orderIds.push(item.id)
          }
          obj = {
            ids: orderIds
          }
          this.service.submitOrder(obj).subscribe(
            (submitResonse: any) => {
              const notifiedRes = submitResonse.result.filter(val => val.status == false);
              if (notifiedRes.length == 0) {
                this.notificationService.success('Success', submitResonse.message)
              } else {
                for (let i = 0; i < notifiedRes.length; i++) {
                  this.notificationService.error('Error', notifiedRes[i].message);
                }
              }

              this.getAllOrders(this.productId);

            }, (err) => {
              this.notificationService.error('Error', err.message.message);
            }
          )
        }
      }
    )
  }
  // Open dialog from the Manage order dialog component
  manageOrderDialog(item, status?) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    if (item === 'add') {
      dialogConfig.data = {
        title: 'Add Order Details',
        buttonText: {
          ok: 'Add',
          cancel: 'Cancel',
        },
      }
    } else if (status) {
      if (status == 'APPROVED' && item.plant_master_id) {
        const payload = {
          ids: [item.id],
          status: 'APPROVED'
        }
        this.service.reviewOrder(payload).subscribe(
          (response: any) => {
            const notifiedRes = response.result.filter(val => val.status == false);
            if (notifiedRes.length == 0) {
              this.notificationService.success('Success', response.message)
            } else {
              for (let i = 0; i < notifiedRes.length; i++) {
                this.notificationService.error('Error', notifiedRes[i].message);
              }
            }

            this.getAllOrders(this.productId);
          }, (err) => {
            this.notificationService.error('Error', err.message.message)
          })

        return false;
      }
      console.log(status);
      dialogConfig.data = {
        title: 'Edit Order Details',
        buttonText: {
          ok: 'Approve',
          cancel: 'Cancel',
        },
        editItem: item,
        status: 'Approve'
      }
    } else {
      dialogConfig.data = {
        title: 'Edit Order Details',
        buttonText: {
          ok: 'Edit',
          cancel: 'Cancel',
        },
        editItem: item
      }
    }

    if (this.mandiList) {
      dialogConfig.data['mandiList'] = this.mandiList
      dialogConfig.data['user'] = this.loggedInUser
      dialogConfig.data['userRole'] = this.loggedInUser.role
      dialogConfig.data['products'] = this.availableProducts
      dialogConfig.data['height'] = "600px",
        dialogConfig.data['width'] = "300px",
        dialogConfig.data['panelClass'] = "addnew-custom-dialog"
    }
    const dialogRef = this.dialog.open(ManageOrderDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      (data: any) => {
        console.log(data);
        this.getAllOrders(this.productId);
      }
    )

  }
  tabChanged(event) {
  }

  applyFilter(filterValue) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  selectedDate(value: any, datepicker?: any) {
    // this is the date  selected
    // console.log(value);

    // any object can be passed to the selected event and it will be passed back here
    datepicker.start = value.start;
    datepicker.end = value.end;
    this.daterange.start = moment(new Date(datepicker.start)).format('YYYY-MM-DD');
    this.daterange.end = moment(new Date(datepicker.end)).format('YYYY-MM-DD');
    // use passed valuable to update state
    this.daterange.label = value.label;
    this.getTableData(this.activeLink);
  }

  acceptAll() {
    const payload = {
      ids: [],
      status: 'APPROVED'
    }

    const selections = this.selection.selected.map(val => val.id);
    if (!selections || selections.length <= 0) {
      this.notificationService.error('Error', 'Select at least one record');
      return false;
    }

    for (const data of this.selection.selected) {
      if (!data.plant_master_id) {
        this.notificationService.error('Error', `Please Select the Plant Code for ${data.mandiObj.mandi_name}`);
        return;
      }

      if (data.status != 'SUBMITTED') {
        this.notificationService.error('Error', `Can not review for ${data.mandiObj.mandi_name}. Only submitted data can be reviewd`);
        return false;
      }

      if (data.status === 'SUBMITTED' && data.plant_master_id) {
        payload.ids.push(data.id);
      }
    }

    this.service.reviewOrder(payload).subscribe(
      (response: any) => {
        if (response.status === true) {
          this.getAllOrders(this.productId)
        } else {

        }
      }, (err) => {
        console.log(err)
      }
    )
  }
  rejectAll() {
    const selections = this.selection.selected.map(val => val.id);
    if (!selections || selections.length <= 0) {
      this.notificationService.error('Error', 'Select at least one record');
      return false;
    }

    for (const data of this.selection.selected) {
      if (data.status != 'SUBMITTED') {
        this.notificationService.error('Error', `Can not review for ${data.mandiObj.mandi_name}. Only submitted data can be reviewd`);
        return false;
      }

    }

    const payload = {
      ids: selections,
      status: 'REJECTED'
    }

    this.service.reviewOrder(payload).subscribe(
      (response: any) => {
        if (response.status === true) {
          this.getAllOrders(this.productId)
        } else {

        }
      }, (err) => {
        console.log(err)
      }
    )
  }
  accept(element, status) {
    if (!element.plant_master_id) {
      this.notificationService.error('Error', 'Please Select the Plant Code')
      return;
    }
    const payload = {
      ids: [element.id],
      status: status
    }
    this.service.reviewOrder(payload).subscribe((res: any) => {
      if (res.status === true) {
        this.getAllOrders(this.productId);
      }
    }, err => { })
  }
  reject(element, status) {
    const payload = {
      ids: [element.id],
      status: status
    }
    this.service.reviewOrder(payload).subscribe((res: any) => {
      if (res.status === true) {
        this.getAllOrders(this.productId);
      }
    }, err => { })
  }
  exportAsXLSX() {
    let exportData;
    if (this.loggedInUser.role === UserRole.Facilitator) {
      exportData = this.dataSource.data.map((val) => (
        {
          id: val.id,
          'Product': val.product_id == 1 ? '6 Row Barley' : '2 Row Barley',
          'Mandi Name': val.mandiObj.mandi_name,
          'Document Date': val.document_date,
          'Status': val.status,
          'Order Number': val.order_number,
          'Order Type': val.type,
          'Reference Number': val.ref_num,
          'Quantity': val.quantity,
          'Base Price': val.base_price,
          'Delivery Location': val.destinationObj?.short_name,
          'For Price': val.order_for_price,
          'Pakka Arthiya': val.userObj?.first_name,

        }
      ));
    } else {
      exportData = this.dataSource.data.map((val) => (
        {
          id: val.id,
          'Product': val.product_id == 1 ? '6 Row Barley' : '2 Row Barley',
          'Mandi Name': val.mandiObj.mandi_name,
          'Document Date': val.document_date,
          'Status': val.status,
          'Order Number': val.order_number,
          'Order Type': val.type,
          'Reference Number': val.ref_num,
          'Quantity': val.quantity,
          'Base Price': val.base_price,
          'Delivery Location': val.destinationObj?.short_name,
          'For Price': val.order_for_price,
          'Pakka Arthiya': val.userObj?.first_name,
          'Suggested Price': val.suggested_price,
          'Celing Price': val.celing_price,

        }
      ));
    }

    this.excelService.exportAsExcelFile(exportData, 'manage-order');
  }
  submitAll() {

  }

  getplantCode() {
    this.plantCodeList = [];
    this.service.getAllPlantCode().subscribe((res: any) => {
      if (res.status) {
        this.plantCodeList = res.result;
      }
    }, error => {
      this.notificationService.error('Error', error.error.message);
    })
  }

  changePlaneCode(code, element) {
    console.log('code, elemt------------->', code, element)
    const payload = {
      plant_master_id: code
    }
    this.service.updateOrderPlantCode(payload, element.id).subscribe((res: any) => {
      if (res.status === true) {
        this.getAllOrders(this.productId);
      }
    }, err => {
      this.notificationService.error('Error', err?.error?.message);
    })

  }

}
