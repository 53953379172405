import { AbstractControl } from '@angular/forms';
export class MinValidation {

    static MinGtMax(AC: AbstractControl) {
        const minPrice = AC.get('min_price').value;
        const maxPrice = AC.get('max_price').value;

        if (minPrice && maxPrice && minPrice > maxPrice) {
            AC.get('max_price').setErrors({ minGtmax: true });
        } else if (!maxPrice) {
            AC.get('max_price').setErrors({ emptyMax: true});
        } else {
            AC.get('max_price').setErrors(null);
            return null;
        }
    }

    static BaseLsMax(AC: AbstractControl) {
        const minPrice = AC.get('order_for_price').value;
        const maxPrice = AC.get('ceiling_price').value;

        if (minPrice && maxPrice && minPrice > maxPrice) {
            AC.get('order_for_price').setErrors({ baseLsMax: true });
        }
    }
}
