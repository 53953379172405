export enum UserRole {
    Facilitator = 'Facilitator',
    UBP = 'UBP',
    PukkaArthiya = 'Pukka Arthiya'
}

export enum ReportType {
    Auction = 'Auction',
    FOR = 'FOR',
    ArrivalMinPrice = 'ArrivalMinPrice',
    ArrivalMaxPrice = 'ArrivalMaxPrice',
    SuggestedPrice = 'SuggestedPrice',
    PurchaseBasePrice = 'PurchaseBasePrice',
    PurchaseFORPrice = 'PurchaseFORPrice',
    PurchaseQuantity = 'PurchaseQuantity'
}

export enum Period {
    YTD = 'YTD',
    MTD = 'MTD',
    Today = 'Today',
    PrvDay = 'PrvDay',
    CurWeek = 'CurWeek',
    PrvWeek = 'PrvWeek',
    PrvMonth = 'PrvMonth',
    PrvYear = 'PrvYear',
    Last7Days = 'Last7Days',
}

export const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const yearList = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear - 1; i < currentYear + 20; i++) {
        years.push(i)
    }
    return years;
};


export enum Products {
    product_6_Row = '1',
    product_2_Row = '2'
}

export enum barleyTabText {
    'Barley_2_Row' = '2 Row Barley',
    'Barley_6_Row' = '6 Row Barley'
}

export enum ArrivalAction {
    create_6_row = 'create_6_row',
    create_2_row = 'create_2_row',
    view_6_row = 'view_6_row',
    update_6_row = 'update_6_row',
    submit_6_row = 'submit_6_row',
    view_2_row = 'view_2_row',
    update_2_row = 'update_2_row',
    submit_2_row = 'submit_2_row'
};

export enum ViewceilingAction {
    view_6_row_offer = 'view_6_row_offer',
    view_6_row = 'view_6_row',
    view_2_row = 'view_2_row',
};

export enum ceilingTabText {
    'Barley_6_Row_Auction' = '6 Row Barley Auction',
    'Barley_6_Row_Offer' = '6 Row Barley Offer',
    'Barley_2_Row_Auction' = '2 Row Barley Auction'
}

export enum orderTabText {
    'Barley_2_Row' = '2 Row Barley',
    'Barley_6_Row' = '6 Row Barley'
}

export enum OrderAction {
    create_6_row = 'create_6_row',
    create_2_row = 'create_2_row',
    view_6_row = 'view_6_row',
    update_6_row = 'update_6_row',
    submit_6_row = 'submit_6_row',
    view_2_row = 'view_2_row',
    update_2_row = 'update_2_row',
    submit_2_row = 'submit_2_row',
    review_2_row = 'review_2_row',
    review_6_row = 'review_6_row'
};

export enum OfferToggleText {
    'Season' = 'Season',
    'Offseason' = 'Offseason'
}

export enum OfferAction {
    create_season_offer = 'create_season_offer',
    create_offseason_offer = 'create_offseason_offer',
    view_season_offer = 'view_season_offer',
    submit_season_offer = 'submit_season_offer',
    view_offseason_offer = 'view_offseason_offer',
    submit_offseason_offer = 'submit_offseason_offer',
    review_offseason_offer = 'review_offseason_offer',
    review_season_offer = 'review_season_offer',
    review_season_counter_offer = 'review_season_counter_offer',
    review_offseason_counter_offer = 'review_offseason_counter_offer'
};

export enum AuctionPriceViewText {
    'Barley_6_Row' = '6 Row Barley',
    'Barley_2_Row' = '2 Row Barley'
}

export enum AuctionPriceViewAction {
    view_6_row = 'view_6_row',
    view_2_row = 'view_2_row'
};

export enum AuctiontabText {
    'Barley_2_Row' = '2 Row Barley',
    'Barley_6_Row' = '6 Row Barley'
}

export enum AuctionAction {
    create_6_row = 'create_6_row',
    view_6_row = 'view_6_row',
    update_6_row = 'update_6_row',
    submit_6_row = 'submit_6_row',
    freeze_6_row = 'freeze_6_row',
    set_ceiling_6_row = 'set_ceiling_6_row',
    notify_6_row = 'notify_6_row',
    review_6_row = 'review_6_row',
    create_2_row = 'create_2_row',
    view_2_row = 'view_2_row',
    update_2_row = 'update_2_row',
    submit_2_row = 'submit_2_row',
    freeze_2_row = 'freeze_2_row',
    set_ceiling_2_row = 'set_ceiling_2_row',
    notify_2_row = 'notify_2_row',
    review_2_row = 'review_2_row'
};

export enum ForDashToggleText {
    Season = 'Season',
    Offseason = 'Offseason'
}

export enum ForDashAction {
    view_season_for = 'view_season_for',
    freeze_season_for = 'freeze_season_for',
    set_ceiling_season_for = 'set_ceiling_season_for',
    send_review_season_for = 'send_review_season_for',
    notify_season_for = 'notify_season_for',
    review_season_for = 'review_season_for',

    view_offseason_for = 'view_offseason_for',
    freeze_offseason_for = 'freeze_offseason_for',
    set_ceiling_offseason_for = 'set_ceiling_offseason_for',
    send_review_offseason_for = 'send_review_offseason_for',
    notify_offseason_for = 'notify_offseason_for',
    review_offseason_for = 'review_offseason_for',
};