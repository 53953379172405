import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MinValidation } from './minValidation';
import { UbService } from 'src/app/services/ub.service';
import { UserRole } from 'src/app/models/schema';
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  dialogData: any;
  arrivalDataForm: FormGroup;
  inputForm: FormGroup;
  title: string;
  mandiList: any;
  stateList: any;
  rowType: any;
  selectedMandi: any;
  selectedCluster: any;
  selectedState: any;
  submitted = false;
  maxPriceError = false;
  clusterList: any;
  role: any;
  mandis: [
    {
      name: "Testing",
      id: 1
    }
  ];
  rowData: any;
  productId;

  documentDate = new Date().toISOString().slice(0, 10).replace('T', ' ')

  constructor(
    private fb: FormBuilder,
    private service: UbService,
    private dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {

    this.title = data.title;
    this.mandiList = data.mandiList;
    this.stateList = data.stateList;
    this.rowType = data.rowType;
    this.rowData = data.rowData;
    this.clusterList = data.clusterList;
    this.productId = data.productId;
    this.role = data.role;
  }

  ngOnInit() {
    if (this.rowType === '6 Row Barley') {
      this.arrivalDataForm = this.fb.group({
        mandi_id: ['', [Validators.required]],
        min_price: ['', [Validators.required, Validators.pattern('^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$')]],
        max_price: ['', [Validators.required, Validators.pattern('^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$')]],
        quantity: ['', [Validators.required, Validators.pattern('^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$')]],
        document_date: [this.documentDate, [Validators.required]],
      },
        {
          validator: MinValidation.MinGtMax
        });
      if (this.rowData && this.rowData.mandi_id) {
        this.arrivalDataForm.controls['mandi_id'].setValue(this.rowData.mandi_id);
        this.arrivalDataForm.controls['min_price'].setValue(this.rowData.min_price);
        this.arrivalDataForm.controls['max_price'].setValue(this.rowData.max_price);
        this.arrivalDataForm.controls['quantity'].setValue(this.rowData.quantity);
        this.arrivalDataForm.controls['document_date'].setValue(this.rowData.document_date);
      }
    }
    if (this.rowType === '2 Row Barley') {
      this.arrivalDataForm = this.fb.group({
        mandi_id: ['', [Validators.required]],
        state_id: ['', [Validators.required]],
        one_price: ['', [Validators.required, Validators.pattern('^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$')]],
        quantity: ['', [Validators.required, Validators.pattern('^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$')]],
        document_date: [this.documentDate, [Validators.required]],
      });

      if (this.rowData && this.rowData.mandi_id) {
        this.arrivalDataForm.controls['mandi_id'].setValue(this.rowData.mandi_id);
        this.arrivalDataForm.controls['state_id'].setValue(this.rowData.state_id);
        this.arrivalDataForm.controls['one_price'].setValue(this.rowData.one_price);
        this.arrivalDataForm.controls['quantity'].setValue(this.rowData.quantity);
        this.arrivalDataForm.controls['document_date'].setValue(this.rowData.document_date);
      }
    }
    if (this.rowType === 'setCeilingPrice') {
      this.arrivalDataForm = this.fb.group({
        ceilingPrice: ['', [Validators.required, Validators.pattern('^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$')]]
      });
    }
    if (this.rowType === 'setCeilingPriceCluster') {
      console.log('setCeilingPriceCluster-------')
      this.arrivalDataForm = this.fb.group({
        cluster_id: ['', [Validators.required]],
        ceiling_for_price: ['', [Validators.required, Validators.pattern('^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$')]]
      });
    }

    if (!this.rowData) {
      // if (this.rowType = 'setCeilingPrice') {
      //   return false
      // }
      if(!this.arrivalDataForm.controls.mandi_id) {
        return false
      }
      this.arrivalDataForm.controls.mandi_id.valueChanges.subscribe((result) => {
        if (this.rowType === '2 Row Barley') {
          let stateID: any;
          if (this.role == UserRole.PukkaArthiya || this.role == UserRole.Facilitator) {
            const mandiData = this.mandiList.find((itm) => itm.mandi_id === result);
            stateID = this.stateList.find((itm) => itm.state_name.trim() === mandiData.mandiObj.state.trim())
          } else {
            const mandiData = this.mandiList.find((itm) => itm.id === result);
            stateID = this.stateList.find((itm) => itm.state_name.trim() === mandiData.locationObj.state.trim())
          }

          console.log('stateID------------->', stateID)
          if (stateID) {
            this.arrivalDataForm.controls['state_id'].setValue(stateID.id);
          }
        }

        // this.getPreviousYear(payload);
      })
    }
  }

  validate(event) {
    return event.charCode >= 48;
  }

  getPreviousYear(payload) {
    this.service.getPreviousYearPrice(payload).subscribe((res) => {
      if (res['status'] && res['result']) {
        this.arrivalDataForm.controls['previous_year_price'].setValue(res['result'].base_price);
      } else {
        this.arrivalDataForm.controls['previous_year_price'].setValue(0);
      }
    })
  }

  saveArrivalData() {
    this.submitted = true;
    if (this.arrivalDataForm.valid) {
      if (this.rowType === 'setCeilingPrice') {
        this.dialogRef.close(this.arrivalDataForm.value.ceilingPrice);
      } else {
        this.dialogRef.close(this.arrivalDataForm.value);
      }
    } else {
      if (this.rowType === '6 Row Barley') {
        if (this.arrivalDataForm.value.max_price > 0) {
          this.maxPriceError = false;
        } else {
          this.maxPriceError = true;
        }
      }
      return false;
    }
  }

  // get af() { return this.arrivalDataForm.controls; }

  close() {
    this.dialogRef.close();
    this.dialogRef.close();
  }
}
