<div class="container-fluid">
    
    <div class="tabContentBlock">
        <div class="row pageHeader">
            MIS Report
         </div>
         
        <div class="row">
            <div class="col-md-6 pl-0">
                <mat-form-field class="cusWidth">
                    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
                    <button mat-icon-button matPrefix> <mat-icon class="primColor">search</mat-icon> </button>
                </mat-form-field>
            </div>
            <div class="col-md-6 buttonAlignRight tabBtnAlign">
                <button class="mat-raised-button mat-button-color mr-10 exportBtn"  (click)="exportAsXLSX()"> Export Excel</button>
            </div>
        </div>
        <div class="row" style="padding: 20px 0px 0px;">
            <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
                <ng-container matColumnDef="item">
                    <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.item}}</mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="Today">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Today</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.Today}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="PrvDay">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Prv Day</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.PrvDay}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="CurWeek">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Cur Week</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.CurWeek}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="PrvWeek">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Prv Week</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.PrvWeek}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="MTD">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>MTD</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.MTD}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="PrvMonth">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Prv Month</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.PrvMonth}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="YTD">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>YTD</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.YTD}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="PrvYear">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Prv Year</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.PrvYear}}</mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns_data"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns_data;"></mat-row>
            </mat-table>
            <mat-paginator [pageSizeOptions]="dataSource.data.length > 50 ? [10, 20, 50, dataSource.data.length] : [10, 20, 50]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
    <simple-notifications [options]="notificationOptions"></simple-notifications>
</div>
