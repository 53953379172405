import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute, Params } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { StatesMasterComponent } from './states-master/states-master.component';
import { RoleMasterComponent } from './role-master/role-master.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ViewceilingComponent } from './viewceiling/viewceiling.component';
import { ManageOrderComponent } from './manage-order/manage-order.component';
import { AuctionPriceViewComponent } from './auction-price-view/auction-price-view.component';
import { AuthGuardService as AuthGuard } from './services/auth-guard.service';

import { UserMasterComponent } from './user-master/user-master.component';
import { MandiDestinationMasterComponent } from './mandi-destination-master/mandi-destination-master.component';
import { AgmarkMasterComponent } from './agmark-master/agmark-master.component';
import { ForFreightMasterComponent } from './for-freight-master/for-freight-master.component';
import { ProductMasterComponent } from './product-master/product-master.component';
import { StorageInterestMasterComponent } from './storage-interest-master/storage-interest-master.component';
import { ClusterMasterComponent } from './cluster-master/cluster-master.component';
import { PremiumMasterComponent } from './premium-master/premium-master.component';
import { ManageOfferComponent } from './manage-offer/manage-offer.component';
import { ForDashboardComponent } from './for-dashboard/for-dashboard.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { ArrivaldataComponent } from './arrivaldata/arrivaldata.component';
import { AuctionDashboardComponent } from './auction-dashboard/auction-dashboard.component';
import { MandimasterComponent } from './mandimaster/mandimaster.component';
import { LocationsMasterComponent } from './locations-master/locations-master.component';
import { ClusterFreightMasterComponent } from './cluster-freight-master/cluster-freight-master.component';
import { RoleManagerMasterComponent } from './role-manager-master/role-manager-master.component';
import { ForlineItemMasterComponent } from './forline-item-master/forline-item-master.component';
import { MandiUserMappingMasterComponent } from './mandi-user-mapping-master/mandi-user-mapping-master.component';
import { DeliveryMasterComponent } from './delivery-master/delivery-master.component';
import { MSPMasterComponent } from './msp-master/msp-master.component';

import { PlantMasterComponent } from './plant-master/plant-master.component';
import { UpperLimitOfCeilingPriceComponent } from './upper-limit-of-ceiling-price/upper-limit-of-ceiling-price.component';
import { AgmarknetUploadComponent } from './agmarknet-upload/agmarknet-upload.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { CreateNewPasswordComponent } from './create-new-password/create-new-password.component';
import { AgmarkReportComponent } from './reports/agmark-report/agmark-report.component';
import { MisReportComponent } from './reports/mis-report/mis-report.component';
import { ArrivalReportComponent } from './reports/arrival-report/arrival-report.component';
import { OrderReportComponent } from './reports/order-report/order-report.component';
import { OfferReportComponent } from './reports/offer-report/offer-report.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'resetpassword',
    component: ResetPasswordComponent
  },
  {
    path: 'createnewpassword',
    component: CreateNewPasswordComponent
  },
  {
    path: '',
    component: LandingpageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard', // child route path
        component: DashboardComponent, // child route component that the router renders
      },
      {
        path: 'arrivaldata', // child route path
        component: ArrivaldataComponent, // child route component that the router renders
      },
      {
        path: 'agmarkupload', // child route path
        component: AgmarknetUploadComponent, // child route component that the router renders
      },
      {
        path: 'viewceiling', // child route path
        component: ViewceilingComponent, // child route component that the router renders
      },
      {
        path: 'auctionpriceview', // child route path
        component: AuctionPriceViewComponent, // child route component that the router renders
      },
      {
        path: 'productmaster', // child route path
        component: ProductMasterComponent, // child route component that the router renders
      },
      {
        path: 'mspmaster', // child route path
        component: MSPMasterComponent, // child route component that the router renders
      },
      {
        path: 'premiummaster', // child route path
        component: PremiumMasterComponent, // child route component that the router renders
      },
      {
        path: 'clustermaster', // child route path
        component: ClusterMasterComponent, // child route component that the router renders
      },
      {
        path: 'plantmaster', // child route path
        component: PlantMasterComponent, // child route component that the router renders
      },
      {
        path: 'auctiondashboard', // child route path
        component: AuctionDashboardComponent, // child route component that the router renders
      },
      {
        path: 'fordashboard', // child route path
        component: ForDashboardComponent, // child route component that the router renders
      },
      {
        path: 'statemaster', // child route path
        component: StatesMasterComponent, // child route component that the router renders
      },
      {
        path: 'mandimaster',
        component: MandimasterComponent, // another child route component that the router renders
      },
      {
        path: 'mandidestinationmaster',
        component: MandiDestinationMasterComponent, // another child route component that the router renders
      },
      {
        path: 'forfreight',
        component: ForFreightMasterComponent, // another child route component that the router renders
      },
      {
        path: 'rolemaster',
        component: RoleMasterComponent, // another child route component that the router renders
      },
      {
        path: 'agmarkmaster',
        component: AgmarkMasterComponent
      },
      {
        path: 'order',
        component: ManageOrderComponent
      },
      {
        path: 'manageoffer',
        component: ManageOfferComponent
      },
      {
        path: 'locationmaster',
        component: LocationsMasterComponent
      },
      {
        path: 'role-menu',
        component: RoleManagerMasterComponent
      },
      {
        path: 'usermaster',
        component: UserMasterComponent
      },
      {
        path: 'upperlimitofceilingprice',
        component: UpperLimitOfCeilingPriceComponent, // another child route component that the router renders
      },
      {
        path: 'clusterforfreight',
        component: ClusterFreightMasterComponent
      },
      {
        path: 'forlineitem',
        component: ForlineItemMasterComponent
      },
      {
        path: 'usermandi',
        component: MandiUserMappingMasterComponent
      },
      {
        path: 'destinationmaster',
        component: DeliveryMasterComponent
      },
      {
        path: 'storageinterest',
        component: StorageInterestMasterComponent
      },
      {
        path: 'agmark-report',
        component: AgmarkReportComponent
      },
      {
        path: 'mis-report',
        component: MisReportComponent
      },
      {
        path: 'arrival-report',
        component: ArrivalReportComponent
      },
      {
        path: 'order-report',
        component: OrderReportComponent
      },
      {
        path: 'offer-report',
        component: OfferReportComponent
      }
    ],
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
