import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MenuService } from '../services/menu.service';
import { UbService } from '../services/ub.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogComponent } from '../widgets/dialog/dialog.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ExcelService } from '../services/excel.service';
import { NotificationsService } from 'angular2-notifications';
import { SelectionModel } from '@angular/cdk/collections';
import { ThemePalette } from '@angular/material/core';

const enum Set_Ceiling_type {
  cluster = 'cluster',
  id = 'id',
}

var uniqueArray = (arr1) => arr1.reduce(function (a, d) {
  const isExist = a.find(val => val.cluster_id == d.clusterObj.id)
  if (!isExist) {
    a.push({
      cluster_id: d.clusterObj.id,
      name: d.clusterObj.name
    });
  }
  return a;
}, []);

import * as moment from 'moment';
import { ForDashAction, ForDashToggleText, UserRole } from '../models/schema';

const initialSelection = [];
const allowMultiSelect = true;

enum Products {
  product_6_Row = '1',
  product_2_Row = '2'
}
@Component({
  selector: 'app-for-dashboard',
  templateUrl: './for-dashboard.component.html',
  styleUrls: ['./for-dashboard.component.scss']
})
export class ForDashboardComponent implements OnInit, AfterViewInit {
  ForDashAction = ForDashAction;
  ForDashToggleText = ForDashToggleText;
  UserRole = UserRole
  color: ThemePalette = 'accent';
  disabled = false;
  loading = false;
  permission: any;
  documentDate = moment(new Date()).format('YYYY-MM-DD');
  animal: string;
  name: string;
  mandiList: any;
  public daterange: any = {
    start: this.documentDate,
    end: this.documentDate
  };
  season = true;
  loggedInUser: any;
  activeSeason = ''
  public options: any = {
    locale: { format: 'D MMMM YYYY' },
    alwaysShowCalendars: false,
  };
  public notificationOptions: any = {
    position: ['bottom', 'right'],
    timeOut: 5000,
    lastOnBottom: true,
    pauseOnHover: true,
    clickToClose: true,
    animate: 'fromRight'
  };
  displayed6RowColumns: any;
  displayed2RowColumns: any;
  forDashboardSeasonColumns: any;
  forDashboardSeasonColumnsBack: any;
  forDashboardOffSeasonColumns: any;
  dataSource = new MatTableDataSource<any>();
  backupData: any;
  stateList: any;
  editRow: any;
  selection: any;
  isFrozen = false;
  isPreviousDate = false;
  approversList = [];
  minimumOfMandis = 0;
  seasonType = '';
  isBothSeasonAllowed = false;
  alarmClass = 'notApprovedAlarm'
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  constructor(
    public menuService: MenuService,
    private router: Router,
    private service: UbService,
    private dialog: MatDialog,
    private excelService: ExcelService,
    private _service: NotificationsService
  ) {
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      return data.min_price.toString().includes(filter) ||
        data.mandiObj.mandi_name.toLocaleLowerCase().includes(filter) ||
        data.max_price.toString().includes(filter) ||
        data.is_submitted.toString().includes(filter) ||
        data.document_date.includes(filter);
    };
    this.selection = new SelectionModel<any>(allowMultiSelect, initialSelection);
  }

  openCeilingPriceDialog() {

    const selections = this.selection.selected;
    if (!selections || selections.length <= 0) {
      this._service.error('Error', 'Select at least one record');
      return false;
    }

    if (!this.isFrozen) {
      this._service.error('Error', 'Please freeze the entry');
      return false;
    }
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      title: 'Set Ceiling For Price',
      rowType: 'setCeilingPrice',
      buttonText: {
        ok: 'Add',
        cancel: 'Cancel',
      },
      height: '600px',
      width: '300px',
      panelClass: 'addnew-custom-dialog'
    };

    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      console.log('Dialog output:', data);
      if (data) {
        this.setAllCeilingPrice(data, Set_Ceiling_type.id);
      }
    });
  }

  openCeilingPriceClusterDialog() {
    if (!this.isFrozen) {
      this._service.error('Error', 'Please freeze the entry');
      return false;
    }
    const dialogConfig = new MatDialogConfig();
    const uniqueCluster = uniqueArray(this.dataSource.data);
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      title: 'Set Ceiling For Price',
      rowType: 'setCeilingPriceCluster',
      clusterList: uniqueCluster,
      buttonText: {
        ok: 'Add',
        cancel: 'Cancel',
      },
      height: '600px',
      width: '300px',
      panelClass: 'addnew-custom-dialog'
    };

    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        const payload = {
          ceiling_for_price: data.ceiling_for_price,
          ids: [data.cluster_id],
          type: Set_Ceiling_type.cluster
        }

        this.updateCeilingPrice(payload);
      }
    });
  }

  applyFilter(filterValue) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  ngOnInit(): void {
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    const menu = this.menuService.getMenu(this.router.url);
    this.permission = menu.actions;
    this.forDashboardSeasonColumns = ['select', 'clister_id', 'delivery_location', 'notified_date', 'avg_price', 'for_freight', 'expenses', 'avg_buying_price', 'suggested_for_price', 'ceiling_for_price', 'approval', 'notify'];
    this.forDashboardOffSeasonColumns = ['select', 'clister_id', 'delivery_location', 'notified_date', 'avg_price', 'storage', 'interest', 'avg_buying_price', 'suggested_for_price', 'ceiling_for_price', 'approval', 'notify'];

    if (this.checkPermission(ForDashAction.review_offseason_for)) {
      this.forDashboardOffSeasonColumns.push('action');
    }

    if (this.checkPermission(ForDashAction.review_season_for)) {
      this.forDashboardSeasonColumns.push('action');
    }

    if (this.checkPermission(ForDashAction.view_season_for) && this.checkPermission(ForDashAction.view_offseason_for)) {
      this.activeSeason = ForDashToggleText.Season
      this.seasonType = ForDashToggleText.Season
      this.isBothSeasonAllowed = true;
    } else if (this.checkPermission(ForDashAction.view_offseason_for)) {
      this.activeSeason = ForDashToggleText.Offseason
      this.seasonType = ForDashToggleText.Offseason
    } if (this.checkPermission(ForDashAction.view_season_for)) {
      this.activeSeason = ForDashToggleText.Season
      this.seasonType = ForDashToggleText.Season
    }

    this.getForDashboardData();
    this.service.getUserMandi(this.loggedInUser.id).subscribe((response: any) => {
      this.mandiList = response.result;
    });
    this.service.getState().subscribe((response: any) => {
      this.stateList = response.result;
    });

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  seasonTypeChange(event) {
    this.seasonType = event.checked ? ForDashToggleText.Offseason : ForDashToggleText.Season;
    if (this.seasonType == ForDashToggleText.Season) {
      this.forDashboardSeasonColumns = ['select', 'clister_id', 'delivery_location', 'notified_date', 'avg_price', 'for_freight', 'avg_buying_price',  'suggested_for_price', 'ceiling_for_price', 'approval', 'notify'];
    } else {
      this.forDashboardSeasonColumns = ['select', 'clister_id', 'delivery_location', 'notified_date', 'avg_price', 'storage', 'interest', 'avg_buying_price',  'suggested_for_price', 'ceiling_for_price', 'approval', 'notify'];
    }

    // if (this.checkPermission(ForDashAction.review_offseason_for)) {
    //   this.forDashboardOffSeasonColumns.push('action');
    // }

    if (this.checkPermission(ForDashAction.review_season_for)) {
      this.forDashboardSeasonColumns.push('action');
    }

    this.getForDashboardData();
  }


  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected == numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  getForDashboardData() {
    this.season = this.seasonType == ForDashToggleText.Season ? true : false;
    const query = `season=${this.season}&document_date=${this.documentDate}`;
    this.loading = true;
    this.service.getForDashboardData(query).subscribe((response: any) => {
      this.selection.clear()
      if (response && response.result && response.result.length > 0) {
        this.isFrozen = response.result[0].is_frozen;
      } else {
        this.isFrozen = false;
      }
      this.dataSource.data = response.result;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      const obj = {
        product_id: Products.product_6_Row,
        documentDate: this.documentDate
      };
      if (this.dataSource.data && this.dataSource.data.length > 0) {
        this.minimumOfMandis = Math.min.apply(Math, this.dataSource.data.map(function (o) {
          return o.avg_price;
        }));
      } else {
        this.minimumOfMandis = 0;
      }

      this.getDataSourceStatus(obj);
      this.loading = false;
    }, error => {
      this.loading = false;
      this._service.error('Error', error.error.message);
    });
  }

  changeSeason() {
    this.season = !this.season;
    console.log('this.season------------->', )
    this.getForDashboardData();

    if (this.season) {
      this.forDashboardSeasonColumns = ['select', 'clister_id', 'delivery_location', 'notified_date', 'avg_price', 'for_freight', 'expenses', 'avg_buying_price', 'suggested_for_price', 'ceiling_for_price', 'approval', 'notify'];
    } else {
      this.forDashboardSeasonColumns = ['select', 'clister_id', 'delivery_location', 'notified_date', 'avg_price', 'storage', 'interest', 'avg_buying_price', 'suggested_for_price', 'ceiling_for_price', 'approval', 'notify'];
    }
  }

  checkPermission(access: string): boolean {
    return this.permission.includes(access);
  }

  setCeilingPrice(rowData) {
    const payload = {
      ceiling_for_price: rowData.ceiling_for_price,
      ids: [rowData.id],
      type: Set_Ceiling_type.id,
      season: this.season
    };
    this.updateCeilingPrice(payload);
  }

  setAllCeilingPrice(price, type) {
    const ids = this.selection.selected.map(val => val.id);
    const payload = {
      ceiling_for_price: price,
      ids: ids,
      type: type,
      season: this.season
    };
    if (ids.length > 0) {
      this.updateCeilingPrice(payload);
    } else {
      this._service.warn('Warning', '0 Mandi selected');
    }
  }

  updateCeilingPrice(payload) {
    // if (this.activeLink === BarleyTabs.Barley_6_Row) {
    this.service.setForDashboardCeilinPrices(payload).subscribe((response: any) => {
      if (response) {
        const updateRes = response.result.filter(val => val.status == false);
        if (updateRes.length == 0) {
          this._service.success('Success', response.message);
        } else {
          for (let i = 0; i < updateRes.length; i++) {
            this._service.error('Error', updateRes[i].message);
          }
        }
        this.getForDashboardData();
      }
    }, error => {
      this._service.error('Error', error.error.message);
    });
  }

  freezeAll(status) {
    let sendData = {
      product_id: 1,
      document_date: this.documentDate,
      is_frozen: 1
    };
    if (status === 'unfreeze') {
      sendData.is_frozen = 0;
    }

    this.service.freezeAuctionData(sendData).subscribe((response: any) => {
      if (response) {
        this._service.success('Success', response.message);
        this.getForDashboardData();
      }
    }, error => {
      this._service.error('Error', error.error.message);
    });
  }

  getDataSourceStatus(data) {
    this.service.getFreezeState(data).subscribe((response: any) => {
      if (response) {
        if (response && response.result && response.result.length > 0) {
          this.isFrozen = response.result[0].is_frozen;
        } else {
          this.isFrozen = false;
        }
      }
    }, error => {
      this._service.error('Error', error.error.message);
    });

    if (this.dataSource.data) {
      let aprsList = [];
      let numApproved;
      let currentApprover;
      let totalApprovers;
      let pendingApprovers;
      for (let i = 0; i < this.dataSource.data.length; i++) {
        if (this.dataSource.data[i].approvermasterObj) {
          currentApprover = this.dataSource.data[i].approvermasterObj.current_approver;
          aprsList = this.dataSource.data[i].approvermasterObj.approvers.split(',');
          totalApprovers = aprsList.length;
          numApproved = aprsList.indexOf(currentApprover);

          let reviewedBy = [];
          if(this.dataSource.data[i].approvermasterObj && this.dataSource.data[i].approvermasterObj.updated_by) {
            reviewedBy = this.dataSource.data[i].approvermasterObj.updated_by.map(val=>val.reviewerObj.first_name);
          }

          if (totalApprovers > 0) {
            this.dataSource.data[i].approvermasterObj.totalApprovers = totalApprovers;
            if (numApproved > 0) {
              pendingApprovers = totalApprovers - (numApproved);
            } else {
              pendingApprovers = totalApprovers;
              numApproved = 0;
            }
            this.dataSource.data[i].approvermasterObj.pendintApprovers = pendingApprovers;
            this.dataSource.data[i].approvermasterObj.pendingName = aprsList.splice(numApproved).join(',');
            this.dataSource.data[i].approvermasterObj.approvedApprovers = numApproved;
            this.dataSource.data[i].approvermasterObj.approvedName = reviewedBy.join(',');
          }
        }
      }
    }
  }

  updateCeilingPriceStatus(status, rowData) {
    let payload = {
      status: status,
      product_id: rowData.product_id,
      reviewed_by: this.loggedInUser.id,
      ids: [rowData.id]
    };
    if (this.selection.selected.length > 0) {
      const ids = this.selection.selected.map(val => val.id);
      payload.ids = ids;
    }
    this.service.approvalFordashboard(payload).subscribe((response: any) => {
      if (response) {
        const approvealRes = response.result.filter(val => val.status == false);
        if (approvealRes.length == 0) {
          this._service.success('Success', response.message);
        } else {
          for (let i = 0; i < approvealRes.length; i++) {
            this._service.error('Error', approvealRes[i].message);
          }
        }
        this.getForDashboardData();
      }
    }, error => {
      this._service.error('Error', error.error.message);
    });
  }

  rejectAll() {
    const isAllowed = this.selection.selected.
      filter(val => (!val.approvermasterObj || (val.approvermasterObj.current_approver.trim() != this.loggedInUser.role)));

    for (let i = 0; i < isAllowed.length; i++) {
      const element = isAllowed[i];
      this._service.error('Error', `Not allowed to review for ${element.mandiObj
      .mandi_name}`);
    }
    if (isAllowed.length > 0) {
      return false
    }

    const selections = this.selection.selected.map(val => val.id);
    if (!selections || selections.length <= 0) {
      this._service.error('Error', 'Select at least one record');
      return false;
    }

    const payload = {
      status: 'Rejected',
      reviewed_by: this.loggedInUser.id,
      ids: selections,
      product_id: 1
    };

    this.service.updateAuctionDataStatus(payload).subscribe((response: any) => {
      if (response) {
        const rejectRes = response.result.filter(val => val.status == false);
        if (rejectRes.length == 0) {
          this._service.success('Success', response.message);
        } else {
          for (let i = 0; i < rejectRes.length; i++) {
            this._service.error('Error', rejectRes[i].message);
          }
        }
        this.getForDashboardData();
      }
    }, error => {
      this._service.error('Error', error.error.message);
    });
  }

  approveAll() {
    const isAllowed = this.selection.selected.
      filter(val => (!val.approvermasterObj || (val.approvermasterObj.current_approver.trim() != this.loggedInUser.role)));

    for (let i = 0; i < isAllowed.length; i++) {
      const element = isAllowed[i];
      this._service.error('Error', `Not allowed to review for ${element.mandiObj
      .mandi_name}`);
    }
    if (isAllowed.length > 0) {
      return false
    }

    const selections = this.selection.selected.map(val => val.id);
    if (!selections || selections.length <= 0) {
      this._service.error('Error', 'Select at least one record');
      return false;
    }

    const payload = {
      status: 'Approved',
      reviewed_by: this.loggedInUser.id,
      ids: selections,
      product_id: 1
    };

    this.service.updateAuctionDataStatus(payload).subscribe((response: any) => {
      if (response) {
        const approveRes = response.result.filter(val => val.status == false);
        if (approveRes.length == 0) {
          this._service.success('Success', response.message);
        } else {
          for (let i = 0; i < approveRes.length; i++) {
            this._service.error('Error', approveRes[i].message);
          }
        }
        this.getForDashboardData();
      }
    }, error => {
      this._service.error('Error', error.error.message);
    });
  }

  notifyAll() {
    const selections = this.selection.selected;
    if (!selections || selections.length <= 0) {
      this._service.error('Error', 'Select at least one record');
      return false;
    }
    const idsList = selections.map(val => val.id);
    const sendData = {
      ids: idsList,
      season: this.season
    };
    this.submitNotification(sendData);
  }

  notifyRow(rowData) {
    let sendData = {
      ids: [rowData.id],
      season: this.season
    };

    this.submitNotification(sendData);
  }

  submitNotification(sendData) {

    this.service.forDashboardNotify(sendData).subscribe((response: any) => {
      if (response) {
        const notifiedRes = response.result.filter(val => val.status == false);
        if (notifiedRes.length == 0) {
          this._service.success('Success', response.message);
        } else {
          for (let i = 0; i < notifiedRes.length; i++) {
            this._service.error('Error', notifiedRes[i].message);
          }
        }

        this.getForDashboardData();
      }
    }, error => {
      this._service.error('Error', error.error.message);
    });
  }

  exportAsXLSX(): void {

    if (this.season) {
      const exportData = this.dataSource.data.map((val) => (
        {
          'Cluster Name': val.clusterObj.name,
          'Delivery Location': val.destinationObj.short_name,
          'Notification Date & time': val.notified_at,
          'Avg Price': val.avg_price,
          'For Freight': val.for_freight,
          'Expenses': val.expenses,
          'Avg Buying Price': val.avg_buying_price,
          'Suggested For Price': val.suggested_for_price,
          'Ceiling For Price': val.ceiling_for_price
        }
      ));
      this.excelService.exportAsExcelFile(exportData, 'arrival-data');

    } else if (!this.season) {
      const exportData = this.dataSource.data.map((val) => (
        {
          id: val.id,
          'Cluster Name': val.clusterObj.name,
          'Delivery Location': val.destinationObj.short_name,
          'Notification Date & time': val.notified_at,
          'Avg Price': val.avg_price,
          'Storage': val.storage,
          'Interest': val.interest,
          'Avg Buying Price': val.avg_buying_price,
          'Suggested For Price': val.suggested_for_price,
          'Ceiling For Price': val.ceiling_for_price
        }
      ));
      this.excelService.exportAsExcelFile(exportData, 'arrival-data');
    }
  }

  showActions(element: any) {
    if (element.approvermasterObj && element.approvermasterObj.current_approver) {
      if (element.approvermasterObj.current_approver.trim() == this.loggedInUser.role) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  checkNotify(element: any) {
    if (element && element.approvermasterObj && element.approvermasterObj.approvers) {
      if (element.approvermasterObj.approvers.includes(this.loggedInUser.role)) {
        const currentApprover = element.approvermasterObj.current_approver;
        const aprsList = element.approvermasterObj.approvers.split(',');
        const currentApprIndex = aprsList.indexOf(currentApprover);
        const userIndex = aprsList.indexOf(this.loggedInUser.role);
        if (userIndex > currentApprIndex) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }

  submitForApproval() {
    const ids = this.selection.selected.map(val => val.id);
    if (!ids || ids.length <= 0) {
      this._service.error('Error', 'Select at least one record for approval');
      return false;
    }
    const payload = {
      ids: ids
    };
    this.triggerApproval(payload);

  }

  triggerApproval(payload) {
    const link = 'fordashboard';
    this.service.triggerApprovalFlow(payload, link).subscribe((response: any) => {
      if (response) {
        const triggerRes = response.result.filter(val => val.status == false);
        if (triggerRes.length == 0) {
          this._service.success('Success', response.message);
        } else {
          for (let i = 0; i < triggerRes.length; i++) {
            this._service.error('Error', triggerRes[i].message);
          }
        }
        this.getForDashboardData();
      }
    }, error => {
      this._service.error('Error', error.error.message);
    });
  }

  getAlarmCLass(element) {
    if (element && element.approvermasterObj && element.approvermasterObj.status == 'Approved') {
      if (element.notified) {
        this.alarmClass = 'notifiedAlarm';
      } else {
        this.alarmClass = 'approvedAlarm';
      }
    } else {
    }
    return this.alarmClass
  }
}


