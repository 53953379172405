import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { UbService } from '../services/ub.service';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  userForm: FormGroup;
  error = false;
  showOtp = false;
  windowRef: any;
  submitted = false;
  loginUb = false;
  loginUser = false;
  user;
  slides = [
    {
      image: 'assets/images/banners/barley-banner1.jpg',
      title: 'caption',
      text: 'text test1'
    },
    {
      image: 'assets/images/banners/barley-banner2.jpg',
      title: 'caption2',
      text: 'text test2'
    },
    {
      image: 'assets/images/banners/barley-banner3.jpg',
      title: 'caption3',
      text: 'text test3'
    }
  ];
  public settings = {
    length: 4,
    numbersOnly: true,
    timer: 12,
    btnClass: 'mt-5'
  };

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private service: UbService,
    private _service: NotificationsService,
    private activatedRoute: ActivatedRoute
  ) { 
    console.log('Redirected');
    console.log(this.router);
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        console.log(params);
    })
  }

  ngOnInit(): void {
    this.userForm = this.formBuilder.group({
      user_id: [null, Validators.required]
    });
  }

  checkPassword(control) {
    const enteredPassword = control.value;
    const passwordCheck = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    return (!passwordCheck.test(enteredPassword) && enteredPassword) ? { requirements: true } : null;
  }

  get f() { return this.userForm.controls; }

  resetPassword() {
    console.log('reset password');
    const payload = {
      user_id: this.userForm.value.user_id
    };
    console.log(payload);
    if (this.userForm.valid){
      this.service.generatePass(payload).subscribe((response: any) => {
        this._service.success('Success', response.message);
        if (response && response.status == true) {
          this.router.navigate(['createnewpassword']);
        }
      }, error => {
        this._service.error('Error', error.error.message);
      });
    }

  }

}
