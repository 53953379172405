import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UbService } from '../services/ub.service';
import { interval, Subscription, timer } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.scss']
})
export class LandingpageComponent implements OnInit, OnDestroy {
  private _mobileQueryListener: () => void;
  mobileQuery: MediaQueryList;
  menus = [];
  userName: any;
  loggedInUser: any;
  notifications: any;
  notificationCount = 0;
  notificationSubscription: Subscription;
  public notificationOptions: any = {
    position: ['bottom', 'right'],
    timeOut: 5000,
    lastOnBottom: true,
    pauseOnHover: true,
    clickToClose: true,
    animate: 'fromRight'
  };
  constructor(media: MediaMatcher, changeDetectorRef: ChangeDetectorRef, private router: Router,private _service: NotificationsService,
    private route: ActivatedRoute, private services: UbService, private activatedRoute:ActivatedRoute) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.userName = this.services.getUserName();
    this.services.getLoggedInName.subscribe(data => this.changeName(data.name));
    this.activatedRoute.queryParams.subscribe(
      (params: Params) => {
        if (params.email) {
          localStorage.setItem('ubtoken', JSON.stringify(params.token));
          this.services.getByEmail(params.email).subscribe((res) => {
            if (!res['status']) {
              this._service.error(res['message'] , '');
              window.location.href = '/bpstest/login';
              return;
              // this.router.navigate(['/login']);
            }
            const result = <any>res;
            if (result && result.user) {
              let permissions = result.user.permissions;
              if (!permissions) {
                localStorage.setItem('menuItems', JSON.stringify([]));
                window.location.href = '/bpstest/login';
                return;
              }
              if (permissions && permissions.length > 0) {
                permissions = permissions.filter((elem) => elem.device_type.toLowerCase() === 'w');
              }
              localStorage.setItem('menuItems', JSON.stringify(permissions));
    
              localStorage.setItem('ubtoken', JSON.stringify(result.token));
              const name = result.user.first_name + result.user.last_name;
              this.services.loginEvent(name);
              const user = {
                id: result.user.id,
                user_id: result.user.user_id,
                email_1: result.user.email_1,
                roles_id: result.user.roles_id,
                role: result.user.role.role
              };
              localStorage.setItem('ubuser', JSON.stringify(user));
              window.location.href = '/bpstest/dashboard';
            } else {
              this._service.error('Failed To login' , '');
              // this.router.navigate(['/login']);
              window.location.href = '/bpstest/login';
            }
          });
        } else {
          const authToken = this.services.getToken();
            if (authToken) {
              return true;
            } else {
              window.location.href = '/bpstest/login';
              // this.router.navigate(['/login']);
              return false;
            }
        }
      })
  }

  private changeName(name: string): void {
    this.userName = name;
  }

  ngOnInit(): void {
    this.userName = this.services.getUserName();
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    let permissions = JSON.parse(localStorage.getItem('menuItems'));
    permissions = permissions.sort(function(a, b) {
      return a.menu.display_order - b.menu.display_order;
    });
    permissions = permissions.reduce(function (result, current) {
      result[current.menu.parent_id] = result[current.menu.parent_id] || [];
      result[current.menu.parent_id].push(current);
      return result;
    }, {});

    for (const key in permissions) {
      if (Object.prototype.hasOwnProperty.call(permissions, key)) {
        const element = permissions[key];
        this.menus.push(element)
      }
    }

    this.getNotifications();
    // notification initialization intervall-------------------------------------
    const notificationBroadCast = interval(1000 * 60* 60);

    this.notificationSubscription = notificationBroadCast.subscribe(res => {
      this.getNotifications();
    });

    // permissions.forEach(permission => {
    //   console.log('permission------>', permission)
    //   if (!permission.menu.menu_id) {
    //     menuArr.push(permission);
    //   }
    //   permission.actions = permission.actions.replace(/\s+/g, '');
    //   permission.actions = permission.actions.split(',');

    // });
    // permissions.forEach(permission => {
    //   const menuItem = menuArr.filter(menu => menu.menu.menu_id === permission.menu.parent_id);
    //   if (menuItem && menuItem[0]) {
    //     if (!menuItem[0].children) {
    //       menuItem[0].children = []
    //     }
    //     menuItem[0].children.push(permission);
    //   }
    // });
    //this.menus = menuArr
    // console.log('this.menus-------->', this.menus)
    // this.navigateMenu(this.menus[0]);
    // console.log(this.menus)
  }

  getNotifications() {


    this.services.getAllNotification(this.loggedInUser.id).subscribe((response: any) => {
      if (response) {
        this.notifications = response.result;
        this.notificationCount = this.notifications.length;
      }
    }, error => {
      console.log(error);
    });
  }

  notificationClosed() {
    console.log('NotificationClosed');
    const userId = this.loggedInUser.id;
    const sendData = {
      read: '1'
    };
    if (this.notificationCount > 0) {
      this.services.updateNotificationStatus(userId, sendData).subscribe((response: any) => {
        if (response) {
          console.log(response);
        }
      }, error => {
        console.log(error);
      });
    }
  }


  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.notificationSubscription.unsubscribe();
  }

  navigate(route) {
    console.log(route);
    this.router.navigate(['/' + route], { relativeTo: this.route });
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  navigateMenu(menuItem: any, linkType: string): void {
    if (linkType == 'parent' && !menuItem[0].menu.menu_id) {
      this.router.navigate(['/' + menuItem[0].menu.navigation_url], { relativeTo: this.route });
    }
    if (linkType == 'child') {
      this.router.navigate(['/' + menuItem.menu.navigation_url], { relativeTo: this.route });
    } else {
      return;
    }
  }
}
