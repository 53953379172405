import { Injectable, Output, EventEmitter } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaderResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class UbService {


  @Output() getLoggedInName: EventEmitter<any> = new EventEmitter();

  BASEURL = 'https://ubldevelop.ublnet.in/bpstestapi/api/v1/';
  // BASEURL = 'http://localhost:8000/api/v1/';

  constructor(private http: HttpClient) { }

  private orderSource = new BehaviorSubject(null);
  currentOrder = this.orderSource.asObservable();

  changeOrder(status) {
    this.orderSource.next(status);
  }

  private offerSource = new BehaviorSubject(null);
  currentOffer = this.offerSource.asObservable();

  changeOffer(status) {
    this.offerSource.next(status);
  }

  private notifiedSource = new BehaviorSubject(null);
  currentNotified = this.notifiedSource.asObservable();

  changeNotified(isNotified) {
    this.notifiedSource.next(isNotified);
  }

  login() {
    return this.http.get(this.BASEURL + 'login', { responseType: 'text', observe: 'response' })
      // return this.http.get(this.BASEURL + 'user/login/UBUserLogin' , {responseType: 'text'  , observe: 'response'})
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getByEmail(email) {
    return this.http.get(this.BASEURL + 'userdata/' + email)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  loginUser(data) {
    return this.http.post(this.BASEURL + 'user/login', data)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  generatePass(data) {
    return this.http.post(this.BASEURL + 'user/forgot-password', data)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  updatePass(data) {
    return this.http.put(this.BASEURL + 'user/change-password', data)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  submitOrder(payload) {
    const token = this.getToken();
    return this.http.put(this.BASEURL + 'order/submit', payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  sumbitArrivalDataExcel(payload) {
    const token = this.getToken();
    return this.http.post(this.BASEURL + 'arrivaldata/import', payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  sumbit2RowArrivalDataExcel(payload) {
    const token = this.getToken();
    return this.http.post(this.BASEURL + 'arrivaldata2row/import', payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  createOrder(payload) {
    const token = this.getToken();
    return this.http.post(this.BASEURL + 'order', payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  updateOrder(payload, orderId) {
    return this.http.put(this.BASEURL + 'order/' + orderId, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  updatePriceOrder(payload) {
    return this.http.put(this.BASEURL + 'order/counter-offer', payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getOrders(filter) {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `order/filter?${filter}`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getOffers(params) {
    const token = this.getToken();
    if (params.created_by) {
      return this.http.get(this.BASEURL + `order/filter?from_date=${params.startDate}&to_date=${params.endDate}&type=FOR&season=${params.season}&created_by=${params.created_by}`)
        .pipe(map(Response => {
          return Response;
        }),
          catchError(errorRes => {
            return throwError(errorRes);
          }));
    } else {
      return this.http.get(this.BASEURL + `order/filter?from_date=${params.startDate}&to_date=${params.endDate}&type=FOR&season=${params.season}`)
        .pipe(map(Response => {
          return Response;
        }),
          catchError(errorRes => {
            return throwError(errorRes);
          }));
    }
  }

  getAllPlantCode() {
    return this.http.get(this.BASEURL + `plant-master`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getOfferById(id) {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `order/` + id)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getProduct() {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `product`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getMspMaster() {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `mspmaster`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getAgmarknet(query) {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `agmarknet/filter?${query}`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }
  importAgmarknet(payload) {
    const token = this.getToken();
    return this.http.post(this.BASEURL + `agmarknet/import`, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getPremium() {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `premiummaster`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getProductByID(id) {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `product/` + id)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getMspByID(id) {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `mspmaster/` + id)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getPremiumByID(id) {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `premiummaster/` + id)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  addPremium(payload) {
    const token = this.getToken();
    return this.http.post(this.BASEURL + `premiummaster`, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  updatePremium(id, payload) {
    const token = this.getToken();
    return this.http.put(this.BASEURL + `premiummaster/` + id, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getCluster() {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `cluster`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getClusterByID(id) {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `cluster/` + id)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  addCluster(payload) {
    const token = this.getToken();
    return this.http.post(this.BASEURL + `cluster`, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  addProduct(payload) {
    const token = this.getToken();
    return this.http.post(this.BASEURL + `product`, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  addMspMaster(payload) {
    const token = this.getToken();
    return this.http.post(this.BASEURL + `mspmaster`, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  updateCluster(id, payload) {
    const token = this.getToken();
    return this.http.put(this.BASEURL + `cluster/` + id, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  updateProduct(id, payload) {
    const token = this.getToken();
    return this.http.put(this.BASEURL + `product/` + id, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  updateMspMaster(id, payload) {
    const token = this.getToken();
    return this.http.put(this.BASEURL + `mspmaster/` + id, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }


  getCeilingPrice(mandiId, productId, documentDate) {
    const token = this.getToken();
    if (productId == 1) {
      return this.http.get(this.BASEURL + `arrivaldata/minimum-ceiling?product_id=${productId}&mandi_id=${mandiId}&document_date=${documentDate}`)
        .pipe(map(Response => {
          return Response;
        }),
          catchError(errorRes => {
            return throwError(errorRes);
          }));
    } else {
      return this.http.get(this.BASEURL + `arrivaldata2row/minimum-ceiling?product_id=${productId}&mandi_id=${mandiId}&document_date=${documentDate}`)
        .pipe(map(Response => {
          return Response;
        }),
          catchError(errorRes => {
            return throwError(errorRes);
          }));
    }
  }
  getForCeilingPrice(productId, destinationId, documentDate, season) {
    return this.http.get(this.BASEURL + `fordashboard/minimum-ceiling?cluster_id=${productId}&destination_id=${destinationId}&document_date=${documentDate}&season=${season}`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getMandiDestination(mandiId) {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `mandi-destination/filter?mandi_id=${mandiId}`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getClusterDestination(mandiId) {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `cluster-destination/filter?cluster_id=${mandiId}`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getUserName() {
    return localStorage.getItem('userName');
  }

  loginEvent(name) {
    localStorage.setItem('userName', name);
    this.getLoggedInName.emit({ name });
  }
  logoutEvent() {
    localStorage.clear();
    this.getLoggedInName.emit({ name: undefined, image: undefined });
  }

  getToken() {
    return localStorage.getItem('ubtoken');
  }

  getAuctionData(userId, documentDate) {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `arrivaldata/filter?is_submitted=true&document_date=${documentDate}`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getForDashboardData(query) {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `fordashboard/filter?${query}`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  triggerApprovalFlow(payload, link) {
    const token = this.getToken();
    return this.http.post(this.BASEURL + `${link}/trigger-approval`, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  forDashboardNotify(payload) {
    const token = this.getToken();
    return this.http.put(this.BASEURL + 'fordashboard/notify', payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  get2RowAuctionData(userId, documentDate) {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `arrivaldata2row/filter?is_submitted=true&document_date=${documentDate}`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getArrivalDataViewCeiling(filter) {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `arrivaldata/view-ceiling?${filter}`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  get2RowViewCeiling(filter) {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `arrivaldata2row/view-ceiling?${filter}`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getForDashboardViewCeiling(filter) {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `fordashboard/view-ceiling?${filter}`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getArrivalData(filter) {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `arrivaldata/filter?${filter}`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getArrivalDataByMandiIds(filter, type) {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `${type}/mandi/filter?${filter}`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  reviewOrder(payload) {
    const token = this.getToken();
    return this.http.put(this.BASEURL + 'order/review', payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  updateOrderPlantCode(payload, orderId) {
    return this.http.put(this.BASEURL + 'order/'+ orderId, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getProducts() {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `product`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getAllMandi() {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `mandi`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getHoLimit() {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `holimit`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  addHoLimit(payload) {
    const token = this.getToken();
    return this.http.post(this.BASEURL + `holimit`, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getHoLimitByID(id) {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `holimit/` + id)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }
  updateHoLimit(id, payload) {
    const token = this.getToken();
    return this.http.put(this.BASEURL + `holimit/` + id, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getAllPlants() {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `plant-master`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  addPlant(payload) {
    const token = this.getToken();
    return this.http.post(this.BASEURL + `plant-master`, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getPlantByID(id) {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `plant-master/` + id)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  updatePlant(id, payload) {
    const token = this.getToken();
    return this.http.put(this.BASEURL + `plant-master/` + id, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getAllCluster() {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `cluster`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getStates() {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `state`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getStatesById(id) {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `state/` + id)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  addState(payload) {
    const token = this.getToken();
    return this.http.post(this.BASEURL + `state`, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  updateState(id, payload) {
    const token = this.getToken();
    return this.http.put(this.BASEURL + `state/` + id, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }




  // get6RowManageOrder(userId, documentDate) {
  //   const token = this.getToken();
  //   return this.http.get(this.BASEURL + `order/filter?user_id=${userId}&from_date=${documentDate.start}&to_date=${documentDate.end}`)
  //     .pipe(map(Response => {
  //       return Response;
  //     }),
  //       catchError(errorRes => {
  //         return throwError(errorRes);
  //       }));
  // }

  // get2RowManageOrder() {
  //   const token = this.getToken();
  //   return this.http.get(this.BASEURL + `order/filter?user_id=${userId}&from_date=${documentDate.start}&to_date=${documentDate.end}`)
  //     .pipe(map(Response => {
  //       return Response;
  //     }),
  //       catchError(errorRes => {
  //         return throwError(errorRes);
  //       }));
  // }



  get2RowArrivalData(filter) {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `arrivaldata2row/filter?${filter}`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getArrivalDatas(filter) {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `arrivaldata/filter?` + filter)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  get2RowArrivalDatas(filter) {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `arrivaldata2row/filter?` + filter)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }
  getUserMandi(userId) {
    return this.http.get(this.BASEURL + `user-mandi/filter?user_id=${userId}&active=1`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getMandiFilter(mandi_id) {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `user-mandi/filter?mandi_id=${mandi_id}&active=1`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getState() {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `state`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getLocations() {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `location`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  saveArrivalData(payload) {
    const token = this.getToken();
    return this.http.post(this.BASEURL + 'arrivaldata', payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  save2RowArrivalData(payload) {
    const token = this.getToken();
    return this.http.post(this.BASEURL + 'arrivaldata2row', payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  updateArrivalData(id, payload) {
    const token = this.getToken();
    return this.http.put(this.BASEURL + 'arrivaldata/' + id, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  update2RowArrivalData(id, payload) {
    const token = this.getToken();
    return this.http.put(this.BASEURL + 'arrivaldata2row/' + id, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  freezeAuctionData(ids) {
    const token = this.getToken();
    return this.http.put(this.BASEURL + `freeze`, ids)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  approvalTaskList(data) {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `approval-task/filter?product_id=${data.product_id}`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getFreezeState(data) {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `freeze/filter?user_id=${data.userId}&product_id=${data.product_id}&document_date=${data.documentDate}`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  set2RowCeilinPrices(ids) {
    const token = this.getToken();
    return this.http.post(this.BASEURL + `arrivaldata2row/set-price`, ids)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  setForDashboardCeilinPrices(payload) {
    const token = this.getToken();
    return this.http.post(this.BASEURL + `fordashboard/set-price`, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  set6RowCeilinPrices(ids) {
    const token = this.getToken();
    return this.http.post(this.BASEURL + `arrivaldata/set-price`, ids)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  submitArrivalData(ids) {
    const token = this.getToken();
    return this.http.put(this.BASEURL + `arrivaldata/submit`, ids)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  submit2RowArrivalData(ids) {
    const token = this.getToken();
    return this.http.put(this.BASEURL + `arrivaldata2row/submit`, ids)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getAllClusterFreightData() {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `cluster-destination`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getAllForFreightData() {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `forfright`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }
  updateAuctionDataStatus(Data) {
    const token = this.getToken();
    return this.http.put(this.BASEURL + `arrivaldata/review`, Data)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  approvalFordashboard(Data) {
    const token = this.getToken();
    return this.http.put(this.BASEURL + `fordashboard/review`, Data)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  auctionData2RowNotify(payload) {
    const token = this.getToken();
    return this.http.put(this.BASEURL + 'arrivaldata2row/notify', payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  auctionData6RowNotify(payload) {
    const token = this.getToken();
    return this.http.put(this.BASEURL + 'arrivaldata/notify', payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getAllNotification(userId) {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `notification/filter?user_id=${userId}`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  updateNotificationStatus(id, data) {
    const token = this.getToken();
    return this.http.put(this.BASEURL + `notification/` + id, data)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getAllForLineItem() {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `forlineitem/`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }
  getAllMandiUser() {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `user-mandi/`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getAllLocations() {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `location/`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }
  getUserDetails() {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `user/`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }
  getAgmarkData() {
    const token = this.getToken();
    return this.http.get(this.BASEURL + 'agmarkmaster')
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getAllLocation() {
    const token = this.getToken();
    return this.http.get(this.BASEURL + 'location')
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getAllDeliveryLocation() {
    const token = this.getToken();
    return this.http.get(this.BASEURL + 'destination')
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  addMandi(payload) {
    const token = this.getToken();
    return this.http.post(this.BASEURL + `mandi`, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getMandiByID(id) {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `mandi/` + id)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }


  updateMandi(id, payload) {
    const token = this.getToken();
    return this.http.put(this.BASEURL + `mandi/` + id, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getAllMandiDestination() {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `mandi-destination`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getMappedMandiDestination(id) {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `mandi-destination/` + id)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  mapMandiDestination(payload) {
    const token = this.getToken();
    return this.http.post(this.BASEURL + `mandi-destination`, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  updateMandiDestination(payload, id) {
    const token = this.getToken();
    return this.http.put(this.BASEURL + `mandi-destination/` + id, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getAllAgmarkDetails() {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `agmarkmaster/`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }
  addForFreight(payload) {
    const token = this.getToken();
    return this.http.post(this.BASEURL + `forfright`, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  addClusterFreight(payload) {
    const token = this.getToken();
    return this.http.post(this.BASEURL + `cluster-destination`, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  updateClusterFreight(id, payload) {
    const token = this.getToken();
    return this.http.put(this.BASEURL + `cluster-destination/` + id, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  createAgmark(payload) {
    const token = this.getToken();
    return this.http.post(this.BASEURL + `agmarkmaster/`, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  createMandiUser(payload) {
    const token = this.getToken();
    return this.http.post(this.BASEURL + `user-mandi/`, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  updateMandiUser(id, payload) {
    const token = this.getToken();
    return this.http.put(this.BASEURL + `user-mandi/` + id, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }


  getForFreighData(id) {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `forfright/` + id)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  updateAgmark(id, payload) {
    const token = this.getToken();
    return this.http.put(this.BASEURL + `agmarkmaster/` + id, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  updateForFreight(payload, id) {
    const token = this.getToken();
    return this.http.put(this.BASEURL + `forfright/` + id, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getAllMenu() {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `menu`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getAllRoleMenu() {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `rolemenu`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getAllRoles() {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `role`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getRoleBy(id) {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `role/` + id)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  addRole(payload) {
    const token = this.getToken();
    return this.http.post(this.BASEURL + `role`, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  updateRole(payload, id) {
    const token = this.getToken();
    return this.http.put(this.BASEURL + `role/` + id, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  createForlineItem(payload) {
    const token = this.getToken();
    return this.http.post(this.BASEURL + `forlineitem`, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  updateForlineItem(id, payload) {
    const token = this.getToken();
    return this.http.put(this.BASEURL + `forlineitem/` + id, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  addStorageInterest(payload) {
    const token = this.getToken();
    return this.http.post(this.BASEURL + `storage-interest`, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }
  createLocations(payload) {
    const token = this.getToken();
    return this.http.post(this.BASEURL + `location`, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  updateStorageInterest(id, payload) {
    const token = this.getToken();
    return this.http.put(this.BASEURL + `storage-interest/` + id, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getAllStorageInterest() {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `storage-interest`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getSeasons() {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `season`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  updateLocations(id, payload) {
    const token = this.getToken();
    return this.http.put(this.BASEURL + `location/` + id, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }


  createUser(payload) {
    const token = this.getToken();
    return this.http.post(this.BASEURL + `user`, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }
  getDeliveryDetails() {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `destination`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getAllSapVendorCode() {
    const token = this.getToken();
    return this.http.get(this.BASEURL + `vendor-sync`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  createRoleManager(payload) {
    const token = this.getToken();
    return this.http.post(this.BASEURL + `rolemenu`, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  updateRoleManager(id, payload) {
    const token = this.getToken();
    return this.http.put(this.BASEURL + `rolemenu/` + id, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  createDelivery(payload) {
    const token = this.getToken();
    return this.http.post(this.BASEURL + `destination`, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  updateUser(id, payload) {
    const token = this.getToken();
    return this.http.put(this.BASEURL + `user/` + id, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }
  updateDelivery(id, payload) {
    const token = this.getToken();
    return this.http.put(this.BASEURL + `destination/` + id, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }
  getPreviousYearPrice(payload) {
    const token = this.getToken();
    return this.http.post(this.BASEURL + `order/previous-year-price`, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  postZeroOrder(payload) {
    const token = this.getToken();
    return this.http.post(this.BASEURL + `order/zero-price`, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getAvailableQty(query) {
    return this.http.get(this.BASEURL + `report/arrival/available-qnt?${query}`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }
  getPendingOffer(query) {
    return this.http.get(this.BASEURL + `report/order/pending-offer?${query}`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getPendingOrder(query) {
    return this.http.get(this.BASEURL + `report/order/pending-order?${query}`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getAuctionNotified(query) {
    return this.http.get(this.BASEURL + `report/arrival/auction-notified?${query}`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getPurchaseHistory(query) {
    return this.http.get(this.BASEURL + `report/order/purchase-history?${query}`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }
  getMIS() {
    return this.http.get(this.BASEURL + `report/mis`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getArrivalReport(payload) {
    const token = this.getToken();
    return this.http.post(this.BASEURL + `report/arrival/filter`, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getOfferReport(payload) {
    const token = this.getToken();
    return this.http.post(this.BASEURL + `report/order/filter`, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getOrderReport(payload) {
    const token = this.getToken();
    return this.http.post(this.BASEURL + `report/order/filter`, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  getAgmarkReport(payload) {
    const token = this.getToken();
    return this.http.post(this.BASEURL + `report/agmarknet/filter`, payload)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

  filterPrivilege(filter) {
    return this.http.get(this.BASEURL + `privilege/filter?${filter}`)
      .pipe(map(Response => {
        return Response;
      }),
        catchError(errorRes => {
          return throwError(errorRes);
        }));
  }

}
