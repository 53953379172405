import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MenuService } from '../services/menu.service';
import { UbService } from '../services/ub.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogComponent } from '../widgets/dialog/dialog.component';
import { ExcelService } from '../services/excel.service';
import { NotificationsService } from 'angular2-notifications';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { barleyTabText, UserRole, Products, ArrivalAction } from '../models/schema';

@Component({
  selector: 'app-arrivaldata',
  templateUrl: './arrivaldata.component.html',
  styleUrls: ['./arrivaldata.component.scss']
})
export class ArrivaldataComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('uploadExcel') uploadExcel;
  barleyTabText = barleyTabText;
  UserRole = UserRole;
  Products = Products;
  ArrivalAction = ArrivalAction;

  permission: any;
  documentDate = moment(new Date()).format('YYYY-MM-DD');
  animal: string;
  name: string;
  mandiList: any;
  loading = false;
  startDate = moment().subtract(1, "days").format("YYYY-MM-DD");
  endDate = moment().format("YYYY-MM-DD");

  public daterange: any = {
    start: this.startDate,
    end: this.endDate,
  };
  loggedInUser: any;
  public options: any = {
    locale: { format: 'D MMMM YYYY' },
    alwaysShowCalendars: false,
    maxDate: new Date(),
    startDate: moment().subtract(1, "days").format("D MMMM YYYY"),
    endDate: moment().format("D MMMM YYYY"),
  };
  public notificationOptions: any = {
    position: ['bottom', 'right'],
    timeOut: 5000,
    lastOnBottom: true,
    pauseOnHover: true,
    clickToClose: true,
    animate: 'fromRight'
  };
  displayed6RowColumns: any;
  displayed2RowColumns: any;
  dataSource = new MatTableDataSource<any>();
  backupData: any;
  links = [];

  activeLink = '';
  stateList: any;
  editRow: any;
  isFrozen = false;
  allMandis: any;
  productId = 1;

  public selectedDate(value: any, datepicker?: any) {
    // this is the date  selected
    // console.log(value);

    // any object can be passed to the selected event and it will be passed back here
    datepicker.start = value.start;
    datepicker.end = value.end;

    // use passed valuable to update state
    // this.daterange.start = value.start.toISOString().slice(0, 10).replace('T', ' ');
    // this.daterange.end = value.end.toISOString().slice(0, 10).replace('T', ' ');
    this.daterange.start = moment(value.start).format('YYYY-MM-DD');
    this.daterange.end = moment(value.end).format('YYYY-MM-DD');
    this.daterange.label = value.label;
    this.getTableData(this.activeLink);
  }

  constructor(
    public menuService: MenuService,
    private router: Router,
    private service: UbService,
    private dialog: MatDialog,
    private excelService: ExcelService,
    private _service: NotificationsService
  ) {
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      if (this.activeLink === barleyTabText.Barley_6_Row) {
        return data.min_price.toString().includes(filter) ||
          data.mandiObj.mandi_name.toLocaleLowerCase().includes(filter) ||
          data.max_price.toString().includes(filter) ||
          data.is_submitted.toString().includes(filter) ||
          data.document_date.includes(filter);
      }

      if (this.activeLink === barleyTabText.Barley_2_Row) {
        return data.one_price.toString().includes(filter) ||
          data.mandiObj.mandi_name.toLocaleLowerCase().includes(filter) ||
          data.stateObj.state_name.toLocaleLowerCase().includes(filter) ||
          data.is_submitted.toString().includes(filter) ||
          data.document_date.includes(filter);
      }
    };
  }

  openDialog() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      title: 'Arrival Data Details',
      mandiList: this.mandiList,
      stateList: this.stateList,
      rowType: this.activeLink,
      rowData: this.editRow,
      productId: this.productId,
      role: this.loggedInUser.role,
      buttonText: {
        ok: 'Add',
        cancel: 'Cancel',
      },
      height: '600px',
      width: '300px',
      panelClass: 'addnew-custom-dialog'
    };

    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      this.editRow = undefined;
      if (data) {
        data.user_id = this.loggedInUser.id;
        data.created_by = this.loggedInUser.id;
        data.previous_year_price = data.previous_year_price ? data.previous_year_price : 0;
        if (this.activeLink === barleyTabText.Barley_6_Row) {
          data.product_id = Products.product_6_Row;
          this.service.saveArrivalData(data).subscribe((response: any) => {
            if (response.status) {
              this.getTableData(this.activeLink);
              this._service.success('Success', '6 Row Barley added Successfully ');
            }
          }, error => {
            this._service.error('Error', error.error.message);
          });
        } else if (this.activeLink === barleyTabText.Barley_2_Row) {
          data.product_id = Products.product_2_Row;
          data.state = data.state_id;
          data.created_by = this.loggedInUser.id;
          this.service.save2RowArrivalData(data).subscribe((response: any) => {
            if (response.status) {
              this.getTableData(this.activeLink);
              this._service.success('Success', '2 Row Barley added Successfully ');
            }
          }, error => {
            this._service.error('Error', error.error.message);
          });
        }
      }
    });
  }

  applyFilter(filterValue) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {

    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    const menu = this.menuService.getMenu(this.router.url);
    this.permission = menu.actions;

    if (this.checkPermission(ArrivalAction.view_6_row)) {
      this.links.push(barleyTabText.Barley_6_Row);
    }

    if (this.checkPermission(ArrivalAction.view_2_row)) {
      this.links.push(barleyTabText.Barley_2_Row);
      this.activeLink = barleyTabText.Barley_2_Row;
    }

    if (this.checkPermission('view_6_row')) {
      this.activeLink = barleyTabText.Barley_6_Row;
    }

    if (this.checkPermission(ArrivalAction.submit_6_row)) {
      this.displayed6RowColumns = ['mandi_name', 'min_price', 'max_price', 'quantity', 'is_submitted', 'document_date', 'action'];
    } else {
      this.displayed6RowColumns = ['mandi_name', 'min_price', 'max_price', 'quantity', 'is_submitted', 'created_on'];
    }

    if (this.checkPermission(ArrivalAction.submit_2_row)) {
      this.displayed2RowColumns = ['mandi_name', 'state', 'one_price', 'quantity', 'is_submitted', 'document_date', 'action'];
    } else {
      this.displayed2RowColumns = ['mandi_name', 'state', 'one_price', 'quantity', 'is_submitted', 'created_on'];
    }

    this.getAllMandiData();
    this.getDataSourceStatus();
    this.get6RowArrivalData(this.loggedInUser.id, this.daterange);
    if (this.loggedInUser.role == UserRole.Facilitator || this.loggedInUser.role == UserRole.PukkaArthiya) {
      this.service.getUserMandi(this.loggedInUser.id).subscribe((response: any) => {
        this.mandiList = response.result;
      });
    } else {
      this.service.getAllMandi().subscribe((response: any) => {
        this.mandiList = response.result;
      });
    }

    this.service.getState().subscribe((response: any) => {
      this.stateList = response.result;
    });

  }

  getAllMandiData() {
    this.service.getAllMandi().subscribe(
      (mandiResponse: any) => {
        if (mandiResponse.status === true) {
          this.allMandis = mandiResponse.result;
        } else {

        }
      }, (err) => {
        console.log(err);
      }
    );
  }

  getTableData(link) {
    this.dataSource = new MatTableDataSource([]);
    if (link === barleyTabText.Barley_2_Row) {
      this.productId = 2;
      this.get2RowArrivalData(this.loggedInUser.id, this.daterange);
      this.getDataSourceStatus();
    } else if (link === barleyTabText.Barley_6_Row) {
      this.productId = 1;
      this.get6RowArrivalData(this.loggedInUser.id, this.daterange);
      this.getDataSourceStatus();
    }
  }

  get6RowArrivalData(id, date) {
    let filter;
    if (this.checkPermission(ArrivalAction.create_6_row)) {
      filter = 'user_id=' + id + '&from_date=' + date.start + '&to_date=' + date.end;
    } else {
      filter = '&from_date=' + date.start + '&to_date=' + date.end;
    }
    this.loading = true;
    this.service.getArrivalDatas(filter).subscribe((response: any) => {
      this.dataSource.data = response.result;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loading = false;
    }, error => {
      this._service.error('Error', error.error.message);
      this.loading = false;
    });
  }


  get2RowArrivalData(id, date) {
    let filter;
    if (this.checkPermission(ArrivalAction.create_2_row)) {
      filter = 'user_id=' + id + '&from_date=' + date.start + '&to_date=' + date.end;
    } else {
      filter = '&from_date=' + date.start + '&to_date=' + date.end;
    }
    this.loading = true;
    this.service.get2RowArrivalDatas(filter).subscribe((response: any) => {
      this.dataSource.data = response.result;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loading = false;
    }, error => {
      this._service.error('Error', error.error.message);
      this.loading = false;
    });
  }

  checkPermission(access: string): boolean {
    return this.permission.includes(access);
  }


  edit(row) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      title: 'Arrival Data Details',
      mandiList: this.mandiList,
      stateList: this.stateList,
      rowType: this.activeLink,
      productId: this.productId,
      rowData: row,
      buttonText: {
        ok: 'Add',
        cancel: 'Cancel',
      },
      height: '600px',
      width: '300px',
      panelClass: 'addnew-custom-dialog'
    };

    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      this.editRow = undefined;
      if (data) {
        data.user_id = this.loggedInUser.id;
        if (this.activeLink === barleyTabText.Barley_6_Row) {
          data.product_id = Products.product_6_Row;
          this.service.updateArrivalData(row.id, data).subscribe((response: any) => {
            if (response.status) {
              this.getTableData(this.activeLink);
              this._service.success('Success', '6 Row Barley Updated Successfully');
            }
          }, error => {
            this._service.error('Error', error.error.message);
          });
        } else if (this.activeLink === barleyTabText.Barley_2_Row) {
          data.product_id = Products.product_2_Row;
          this.service.update2RowArrivalData(row.id, data).subscribe((response: any) => {
            if (response.status) {
              this.getTableData(this.activeLink);
              this._service.success('Success', '2 Row Barley Updated Successfully');
            }
          }, error => {
            this._service.error('Error', error.error.message);
          });
        }
      }
    });
  }

  submit(id) {
    const payload = {
      ids: [id],
    };
    this.service.submitArrivalData(payload).subscribe((response: any) => {
      if (response) {
        this.get6RowArrivalData(this.loggedInUser.id, this.daterange);
        this._service.success('Success', '6 Row Barley Updated Successfully');
      }
    }, error => {
      this._service.error('Error', error.error.message);
    });
  }

  submit2Row(id) {
    const payload = {
      ids: [id]
    };
    this.service.submit2RowArrivalData(payload).subscribe((response: any) => {
      if (response) {
        this.get2RowArrivalData(this.loggedInUser.id, this.daterange);
        this._service.success('Success', '2 Row Barley Updated Successfully');
      }
    }, error => {
      this._service.error('Error', error.error.message);
    });
  }

  submitAll() {

    const ids = this.dataSource.data.filter(val => val.is_submitted == false).map(val => val.id);

    if (this.activeLink === barleyTabText.Barley_2_Row) {
      this.service.submit2RowArrivalData({ ids, user_id: this.loggedInUser.id }).subscribe((response: any) => {
        if (response) {
          const updateRes = response.result.filter(val => val.status == false);
          if (updateRes.length == 0) {
            this._service.success('Success', response.message);
          } else {
            for (let i = 0; i < updateRes.length; i++) {
              this._service.error('Error', updateRes[i].message);
            }
          }
          this.get2RowArrivalData(this.loggedInUser.id, this.daterange);
        }
      }, error => {
        this._service.error('Error', error.error.message);
      });
    } else if (this.activeLink === barleyTabText.Barley_6_Row) {
      this.service.submitArrivalData({ ids, user_id: this.loggedInUser.id }).subscribe((response: any) => {
        if (response) {
          const updateRes = response.result.filter(val => val.status == false);
          if (updateRes.length == 0) {
            this._service.success('Success', response.message);
          } else {
            for (let i = 0; i < updateRes.length; i++) {
              this._service.error('Error', updateRes[i].message);
            }
          }
          this.get6RowArrivalData(this.loggedInUser.id, this.daterange);
        }
      }, error => {
        this._service.error('Error', error.error.message);
      });
    }
  }

  getDataSourceStatus() {
    const data = {
      userId: this.loggedInUser.id,
      product_id: this.productId,
      documentDate: this.documentDate
    }
    this.service.getFreezeState(data).subscribe((response: any) => {
      if (response) {
        if (response && response.result && response.result.length > 0) {
          this.isFrozen = response.result[0].is_frozen;
        } else {
          this.isFrozen = false;
        }
      }
    }, error => {
      this._service.error('Error', error.error.message);
    });
  }
  onFileChange(ev: any) {
    const reader = new FileReader();
    const file = ev.target.files[0];
    this.uploadExcel.nativeElement.value = '';
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellDates: true, cellNF: false, cellText: false });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      const arrivalDataExcel = (XLSX.utils.sheet_to_json(ws, { raw: false }));
      if (this.allMandis) {
        let arrivalData = [];
        for (const data of arrivalDataExcel) {
          const mandiExist = this.allMandis.find(val => {
            let mandi_name = val.mandi_name;
            return mandi_name.toLocaleLowerCase() == data['Mandi Name'].toLocaleLowerCase()
          });
          if (!mandiExist) {
            this._service.error('Error', `Mandi ${data['Mandi Name']} doesn't exists`);
            return false;
          }

          if (data['Quantity'] < 0) {
            this._service.error('Error', `Add valid quantity`);
            return false;
          }

          let obj = {};
          if (this.activeLink === barleyTabText.Barley_6_Row) {

            if (data['Min Price'] < 0 || data['Max Price'] < 0 || data['Max Price'] < data['Min Price']) {
              this._service.error('Error', `Add valid Min and Max price`);
              return false;
            }

            obj = {
              mandi_id: mandiExist.id,
              user_id: this.loggedInUser.id,
              created_by: this.loggedInUser.id,
              product_id: this.productId,
              min_price: data['Min Price'],
              max_price: data['Max Price'],
              document_date: moment(new Date(data['Date'])).format('YYYY-MM-DD'),
              quantity: data['Quantity'] ? data['Quantity'] : 0
            };
            arrivalData.push(obj);

          } else if (this.activeLink === barleyTabText.Barley_2_Row) {

            if (data['One Price'] < 0) {
              this._service.error('Error', `Add valid One price`);
              return false;
            }
            obj = {
              mandi_id: mandiExist.id,
              user_id: this.loggedInUser.id,
              created_by: this.loggedInUser.id,
              product_id: this.productId,
              one_price: data['One Price'],
              document_date: moment(new Date(data['Date'])).format('YYYY-MM-DD'),
              quantity: data['Quantity'] ? data['Quantity'] : 0
            };

            arrivalData.push(obj);
          }
        }

        if (this.activeLink === barleyTabText.Barley_6_Row && arrivalData.length > 0) {
          this.loading = true;
          this.service.sumbitArrivalDataExcel(arrivalData).subscribe(
            (response: any) => {
              const notifiedRes = response.result.filter(val => val.status == false);
              if (notifiedRes.length == 0) {
                this._service.success('Success', 'Excel uploaded successfully');
              } else {
                for (let i = 0; i < notifiedRes.length; i++) {
                  this._service.error('Error', notifiedRes[i].message);
                }
              }
              this.getTableData(this.activeLink);
            }, (error) => {
              this._service.error('Error', error.error.message);
              this.loading = false;
            }
          );
        } else if (this.activeLink === barleyTabText.Barley_2_Row && arrivalData.length > 0) {
          this.loading = true;
          this.service.sumbit2RowArrivalDataExcel(arrivalData).subscribe(
            (response: any) => {
              const notifiedRes = response.result.filter(val => val.status == false);
              if (notifiedRes.length == 0) {
                this._service.success('Success', 'Excel uploaded successfully');
              } else {
                for (let i = 0; i < notifiedRes.length; i++) {
                  this._service.error('Error', notifiedRes[i].message);
                }
              }
              this.getTableData(this.activeLink);

            }, (error) => {
              this.loading = false;
              this._service.error('Error', error.error.message);
            }
          );
        }
      } else {
        this._service.error('Error', 'Upload Excel Failed');
        this.loading = false;
      }
    };
    reader.readAsBinaryString(file);
  }

  exportAsXLSX(): void {

    if (this.activeLink === barleyTabText.Barley_6_Row) {
      if (this.checkPermission(ArrivalAction.create_6_row)) {
        const exportData = this.dataSource.data.map((val) => (
          {
            id: val.id,
            'Mandi Name': val.mandiObj.mandi_name,
            'Document Date': val.document_date,
            'Min Pprice': val.min_price,
            'Max Price': val.max_price,
            'Quantity': val.quantity,
            'Submitted': val.is_submitted
          }
        ));
        this.excelService.exportAsExcelFile(exportData, 'arrival-data');
      } else {
        const exportData = this.dataSource.data.map((val) => (
          {
            id: val.id,
            'Mandi Name': val.mandiObj.mandi_name,
            'Created On': val.document_date,
            'Min Pprice': val.min_price,
            'Max Price': val.max_price,
            'Quantity': val.quantity,
            'Submitted': val.is_submitted
          }
        ));
        this.excelService.exportAsExcelFile(exportData, 'arrival-data');
      }
    } else if (this.activeLink === barleyTabText.Barley_2_Row) {
      if (this.checkPermission(ArrivalAction.create_2_row)) {
        const exportData = this.dataSource.data.map((val) => (
          {
            id: val.id,
            'Mandi Name': val.mandiObj.mandi_name,
            'State': val.state,
            'Document Date': val.document_date,
            'One Price': val.one_price,
            'Quantity': val.quantity,
            'Submitted': val.is_submitted
          }
        ));
        this.excelService.exportAsExcelFile(exportData, 'arrival-data');
      } else {
        const exportData = this.dataSource.data.map((val) => (
          {
            id: val.id,
            'Mandi Name': val.mandiObj.mandi_name,
            'State': val.state,
            'Created On': val.document_date,
            'One Price': val.one_price,
            'Quantity': val.quantity,
            'Submitted': val.is_submitted
          }
        ));
        this.excelService.exportAsExcelFile(exportData, 'arrival-data');
      }
    }
  }

}
