<h2 class="dialog-heading" *ngIf="title" mat-dialog-title>{{title}}</h2>
<mat-dialog-content style="width:500px">
    <form class="example-full-width" [formGroup]="stateForm" *ngIf="type === 'add'">
        <mat-form-field class="example-full-width">
            <input formControlName="state_name" placeholder="State name " matInput type='text' required>
            <mat-error>
                <span *ngIf="stateForm.get('state_name').hasError('required')">State name is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="sort_order" placeholder="Sort Order" matInput type='text' required>
            <mat-error>
                <span *ngIf="stateForm.get('sort_order').hasError('required')">Sort order is required</span>
            </mat-error>
            <mat-error>
                <span *ngIf="stateForm.get('sort_order').hasError('pattern')">Sort order should be a number</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="short_name" placeholder="Short name" matInput type='text' required>
            <mat-error>
                <span *ngIf="stateForm.get('short_name').hasError('required')"> Short name is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label>Status</mat-label>
            <mat-select class="matSelect" formControlName="active" required>
                <mat-option *ngFor="let active of isActive" [value]="active.value">
                    {{active.name}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!stateForm.get('active').valid || stateForm.get('active').touched">Status is required</span>
            </mat-error>
        </mat-form-field>
    </form>
    <mat-dialog-actions  *ngIf="type === 'add'">
        <button class="mat-raised-button mr-10" (click)="close()">Close</button>
        <button class="mat-raised-button mat-primary" (click)="addState()">Add</button>
    </mat-dialog-actions>


    <form class="example-full-width" [formGroup]="stateForm" *ngIf="type === 'edit'">
        <mat-form-field class="example-full-width">
            <input formControlName="state_name" placeholder="State name " matInput type='text' required>
            <mat-error>
                <span *ngIf="stateForm.get('state_name').hasError('required')">State name is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="sort_order" placeholder="Sort Order" matInput type='text' required>
            <mat-error>
                <span *ngIf="stateForm.get('sort_order').hasError('required')">Sort order is required</span>
            </mat-error>
            <mat-error>
                    <span *ngIf="stateForm.get('sort_order').hasError('pattern')">Sort order should be a number</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
                <input formControlName="short_name" placeholder="Short name" matInput type='text' required>
            <mat-error>
                <span *ngIf="stateForm.get('short_name').hasError('required')"> Short name is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label>Status</mat-label>
            <mat-select class="matSelect" formControlName="active" required>
                <mat-option *ngFor="let active of isActive" [value]="active.value">
                    {{active.name}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!stateForm.get('active').valid || stateForm.get('active').touched">Status is required</span>
            </mat-error>
        </mat-form-field>
    </form>
    <mat-dialog-actions  *ngIf="type === 'edit'">
        <button class="mat-raised-button mr-10" (click)="close()">Close</button>
        <button class="mat-raised-button mat-primary" (click)="editState()">Save</button>
    </mat-dialog-actions>
</mat-dialog-content>
