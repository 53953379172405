<h2 class="dialog-heading" mat-dialog-title>Create Zero Order</h2>
<div style="text-align:center" mat-dialog-content>
    {{message}}
</div>
<div class="mandi">
    <label>Mandi Names</label>
</div>
<div class="arrivalData">
    <div *ngFor="let data of arrivalData">
        <label>{{data.data.mandiObj.mandi_name}}</label>
    </div>
</div>
<mat-dialog-actions align="centre">
        <button class="mat-raised-button mr-10" (click)="close()">No</button>
        <button class="mat-raised-button mat-primary" (click)="confirm()">Yes</button>
 </mat-dialog-actions>
 <simple-notifications [options]="notificationOptions"></simple-notifications>