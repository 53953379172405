<div class="container-fluid">
    <div class="pageHeader">
        Auction Dashboard
    </div>

    <div class="minOfMandisBlock">
        <span class="minMandiContent"> <span class="minMandiCount">{{minimumOfMandis}}</span> Minimum of all Mandis
        </span>
    </div>

    <div class="tabsBlock">
        <nav mat-tab-nav-bar>
            <a mat-tab-link *ngFor="let link of links" (click)="activeLink = link;getCurrentDate();getTableData(link);"
                [active]="activeLink == link"> {{link}}
            </a>
        </nav>
        <div *ngIf="activeLink == AuctiontabText.Barley_6_Row" class="tabContentBlock">
            <div class="row">
                <div class="col-md-4 pl-0">
                    <mat-form-field class="cusWidth">
                        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
                        <button mat-icon-button matPrefix>
                            <mat-icon class="primColor">search</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
                <div class="col-md-8 buttonAlignRight tabBtnAlign">
                    <button class="mat-raised-button mat-primary previouDayBtn mr-10" (click)="getPreviousDate()"
                        *ngIf="isPreviousDate == false">
                        <fa-icon [icon]="['fas', 'calendar']"></fa-icon> Previous Day
                    </button>

                    <button class="mat-raised-button mat-primary previouDayBtn mr-10" (click)="getCurrentDate()"
                        *ngIf="isPreviousDate == true">
                        <fa-icon [icon]="['fas', 'calendar']"></fa-icon> Current Day
                    </button>

                    <button class="mat-raised-button mat-primary freezeAllBtn mr-10" (click)="freezeAll('freeze')"
                        *ngIf="checkPermission(AuctionAction.freeze_6_row) && (isFrozen == false || isFrozen == null)">Freeze
                        All</button>

                    <button class="mat-raised-button mat-primary unfreezeAllBtn mr-10" (click)="freezeAll('unfreeze')"
                        *ngIf="checkPermission(AuctionAction.freeze_6_row) && isFrozen == true">UnFreeze All</button>

                    <div *ngIf="checkPermission(AuctionAction.set_ceiling_6_row)" matTooltip='Will be enabled post freeze'
                        [matTooltipDisabled]="isFrozen">
                        <button class="mat-raised-button mat-primary  mr-10" (click)="openCeilingPriceDialog()"
                            [disabled]="!isFrozen">Set All</button>
                    </div>

                    <div *ngIf="checkPermission(AuctionAction.set_ceiling_6_row)" matTooltip='Will be enabled post freeze'
                        [matTooltipDisabled]="isFrozen">
                        <button class="mat-raised-button mat-primary  mr-10" (click)="submitForApproval()"
                        *ngIf="checkPermission(AuctionAction.set_ceiling_6_row)"  [disabled]="!isFrozen">Submit for Approval</button>
                    </div>                   

                    <div *ngIf="checkPermission(AuctionAction.notify_6_row)" matTooltip='Will be enabled post freeze'
                        [matTooltipDisabled]="isFrozen">
                        <button class="mat-raised-button mat-primary notifyAllBtn mr-10" (click)="notifyAll()"
                            [disabled]="!isFrozen">Notify All</button>
                    </div>

                    <button class="mat-raised-button mat-primary rejectAllBtn mr-10" (click)="rejectAll();"
                        *ngIf="checkPermission(AuctionAction.review_6_row)">Reject All</button>

                    <button class="mat-raised-button mat-primary approveAllBtn mr-10" (click)="approveAll();"
                        *ngIf="checkPermission(AuctionAction.review_6_row)">Approve All</button>

                    <button class="mat-raised-button mat-primary exportBtn" (click)="exportAsXLSX()">
                        <fa-icon [icon]="['fas', 'download']"></fa-icon> Export Excel
                    </button>
                </div>
            </div>
            <div class="row" style="padding: 20px 0px 0px;">
                <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
                <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="$event ? masterToggle() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="mandi_name">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Mandi Name </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.mandiObj.mandi_name}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="notified_date">
                        <mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 200px;"> Notified Date &
                            time </mat-header-cell>
                        <mat-cell *matCellDef="let element" style="min-width: 200px;"> {{element.notified_at | date:
                            'dd/MM/yyyy h:mm:ss'}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="quantity">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Quantity <br> (In Quintal)
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.quantity}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="min_price">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Min Price <br> (&#x20b9;)
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.min_price}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="max_price">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Max Price <br> (&#x20b9;)
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.max_price}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="previous_year_price">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Previous Year Price <br> (&#x20b9;)
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.previous_year_price}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="avg_buying">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Avg Buying <br> (&#x20b9;)
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.avg_buying_price}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="modal_price">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Modal Price <br> (&#x20b9;)
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.modal_price}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="suggested_price">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Suggested Price <br> (&#x20b9;)
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.suggested_price}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="ceiling_price">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Ceiling Price <br> (&#x20b9;)
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element  let i = index">
                            <div *ngIf="element.is_submitted == true" [ngClass]="isFrozen ?'frozenCell':'unfreezeCell'">
                                <input matInput [disabled]="!isFrozen" [(ngModel)]="element.ceiling_price"
                                    class="ceilingInput">
                                <button mat-raised-button matSuffix [disabled]="!isFrozen"  [ngClass]="element.is_ceiling_set ?'ceilingPriceBtn mr-10':'ceilingPriceBtnSet mr-10'"
                                    (click)="setCeilingPrice(element)" *ngIf="checkPermission(AuctionAction.set_ceiling_6_row)">
                                    set
                                </button>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="approval">
                        <mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 100px;"> Approval </mat-header-cell>
                        <mat-cell *matCellDef="let element" style="min-width: 100px;">

                            <span class="submittedRow"
                                *ngIf="element.approvermasterObj && element.approvermasterObj.status == 'Approved'"
                                matTooltip="{{element.approvermasterObj.approvedName}}"> <span *ngIf="!element.is_edited">Approved</span> <span *ngIf="element.is_edited" style="color:rgb(231, 114, 68)">Re-submitted</span></span>
                            <span class="notSubmittedRow"
                                *ngIf="element.approvermasterObj && element.approvermasterObj.status == 'Rejected'"><span *ngIf="!element.is_edited">Rejected</span> <span *ngIf="element.is_edited" style="color:rgb(231, 114, 68)">Re-submitted</span></span>

                            <div class="pendingApprovals"
                                *ngIf="element.approvermasterObj && element.approvermasterObj.status == 'Pending'">
                                <div class="approvalStatusBlock"
                                    *ngIf="element.approvermasterObj && element.approvermasterObj.status != 'Approved' && element.approvermasterObj.status !='Rejected'">

                                    <span class="approvedCountIcon"
                                        *ngIf="element.approvermasterObj.approvedApprovers > 0">
                                        <fa-icon [icon]="['fas', 'user-circle']"
                                            matTooltip="{{element.approvermasterObj.approvedName}}"></fa-icon>
                                        {{element.approvermasterObj.approvedApprovers}}
                                    </span>
                                    <span class="rejectedCountIcon">
                                        <fa-icon [icon]="['fas', 'user-circle']"
                                            matTooltip="{{element.approvermasterObj.pendingName}}"></fa-icon>
                                        {{element.approvermasterObj.pendintApprovers}}
                                    </span>

                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="notify">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Notify </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                           
                            <img *ngIf="!checkPermission(AuctionAction.notify_6_row) || element.is_edited" class="notApprovedAlarm"
                                src="assets/images/alarm.svg">

                            <img *ngIf="checkPermission(AuctionAction.notify_6_row) && !element.is_edited" [ngClass]="element && element.approvermasterObj && element.approvermasterObj.status == 'Approved' ? element.notified ? 'notifiedAlarm' : 'approvedAlarm' : 'notApprovedAlarm' "
                                src="assets/images/alarm.svg" (click)="notifyRow(element)">

                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 130px;"> Action </mat-header-cell>
                        <mat-cell *matCellDef="let element" style="min-width: 130px;">
                            <div class="actionRowBlock" *ngIf="showActions(element) && checkPermission(AuctionAction.review_6_row)">
                                <a class="approvedRow mr-10" (click)="updateCeilingPriceStatus('Approved',element)"
                                    *ngIf="element.ceiling_price != null && element.ceiling_price > 0 && element.approvermasterObj && element.approvermasterObj.status == 'Pending'">
                                    Approve
                                </a>
                                <a class="rejectedRow mr-10" (click)="updateCeilingPriceStatus('Rejected',element)"
                                    *ngIf="element.ceiling_price != null && element.ceiling_price > 0 && element.approvermasterObj && element.approvermasterObj.status == 'Pending'">
                                    Reject
                                </a>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayed6RowColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayed6RowColumns;"></mat-row>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No Data Found on {{documentDate}}</td>
                    </tr>
                </mat-table>
                <mat-paginator
                    [pageSizeOptions]="dataSource.data.length > 50 ? [10, 20, 50, dataSource.data.length] : [10, 20, 50]"
                    showFirstLastButtons></mat-paginator>
            </div>
        </div>
        <div *ngIf="activeLink == AuctiontabText.Barley_2_Row" class="tabContentBlock">

            <div class="row">
                <div class="col-md-4 pl-0">
                    <mat-form-field class="cusWidth">
                        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
                        <button mat-icon-button matPrefix>
                            <mat-icon class="primColor">search</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
                <div class="col-md-8 buttonAlignRight tabBtnAlign">
                    <button class="mat-raised-button mat-primary previouDayBtn mr-10" (click)="getPreviousDate()"
                        *ngIf="isPreviousDate == false">
                        <fa-icon [icon]="['fas', 'calendar']"></fa-icon> Previous Day
                    </button>

                    <button class="mat-raised-button mat-primary previouDayBtn mr-10" (click)="getCurrentDate()"
                        *ngIf="isPreviousDate == true">
                        <fa-icon [icon]="['fas', 'calendar']"></fa-icon> Current Day
                    </button>

                    <button class="mat-raised-button mat-primary freezeAllBtn mr-10" (click)="freezeAll('freeze')"
                        *ngIf="checkPermission(AuctionAction.freeze_2_row) && (isFrozen == false || isFrozen == null)">Freeze
                        All</button>

                    <button class="mat-raised-button mat-primary unfreezeAllBtn mr-10" (click)="freezeAll('unfreeze')"
                        *ngIf="checkPermission(AuctionAction.freeze_2_row) && isFrozen == true">UnFreeze All</button>

                    <div *ngIf="checkPermission(AuctionAction.set_ceiling_2_row)" matTooltip='Will be enabled post freeze'
                        [matTooltipDisabled]="isFrozen">
                        <button class="mat-raised-button mat-primary  mr-10" (click)="openCeilingPriceDialog()"
                            [disabled]="!isFrozen">Set All</button>
                    </div>

                    <div *ngIf="checkPermission(AuctionAction.set_ceiling_2_row)" matTooltip='Will be enabled post freeze'
                        [matTooltipDisabled]="isFrozen">
                        <button class="mat-raised-button mat-primary  mr-10" (click)="submitForApproval()"
                            *ngIf="checkPermission(AuctionAction.set_ceiling_2_row)" [disabled]="!isFrozen">Submit for Approval</button>
                    </div>

                    <div *ngIf="checkPermission(AuctionAction.notify_2_row)" matTooltip='Will be enabled post freeze'
                        [matTooltipDisabled]="isFrozen">
                        <button class="mat-raised-button mat-primary notifyAllBtn mr-10" (click)="notifyAll()"
                            [disabled]="!isFrozen">Notify All</button>
                    </div>

                    <button class="mat-raised-button mat-primary rejectAllBtn mr-10" (click)="rejectAll();"
                        *ngIf="checkPermission(AuctionAction.review_2_row)">Reject All</button>

                    <button class="mat-raised-button mat-primary approveAllBtn mr-10" (click)="approveAll();"
                        *ngIf="checkPermission(AuctionAction.review_2_row)">Approve All</button>

                    <button class="mat-raised-button mat-primary exportBtn" (click)="exportAsXLSX()">
                        <fa-icon [icon]="['fas', 'download']"></fa-icon> Export Excel
                    </button>
                </div>
            </div>
            <div class="row" style="padding: 20px 0px 0px;">
                <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
                <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="$event ? masterToggle() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="mandi_name">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Mandi Name </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.mandiObj.mandi_name}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="notified_date">
                        <mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 200px;"> Notified Date & time </mat-header-cell>
                        <mat-cell *matCellDef="let element" style="min-width: 200px;"> {{element.notified_at | date: 'dd/MM/yyyy h:mm:ss'}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="quantity">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Quantity <br> (In Quintal)
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.quantity}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="state">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> State </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.stateObj?.state_name}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="one_price">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> One Price <br> (&#x20b9;)
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.one_price}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="previous_year_price">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Previous Year Price <br> (&#x20b9;)
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.previous_year_price}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="MSP">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> MSP <br> (&#x20b9;)
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.msp}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="premium">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Premium <br> (&#x20b9;)
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.premium}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="modal_price">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Modal Price <br> (&#x20b9;)
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.modal_price}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="suggested_price">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Suggested Price <br> (&#x20b9;)
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.suggested_price}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="ceiling_price">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Ceiling Price <br> (&#x20b9;)
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element  let i = index">
                            <div *ngIf="element.is_submitted == true "
                                [ngClass]="isFrozen ?'frozenCell':'unfreezeCell'">
                                <input matInput [disabled]="!isFrozen" [(ngModel)]="element.ceiling_price"
                                    class="ceilingInput">
                                <button mat-raised-button matSuffix [disabled]="!isFrozen" [ngClass]="element.is_ceiling_set ?'ceilingPriceBtn mr-10':'ceilingPriceBtnSet mr-10'"
                                    (click)="setCeilingPrice(element)" *ngIf="checkPermission(AuctionAction.set_ceiling_2_row)">
                                    set
                                </button>
                            </div>

                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="approval">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Approval </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <span class="submittedRow"
                                *ngIf="element.approvermasterObj && element.approvermasterObj.status == 'Approved'"
                                matTooltip="{{element.approvermasterObj.approvedName}}"> <span *ngIf="!element.is_edited">Approved</span> <span *ngIf="element.is_edited" style="color:rgb(231, 114, 68)">Re-submitted</span></span>
                            <span class="notSubmittedRow"
                                *ngIf="element.approvermasterObj && element.approvermasterObj.status == 'Rejected'"><span *ngIf="!element.is_edited">Rejected</span> <span *ngIf="element.is_edited" style="color:rgb(231, 114, 68)">Re-submitted</span></span>

                            <div class="pendingApprovals"
                                *ngIf="element.approvermasterObj && element.approvermasterObj.status == 'Pending'">
                                <div class="approvalStatusBlock"
                                    *ngIf="element.approvermasterObj && element.approvermasterObj.status != 'Approved' && element.approvermasterObj.status !='Rejected'">

                                    <span class="approvedCountIcon"
                                        *ngIf="element.approvermasterObj.approvedApprovers > 0"
                                        matTooltip="{{element.approvermasterObj.approvedName}}">
                                        <fa-icon [icon]="['fas', 'user-circle']"></fa-icon>
                                        {{element.approvermasterObj.approvedApprovers}}
                                    </span>
                                    <span class="rejectedCountIcon"
                                        matTooltip="{{element.approvermasterObj.pendingName}}">
                                        <fa-icon [icon]="['fas', 'user-circle']"></fa-icon>
                                        {{element.approvermasterObj.pendintApprovers}}
                                    </span>

                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="notify">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Notify </mat-header-cell>
                        <mat-cell *matCellDef="let element">

                            <img *ngIf="!checkPermission(AuctionAction.notify_2_row) || element.is_edited" class="notApprovedAlarm"
                                src="assets/images/alarm.svg">

                                <img *ngIf="checkPermission(AuctionAction.notify_2_row) && !element.is_edited" [ngClass]="element && element.approvermasterObj && element.approvermasterObj.status == 'Approved' ? element.notified ? 'notifiedAlarm' : 'approvedAlarm' : 'notApprovedAlarm' "
                                src="assets/images/alarm.svg" (click)="notifyRow(element)">
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 200px;"> Action </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <div class="actionRowBlock" *ngIf="showActions(element) && checkPermission(AuctionAction.review_2_row)">
                                <a class="approvedRow mr-10" (click)="updateCeilingPriceStatus('Approved',element)"
                                    *ngIf="element.ceiling_price != null && element.ceiling_price > 0 && element.approvermasterObj && element.approvermasterObj.status == 'Pending'">
                                    Approve
                                </a>
                                <a class="rejectedRow mr-10" (click)="updateCeilingPriceStatus('Rejected',element)"
                                    *ngIf="element.ceiling_price != null && element.ceiling_price > 0 && element.approvermasterObj && element.approvermasterObj.status == 'Pending'">
                                    Reject
                                </a>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayed2RowColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayed2RowColumns;"></mat-row>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No Data Found on {{documentDate}}</td>
                    </tr>
                </mat-table>
                <mat-paginator
                    [pageSizeOptions]="dataSource.data.length > 50 ? [10, 20, 50, dataSource.data.length] : [10, 20, 50]"
                    showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
    <simple-notifications [options]="notificationOptions"></simple-notifications>
</div>