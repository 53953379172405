import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MenuService } from '../services/menu.service';
import { UbService } from '../services/ub.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogComponent } from '../widgets/dialog/dialog.component';
import { ExcelService } from '../services/excel.service';
import { Daterangepicker } from 'ng2-daterangepicker';
import { NotificationsService } from 'angular2-notifications';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { RoleManagerMasterDialogComponent } from '../widgets/role-manager-master-dialog/role-manager-master-dialog.component';

@Component({
  selector: 'app-role-manager-master',
  templateUrl: './role-manager-master.component.html',
  styleUrls: ['./role-manager-master.component.scss']
})
export class RoleManagerMasterComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  permission: any;
  documentDate = moment(new Date()).format('YYYY-MM-DD');
  loggedInUser: any;
  public notificationOptions: any = {
    position: ['bottom', 'right'],
    timeOut: 5000,
    lastOnBottom: true,
    pauseOnHover: true,
    clickToClose: true,
    animate: 'fromRight'
  };
  dataSource = new MatTableDataSource<any>();
  displayedRowColumns: any;

  constructor(
    public menuService: MenuService,
    private router: Router,
    private service: UbService,
    private dialog: MatDialog,
    private excelService: ExcelService,
    private _service: NotificationsService
  ) { 
  }

  ngOnInit(): void {
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    const menu = this.menuService.getMenu(this.router.url);
    this.permission = menu.actions;
    this.displayedRowColumns = ['role_name', 'menu_name', 'device_type', 'actions', 'status', 'action'];
    this.getAllMenu();
  }

  applyFilter(filterValue) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getAllMenu() {
    this.service.getAllRoleMenu().subscribe((response: any) => {
      this.dataSource.data = response.result;
      for (const data of  this.dataSource.data) {
        data.is_active = data.is_active == 1 ? 'Active' : 'Inactive';
        data.role_name = data.role.role,
        data.menu_name = data.menu.display_name
      }
      this.dataSource.filterPredicate = (data: any, filter: string) => {
        return data.device_type == 'M' ? 'Mobile'.toLocaleLowerCase().includes(filter) : 'Web'.toLocaleLowerCase().includes(filter)
        || data.role_name?.toString().toLocaleLowerCase().includes(filter) ||
          data.menu_name?.toLocaleLowerCase().includes(filter) ||
          data.is_active?.toLocaleLowerCase().includes(filter) ||
          data.actions?.toLocaleLowerCase().includes(filter);
      };
      this.dataSource.paginator = this.paginator;
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch(property) {
          case 'status': return item.is_active;
          default: return item[property];
        }
      }
      this.dataSource.sort = this.sort;
    }, error => {
      this._service.error('Error', error.error.message);
    });
  }

  addRoleManager() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Create Role Menu Mapping',
      height: '800px',
      width: '300px',
      panelClass: 'addnew-custom-dialog',
      type: 'add'
    };
    const dialogRef = this.dialog.open(RoleManagerMasterDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.getAllMenu();
    });
  }

  editRoleManager(rowData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Create Role Menu Mapping',
      height: '800px',
      width: '300px',
      panelClass: 'addnew-custom-dialog',
      type: 'edit',
      roleManagerRowData: rowData
    };
    const dialogRef = this.dialog.open(RoleManagerMasterDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.getAllMenu();
    });
  }

  checkPermission(access: string): boolean {
    return this.permission.includes(access);
  }

  exportAsXLSX(): void {
    const exportData = this.dataSource.data.map((val) => (
      {
        id: val.id,
        'Role Name': val.role.role,
        'Menu Name': val.menu.display_name,
        'Device Type':( val.device_type == 'W') ? 'Web' : 'Mobile',
        'Actions': val.actions
      }
    ));
    this.excelService.exportAsExcelFile(exportData, 'Role Manager Master');
  }

}
