import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationsService } from 'angular2-notifications';
import { UbService } from 'src/app/services/ub.service';

@Component({
  selector: 'app-mandi-master-dialog',
  templateUrl: './mandi-master-dialog.component.html',
  styleUrls: ['./mandi-master-dialog.component.scss']
})
export class MandiMasterDialogComponent implements OnInit {
  title: string;
  mandiForm: FormGroup;
  agmarkList = [];
  locationList = [];
  deliveryLocationList = [];
  clusterList = [];
  loggedInUser;
  mandi_id;
  type;

  constructor(
    private service: UbService,
    private fb: FormBuilder,
    private notificationService: NotificationsService,
    private dialogRef: MatDialogRef<MandiMasterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.title = data.title;
    this.mandi_id = data.mandi_id;
    this.type = data.type;
  }

  ngOnInit() {
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    this.mandiForm = this.fb.group({
      short_name: ['', Validators.required],
      mandi_name: ['', [Validators.required]],
      location_id: ['', [Validators.required]],
      delivery_id: ['', [Validators.required]],
      cluster_id: ['', [Validators.required]],
      agmark_id: ['', [Validators.required]],
      address_1: ['', [Validators.required]],
      address_2: ['', []],
      pincode: ['', [Validators.required]],
    });
    this.getAgmarkList();
    this.getClusterList()
  }

  getAgmarkList() {
    this.agmarkList = [];
    this.service.getAgmarkData().subscribe((res) => {
      if (res['status']) {
        this.agmarkList = res['result'];
        this.getAllLocation();
      }
    }, err => { });
  }

  getClusterList() {
    this.agmarkList = [];
    this.service.getCluster().subscribe((res) => {
      if (res['status']) {
        this.clusterList = res['result'];
      }
    }, err => {
      this.notificationService.error('Error', err?.error?.message);
    });
  }

  getAllLocation() {
    this.locationList = [];
    this.service.getAllLocation().subscribe((res) => {
      if (res['status']) {
        this.locationList = res['result'];
        this.locationList = this.locationList.filter(val => val.is_active == true || val.is_active == 1)
        this.getAllDeliveryLocation();
      }
    }, err => {
      this.notificationService.error('Error', err?.error?.message);
    });
  }

  getAllDeliveryLocation() {
    this.deliveryLocationList = [];
    this.service.getAllDeliveryLocation().subscribe((res) => {
      if (res['status']) {
        this.deliveryLocationList = res['result'];

        if (this.mandi_id) {
          this.getMandiData(this.mandi_id);
        }
      }
    }, err => {
      this.notificationService.error('Error', err?.error?.message);
    });
  }

  getMandiData(id) {
    this.service.getMandiByID(id).subscribe((res) => {
      if (res['status']) {
        const data = res['result'];
        this.mandiForm.controls.mandi_name.setValue(data.mandi_name);
        this.mandiForm.controls.short_name.setValue(data.short_name);
        this.mandiForm.controls.location_id.setValue(data.location_id);
        this.mandiForm.controls.agmark_id.setValue(data.agmark_market_id);
        this.mandiForm.controls.delivery_id.setValue(data.destination_id);
        this.mandiForm.controls.cluster_id.setValue(data.cluster_id);
        this.mandiForm.controls.address_1.setValue(data.address_1);
        this.mandiForm.controls.address_2.setValue(data.address_2);
        this.mandiForm.controls.pincode.setValue(data.pincode);
      }
    }, err => {
      this.notificationService.error('Error', err?.error?.message);
    })
  }


  close() {
    this.dialogRef.close();
  }

  addMandi() {
    for (const c in this.mandiForm.controls) {
      if (this.mandiForm.controls.hasOwnProperty(c)) {
        this.mandiForm.controls[c].markAsTouched();
      }
    }
    if (this.mandiForm.valid) {
      const data = this.locationList.filter((item) => item.id == this.mandiForm.value.location_id);
      let city = '';
      let state = '';
      let district = '';
      if (data.length === 1) {
        city = data[0].town_city,
          state = data[0].state,
          district = data[0].district
      }
      const payload = {
        "mandi_name": this.mandiForm.value.mandi_name,
        "short_name": this.mandiForm.value.short_name,
        "location_id": this.mandiForm.value.location_id,
        "cluster_id": this.mandiForm.value.cluster_id,
        "agmark_market_id": this.mandiForm.value.agmark_id,
        "destination_id": this.mandiForm.value.delivery_id,
        "address_1": this.mandiForm.value.address_1,
        "address_2": this.mandiForm.value.address_2,
        "pincode": this.mandiForm.value.pincode,
        "city": city,
        "district": district,
        "state": state,
        "user_id": this.loggedInUser.id,
        "created_by": this.loggedInUser.id,
        "updated_by": this.loggedInUser.id
      }
      this.service.addMandi(payload).subscribe((res) => {
        this.notificationService.success('Success', 'Mandi Added Successfully');
        this.dialogRef.close();
      }, error => {
        this.notificationService.error('Error', error.error.message);
        this.dialogRef.close();
      });
    }
  }

  editMandi() {
    for (const c in this.mandiForm.controls) {
      if (this.mandiForm.controls.hasOwnProperty(c)) {
        this.mandiForm.controls[c].markAsTouched();
      }
    }
    if (this.mandiForm.valid) {
      const data = this.locationList.filter((item) => item.id == this.mandiForm.value.location_id);
      let city = '';
      let state = '';
      let district = '';
      if (data.length === 1) {
        city = data[0].town_city,
          state = data[0].state,
          district = data[0].district
      }
      const payload = {
        "mandi_name": this.mandiForm.value.mandi_name,
        "short_name": this.mandiForm.value.short_name,
        "location_id": this.mandiForm.value.location_id,
        "cluster_id": this.mandiForm.value.cluster_id,
        "agmark_market_id": this.mandiForm.value.agmark_id,
        "destination_id": this.mandiForm.value.delivery_id,
        "address_1": this.mandiForm.value.address_1,
        "address_2": this.mandiForm.value.address_2,
        "pincode": this.mandiForm.value.pincode,
        "city": city,
        "district": district,
        "state": state,
        "user_id": this.loggedInUser.id,
        "updated_by": this.loggedInUser.id
      }
      this.service.updateMandi(this.mandi_id, payload).subscribe((res) => {
        this.notificationService.success('Success', 'Mandi Udapted Successfully');
        this.dialogRef.close();
      }, error => {
        this.notificationService.error('Error', error.error.message);
        this.dialogRef.close();
      });
    }
  }
}
