<h2 class="dialog-heading" *ngIf="title" mat-dialog-title>{{title}}</h2>
<mat-dialog-content style="width:500px">
    <form class="example-full-width" [formGroup]="plantForm" *ngIf="type === 'add'">
        <mat-form-field class="example-full-width">
            <input formControlName="name" placeholder="Name" matInput type='text' required>
            <mat-error>
                <span *ngIf="plantForm.get('name').hasError('required')">Plant Name is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="code" placeholder="Code" matInput type='text' required>
            <mat-error>
                <span *ngIf="plantForm.get('code').hasError('required')">Code is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="description" placeholder="Description" matInput type='text' required>
            <mat-error>
                <span *ngIf="plantForm.get('description').hasError('required')"> Description is required</span>
            </mat-error>
        </mat-form-field>
    </form>
    <mat-dialog-actions  *ngIf="type === 'add'">
        <button class="mat-raised-button mr-10" (click)="close()">Close</button>
        <button class="mat-raised-button mat-primary" (click)="addPlant()">Add</button>
    </mat-dialog-actions>


    <form class="example-full-width" [formGroup]="plantForm" *ngIf="type === 'edit'">
        <mat-form-field class="example-full-width">
            <input formControlName="name" placeholder="Name" matInput type='text' required>
            <mat-error>
                <span *ngIf="plantForm.get('name').hasError('required')">Plant Name is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="code" placeholder="Code" matInput type='text' required>
            <mat-error>
                <span *ngIf="plantForm.get('code').hasError('required')">Code is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="description" placeholder="Description" matInput type='text' required>
            <mat-error>
                <span *ngIf="plantForm.get('description').hasError('required')"> Description is required</span>
            </mat-error>
        </mat-form-field>
    </form>
    <mat-dialog-actions  *ngIf="type === 'edit'">
        <button class="mat-raised-button mr-10" (click)="close()">Close</button>
        <button class="mat-raised-button mat-primary" (click)="editPlant()">Save</button>
    </mat-dialog-actions>
</mat-dialog-content>
