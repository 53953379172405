import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MenuService } from '../services/menu.service';
import { UbService } from '../services/ub.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ExcelService } from '../services/excel.service';
import { NotificationsService } from 'angular2-notifications';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import * as moment from 'moment';
import { DeliveryMasterDialogComponent } from '../widgets/delivery-master-dialog/delivery-master-dialog.component';

@Component({
  selector: 'app-delivery-master',
  templateUrl: './delivery-master.component.html',
  styleUrls: ['./delivery-master.component.scss']
})
export class DeliveryMasterComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  permission: any;
  documentDate = moment(new Date()).format('YYYY-MM-DD');
  loggedInUser: any;

  public notificationOptions: any = {
    position: ['bottom', 'right'],
    timeOut: 5000,
    lastOnBottom: true,
    pauseOnHover: true,
    clickToClose: true,
    animate: 'fromRight'
  };
  dataSource = new MatTableDataSource<any>();
  displayRowColums: any;
  loading = false;
  constructor(
    public menuService: MenuService,
    private router: Router,
    private service: UbService,
    private dialog: MatDialog,
    private excelService: ExcelService,
    private _service: NotificationsService
  ) {
  }

  applyFilter(filterValue) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    const menu = this.menuService.getMenu(this.router.url);
    this.permission = menu.actions;
    this.getDeliveryDetails();
    this.displayRowColums = ['delivery_type', 'delivery_name', 'short_name', 'address_1', 'pincode', 'district', 'address_2', 'state', 'mobile_1', 'mobile_2', 'email_1', 'email_2','gstin','action'];
  }

  getDeliveryDetails() {
    this.loading = true;
    this.service.getDeliveryDetails().subscribe((response: any) => {
      if (response) {
        if (response && response.result.length > 0) {
          this.dataSource = new MatTableDataSource(response.result);
          this.dataSource.filterPredicate = (data: any, filter: string) => {
            return data.delivery_type?.toLocaleLowerCase().includes(filter) ||
              data.delivery_name?.toLocaleLowerCase().includes(filter) ||
              data.short_name?.toLocaleLowerCase().includes(filter) ||
              data.address_1?.toLocaleLowerCase().includes(filter) ||
              data.city?.toLocaleLowerCase().includes(filter) ||
              data.pincode?.toLocaleLowerCase().includes(filter) ||
              data.district?.toLocaleLowerCase().includes(filter) ||
              data.address_2?.toLocaleLowerCase().includes(filter) ||
              data.mobile_1?.toLocaleLowerCase().includes(filter) ||
              data.mobile_2?.toLocaleLowerCase().includes(filter) ||
              data.email_1?.toLocaleLowerCase().includes(filter)||
              data.email_2?.toLocaleLowerCase().includes(filter) ||
              data.gstin?.toLocaleLowerCase().includes(filter) ||
              data.state?.toLocaleLowerCase().includes(filter);
          };
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      }
      this.loading = false;
    }, error => {
      this._service.error('Error', error.error.message);
      this.loading = false;
    });
  }

  checkPermission(access: string): boolean {
    return this.permission.includes(access);
  }

  addDelivery() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Add Delivery',
      height: '800px',
      width: '300px',
      panelClass: 'addnew-custom-dialog',
      type: 'add'
    };
    const dialogRef = this.dialog.open(DeliveryMasterDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.getDeliveryDetails();
    });
  }

  editRow(rowData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Update Delivery',
      height: '800px',
      width: '300px',
      panelClass: 'addnew-custom-dialog',
      type: 'edit',
      deliveryRowData: rowData
    };
    const dialogRef = this.dialog.open(DeliveryMasterDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.getDeliveryDetails();
    });
  }

  exportAsXLSX(): void {

    const exportData = this.dataSource.data.map((val) => (
      {
        'Id': val.id,
        'Delivery Type': val.delivery_type,
        'Delivery Name': val.delivery_name,
        'Short Name': val.short_name,
        'Address 1': val.address_1,
        'Address 2': val.address_2,
        'Pincode': val.pincode,
        'City': val.city,
        'District': val.district,
        'State': val.state,
        'Email 1': val.email_1,
        'Email 2': val.email_2,
        'Mobile 1': val.mobile_1,
        'Mobile 2': val.mobile_2,
        'GSTIN': val.gstin
      }
    ));
    this.excelService.exportAsExcelFile(exportData, 'Delivery-data');
  }

}
