import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuService } from '../services/menu.service';
import { UbService } from '../services/ub.service';
import { Router } from '@angular/router';
import { ExcelService } from '../services/excel.service';
import { Daterangepicker } from 'ng2-daterangepicker';
import { NotificationsService } from 'angular2-notifications';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
enum UserRole {
  Facilitator = 'Facilitator',
  UBP = 'UBP'
}
const PRODUCT_ID = 1;

@Component({
  selector: 'app-agmarknet-upload',
  templateUrl: './agmarknet-upload.component.html',
  styleUrls: ['./agmarknet-upload.component.scss']
})
export class AgmarknetUploadComponent implements OnInit {
  @ViewChild('uploadExcel') uploadExcel;
  dp1;
  loading = false;
  //datepipe;
  permission: any;
  documentDate = moment(new Date()).format('YYYY-MM-DD');
  startDate = moment().subtract(1, "days").format("YYYY-MM-DD");
  endDate = moment().format("YYYY-MM-DD");

  public daterange: any = {
    start: this.startDate,
    end: this.endDate,
  };
  public notificationOptions: any = {
    position: ['bottom', 'right'],
    timeOut: 5000,
    lastOnBottom: true,
    pauseOnHover: true,
    clickToClose: true,
    animate: 'fromRight'
  };
  displayedColumns = ['market_place_name', 'state', 'district', 'commodity', 'variety', 'grade', 'min', 'max', 'modal', 'date'];
  //documentDate = new Date().toISOString().slice(0, 10).replace('T', ' ');   
 
  loggedInUser: any;
  
  public options: any = {
    locale: { format: 'D MMMM YYYY' },
    alwaysShowCalendars: false,
    maxDate: new Date(),
    startDate: moment().subtract(1, "days").format("D MMMM YYYY"),
    endDate: moment().format("D MMMM YYYY"),
  };
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public menuService: MenuService,
    private router: Router,
    private service: UbService,
    private excelService: ExcelService,
    private _service: NotificationsService) {
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      if (data.state_name) {
        return data.state_name.toLocaleLowerCase().includes(filter) ||
          data.market_center_name.toLocaleLowerCase().includes(filter) ||
          data.variety.toLocaleLowerCase().includes(filter) ||
          data.district_name.toLocaleLowerCase().includes(filter) ||
          data.date_arrival.toString().includes(filter) ||
          data.ag_min.toString().includes(filter) ||
          data.ag_max.toString().includes(filter) ||
          data.modal.toString().includes(filter) ||
          data.commodity.toLocaleLowerCase().includes(filter) ||
          data.grade.toLocaleLowerCase().includes(filter);
      } else {
          return  data.market_center_name.toLocaleLowerCase().includes(filter) ||
            data.variety.toLocaleLowerCase().includes(filter) ||
            data.district_name.toLocaleLowerCase().includes(filter) ||
            data.date_arrival.toString().includes(filter) ||
            data.ag_min.toString().includes(filter) ||
            data.ag_max.toString().includes(filter) ||
            data.modal.toString().includes(filter) ||
            data.commodity.toLocaleLowerCase().includes(filter) ||
            data.grade.toLocaleLowerCase().includes(filter);
      }
    };
  }

  ngOnInit(): void {
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    const menu = this.menuService.getMenu(this.router.url);
    this.permission = menu.actions;
    this.getTableData(this.daterange);
  }
  public selectedDate(value: any, datepicker?: any) {
    // this.startDate = new Date(value.start).toDateString();
    // this.endDate = new Date(value.end).toDateString();    
    // this.datepipe.transform(value, 'M/d/yyyy');
    datepicker.start = value.start;
    datepicker.end = value.end;
    this.daterange.start = moment(value.start).format('YYYY-MM-DD');
    this.daterange.end = moment(value.end).format('YYYY-MM-DD');
    this.daterange.label = value.label;
    this.getTableData(this.daterange);
  }


  checkPermission(access: string): boolean {
    return this.permission.includes(access);
  }
  applyFilter(filterValue) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getTableData(date) {
    this.dataSource.data = [];
    const document_date = {
      start: this.startDate,
      end: this.endDate
    }
    this.loading = true;
    const filter = 'from_date=' + date.start + '&to_date=' + date.end;
    this.service.getAgmarknet(filter).subscribe((response: any) => {
      if (response['status']) {
        this.dataSource.data = response.result;
      }
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loading = false;
    }, err => { 
      this.loading = false;
    });
  }
  onFileChange(ev: any) {
    const reader = new FileReader();
    const file = ev.target.files[0];
    this.uploadExcel.nativeElement.value = '';
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellDates: true, cellNF: false, cellText: false });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      const agmarknetDataExcel = (XLSX.utils.sheet_to_json(ws, { raw: false }));
      let agmarknetData = [];
      for (const data of agmarknetDataExcel) {
        
        const obj = {
          "market_center_name": data['Market Name'],
          "district_name": data['District Name'],
          "commodity": data['Commodity'],
          "variety": data['Variety'],
          "grade": data['Grade'],
          "ag_min": data['Min Price (Rs./Quintal)'],
          "ag_max": data['Max Price (Rs./Quintal)'],
          "modal": data['Modal Price (Rs./Quintal)'],
          "created_by": this.loggedInUser.id,
          "updated_by": this.loggedInUser.id,
          "date_arrival": moment(new Date(data['Price Date'])).format('YYYY-MM-DD'),
        };
        agmarknetData.push(obj);
      }

      this.loading = true;
      this.service.importAgmarknet(agmarknetData).subscribe(
        (response: any) => {
          const notifiedRes = response.result.filter(val => val.status == false);
          if (notifiedRes.length == 0) {
            this._service.success('Success', 'Excel uploaded successfully');
          } else {
            for (let i = 0; i < notifiedRes.length; i++) {
              this._service.error('Error', notifiedRes[i].message);
            }
          }

          this.getTableData(this.daterange);

        }, (error) => {
          this._service.error('Error', error.error.message);
          this.loading = false;
        }
      );
    };
    reader.readAsBinaryString(file);
  }

  exportAsXLSX(): void {
    const exportData = this.dataSource.data.map((val) => (
      {
        id: val.id,
        "Market Place Name": val.market_center_name,
        "State": val.state_name,
        "District": val.district_name,
        "Commodity": val.commodity,
        "Variety": val.variety,
        "Grade": val.grade,
        "Minimum": val.ag_min,
        "Maximum": val.ag_max,
        "Modal": val.modal,
        "Date": val.date_arrival
      }));
    this.excelService.exportAsExcelFile(exportData, 'Agmarknet-Data');
  }

}
