import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuService } from '../services/menu.service';
import { UbService } from '../services/ub.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ExcelService } from '../services/excel.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProductMasterDialogComponent } from '../widgets/product-master-dialog/product-master-dialog.component';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-product-master',
  templateUrl: './product-master.component.html',
  styleUrls: ['./product-master.component.scss']
})
export class ProductMasterComponent implements OnInit {
  loggedInUser;
  loading = false;
  public notificationOptions: any = {
    position: ['bottom', 'right'],
    timeOut: 5000,
    lastOnBottom: true,
    pauseOnHover: true,
    clickToClose: true,
    animate: 'fromRight'
  };
  displayedColumns_data = ['name', 'description', 'sap_material_no', 'action'];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  permission;
  constructor(public menuService: MenuService,
    private service: UbService,
    private excelService: ExcelService,
    private _service: NotificationsService,
    private router: Router,
    public dialog: MatDialog) {
  }

  ngOnInit(): void {
    const menu = this.menuService.getMenu(this.router.url);
    this.permission = menu.actions;
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    this.getTableData();
  }

  checkPermission(access: string): boolean {
    return this.permission.includes(access);
  }

  applyFilter(filterValue) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  addProduct() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Product Details',
      height: '800px',
      width: '300px',
      panelClass: 'addnew-custom-dialog',
      type: 'add'
    };
    const dialogRef = this.dialog.open(ProductMasterDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.ngOnInit();
    })
  }

  editProduct(data) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Edit Product',
      height: '800px',
      width: '300px',
      panelClass: 'addnew-custom-dialog',
      type: 'edit',
      product_id: data.id
    };
    const dialogRef = this.dialog.open(ProductMasterDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.ngOnInit();
    })
  }
  getTableData() {
    this.loading = true;
    this.service.getProducts().subscribe((response: any) => {
      if (response['status']) {
        this.dataSource.data = response.result;
      }
      this.dataSource.filterPredicate = (data: any, filter: string) => {
        return  data.name?.toLocaleLowerCase().includes(filter) ||
          data.description?.toLocaleLowerCase().includes(filter) ||
          data.sap_material_no?.toLocaleLowerCase().includes(filter)
      };
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loading = false;
    }, err => { 
      this.loading = false;
      this._service.error('Error', err.error.message);
    });
  }

  exportAsXLSX(): void {
    const exportData = this.dataSource.data.map((val) => ({
      'Id': val.id,
      "Name": val.name,
      "Description": val.description,
      "Sap Material No": val.sap_material_no
    }));
    this.excelService.exportAsExcelFile(exportData, 'Product-Master-Data');
  }

}
