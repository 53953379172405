<div class="example-container" [class.example-is-mobile]="mobileQuery.matches">


    <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
        <mat-sidenav #snav opened="true" [mode]="mobileQuery.matches ? 'over' : 'side'"
            [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
            <mat-nav-list class="customNavList">
                <div class="fullLength"><img class="sideBarLogo" src="assets/images/main_logo.svg"></div>
            </mat-nav-list>
            <mat-nav-list *ngFor="let menu of menus" color="primary">

                <a mat-list-item (click)="navigateMenu(menu, 'parent')" routerLinkActive="true"
                    [routerLinkActiveOptions]="{ exact: true }" style="height: 34px;"><span><img height="16" style="padding-right: 3px; margin-top: -5px;" width="16" src="assets/images/{{menu[0].menu.icon}}"></span><span style="padding-left: 2px;">{{menu[0].menu.parent_id}}</span></a>
                <div *ngIf="menu[0].menu.menu_id">
                    <mat-nav-list *ngFor="let childMenu of menu">
                        <a style="padding-left: 20px; height: 34px;"  mat-list-item  (click)="navigateMenu(childMenu, 'child')"
                            routerLinkActive="router-link-active">{{childMenu.menu.display_name}}</a>
                    </mat-nav-list>
                </div>
            </mat-nav-list>
        </mat-sidenav>
        <mat-sidenav-content>
            <mat-toolbar class="example-toolbar">
                <div class="flex-ac">
                    <button mat-icon-button (click)="snav.toggle()">
                        <mat-icon>menu</mat-icon>
                    </button>
                </div>

                <div class="flex-ac" *ngIf="userName == undefined">
                    <i class="fa fa-user-circle mr-10" aria-hidden="false"></i>
                    <a class="logout" (click)="logout()">Logout</a>
                </div>
                <div class="flex-ac" *ngIf="userName != undefined">
                    <!-- <a class="logout" >{{userName}}</a> -->
                    <ng-template ngIf="true">
                        <button mat-icon-button [matMenuTriggerFor]="notificationsMenu" (menuClosed)="notificationClosed()">
                            <mat-icon matBadge="{{notificationCount}}" matBadgeColor="warn">notifications</mat-icon>
                        </button>
                        <mat-menu #notificationsMenu="matMenu" class="notificationBlock">
                            <button mat-menu-item *ngFor="let note of notifications">
                                <span>{{note.actual_message}}</span>
                            </button>
                        </mat-menu>
                        <button mat-button [matMenuTriggerFor]="menu" #matMenuTrigger="matMenuTrigger"
                            (mouseenter)="matMenuTrigger.openMenu()">
                            <fa-icon [icon]="['fas', 'user-circle']"></fa-icon> {{userName}}
                        </button>
                        <mat-menu #menu="matMenu" [overlapTrigger]="false">
                            <div (mouseleave)="matMenuTrigger.closeMenu()">
                                <button mat-menu-item (click)="logout()">Logout</button>
                            </div>
                        </mat-menu>
                    </ng-template>
                </div>
            </mat-toolbar>
            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
<simple-notifications [options]="notificationOptions"></simple-notifications>