import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MenuService } from '../services/menu.service';
import { UbService } from '../services/ub.service';
import { NotificationsService } from 'angular2-notifications';
import * as moment from 'moment';
import { Period, UserRole } from '../models/schema';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {
  documentDate = moment(new Date()).format('YYYY-MM-DD');
  loggedInUser: any;
  availableQty: any;
  pendingOffer: any;
  pendingOrder: any;
  auctionNotified: any;
  availableQtyBack: any;
  pendingOfferBack: any;
  pendingOrderBack: any;
  auctionNotifiedBack: any;

  pH6OfferYTD: any;
  pH6OfferL7D: any;
  pH6AuctionYTD: any;
  pH6AuctionL7D: any;
  pH2OfferYTD: any;
  pH2OfferL7D: any;
  pH2AuctionYTD: any;
  pH2AuctionL7D: any;
  is6Row = true;
  productNumber = 6;
  disabled = false;
  color: ThemePalette = 'accent';
  userRoles = UserRole
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private service: UbService,
    private _service: NotificationsService
  ) {
    console.log('Redirected');
    console.log(this.router);
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        console.log(params);
      })
  }

  ngOnInit(): void {
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    this.getAvailableQty(this.loggedInUser.id);
    this.getPendingOffer(this.loggedInUser.id);
    this.getPendingOrder(this.loggedInUser.id);
    this.getAuctionNotified(this.loggedInUser.id);
    this.getPurchaseHistory(this.loggedInUser.id);
  }

  getAvailableQty(id) {
    let filter;
    if (this.loggedInUser.role === UserRole.Facilitator || this.loggedInUser.role === UserRole.PukkaArthiya) {
      filter = 'created_by=' + id + '&document_date=' + this.documentDate;
    } else if (this.loggedInUser.role === UserRole.UBP) {
      filter = 'document_date=' + this.documentDate;
    } else {
      filter = 'document_date=' + this.documentDate;
    }

    this.service.getAvailableQty(filter).subscribe(
      (data: any) => {
        this.availableQty = data.sixRowResult;
        this.availableQtyBack = data;
      }, (error: any) => {
        console.log(error);
        this._service.error('Error', error.error.message);
      }
    );
  }

  getPendingOffer(id) {
    let filter;
    if (this.loggedInUser.role === UserRole.Facilitator || this.loggedInUser.role === UserRole.PukkaArthiya) {
      filter = 'created_by=' + id ;
    } else if (this.loggedInUser.role === UserRole.UBP) {
      filter = ''
    } else {
      filter = '';
    }

    this.service.getPendingOffer(filter).subscribe(
      (data: any) => {

        this.pendingOffer = data.sixRowResult;
        this.pendingOfferBack = data;

        console.log('this.pendingOffer---------->', this.pendingOffer)
      }, (error: any) => {
        console.log(error);
        this._service.error('Error', error.error.message);
      }
    );
  }

  getPendingOrder(id) {
    let filter;
    if (this.loggedInUser.role === UserRole.Facilitator || this.loggedInUser.role === UserRole.PukkaArthiya) {
      filter = 'created_by=' + id ;
    } else if (this.loggedInUser.role === UserRole.UBP) {
      filter = '';
    } else {
      filter = '';
    }

    this.service.getPendingOrder(filter).subscribe(
      (data: any) => {
        this.pendingOrder = data.sixRowResult
        this.pendingOrderBack = data
      }, (error: any) => {
        console.log(error);
        this._service.error('Error', error.error.message);
      }
    );
  }

  getAuctionNotified(id) {
    let filter;
    if (this.loggedInUser.role === UserRole.Facilitator || this.loggedInUser.role === UserRole.PukkaArthiya) {
      filter = 'created_by=' + id + '&document_date=' + this.documentDate;
    } else if (this.loggedInUser.role === UserRole.UBP) {
      filter = 'document_date=' + this.documentDate;
    } else {
      filter = 'document_date=' + this.documentDate;
    }

    this.service.getAuctionNotified(filter).subscribe(
      (data: any) => {
        this.auctionNotified = data.sixRowResult.length
        this.auctionNotifiedBack = data
      }, (error: any) => {
        console.log(error);
        this._service.error('Error', error.error.message);
      }
    );
  }

  getPurchaseHistory(id) {
    let filter;
    if (this.loggedInUser.role === UserRole.Facilitator || this.loggedInUser.role === UserRole.PukkaArthiya) {
      // filter = 'created_by=' + id + '&document_date=' + this.documentDate;
      return false
    } else if (this.loggedInUser.role === UserRole.UBP) {
      filter = 'document_date=' + this.documentDate;
    } else {
      filter = 'document_date=' + this.documentDate;
    }

    this.service.getPurchaseHistory(filter).subscribe(
      (data: any) => {
        this.pH6AuctionYTD = data.sixRowResult.find(val => val.product_id == 1 && val.type == 'Auction' && val.period == Period.YTD)
        if (!this.pH6AuctionYTD) {
          this.pH6AuctionYTD = {
            total_quantity: 0,
            total_amount: 0,
            total_for_price: 0
          }
        }
        this.pH6AuctionL7D = data.sixRowResult.find(val => val.product_id == 1 && val.type == 'Auction' && val.period == Period.Last7Days)
        if (!this.pH6AuctionL7D) {
          this.pH6AuctionL7D = {
            total_quantity: 0,
            total_amount: 0,
            total_for_price: 0
          }
        }


        this.pH6OfferYTD = data.sixRowResult.find(val => val.product_id == 1 && val.type == 'FOR' && val.period == Period.YTD)
        if (!this.pH6OfferYTD) {
          this.pH6OfferYTD = {
            total_quantity: 0,
            total_amount: 0,
            total_for_price: 0
          }
        }

        this.pH6OfferL7D = data.sixRowResult.find(val => val.product_id == 1 && val.type == 'FOR' && val.period == Period.Last7Days)

        if (!this.pH6OfferL7D) {
          this.pH6OfferL7D = {
            total_quantity: 0,
            total_amount: 0,
            total_for_price: 0
          }
        }

        this.pH2AuctionYTD = data.twoRowResult.find(val => val.product_id == 2 && val.type == 'Auction' && val.period == Period.YTD)
        if (!this.pH2AuctionYTD) {
          this.pH2AuctionYTD = {
            total_quantity: 0,
            total_amount: 0,
            total_for_price: 0
          }
        }

        this.pH2AuctionL7D = data.twoRowResult.find(val => val.product_id == 2 && val.type == 'Auction' && val.period == Period.Last7Days)
        if (!this.pH2AuctionL7D) {
          this.pH2AuctionL7D = {
            total_quantity: 0,
            total_amount: 0,
            total_for_price: 0
          }
        }

        this.pH2OfferYTD = data.twoRowResult.find(val => val.product_id == 2 && val.type == 'FOR' && val.period == Period.YTD)
        if (!this.pH2OfferYTD) {
          this.pH2OfferYTD = {
            total_quantity: 0,
            total_amount: 0,
            total_for_price: 0
          }
        }
        this.pH2OfferL7D = data.twoRowResult.find(val => val.product_id == 2 && val.type == 'FOR' && val.period == Period.Last7Days)
        if (!this.pH2OfferL7D) {
          this.pH2OfferL7D = {
            total_quantity: 0,
            total_amount: 0,
            total_for_price: 0
          }
        }

      }, (error: any) => {
        console.log(error);
        this._service.error('Error', error.error.message);
      }
    );
  }

  changeProduct() {
    this.is6Row = !this.is6Row;
    if(!this.is6Row) {
      this.productNumber = 2;
      this.auctionNotified = this.auctionNotifiedBack.twoRowResult.length
      this.availableQty = this.availableQtyBack.twoRowResult
      this.pendingOrder = this.pendingOrderBack.twoRowResult
      this.pendingOffer = this.pendingOfferBack.twoRowResult
    } else {
      this.productNumber = 6;
      this.auctionNotified = this.auctionNotifiedBack.sixRowResult.length
      this.availableQty = this.availableQtyBack.sixRowResult
      this.pendingOrder = this.pendingOrderBack.sixRowResult
      this.pendingOffer = this.pendingOfferBack.sixRowResult
    }
  }

  getRoundOff(amount) {
    return parseFloat(amount).toFixed(2);
  }

  gotoOffer() {
    this.service.changeOffer('SUBMITTED');
    this.router.navigate(['/manageoffer']);
  }

  gotoOrder() {
    this.service.changeOrder('SUBMITTED');
    this.router.navigate(['/order']);
  }

  gotoViewCeiling() {
    this.service.changeNotified(0);
    this.router.navigate(['/viewceiling']);
  }

}
