<div class="container-fluid h100">
    <!-- <app-header></app-header> -->
    <div class="login-container">
        <div class="loginInputBlock h100">
            <div class="loginHeader">
                <img src="assets/images/main_logo.svg">
            </div>
            <div class="loginHeaderlogoText m-t10">
                Barley Procurement
            </div>
            <div class="loginHeaderText m-t10 m-b20">
                Create New Password
            </div>
            <div class="userForm">
                <form [formGroup]="userForm">
                    <div class="form-group">

                        <mat-form-field appearance="fill">
                            <mat-label> User Id </mat-label>
                            <input matInput type="text" class="example-right-align" formControlName="user_id" required>
                            <img matPrefix class="input-icon" src="assets/images/icons/person.svg">
                            <mat-error *ngIf="userForm.get('user_id').hasError('required')">Confirm Password Required
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label> Temp Password </mat-label>
                            <input matInput type="text" class="example-right-align" formControlName="currentPassword" required>
                            <img matPrefix class="input-icon" src="assets/images/icons/lock.svg">
                            <mat-error *ngIf="userForm.get('currentPassword').hasError('required')">Password Required
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label> New Password </mat-label>
                            <input matInput type="password" class="example-right-align" formControlName="newPassword" required>
                            <img matPrefix class="input-icon" src="assets/images/icons/lock.svg">
                            <mat-error *ngIf="userForm.get('newPassword').hasError('required')">New Password Required
                            </mat-error>
                        </mat-form-field>                        

                    </div>
                    <div class="form-group">
                        <button mat-raised-button color="primary" (click)="generateNewPass()" class="w-100">
                            Create Password
                        </button>
                    </div>
                </form>
            </div>
            <div class="alternateBtnBlock m-b20">
                <div class="horizontalLine">
                    <span>-</span>
                </div>
                <div>
                    <button mat-raised-button color="secondary"  class="w-100" routerLink = "/">
                        Cancel
                    </button>
                </div>
            </div>
            <div class="loginFooter m-t10 m-b20">
                <div class="loginFooterCompany">
                    UNITED BREWERIES LIMITED
                </div>
                <div>
                    &#169; 2020 | All rights reserved
                </div>
            </div>

        </div>
        <div class="loginCarasoleBlock">
            <ngb-carousel [showNavigationArrows]="true" [showNavigationIndicators]="true" interval="12000"
                [keyboard]="true" [pauseOnHover]="false" [wrap]="true" [activeId]="'secondSlide'">
                <ng-template ngbSlide *ngFor="let slide of slides">
                    <div class="picsum-img-wrapper">
                        <img src="{{slide.image}}" alt="Carousel" class="mh-100" style="width: 100%; height: 100vh">
                    </div>
                    <div class="carousel-caption">
                        <h3>{{slide.title}}</h3>
                        <p>{{slide.text}}</p>
                    </div>
                </ng-template>
            </ngb-carousel>
        </div>

    </div>
</div>