import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { ExcelService } from '../services/excel.service';
import { MenuService } from '../services/menu.service';
import { UbService } from '../services/ub.service';
import { RoleMasterDialogComponent } from '../widgets/role-master-dialog/role-master-dialog.component';


@Component({
  selector: 'app-role-master',
  templateUrl: './role-master.component.html',
  styleUrls: ['./role-master.component.scss']
})
export class RoleMasterComponent implements OnInit {
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayColumns = ['role', 'active' , 'action'];
  currentPage = 0;
  public notificationOptions: any = {
    position: ['bottom', 'right'],
    timeOut: 5000,
    lastOnBottom: true,
    pauseOnHover: true,
    clickToClose: true,
    animate: 'fromRight'
  };
  permission;
  loading = false;
  constructor(
    private service: UbService,
    private _service: NotificationsService,
    private excelService: ExcelService,
    private dialog: MatDialog,
    private menuService: MenuService,
    private router: Router
  ) { 
  }

  ngOnInit(){
    const menu = this.menuService.getMenu(this.router.url);
    this.permission = menu.actions;
    this.loading = true;
    this.service.getAllRoles().subscribe((response: any) => {
      if (response['status']) {
        for (const data of response.result) {
          data.active = data.active == 1 ? 'Active' : 'Inactive';
        }
        response.result = response.result.sort((a, b ) => b.id - a.id);
        this.dataSource.data = response.result;
        this.dataSource.filterPredicate = (data: any, filter: string) => {
          return  data.role?.toLocaleLowerCase().includes(filter) ||
            data.active?.toLocaleLowerCase().includes(filter)
        };
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        
      }
      this.loading = false;
    }, error => {
      this._service.error('Error', error.error.message);
      this.loading = false;
    });
  }

  checkPermission(access: string): boolean {
    return this.permission.includes(access);
  }

  applyFilter(filterValue) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  exportAsXLSX(): void {
    const exportData = this.dataSource.data.map((val) => (
      {
        'ID': val.id,
        'Role': val.role,
        'Active' : val.active
      }
    ));
    this.excelService.exportAsExcelFile(exportData, 'role-master');
  }

  mapMandiDestination() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Role Details',
      height: '800px',
      width: '300px',
      panelClass: 'addnew-custom-dialog',
      type : 'add'
    };
    const dialogRef = this.dialog.open(RoleMasterDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.ngOnInit();
    })
  }

  editMandi(data) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Role Details',
      height: '800px',
      width: '300px',
      panelClass: 'addnew-custom-dialog',
      type : 'edit',
      role_id : data.id
    };
    const dialogRef = this.dialog.open(RoleMasterDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.ngOnInit();
    })
  }

  onPageChange(currentPage) {
  }

}
