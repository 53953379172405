<h2 class="dialog-heading" *ngIf="title" mat-dialog-title>{{title}}</h2>
<mat-dialog-content style="width:500px">
    <form class="example-full-width" [formGroup]="forLineItemForm">
        
        <mat-form-field class="example-full-width">
            <input formControlName="parameter" placeholder="Parameter" matInput type='text' required>
            <mat-error>
                <span *ngIf="forLineItemForm.get('parameter').hasError('required')">Parameter is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="group" placeholder="Group" matInput type='text' required>
            <mat-error>
                <span *ngIf="forLineItemForm.get('group').hasError('required')">Group is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="data_type" placeholder="Data Type" matInput type='text' required>
            <mat-error>
                <span *ngIf="forLineItemForm.get('data_type').hasError('required')">Data Type is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="sequence" placeholder="Sequence" matInput type='number' required>
            <mat-error>
                <span *ngIf="forLineItemForm.get('sequence').hasError('required')">Sequence is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="base_type" placeholder="Base Type" matInput type='text'>
            
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="base" placeholder="Base" matInput type='text'>
            
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="value" placeholder="Value" matInput type='text' required>
            <mat-error>
                <span *ngIf="forLineItemForm.get('value').hasError('required')">Value is required</span>
            </mat-error>
            <mat-error>
                <span *ngIf="forLineItemForm.get('value').hasError('pattern')">Value should be greater than 0 and should be upto 2 decimal place</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label [class.active]="forLineItemForm.get('valid_from').value">Valid From</mat-label>
            <input formControlName="valid_from" matInput  [matDatepicker]="picker1" [max]="forLineItemForm.value.valid_to">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1 color="primary"></mat-datepicker>
            <mat-error>
                <span *ngIf="forLineItemForm.get('valid_from').hasError('required')">Valid From is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label [class.active]="forLineItemForm.get('valid_to').value">Valid To</mat-label>
            <input formControlName="valid_to" matInput  [matDatepicker]="picker2" [min]="forLineItemForm.value.valid_from">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2 color="primary"></mat-datepicker>
            <mat-error>
                <span *ngIf="forLineItemForm.get('valid_to').hasError('required')">Valid To is required</span>
            </mat-error>
        </mat-form-field>
    </form>
    <mat-dialog-actions>
        <button class="mat-raised-button mr-10" (click)="close()">Close</button>
        <button class="mat-raised-button mat-primary" (click)="saveForLineItem()">Save</button>
    </mat-dialog-actions>
</mat-dialog-content>
