import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationsService } from 'angular2-notifications';
import { UbService } from 'src/app/services/ub.service';

@Component({
  selector: 'app-role-manager-master-dialog',
  templateUrl: './role-manager-master-dialog.component.html',
  styleUrls: ['./role-manager-master-dialog.component.scss']
})
export class RoleManagerMasterDialogComponent implements OnInit {
  @ViewChild('matRefAction') matRefAction;
  dialogData: any;
  title: string;
  roleManagerForm: FormGroup;
  roleManagerData: any;
  loggedInUser: any;
  roleList: any;
  menuList: any;
  type: any;
  isActive = [{ name: 'Active', id: 1 }, { name: 'InActive', id: 0 }];
  device = [{ name: 'Mobile', value: 'M' }, { name: 'Web', value: 'W' }];
  actionList: [];
  constructor(
    private service: UbService,
    private fb: FormBuilder,
    private notificationService: NotificationsService,
    private dialogRef: MatDialogRef<RoleManagerMasterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.title = data.title;
    this.roleManagerData = data.roleManagerRowData;
    this.type = data.type;
  }

  ngOnInit(): void {
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    this.roleManagerForm = this.fb.group({
      role: ['', [Validators.required]],
      menu: ['', [Validators.required]],
      device_type: ['', [Validators.required]],
      is_active: ['' , [Validators.required]],
      actions: [''],
    });
    this.roleManagerForm.controls['is_active'].setValue(1);
    this.getMenu();
  }

  close() {
    this.dialogRef.close();
  }

  getMenu() {
    this.service.getAllMenu().subscribe((res) => {
      if (res['status']) {
        this.menuList = res['result'];
        this.getRole();
      }
    }, error => {
      this.notificationService.error('Error', error.error.message);
    });
  }

  getRole() {
    this.service.getAllRoles().subscribe((res) => {
      if (res['status']) {
        this.roleList = res['result'];
        if (this.roleManagerData) {
          console.log(this.roleManagerData);
          this.roleManagerForm.controls['role'].setValue(this.roleManagerData.role_id);
          this.roleManagerForm.controls['menu'].setValue(this.roleManagerData.menu_id);
          this.roleManagerForm.controls['device_type'].setValue(this.roleManagerData.device_type);
          this.roleManagerForm.controls['actions'].setValue(this.roleManagerData.actions.split(','));
          this.roleManagerForm.controls['is_active'].setValue(this.roleManagerData.is_active == 'Active'  ? 1 : 0);
          this.changeMenu(this.roleManagerData.menu_id);
        }
      }
    }, error => {
      this.notificationService.error('Error', error.error.message);
    });
  }

  saveLocations() {
    for (const c in this.roleManagerForm.controls) {
      if (this.roleManagerForm.controls.hasOwnProperty(c)) {
        this.roleManagerForm.controls[c].markAsTouched();
      }
    }
    if (this.roleManagerForm.valid) {
      const payload = {
        "role_id": this.roleManagerForm.value.role,
        "menu_id": this.roleManagerForm.value.menu,
        "device_type": this.roleManagerForm.value.device_type,
        "actions": this.roleManagerForm.value.actions.lenght > 0 ? this.roleManagerForm.value.actions.join(',') : '',
        "is_active": this.roleManagerForm.value.is_active
      };
      if (this.type === 'add') {
        this.service.createRoleManager(payload).subscribe((res) => {
          if (res['status']) {
            this.notificationService.success('Success', res['message']);
            this.dialogRef.close();
          }
        }, error => {
          this.notificationService.error('Error', error.error.message);
        });
      } else if (this.type === 'edit') {

        this.service.updateRoleManager(this.roleManagerData.id, payload).subscribe((res) => {
          if (res['status']) {
            this.notificationService.success('Success', res['message']);
            this.dialogRef.close();
          }
        }, error => {
          this.notificationService.error('Error', error.error.message);
        });

      }
    }
  }

  changeMenu(menuId) {
    const filter = `menu_id=${menuId}`
    console.log('event---------->', event)
    this.service.filterPrivilege(filter).subscribe((res: any) => {
      if (res.status && res.result) {
        this.actionList = res.result.map(val=>val.action)
      }
    }, error => {
      this.notificationService.error('Error', error.error.message);
    });
  }
  actionSelChange(event) {
    console.log('actionSelChange event--------->', event)
  }
  actionChange(event) {
    console.log('actionChange event--------->', event)
  }

}
