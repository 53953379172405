import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { UbService } from './ub.service';
import { AuthGuardService } from './auth-guard.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(public dataService: UbService, public router: Router, private authGuard: AuthGuardService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const authToken = this.dataService.getToken();

        const isValidTone = this.authGuard.validateToken(authToken);

        if (!isValidTone) {
            this.router.navigate(['login']);
        }

        req = req.clone({
            setHeaders: {
                'Content-Type': 'application/json; charset=utf-8',
                Accept: 'application/json',
                Authorization: `${authToken}`,
            },
        });

        return next.handle(req);
    }
}
