import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { UbService } from '../services/ub.service';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-create-new-password',
  templateUrl: './create-new-password.component.html',
  styleUrls: ['./create-new-password.component.scss']
})
export class CreateNewPasswordComponent implements OnInit {

  userForm: FormGroup;
  error = false;
  showOtp = false;
  windowRef: any;
  submitted = false;
  loginUb = false;
  loginUser = false;
  user;
  slides = [
    {
      image: 'assets/images/banners/barley-banner1.jpg',
      title: 'caption',
      text: 'text test1'
    },
    {
      image: 'assets/images/banners/barley-banner2.jpg',
      title: 'caption2',
      text: 'text test2'
    },
    {
      image: 'assets/images/banners/barley-banner3.jpg',
      title: 'caption3',
      text: 'text test3'
    }
  ];
  public settings = {
    length: 4,
    numbersOnly: true,
    timer: 12,
    btnClass: 'mt-5'
  };

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private service: UbService,
    private _service: NotificationsService
  ) { }

  ngOnInit(): void {
    this.userForm = this.formBuilder.group({
      currentPassword: [null, Validators.required],
      newPassword: [null, Validators.required],
      user_id: [null, Validators.required],
    });
  }

  checkPassword(control) {
    const enteredPassword = control.value;
    const passwordCheck = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    return (!passwordCheck.test(enteredPassword) && enteredPassword) ? { requirements: true } : null;
  }

  get f() { return this.userForm.controls; }

  generateNewPass() {
    const payload = {
      user_id: this.userForm.value.user_id,
      currentPassword: this.userForm.value.currentPassword,
      newPassword: this.userForm.value.newPassword
    };
    this.service.updatePass(payload).subscribe((response: any) => {
      if (response && response.status == true) {
        this._service.success('Success', response.message);
        this.router.navigate(['createnewpassword']);
        this.router.navigate(['/']);
      }else {
        this._service.error('Error', ' Password Update Failed');
      }
    }, error => {
      this._service.error('Error', error.error.message);
    });

  }

}
