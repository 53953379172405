import { Component } from '@angular/core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { UbService } from './services/ub.service';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Ubl';

  constructor( private library: FaIconLibrary, private ubService: UbService, private router: Router, private activatedRoute:ActivatedRoute) {
    library.addIconPacks(fas);
  }
}
