<div class="pageHeader">
  Manage Order
</div>
<div class="dateRangeBlock" style="padding: 20px 0px 0px;">
  <div class="col-md-6">
    <div class="input-wrapper">
      <input type="text" class="daterangeInput input-field" name="daterangeInput" daterangepicker [options]="options"
        (selected)="selectedDate($event, daterange)" /> &nbsp;
      <fa-icon [icon]="['fas', 'calendar']" class="icon-favIcon primColor"></fa-icon>
    </div>
  </div>
  <div class="col-md-6 buttonAlignRight">
    <div *ngIf="checkPermission('update')">
      <button type="button" mat-raised-button (click)="fileInput.click()">Upload File</button>
      <input hidden #fileInput type="file" id="file">
    </div>
    <div *ngIf="activeLink == orderTabText.Barley_6_Row && checkPermission(OrderAction.create_6_row)">
      <button class="mat-raised-button mat-primary mr-10 ml-10" (click)="manageOrderDialog('add')">
        <fa-icon [icon]="['fas', 'plus']"></fa-icon> Add New
      </button>
    </div>

    <div *ngIf="activeLink == orderTabText.Barley_2_Row && checkPermission(OrderAction.create_2_row)">
      <button class="mat-raised-button mat-primary mr-10 ml-10" (click)="manageOrderDialog('add')">
        <fa-icon [icon]="['fas', 'plus']"></fa-icon> Add New
      </button>
    </div>

  </div>
</div>
<div class="tabsBlock">
  <nav mat-tab-nav-bar>
    <a mat-tab-link *ngFor="let link of links" (click)="activeLink = link;getTableData(link);"
      [active]="activeLink == link"> {{link}}
    </a>
  </nav>
  <div *ngIf="activeLink == orderTabText.Barley_6_Row" class="tabContentBlock">
    <div *ngIf="checkPermission(OrderAction.submit_6_row)">
      <ng-container *ngTemplateOutlet="facilitatorManageOrder"></ng-container>
    </div>
    <div *ngIf="checkPermission(OrderAction.review_6_row)">
      <ng-container *ngTemplateOutlet="ubFlowManageOrder"></ng-container>
    </div>
  </div>
  <div *ngIf="activeLink == orderTabText.Barley_2_Row" class="tabContentBlock">
    <div *ngIf="checkPermission(OrderAction.submit_2_row)">
      <ng-container *ngTemplateOutlet="facilitatorManageOrder"></ng-container>
    </div>
    <div *ngIf="checkPermission(OrderAction.review_2_row)">
      <ng-container *ngTemplateOutlet="ubFlowManageOrder"></ng-container>
    </div>
  </div>
</div>
<ng-template #facilitatorManageOrder let-entry="entry">
  <div class="row">
    <div class="col-md-4 pl-0">
      <mat-form-field class="cusWidth">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
        <button mat-icon-button matPrefix>
          <mat-icon class="primColor">search</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="col-md-2">
      <mat-form-field class="example-full-width">
        <mat-label required>Status</mat-label>
        <mat-select class="matSelect" (selectionChange)="changeStatus($event)" [(value)]="statusSelected" required>
          <mat-option *ngFor="let status of avilableStatus" [value]="status">{{status}}</mat-option>
        </mat-select>
      </mat-form-field>

    </div>
    <div class="col-md-6 buttonAlignRight tabBtnAlign">
      <button class="mat-raised-button mat-primary mr-10" (click)="openDialogConfirmation('all', 'submit order')">Submit
        All</button>

      <button class="mat-raised-button mat-primary mr-10 exportBtn" (click)="exportAsXLSX()"><fa-icon [icon]="['fas', 'download']"></fa-icon>
        Export Excel</button>
    </div>
  </div>
  <div class="row" style="padding: 20px 0px 0px;" >
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
            </mat-checkbox>
        </td>
    </ng-container>
      <ng-container matColumnDef="order_number">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Order No. </mat-header-cell>
        <mat-cell *matCellDef="let element" class="wc-200"> {{element.order_number}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="order_type">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Order Type </mat-header-cell>
        <mat-cell *matCellDef="let element" class="wc-200"> {{element.type}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="order_created">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Order Created</mat-header-cell>
        <mat-cell *matCellDef="let element" class="wc-200"> {{element.document_date | date: 'dd/MM/yyyy' }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="mandi_name">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Mandi Name </mat-header-cell>
        <mat-cell *matCellDef="let element" class="wc-200"> {{element.mandiObj.mandi_name}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="pakka_arthiya">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Pakka Arthiya </mat-header-cell>
        <mat-cell *matCellDef="let element" class="wc-200"> {{element.userObj.first_name}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="reference_no">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Reference No. </mat-header-cell>
        <mat-cell *matCellDef="let element" class="wc-200"> {{element.ref_num}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="delivery_location">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Delivery Location </mat-header-cell>
        <mat-cell *matCellDef="let element" class="wc-200"> {{element.destinationObj.short_name}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="plant_code">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Plant Code </mat-header-cell>
        <mat-cell *matCellDef="let element" style="width: 100px; margin-right: 50px">
          <mat-form-field >
            
            <mat-select (selectionChange)="changePlaneCode($event.value, element)"
              [(value)]="element.plant_master_id">
              <mat-option>Select Plant Code</mat-option>
              <mat-option *ngFor="let option of plantCodeList" [value]="option.id"
                selected="element.plant_master_id == option.id">{{option.code}}</mat-option>
            </mat-select>
          </mat-form-field>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="previous_year_price">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Previous Year Price </mat-header-cell>
        <mat-cell *matCellDef="let element" class="wc-200"> {{element.previous_year_price}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="base_price">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Base Price </mat-header-cell>
        <mat-cell *matCellDef="let element" class="wc-200"> {{element.base_price}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="quantity">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Quantity (In Quintal) </mat-header-cell>
        <mat-cell *matCellDef="let element" class="wc-200"> {{element.quantity}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="for_price">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> For Price </mat-header-cell>
        <mat-cell *matCellDef="let element" class="wc-200"> {{element.order_for_price}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="createdBy">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Created By </mat-header-cell>
        <mat-cell *matCellDef="let element" class="wc-200"> {{element.createdByObj.first_name}} </mat-cell>
      </ng-container>
      
      
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200">Actions</mat-header-cell>
        <mat-cell *matCellDef="let element" class="wc-200">
          <span *ngIf="element.status === 'SAVED' && orderTabText.Barley_6_Row == activeLink" style="display:flex">
              <span class="action-btn" *ngIf="checkPermission(OrderAction.update_6_row)" (click)="manageOrderDialog(element)">Edit</span> |
              <span class="action-btn submitActionBtn" *ngIf="checkPermission(OrderAction.submit_6_row)" (click)="openDialogConfirmation(element, 'Submit Order')">Submit</span>
          </span>

          <span *ngIf="element.status === 'SAVED' && orderTabText.Barley_2_Row == activeLink" style="display:flex">
            <span class="action-btn" *ngIf="checkPermission(OrderAction.update_2_row)" (click)="manageOrderDialog(element)">Edit</span> |
            <span class="action-btn submitActionBtn" *ngIf="checkPermission(OrderAction.submit_2_row)" (click)="openDialogConfirmation(element, 'Submit Order')">Submit</span>
        </span>

        <span *ngIf="element.status !== 'SAVED'" style="color: green; font-weight: bold;">
          {{element.status | titlecase }}
          </span>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayFacilitator"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayFacilitator;"></mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="dataSource.data.length > 50 ? [10, 20, 50, dataSource.data.length] : [10, 20, 50]" showFirstLastButtons></mat-paginator>
  </div>
  <div class="row" style="padding: 20px 0px 0px;"
    *ngIf="dataSource.data.length === 0 || dataSource.data.length === null">
    <div class="noDataMessage">
      No Data Found on {{documentDate}}
    </div>
  </div>
</ng-template>

<ng-template #ubFlowManageOrder let-entry="entry">
  <div class="row">
    <div class="col-md-6 pl-0">
      <mat-form-field class="cusWidth">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
        <button mat-icon-button matPrefix>
          <mat-icon class="primColor">search</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="col-md-2">
      <mat-form-field class="example-full-width">
        <mat-label required>Status</mat-label>
        <mat-select class="matSelect" (selectionChange)="changeStatus($event)" [(value)]="statusSelected" required>
          <mat-option *ngFor="let status of avilableStatus" [value]="status">{{status}}</mat-option>
        </mat-select>
      </mat-form-field>

    </div>
    <div class="col-md-4 buttonAlignRight tabBtnAlign">
      <button class="mat-raised-button mat-primary mr-10 rejectBtn" (click)="rejectAll()">Reject All</button>
      <button class="mat-raised-button mat-primary mr-10 acceptBtn" (click)="acceptAll()">Accept All</button>
      <button class="mat-raised-button mat-primary mr-10 exportBtn" (click)="exportAsXLSX()"><fa-icon [icon]="['fas', 'download']"></fa-icon>Export Excel</button>
    </div>
  </div>
  <div class="row" style="padding: 20px 0px 0px;" >
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="order_number">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Order No. </mat-header-cell>
        <mat-cell *matCellDef="let element" class="wc-200"> {{element.order_number}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="order_type">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Order Type </mat-header-cell>
        <mat-cell *matCellDef="let element" class="wc-200"> {{element.type}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="document_date">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Document Date </mat-header-cell>
        <mat-cell *matCellDef="let element" class="wc-200"> {{element.document_date}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="mandi_name">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Mandi Name </mat-header-cell>
        <mat-cell *matCellDef="let element" class="wc-200"> {{element.mandiObj.mandi_name}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="pakka_arthiya">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Pakka Arthiya </mat-header-cell>
        <mat-cell *matCellDef="let element" class="wc-200"> {{element.userObj.first_name}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="delivery_location">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Delivery Location </mat-header-cell>
        <mat-cell *matCellDef="let element" class="wc-200"> {{element.destinationObj.short_name}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="reference_no">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Reference No. </mat-header-cell>
        <mat-cell *matCellDef="let element" class="wc-200"> {{element.ref_num}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="quantity">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Quantity (In Quintal) </mat-header-cell>
        <mat-cell *matCellDef="let element" class="wc-200"> {{element.quantity}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="base_price">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Base Price </mat-header-cell>
        <mat-cell *matCellDef="let element" class="wc-200"> {{element.base_price}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="for_price">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> For Price </mat-header-cell>
        <mat-cell *matCellDef="let element" class="wc-200"> {{element.order_for_price}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="plant_code">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Plant Code </mat-header-cell>
        <mat-cell *matCellDef="let element" style="width: 100px; margin-right: 50px">
          <mat-form-field >
            
            <mat-select (selectionChange)="changePlaneCode($event.value, element)"
              [(value)]="element.plant_master_id">
              <mat-option>Select Plant Code</mat-option>
              <mat-option *ngFor="let option of plantCodeList" [value]="option.id"
                selected="element.plant_master_id == option.id">{{option.code}}</mat-option>
            </mat-select>
          </mat-form-field>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="previous_year_price">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Previous Year Price </mat-header-cell>
        <mat-cell *matCellDef="let element" class="wc-200"> {{element.previous_year_price}} </mat-cell>
      </ng-container>
      
      <ng-container matColumnDef="suggested_price">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Suggested Price </mat-header-cell>
        <mat-cell *matCellDef="let element" class="wc-200"> {{element.suggested_price}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="ceiling_price">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Ceiling Price </mat-header-cell>
        <mat-cell *matCellDef="let element" class="wc-200"> {{element.ceiling_price}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="createdBy">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Created By </mat-header-cell>
        <mat-cell *matCellDef="let element" class="wc-200"> {{element.createdByObj.first_name}} </mat-cell>
      </ng-container>
      
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="w-250">Actions</mat-header-cell>
        <mat-cell *matCellDef="let element" class="wc-250">
          <span  *ngIf="element.status === 'SUBMITTED'">
            <!-- <span class="action-btn" (click)="manageOrderDialog(element)">Edit</span> | -->
            <span class="action-btn color-accept" (click)="manageOrderDialog(element, 'APPROVED')">Accept</span> | <span
              class="action-btn color-reject" (click)="reject(element, 'REJECTED')">Reject</span>
          </span>
          <span *ngIf="element.status !== 'SAVED'" style="color: green; font-weight: bold;">
            {{element.status | titlecase }}
          </span>
        </mat-cell>
      </ng-container>


      <mat-header-row *matHeaderRowDef="displayUbFlow"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayUbFlow;"></mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="dataSource.data.length > 50 ? [10, 20, 50, dataSource.data.length] : [10, 20, 50]" showFirstLastButtons></mat-paginator>
    <simple-notifications [options]="notificationOptions"></simple-notifications>
  </div>
  <div class="row" style="padding: 20px 0px 0px;"
    *ngIf="dataSource.data.length === 0 || dataSource.data.length === null">
    <div class="noDataMessage">
      No Data Found on {{documentDate}}
    </div>
  </div>
</ng-template>