import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationsService } from 'angular2-notifications';
import { UbService } from 'src/app/services/ub.service';
import { HIGH_CONTRAST_MODE_ACTIVE_CSS_CLASS } from '@angular/cdk/a11y/high-contrast-mode/high-contrast-mode-detector';
@Component({
  selector: 'app-cluster-master-dialog',
  templateUrl: './cluster-master-dialog.component.html',
  styleUrls: ['./cluster-master-dialog.component.scss']
})
export class ClusterMasterDialogComponent implements OnInit {

  clusterForm = this.fb.group({
    name: ['', [Validators.required]],
    description: ['', [Validators.required]],
  });
  loggedInUser;
  title;
  cluster_id;
  type;
  constructor(
    private services: UbService,
    private fb: FormBuilder,
    private notificationService: NotificationsService,
    private dialogRef: MatDialogRef<ClusterMasterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.title = data.title;
    this.cluster_id = data.cluster_id;
    this.type = data.type;
  }

  ngOnInit(): void {
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    if (this.type == 'edit') {
      this.getClusterData(this.cluster_id);
    }
  }
  close() {
    this.dialogRef.close();
  }

  getClusterData(id) {
    this.services.getClusterByID(id).subscribe((res) => {
      if (res['status']) {
        const data = res['result'];
        this.clusterForm.controls.name.setValue(data.name);
        this.clusterForm.controls.description.setValue(data.description);
      }
    }, err => {
      this.notificationService.error('Error', err?.error?.message);
    })
  }

  addCluster() {
    for (const c in this.clusterForm.controls) {
      if (this.clusterForm.controls.hasOwnProperty(c)) {
        this.clusterForm.controls[c].markAsTouched();
      }
    }
    if (this.clusterForm.valid) {
      const payload = {
        "name": this.clusterForm.value.name,
        "description": this.clusterForm.value.description,
      }
      this.services.addCluster(payload).subscribe((res: any) => {
        this.notificationService.success('Success', res.message);
        this.dialogRef.close();
      }, error => {
        this.notificationService.error('Error', error.error.message);
        this.dialogRef.close();
      });
    }
  }

  editCluster() {
    for (const c in this.clusterForm.controls) {
      if (this.clusterForm.controls.hasOwnProperty(c)) {
        this.clusterForm.controls[c].markAsTouched();
      }
    }
    if (this.clusterForm.valid) {
      const payload = {
        "name": this.clusterForm.value.name,
        "description": this.clusterForm.value.description
      }
      this.services.updateCluster(this.cluster_id, payload).subscribe((res) => {
        this.notificationService.success('Success', 'Cluster Updated Successfully');
        this.dialogRef.close();
      }, error => {
        this.notificationService.error('Error', error.error.message);
        this.dialogRef.close();
      });
    }
  }


}
