<div class="container-fluid">

    <div class="report-header">
        <div class="row pageHeader">
            Offer Report
        </div>
    </div>

    <div class="report-body">
        <form [formGroup]="filterForm">
            <div class="row">
                <div class="col-md-12">
                    <p class="body-text">Fill Mandatory Details to execute report</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Select State</mat-label>
                        <mat-select #matRefState formControlName="state" (openedChange)="stateChange($event)" multiple required>

                            <mat-option (selectionChange)="stateSelChange($event)" value="All">All</mat-option>
                            <mat-option (selectionChange)="stateSelChange($event)" *ngFor="let state of stateList"
                                [value]="state.state_id">{{state.state }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="filterForm.get('state').hasError('required')"> State Value Required </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-md-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Discrict</mat-label>
                        <mat-select #matRefDistrict formControlName="district" (openedChange)="districtChange($event)"
                            multiple required>

                            <mat-option (selectionChange)="districtSelChange($event)" value="All">All</mat-option>
                            <mat-option (selectionChange)="districtSelChange($event)"
                                *ngFor="let district of districtList" [value]="district.district">{{district.district}}
                                - {{district.state}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="filterForm.get('district').hasError('required')"> Discrict Value Required </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-md-3">
                    <mat-form-field appearance="fill">
                        <mat-label>City</mat-label>
                        <mat-select #matRefCity formControlName="city" (openedChange)="cityChange($event)" multiple required>
                            <mat-option (selectionChange)="citySelChange($event)" value="All">All</mat-option>
                            <mat-option (selectionChange)="citySelChange($event)" *ngFor="let city of cityList"
                                [value]="city.id">{{city.town_city}} - {{city.district}} - {{city.state}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="filterForm.get('city').hasError('required')"> City Value Required </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-md-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Mandi</mat-label>
                        <mat-select #matRefMandi formControlName="mandi" (openedChange)="mandiChange($event)" multiple required>

                            <mat-option (selectionChange)="mandiSelChange($event)" value="All">All</mat-option>
                            <mat-option (selectionChange)="mandiSelChange($event)" *ngFor="let mandi of mandiList"
                                [value]="mandi.id">{{mandi.mandi_name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="filterForm.get('mandi').hasError('required')"> Mandi Value Required </mat-error>
                    </mat-form-field>
                </div>

            </div>

            <div class="row">

                <div class="col-md-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Delivery</mat-label>
                        <mat-select #matRefDelivery formControlName="destination_id" (openedChange)="deliveryChange($event)" multiple required>

                            <mat-option (selectionChange)="deliveryChange($event)" value="All">All</mat-option>
                            <mat-option (selectionChange)="deliveryChange($event)" *ngFor="let deli of deliveryList"
                                [value]="deli.id">{{deli.delivery_name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="filterForm.get('destination_id').hasError('required')"> Delivery Value Required </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-md-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Arthiya</mat-label>
                        <mat-select #matRefAratiya formControlName="arthiya_id" (openedChange)="arthiyaChange($event)" multiple required>

                            <mat-option (selectionChange)="arthiyaChange($event)" value="All">All</mat-option>
                            <mat-option (selectionChange)="arthiyaChange($event)" *ngFor="let user of userList"
                                [value]="user.id">{{user.first_name}} {{user.last_name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="filterForm.get('arthiya_id').hasError('required')"> Arthiya Value Required </mat-error>
                    </mat-form-field>
                </div>               

                <div class="col-md-3">

                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <p class="body-text">Fill Arrival details</p>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <mat-form-field>
                        <mat-label required>offer Status</mat-label>
                        <mat-select class="matSelect" formControlName="status">
                            <mat-option *ngFor="let status of statusList" [value]="status.value">
                                {{status.title}}
                            </mat-option>
                        </mat-select>

                    </mat-form-field>
                </div>
                <div class="col-md-4">

                </div>
                <div class="col-md-4">

                </div>
            </div>

            <div class="row">

                <div class="col-md-3">
                    <mat-form-field>
                        <input formControlName="order_number_from" placeholder="Order Number from" matInput type='number'>
                    </mat-form-field>
                </div>

                <div class="col-md-1 body-to-text">
                    To
                </div>

                <div class="col-md-3">
                    <mat-form-field>
                        <input formControlName="order_number_to" placeholder="Order Number to" matInput type='number'>
                    </mat-form-field>
                </div>
                <div class="col-md-1">

                </div>
                <div class="col-md-4">
                    <label class="mb-0">Order Date</label>
                    <div class="input-wrapper">
                        <input type="text" formControlName="order_date" class="daterangeInput input-field" name="daterangeInput" daterangepicker
                            [options]="options" (selected)="selectedDate($event, daterange)" /> &nbsp;
                        <fa-icon [icon]="['fas', 'calendar']" class="icon-favIcon primColor"></fa-icon>
                    </div>
                </div>
               

            </div>

            <div class="row">
                <div class="col-md-3">
                    <mat-form-field>
                        <input formControlName="offer_number_from" placeholder="Offer Number from" matInput type='number'>
                    </mat-form-field>
                </div>

                <div class="col-md-1 body-to-text">
                    To
                </div>

                <div class="col-md-3">
                    <mat-form-field>
                        <input formControlName="offer_number_to" placeholder="Offer Number to" matInput type='number'>
                    </mat-form-field>
                </div>

                <div class="col-md-1">

                </div>

                <div class="col-md-4">
                    <label class="mb-0">Offer Date</label>
                    <div class="input-wrapper">
                        <input type="text" formControlName="offer_date" class="daterangeInput input-field" name="daterangeInput" daterangepicker
                            [options]="options" (selected)="selectOfferDate($event, daterange)" /> &nbsp;
                        <fa-icon [icon]="['fas', 'calendar']" class="icon-favIcon primColor"></fa-icon>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-3">

                </div>
                <div class="col-md-3">

                </div>
              
                <div class="col-md-2">
                    <button class="mat-raised-button mat-primary mr-10 ml-10" (click)="execute()">
                        Execute
                    </button>
                </div>

                <div class="col-md-2">
                    <button class="mat-raised-button mat-primary mr-10 ml-10" (click)="reset()">
                        Reset
                    </button>
                </div>
                <div class="col-md-2">
                    <button [disabled] = "!dataSource.data[0]" class="mat-raised-button mat-primary exportBtn" (click)="exportAsXLSX()">
                        <fa-icon [icon]="['fas', 'download']"></fa-icon> Export Excel
                      </button>
                </div>
            </div>
        </form>


        <div class="row" style="margin-top: 20px;">
            <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
            <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
                <ng-container matColumnDef="state">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> State</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.mandiObj.state}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="district">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> District </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.mandiObj.district}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="city">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> City </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.mandiObj.city}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="offer_number">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Offer Number </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.offer_number}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="offer_date">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Offer Date </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.document_date}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="order_number">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Order Number </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.order_number}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="order_type">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Order Type </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.type}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="order_date">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Order Date </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.document_date}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="mandi_name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Mandi Name </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.mandiObj.mandi_name}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="pakka_arthiya">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Pakka Arthiya </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.user_id}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="reference_number">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Reference Number </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.ref_num}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="delivery_location">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Delivery Location </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.destinationObj?.delivery_name}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="base_price">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Base Price <br> (&#x20b9;)</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.base_price}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="quantity">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Quantity <br> (In Quintal) </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.quantity}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="for_price">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> For Price <br> (&#x20b9;) </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.order_for_price}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="counter_offer_price">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Counter Offer Price <br> (&#x20b9;) </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.counter_offer_price}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="created_by">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Created By</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.createdByObj?.first_name}} {{element.createdByObj?.last_name}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="offer_created_date">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Offer Created Date </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.created_at | date: 'dd/MM/yyyy h:mm:ss'}} </mat-cell>
                </ng-container>

                
                <ng-container matColumnDef="counter_offer_date">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Counter Offer Date </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.counter_offer_date}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="offer_change_state">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Offer Accept/Reject Date </mat-header-cell>
                    <mat-cell *matCellDef="let element"> 
                        <span class="submittedRow" *ngIf="element.is_counter_offer_set == true">Accepted</span>
                        <span class="notSubmittedRow" *ngIf="element.is_counter_offer_set == false">Rejected</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="order_creation_date">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Order Creation Date </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.created_at | date: 'dd/MM/yyyy h:mm:ss'}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="is_submitted">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                       {{element.status}}
                    </mat-cell>
                </ng-container>     
                
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No Data Found on {{documentDate}};</td>
                </tr>
            </mat-table>
            <mat-paginator [pageSizeOptions]="dataSource.data.length > 50 ? [10, 20, 50, dataSource.data.length] : [10, 20, 50]" showFirstLastButtons></mat-paginator>

        </div>
    </div>
</div>