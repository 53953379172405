import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { UbService } from '../../services/ub.service';
import * as moment from 'moment';
import { NotificationsService } from 'angular2-notifications';
import { UserRole } from 'src/app/models/schema';
@Component({
  selector: 'app-manage-order-dialog',
  templateUrl: './manage-order-dialog.component.html',
  styleUrls: ['./manage-order-dialog.component.scss']
})
export class ManageOrderDialogComponent implements OnInit {
  UserRole = UserRole;
  dialogData: any;
  mandiList: any;
  selectedMandi: any;
  selectedProduct: any;
  selectedLocation: any;
  selectedPakkaArthiya: any;
  manageOrderForm: FormGroup;
  documentDate = new Date().toISOString().slice(0, 10).replace('T', ' ')
  deliveryLocation: any;
  higherBasePrice: boolean;
  statusSaved: 'SAVED';
  orderType: 'Auction';
  pakka_arathiyas: any;
  userDetails: any;
  selectedUser: any;
  plantCodeList = [];
  isShowPlantCode = false;
  status;
  public notificationOptions: any = {
    position: ['bottom', 'right'],
    timeOut: 5000,
    lastOnBottom: true,
    pauseOnHover: true,
    clickToClose: true,
    animate: 'fromRight'
  };
  refPattern = '^(?=.*[A-Za-z])(?=.*[0-9])[A-Za-z0-9]+$';

  isUbLoggedIn = false;
  constructor(
    private services: UbService,
    private fb: FormBuilder,
    private notificationService: NotificationsService,
    private dialogRef: MatDialogRef<ManageOrderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.dialogData = data;
    this.status = data.status;
    this.mandiList = data.mandiList;
    if (this.dialogData.editItem) {
      this.getMandiList(this.dialogData.editItem.user_id);
    } else {
      this.getMandiList(this.dialogData.user.id);
    }

    if (this.dialogData.user.role === UserRole.Facilitator || this.dialogData.user.role === UserRole.PukkaArthiya) {
      this.manageOrderForm = this.fb.group({
        mandi_id: ['', [Validators.required]],
        pakka_arthiya: ['', [Validators.required]],
        referenceNo: [''],
        quantity: ['', [Validators.pattern('^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$')]],
        document_date: [this.documentDate, [Validators.required]],
        product_id: ['', [Validators.required]],
        delivery_location: ['', [Validators.required]],
        ceiling_price: ['', [Validators.required]],
        base_price: ['', [Validators.required, Validators.pattern('^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$')]]
      });
    } else {
      this.isShowPlantCode = true;
      this.manageOrderForm = this.fb.group({
        mandi_id: ['', [Validators.required]],
        pakka_arthiya: ['', [Validators.required]],
        referenceNo: ['', [Validators.required]],
        quantity: ['', [Validators.pattern('^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$')]],
        document_date: [this.documentDate, [Validators.required]],
        product_id: ['', [Validators.required]],
        delivery_location: ['', [Validators.required]],
        ceiling_price: ['', [Validators.required]],
        base_price: ['', [Validators.required, Validators.pattern('^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$')]],
        plant_master_id: ['', [Validators.required]]
      });
    }
    if (this.dialogData.userRole == UserRole.PukkaArthiya) {
      this.manageOrderForm.controls['pakka_arthiya'].setValue(this.dialogData.user.user_id);
    }
    this.manageOrderForm.controls.mandi_id.valueChanges.subscribe((result) => {
      this.getplantCode();
    });
    if (this.dialogData.editItem) {
      console.log('this.dialogData.editItem.mandi_id------------->', this.dialogData.editItem.mandi_id)
      this.getDeliveryLocation(this.dialogData.editItem.mandi_id);
      this.manageOrderForm.controls['mandi_id'].setValue(this.dialogData.editItem.mandi_id);
      this.selectedMandi = this.dialogData.editItem.mandi_id
      this.manageOrderForm.controls['pakka_arthiya'].setValue(this.dialogData.editItem.user_id);
      this.manageOrderForm.controls['referenceNo'].setValue(this.dialogData.editItem.ref_num);
      this.manageOrderForm.controls['quantity'].setValue(this.dialogData.editItem.quantity);
      this.manageOrderForm.controls['document_date'].setValue(this.dialogData.editItem.document_date);
      this.manageOrderForm.controls['product_id'].setValue(this.dialogData.editItem.product_id);
      this.manageOrderForm.controls['delivery_location'].setValue(this.dialogData.editItem.destination_id);
      this.manageOrderForm.controls['ceiling_price'].setValue(this.dialogData.editItem.order_for_price);
      this.manageOrderForm.controls['base_price'].setValue(this.dialogData.editItem.base_price);
      if (this.isShowPlantCode) {
        this.manageOrderForm.controls['plant_master_id'].setValue(this.dialogData.editItem.plant_master_id);
        this.manageOrderForm.controls['pakka_arthiya'].setValue(this.dialogData.editItem.userObj.first_name);
      }
      this.manageOrderForm.controls.mandi_id.valueChanges.subscribe((result) => {
        this.getplantCode();
      });
    } else {
      // this.generateReferenceNo()
    }
    this.manageOrderForm.controls.mandi_id.valueChanges.subscribe((result) => {
      this.getDeliveryLocation(result);
      this.getplantCode();
    });
  }

  getplantCode() {
    this.plantCodeList = [];
    this.services.getAllPlantCode().subscribe((res) => {
      if (res['status']) {
        this.plantCodeList = res['result'];
      }
    }, error => {
      this.notificationService.error('Error', error.error.message);
    })
  }

  ngOnInit(): void {

    // this.getMandiList();
  }

  onKeyUpEvent(event: any) {
    if (this.manageOrderForm.value && this.manageOrderForm.value.ceiling_price) {
      if (event.target && event.target.value > this.manageOrderForm.value.ceiling_price) {
        this.higherBasePrice = true;
      } else {
        this.higherBasePrice = false;
      }
    }
  }

  createOrder(type) {
    const data = {
      status: 'SAVED',
      type: 'Auction',
      mandi_id: this.manageOrderForm.value.mandi_id,
      document_date: this.documentDate,
      user_id: this.dialogData.user.role === 'Facilitator' ? this.selectedUser : this.dialogData.user.id,
      product_id: this.manageOrderForm.value.product_id,
      quantity: this.manageOrderForm.value.quantity,
      ref_num: this.manageOrderForm.value.referenceNo.toString(),
      destination_id: this.manageOrderForm.value.delivery_location,
      base_price: this.manageOrderForm.value.base_price,
      ceiling_price: this.manageOrderForm.value.ceiling_price,
      suggested_price: this.manageOrderForm.value.ceiling_price,
      created_by: this.dialogData.user.id,
      updated_by: this.dialogData.user.id,
    }

    if (type == 'add' && this.dialogData.user.role == UserRole.UBP) {
      data['plant_master_id'] = this.manageOrderForm.value.plant_master_id;
      data['status'] = 'SAVED';
    }

    if (type == 'edit') {
      delete data.created_by;
      delete data.user_id;
      data['plant_master_id'] = this.manageOrderForm.value.plant_master_id;
      data['status'] = 'SUBMITTED';
    }
    return data;
  }

  submitOrder() {
    for (const c in this.manageOrderForm.controls) {
      if (this.manageOrderForm.controls.hasOwnProperty(c)) {
        this.manageOrderForm.controls[c].markAsTouched();
      }
    }
    if (this.manageOrderForm.valid) {
      if (this.manageOrderForm.value.base_price > this.manageOrderForm.value.ceiling_price) {
        this.notificationService.error('Error', 'Base price should be lesser than ceiling price');
        return false;
      }

      let data = this.createOrder('add');
      if (data) {
        this.services.createOrder(data).subscribe(
          (orderResponse: any) => {
            if (orderResponse.status === true) {
              if (this.dialogData.user.role == UserRole.UBP) {
                let obj = {
                  ids: [orderResponse.result.id]
                }
                this.services.submitOrder(obj).subscribe(
                  (submitResonse: any) => {
                    this.dialogRef.close();
                    this.notificationService.success('Success', orderResponse.message);
                  })
              } else {
                this.dialogRef.close();
                this.notificationService.success('Success', orderResponse.message);
              }
            }
          }, (error) => {
            console.log(error)
            this.notificationService.error('Error', error.error.message);
          }
        );
      }
    }
  }

  editOrder() {
    for (const c in this.manageOrderForm.controls) {
      if (this.manageOrderForm.controls.hasOwnProperty(c)) {
        this.manageOrderForm.controls[c].markAsTouched();
      }
    }
    if (this.manageOrderForm.valid) {
      let data = this.createOrder('edit');
      if (data) {
        this.services.updateOrder(data, this.dialogData.editItem.id).subscribe(
          (updateResponse: any) => {
            if (updateResponse.status === true) {
              this.dialogRef.close();
            }
          }, (err) => {
            console.log(err);
          }
        );
      }
    }
  }

  approveOrder() {
    for (const c in this.manageOrderForm.controls) {
      if (this.manageOrderForm.controls.hasOwnProperty(c)) {
        this.manageOrderForm.controls[c].markAsTouched();
      }
    }
    if (this.manageOrderForm.valid) {
      const data = {
        type: 'Auction',
        mandi_id: this.manageOrderForm.value.mandi_id,
        document_date: this.documentDate,
        product_id: this.manageOrderForm.value.product_id,
        quantity: this.manageOrderForm.value.quantity,
        ref_num: this.manageOrderForm.value.referenceNo.toString(),
        destination_id: this.manageOrderForm.value.delivery_location,
        base_price: this.manageOrderForm.value.base_price,
        order_price: this.manageOrderForm.value.ceiling_price,
        reviewed_by: this.dialogData.user.id,
        plant_master_id: this.manageOrderForm.value.plant_master_id,
        updated_by: this.dialogData.user.id
      }
      if (data) {
        this.services.updateOrder(data, this.dialogData.editItem.id).subscribe(
          (updateResponse: any) => {
            if (updateResponse.status === true) {
              const payload = {
                ids: [this.dialogData.editItem.id],
                status: 'APPROVED'
              }
              this.services.reviewOrder(payload).subscribe(
                (response: any) => {
                  if (response.status === true) {
                    this.dialogRef.close();
                  }
                }, (err) => {
                  console.log(err)
                }
              )
            }
          }, (err) => {
            console.log(err);
          }
        );
      }
    }
  }

  close() {
    this.dialogRef.close();
  }

  getDeliveryLocation(mandi) {
    if (mandi) {
      this.services.getMandiDestination(mandi).subscribe(
        (destinationResponse: any) => {
          if (destinationResponse.status === true) {
            this.deliveryLocation = destinationResponse.result;
            if (this.manageOrderForm.value.mandi_id && this.manageOrderForm.value.product_id && this.documentDate) {
              this.getCeilingPrice();
            }
          } else {

          }
        }, (err) => {
          console.log(err)
        }
      );
      // if (this.dialogData.user.role === 'Facilitator') {
      this.getMandiById(mandi);
      // }
    } else {
      console.log("error")
    }
  }

  getMandiById(mandiId) {
    this.services.getMandiFilter(mandiId).subscribe(
      (mandiResponse: any) => {
        if (mandiResponse.status === true) {
          if (mandiResponse.result.length > 0) {
            this.userDetails = mandiResponse.result;
            this.userDetails = this.userDetails.filter((itm) => itm.userObj.role_id === 2);
            if (this.userDetails.userObj) {
              this.selectedUser = this.userDetails[0].userObj.id;
            }
          } else {

          }
        } else {
          console.log("Error")
        }
      }
    );
  }

  getUserDetails(event) {
  }

  generateReferenceNo() {
    let referenceNo = Math.floor(Math.random() * (999 - 100 + 1) + 100);
    if (referenceNo) {
      this.manageOrderForm.controls['referenceNo'].setValue(referenceNo);
    }
  }


  getCeilingPrice() {
    this.manageOrderForm.controls['ceiling_price'].setValue('');
    if (this.manageOrderForm.value.document_date) {
      this.documentDate = moment(new Date(this.manageOrderForm.value.document_date)).format('YYYY-MM-DD');
    }
    if (this.manageOrderForm.value.mandi_id && this.manageOrderForm.value.product_id && this.documentDate && this.manageOrderForm) {
      this.services.getCeilingPrice(this.manageOrderForm.value.mandi_id, this.manageOrderForm.value.product_id, this.documentDate).subscribe(
        (response: any) => {
          if (response.status === true) {
            if (response.result) {
              this.manageOrderForm.controls['ceiling_price'].setValue(response.result.ceiling_price);
            } else {
              this.notificationService.error('Error', 'Ceiling Price is not set');
            }

            if (this.manageOrderForm.value.base_price) {
              this.onKeyUpEvent(this.manageOrderForm.value.base_price);
            }
          } else {
            console.log('error')
          }
        }, (err) => {
          console.log(err);
        }
      );
    }
  }


  getMandiList(userId) {
    
    if (this.dialogData.user.role == UserRole.Facilitator || this.dialogData.user.role == UserRole.PukkaArthiya) {
      this.services.getUserMandi(userId).subscribe(
        (mandiResponse: any) => {
          if (mandiResponse.status === true) {
            this.mandiList = mandiResponse.result;
          }
          else {
            console.log('error')
          }
        }, (err) => {
          console.log(err)
        }
      );
    } else {
      this.isUbLoggedIn = true;
      this.services.getAllMandi().subscribe(
        (mandiResponse: any) => {
          if (mandiResponse.status === true) {
            this.mandiList = mandiResponse.result;
          }
          else {
            console.log('error')
          }
        }, (err) => {
          console.log(err)
        }
      );
    }
  }
}
