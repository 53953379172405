<div class="container-fluid">

    <div class="tabContentBlock">
        <div class="row pageHeader">
            Dashboard
        </div>
    </div>

    <div class="card-div" class="toggle-div">
        <div class="row">
            <div class="col-md-12">
                <span class="toggle-header"> {{productNumber}} Row Barley Activity
                </span>
                <span class="toggle-span">6 Row
                    <mat-slide-toggle class="example-margin" (change)="changeProduct()" [color]="color"
                        [checked]="!is6Row" [disabled]="disabled">
                        2 Row
                    </mat-slide-toggle>
                </span>
            </div>
        </div>

        <div class="flex-container">

            <div style="flex-grow: 1; ">
                <mat-card class="dashboard-card card-quantity">
                    <div class="quantity-container">
                        <div class="dashboard-icon">
                            <img src="assets/images/dashboard/ico_dash_1.svg">
                        </div>
                        <div class="quantity-text-container" *ngIf="availableQty">
                            <div class="card-text-header-1">{{availableQty.available_qty ? availableQty.available_qty :
                                0}}
                                ql
                            </div>
                            <div class="card-text-header-2">Available Qty</div>
                            <div class="card-text-header-3">{{availableQty.available_qty ? availableQty.available_qty :
                                0}}
                                /
                                {{availableQty.mandi_count ? availableQty.mandi_count : 0}} mandis</div>
                            <div class="card-text-header-4" [routerLink]="['/arrivaldata']" >View Details<span><img class="ico-forward"
                                        src="assets/images/dashboard/ico_forward.svg"></span></div>
                        </div>
                    </div>
                </mat-card>
            </div>

            <div style="flex-grow: 1; ">
                <mat-card class="dashboard-card card-offer">
                    <div class="quantity-container">
                        <div class="dashboard-icon">
                            <img src="assets/images/dashboard/ico_dash_2.svg">
                        </div>
                        <div class="quantity-text-container" *ngIf="pendingOffer">
                            <div class="card-text-header-1">{{pendingOffer.pending_offer ? pendingOffer.pending_offer :
                                0}}
                                ql
                            </div>
                            <div class="card-text-header-2">Pending Offers</div>
                            <div class="card-text-header-3">{{pendingOffer.row_count ? pendingOffer.row_count : 0}}
                                Offers
                            </div>
                            <div class="card-text-header-4" (click)="gotoOffer()">View Details<span><img class="ico-forward"
                                        src="assets/images/dashboard/ico_forward.svg"></span></div>
                        </div>
                    </div>
                </mat-card>
            </div>

            <div style="flex-grow: 1; ">
                <mat-card class="dashboard-card card-order">
                    <div class="quantity-container">
                        <div class="dashboard-icon">
                            <img src="assets/images/dashboard/ico_dash_3.svg">
                        </div>
                        <div class="quantity-text-container" *ngIf="pendingOrder">
                            <div class="card-text-header-1">{{pendingOrder.pending_order ? pendingOrder.pending_order :
                                0}}
                                ql
                            </div>
                            <div class="card-text-header-2">Pending Orders</div>
                            <div class="card-text-header-3">{{pendingOrder.row_count ? pendingOrder.row_count : 0}}
                                Orders
                            </div>
                            <div class="card-text-header-4" (click)="gotoOrder()">View Details<span><img class="ico-forward"
                                        src="assets/images/dashboard/ico_forward.svg"></span></div>
                        </div>
                    </div>
                </mat-card>
            </div>



            <div style="flex-grow: 1; ">
                <mat-card class="dashboard-card card-auction">
                    <div class="quantity-container">
                        <div class="dashboard-icon">
                            <img src="assets/images/dashboard/ico_dash_4.svg">
                        </div>
                        <div class="quantity-text-container">
                            <div class="card-text-header-1">{{auctionNotified}}</div>
                            <div class="card-text-header-2">Not yet Notified</div>
                            <div class="card-text-header-3">Todays Auction price</div>
                            <div class="card-text-header-4" (click)="gotoViewCeiling()">View Details<span><img class="ico-forward"
                                        src="assets/images/dashboard/ico_forward.svg"></span></div>
                        </div>
                    </div>
                </mat-card>
            </div>

        </div>
    </div>

    <div class="table-container" *ngIf="loggedInUser.role != userRoles.Facilitator && loggedInUser.role != userRoles.PukkaArthiya">

        <table class="table table-hover table-bordered">
            <thead>
                <tr class="ph-table">
                    <th colspan="5" style="font-weight: 500;">Purchase History - 6 Row</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td colspan="2"></td>
                    <td class="table-bold">Quantity (Quintal)</td>
                    <td class="table-bold">Average Purchase Price</td>
                    <td class="table-bold">Average FOR Rate</td>
                </tr>
                <tr *ngIf="pH6AuctionL7D">
                    <td rowspan="3" class="table-bold">Last 7 Days</td>
                    <td class="table-bold"> Auction</td>
                    <td>{{pH6AuctionL7D.sum_total_quantity}}</td>
                    <td>{{getRoundOff(pH6AuctionL7D.sum_total_amount)}}</td>
                    <td>{{getRoundOff(pH6AuctionL7D.sum_total_for_amount)}}</td>
                </tr>
                <tr *ngIf="pH6OfferL7D">
                    <td class="table-bold">Offer</td>
                    <td>{{pH6OfferL7D.sum_total_quantity}}</td>
                    <td>{{getRoundOff(pH6OfferL7D.sum_total_amount)}}</td>
                    <td>{{getRoundOff(pH6OfferL7D.sum_total_for_amount)}}</td>
                </tr>
                <tr *ngIf="pH6OfferL7D">
                    <td class="table-bold">Total</td>
                    <td>{{pH6OfferL7D.sum_total_quantity + pH6AuctionL7D.sum_total_quantity}}</td>
                    <td>{{getRoundOff(pH6OfferL7D.sum_total_amount + pH6AuctionL7D.sum_total_amount / pH6OfferL7D.sum_count +
                        pH6OfferL7D.sum_count)}}</td>
                    <td>{{getRoundOff(pH6OfferL7D.sum_total_amount + pH6AuctionL7D.sum_total_for_amount / pH6OfferL7D.sum_count +
                        pH6OfferL7D.sum_total_for_amount)}}</td>

                </tr>

                <tr *ngIf="pH6AuctionYTD">
                    <td rowspan="3" class="table-bold">YTD</td>
                    <td class="table-bold"> Auction</td>
                    <td>{{pH6AuctionYTD.sum_total_quantity}}</td>
                    <td>{{getRoundOff(pH6AuctionYTD.sum_total_amount)}}</td>
                    <td>{{getRoundOff(pH6AuctionYTD.sum_total_for_amount)}}</td>
                </tr>
                <tr *ngIf="pH6OfferYTD">
                    <td class="table-bold">Offer</td>
                    <td>{{pH6OfferYTD.sum_total_quantity}}</td>
                    <td>{{getRoundOff(pH6OfferYTD.sum_total_amount)}}</td>
                    <td>{{getRoundOff(pH6OfferYTD.sum_total_for_amount)}}</td>
                </tr>
                <tr *ngIf="pH6OfferYTD">
                    <td class="table-bold">Total</td>
                    <td>{{pH6OfferYTD.sum_total_quantity + pH6AuctionYTD.sum_total_quantity}}</td>
                    <td>{{getRoundOff(pH6OfferYTD.sum_total_amount + pH6AuctionYTD.sum_total_amount / pH6OfferYTD.sum_count +
                        pH6OfferYTD.sum_count)}}</td>
                    <td>{{getRoundOff(pH6OfferYTD.sum_total_amount + pH6AuctionYTD.sum_total_for_amount / pH6OfferYTD.sum_count +
                        pH6OfferYTD.sum_total_for_amount)}}</td>

                </tr>
            </tbody>
        </table>

        <table class="table table-hover table-bordered">
            <thead>
                <tr style="background-color: #264595; height: 50px; color: #FFF;">
                    <th colspan="5" style="font-weight: 500;">Purchase History - 2 Row</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td colspan="2"></td>
                    <td class="table-bold">Quantity (Quintal)</td>
                    <td class="table-bold">Average Purchase Price</td>
                    <td class="table-bold">Average FOR Rate</td>
                </tr>
                <tr *ngIf="pH2AuctionL7D">
                    <td rowspan="3" class="table-bold">Last 7 Days</td>
                    <td class="table-bold"> Auction</td>
                    <td>{{pH2AuctionL7D.sum_total_quantity}}</td>
                    <td>{{pH2AuctionL7D.sum_total_amount}}</td>
                    <td>{{pH2AuctionL7D.sum_total_for_amount}}</td>
                </tr>
                <tr *ngIf="pH2OfferL7D">
                    <td class="table-bold">Offer</td>
                    <td>{{pH2OfferL7D.sum_total_quantity}}</td>
                    <td>{{getRoundOff(pH2OfferL7D.sum_total_amount)}}</td>
                    <td>{{getRoundOff(pH2OfferL7D.sum_total_for_amount)}}</td>
                </tr>
                <tr *ngIf="pH2OfferL7D">
                    <td class="table-bold">Total</td>
                    <td>{{pH2OfferL7D.sum_total_quantity + pH2AuctionL7D.sum_total_quantity}}</td>
                    <td>{{getRoundOff(pH2OfferL7D.sum_total_amount + pH2AuctionL7D.sum_total_amount / pH2OfferL7D.sum_count +
                        pH2OfferL7D.sum_count)}}</td>
                    <td>{{getRoundOff(pH2OfferL7D.sum_total_amount + pH2AuctionL7D.sum_total_for_amount / pH2OfferL7D.sum_count +
                        pH2OfferL7D.sum_total_for_amount)}}</td>

                </tr>

                <tr *ngIf="pH2AuctionYTD">
                    <td rowspan="3" class="table-bold">YTD</td>
                    <td class="table-bold"> Auction</td>
                    <td>{{pH2AuctionYTD.sum_total_quantity}}</td>
                    <td>{{getRoundOff(pH2AuctionYTD.sum_total_amount)}}</td>
                    <td>{{getRoundOff(pH2AuctionYTD.sum_total_for_amount)}}</td>
                </tr>
                <tr *ngIf="pH2OfferYTD">
                    <td class="table-bold">Offer</td>
                    <td>{{pH2OfferYTD.sum_total_quantity}}</td>
                    <td>{{getRoundOff(pH2OfferYTD.sum_total_amount)}}</td>
                    <td>{{getRoundOff(pH2OfferYTD.sum_total_for_amount)}}</td>
                </tr>
                <tr *ngIf="pH2OfferYTD">
                    <td class="table-bold">Total</td>
                    <td>{{pH2OfferYTD.sum_total_quantity + pH2AuctionYTD.sum_total_quantity}}</td>
                    <td>{{getRoundOff(pH2OfferYTD.sum_total_amount + pH2AuctionYTD.sum_total_amount / pH2OfferYTD.sum_count +
                        pH2OfferYTD.sum_count)}}</td>
                    <td>{{getRoundOff(pH2OfferYTD.sum_total_amount + pH2AuctionYTD.sum_total_for_amount / pH2OfferYTD.sum_count +
                        pH2OfferYTD.sum_total_for_amount)}}</td>

                </tr>
            </tbody>
        </table>
    </div>
</div>