import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationsService } from 'angular2-notifications';
import { UbService } from 'src/app/services/ub.service';
import * as moment from 'moment';

@Component({
  selector: 'app-forline-item-master-dialog',
  templateUrl: './forline-item-master-dialog.component.html',
  styleUrls: ['./forline-item-master-dialog.component.scss']
})
export class ForlineItemMasterDialogComponent implements OnInit {

  title: string;
  forLineItemForm: FormGroup;
  mandiList = [];
  locationList = [];
  deliveryLocationList = [];
  loggedInUser: any;
  forlineRowItem: any;
  type: any;

  constructor(
    private service: UbService,
    private fb: FormBuilder,
    private notificationService: NotificationsService,
    private dialogRef: MatDialogRef<ForlineItemMasterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.title = data.title;
    this.forlineRowItem = data.forlineRowItem;
    this.type = data.type;
    console.log(this.forlineRowItem);
  }

  ngOnInit(): void {
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    this.forLineItemForm = this.fb.group({
      parameter: ['', [Validators.required]],
      group: ['', [Validators.required]],
      data_type: ['', [Validators.required]],
      sequence: ['', [Validators.required]],
      base_type: [''],
      base: [''],
      value: ['', [Validators.required , Validators.pattern('^[1-9]+[0-9]*(\.?[0-9]{1,2}$)?')]],
      valid_from: ['', [Validators.required]],
      valid_to: ['', [Validators.required]]
    });
    this.getAllMandis();
  }

  getAllMandis() {
    this.service.getAllMandi().subscribe((res) => {
      if (res['status']) {
        this.mandiList = res['result'];
        if (this.forlineRowItem) {
          this.forLineItemForm.controls.parameter.setValue(this.forlineRowItem.parameter);
          this.forLineItemForm.controls.group.setValue(this.forlineRowItem.group);
          this.forLineItemForm.controls.data_type.setValue(this.forlineRowItem.data_type);
          this.forLineItemForm.controls.sequence.setValue(this.forlineRowItem.sequence);
          this.forLineItemForm.controls.base_type.setValue(this.forlineRowItem.base_type);
          this.forLineItemForm.controls.base.setValue(this.forlineRowItem.base);
          this.forLineItemForm.controls.value.setValue(this.forlineRowItem.value);
          this.forLineItemForm.controls.valid_from.setValue(this.forlineRowItem.valid_from);
          this.forLineItemForm.controls.valid_to.setValue(this.forlineRowItem.valid_to);
        }
      }
    }, error => {
      this.notificationService.error('Error', error.error.message);
    });
  }

  close() {
    this.dialogRef.close();
  }

  saveForLineItem() {
    for (const c in this.forLineItemForm.controls) {
      if (this.forLineItemForm.controls.hasOwnProperty(c)) {
        this.forLineItemForm.controls[c].markAsTouched();
      }
    }
    const payload = {
      parameter: this.forLineItemForm.value.parameter,
      group: this.forLineItemForm.value.group,
      data_type: this.forLineItemForm.value.data_type,
      sequence: this.forLineItemForm.value.sequence,
      base_type: this.forLineItemForm.value.base_type,
      base: this.forLineItemForm.value.base,
      value: this.forLineItemForm.value.value,
      valid_from: this.forLineItemForm.value.valid_from,
      valid_to: this.forLineItemForm.value.valid_to
    };
    console.log(payload);
    if (this.forLineItemForm.valid) {
      if (this.type === 'add') {
        this.service.createForlineItem(payload).subscribe((res) => {
          if (res['status']) {
            this.notificationService.success('Success', res['message']);
            this.dialogRef.close();
          }
        }, error => {
          this.notificationService.error('Error', error.error.message);
        });
      } else if (this.type === 'edit') {
  
        this.service.updateForlineItem(this.forlineRowItem.id, payload).subscribe((res) => {
          if (res['status']) {
            this.notificationService.success('Success', res['message']);
            this.dialogRef.close();
          }
        }, error => {
          this.notificationService.error('Error', error.error.message);
        });
  
      }
    }
  }

}
