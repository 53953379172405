import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationsService } from 'angular2-notifications';
import { UbService } from 'src/app/services/ub.service';
import { HIGH_CONTRAST_MODE_ACTIVE_CSS_CLASS } from '@angular/cdk/a11y/high-contrast-mode/high-contrast-mode-detector';

interface Months {  
  month:number;
}
@Component({
  selector: 'app-premium-master-dialog',
  templateUrl: './premium-master-dialog.component.html',
  styleUrls: ['./premium-master-dialog.component.scss']
})
export class PremiumMasterDialogComponent implements OnInit {

  premiumForm = this.fb.group({
    product_name: ['', [Validators.required]],
    state_name: ['', [Validators.required]],
    premium: ['', [Validators.required ,Validators.pattern('^[1-9]+[0-9]*(\.?[0-9]{1,2}$)?')]],
    year: ['',[Validators.required ,Validators.pattern('^[1-9]+[0-9]*(\.?[0-9]{1,2}$)?')]],
    month: ['',[Validators.required]]
  });
    loggedInUser;
    title;
    premium_id;
    type;
    states;
    products;

    months: Months[] = [
      {month:1},
      {month:2},
      {month:3},
      {month:4},
      {month:5},
      {month:6},
      {month:7},
      {month:8},
      {month:9},
      {month:10},
      {month:11},
      {month:12}
  
      ];
    constructor(
      private services : UbService,
      private fb: FormBuilder,
      private notificationService: NotificationsService,
      private dialogRef: MatDialogRef<PremiumMasterDialogComponent>,
      @Inject(MAT_DIALOG_DATA) data) { 
        this.title = data.title;
        this.premium_id = data.premium_id;
        this.type = data.type;
      }
  
    ngOnInit(): void {
      const user: any = localStorage.getItem('ubuser');
      this.loggedInUser = JSON.parse(user);
      this.getStates();
      this.getProdcuts();
      if(this.premium_id){
      this.getProductData(this.premium_id);
      }
    }
    close() {
      this.dialogRef.close();
    }
  
    getProductData(id) {
      this.services.getPremiumByID(id).subscribe((res) => {
        if (res['status']) {
          const data = res['result'];
          this.premiumForm.controls.product_name.setValue(data.product_id);
          this.premiumForm.controls.state_name.setValue(data.state_id);
          this.premiumForm.controls.premium.setValue(data.premium);
          this.premiumForm.controls.year.setValue(data.year);
          this.premiumForm.controls.month.setValue(data.month);
        }
      } , err => {})
    }

    
    getStates() {
      this.states = [];
      this.services.getState().subscribe((res) => {
        if (res['status']) {
          this.states = res['result'];
          this.states = this.states.filter(val => val.status == '1')
        }
      }, err => {});
    }
    getProdcuts() {
      this.products = [];
      this.services.getProduct().subscribe((res) => {
        if (res['status']) {
          this.products = res['result'];
        }
      }, err => {});
    }
  
    addProduct() {
      for (const c in this.premiumForm.controls) {
        if (this.premiumForm.controls.hasOwnProperty(c)) {
          this.premiumForm.controls[c].markAsTouched();
        }
      }
      if (this.premiumForm.valid) {
         const payload = {
          "product_id": this.premiumForm.value.product_name,
          "state_id":this.premiumForm.value.state_name,   
          "premium": this.premiumForm.value.premium,
          "year": this.premiumForm.value.year,
          "month":this.premiumForm.value.month,
          created_by: this.loggedInUser.id,
          updated_by: this.loggedInUser.id
        }
        this.services.addPremium(payload).subscribe((res) => {
          this.notificationService.success('Success', 'Premium Added Successfully');
          this.dialogRef.close();
        }, error => {
          this.notificationService.error('Error', error.error.message);
          this.dialogRef.close();
        });
      }
    }
  
    editProduct() {
      for (const c in this.premiumForm.controls) {
        if (this.premiumForm.controls.hasOwnProperty(c)) {
          this.premiumForm.controls[c].markAsTouched();
        }
      }
      if (this.premiumForm.valid) {     
        const payload = {
          "product_id": this.premiumForm.value.product_name,
          "state_id":this.premiumForm.value.state_name, 
          "premium": this.premiumForm.value.premium,
          "year": this.premiumForm.value.year,
          "month":this.premiumForm.value.month,
          updated_by: this.loggedInUser.id
        }
        this.services.updatePremium(this.premium_id , payload).subscribe((res) => {
          this.notificationService.success('Success', 'Premium Updated Successfully');
          this.dialogRef.close();
        }, error => {
          this.notificationService.error('Error', error.error.message);
          this.dialogRef.close();
        });
      }
    }
  

}
