<div class="container-fluid">

    <div class="tabContentBlock">
        <div class="row pageHeader">
            MSP Details
        </div>

        <div class="row" style="margin-top: 12px;">
            <div class="col-md-6 pl-0">
                <mat-form-field class="cusWidth">
                    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
                    <button mat-icon-button matPrefix>
                        <mat-icon class="primColor">search</mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <div class="col-md-6 buttonAlignRight tabBtnAlign">
                <button class="mat-raised-button mat-button-color mr-10 exportBtn" (click)="exportAsXLSX()"> 
                    <fa-icon [icon]="['fas', 'download']" style="margin-right: 10px;"></fa-icon>Export Excel</button>
                <button class="mat-raised-button mat-primary exportBtn" (click)="addMspMaster()"> Add Record</button>
            </div>
        </div>

        <div class="row" style="padding: 20px 0px 20px;">
            <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
            <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
                <ng-container matColumnDef="product">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Product</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.productObj.name}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="state">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> State</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.stateObj.state_name}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="msp">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> MSP</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.msp}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="month">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Month</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.month}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="year">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Year</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.year}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="action">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Action</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="actionRowBlock">
                            <a class="mr-10" (click)="editMspMaster(element)">
                                Edit
                            </a>
                        </div>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns_data"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns_data;"></mat-row>
            </mat-table>
            <mat-paginator [pageSizeOptions]="dataSource.data.length > 50 ? [10, 20, 50, dataSource.data.length] : [10, 20, 50]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
    <simple-notifications [options]="notificationOptions"></simple-notifications>
</div>