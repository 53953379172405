import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationsService } from 'angular2-notifications';
import { UbService } from 'src/app/services/ub.service';

@Component({
  selector: 'app-mandi-user-mapping-master-dialog',
  templateUrl: './mandi-user-mapping-master-dialog.component.html',
  styleUrls: ['./mandi-user-mapping-master-dialog.component.scss']
})
export class MandiUserMappingMasterDialogComponent implements OnInit {

  dialogData: any;
  title: string;
  mandiForm: FormGroup;
  mandi: any;
  mandiData: any;
  loggedInUser;
  userList: any;
  status = [
    {
      name: 'Active',
      id: 1
    },
    {
      name: 'InActive',
      id: 0
    }
  ];

  constructor(
    private service: UbService,
    private fb: FormBuilder,
    private notificationService: NotificationsService,
    private dialogRef: MatDialogRef<MandiUserMappingMasterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.title = data.title;
    
    this.mandiData = data;
  }

  ngOnInit(): void {
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    this.mandiForm = this.fb.group({
      mandi_id: ['', [Validators.required]],
      user_id: ['', [Validators.required]],
      active: ['', [Validators.required]],
    });
    this.getMandi();
    this.getAllUser();
  }

  getAllUser() {
    this.userList = [];
    this.service.getUserDetails().subscribe((res: any) => {
      console.log(res);
      if (res.status) {
        this.userList = res.result;
        if (this.mandiData.type == 'edit') {
          this.mandiForm.controls.mandi_id.setValue(this.mandiData.mandiRowData.mandi_id);
          this.mandiForm.controls.user_id.setValue(this.mandiData.mandiRowData.user_id);
          this.mandiForm.controls.active.setValue(this.mandiData.mandiRowData.active);
        }
      }
    }, error => {
      this.notificationService.error('Error', error.error.message);
    });
  }

  getMandi() {
    this.service.getAllMandi().subscribe((res: any) => {
      if (res.status) {
        this.mandi = res.result;
        if (this.mandiData.type == 'edit') {
          console.log(this.mandiData.agmarkData);
          this.mandiForm.controls['mandi_id'].setValue(this.mandiData.mandiRowData.mandi_id);
          this.mandiForm.controls['user_id'].setValue(this.mandiData.mandiRowData.user_id);
          this.mandiForm.controls['active'].setValue(this.mandiData.mandiRowData.active);
        }
      }
    }, error => {
      this.notificationService.error('Error', error.error.message);
    });
  }

  close() {
    this.dialogRef.close();
  }

  saveAgmark() {
    if (this.mandiForm.valid) {
      const payload = {
        mandi_id: this.mandiForm.value.mandi_id,
        active: this.mandiForm.value.active,
        user_id: this.mandiForm.value.user_id
      };
      if (this.mandiData.type === 'add') {
        this.service.createMandiUser(payload).subscribe((res) => {
          if (res['status']) {
            this.notificationService.success('Success', res['message']);
            this.dialogRef.close();
          }
        }, error => {
          this.notificationService.error('Error', error.error.message);
        });
      } else if (this.mandiData.type === 'edit') {

        this.service.updateMandiUser(this.mandiData.mandiRowData.id, payload).subscribe((res) => {
          if (res['status']) {
            this.notificationService.success('Success', res['message']);
            this.dialogRef.close();
          }
        }, error => {
          this.notificationService.error('Error', error.error.message);
        });

      }
    }
  }

}
