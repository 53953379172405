<h2 class="dialog-heading" *ngIf="title" mat-dialog-title>{{title}}</h2>
<mat-dialog-content style="width:500px">
    <form class="example-full-width" [formGroup]="roleForm">
        <mat-form-field class="example-full-width">
            <input formControlName="role" placeholder="Role" matInput type='text' required>
            <mat-error>
                <span *ngIf="roleForm.get('role').hasError('required')">Role is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label required>Active</mat-label>
            <mat-select class="matSelect" formControlName="active" required>
                <mat-option *ngFor="let active of activeList" [value]="active.id">
                    {{active.name}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!roleForm.get('active').valid || roleForm.get('active').touched">Please select Active/Inactive</span>
            </mat-error>
        </mat-form-field>
    </form>
    <mat-dialog-actions  *ngIf="type === 'add'">
        <button class="mat-raised-button mr-10" (click)="close()">Close</button>
        <button class="mat-raised-button mat-primary" (click)="addRole()">Add</button>
    </mat-dialog-actions>
    <mat-dialog-actions  *ngIf="type === 'edit'">
        <button class="mat-raised-button mr-10" (click)="close()">Close</button>
        <button class="mat-raised-button mat-primary" (click)="editRole()">Save</button>
    </mat-dialog-actions>
</mat-dialog-content>
