<h2 class="dialog-heading" *ngIf="this.title" mat-dialog-title>{{title}}</h2>
<mat-dialog-content style="width:500px" *ngIf="rowType == '6 Row Barley'">
    <form class="example-full-width" [formGroup]="arrivalDataForm">
        <mat-form-field class="example-full-width">
            <mat-label required>Select Mandi</mat-label>
            
            <mat-select class="matSelect" formControlName="mandi_id" [(value)]="selectedMandi" required>
                <mat-option *ngFor="let mandi of mandiList" [value]="mandi.mandiObj ? mandi.mandi_id : mandi.id">
                    <span *ngIf="mandi.mandiObj">{{mandi.mandiObj.mandi_name}}</span>
                    <span *ngIf="!mandi.mandiObj">{{mandi.mandi_name}}</span>
                </mat-option>

                <!-- <mat-option *ngFor="let mandi of mandiList" [value]="mandi.mandi_id">
                    {{mandi.mandiObj.mandi_name}}
                </mat-option> -->
            </mat-select>
            <mat-error>
                <span *ngIf="!arrivalDataForm.get('mandi_id').valid || arrivalDataForm.get('mandi_id').touched">One
                    Mandi is
                    compulsory</span>
            </mat-error>
        </mat-form-field>


        <mat-form-field style="width:100%" color="accent" appearance="fill">
            <mat-label [class.active]="arrivalDataForm.get('document_date').value">Document Date</mat-label>
            <input formControlName="document_date" matInput [readonly]="true" [matDatepicker]="picker2">
            <!-- <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle> -->
            <mat-datepicker #picker2 color="primary"></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="min_price" placeholder="Minimum Price" matInput type='number' required>
            <mat-error>
                <span *ngIf="arrivalDataForm.get('min_price').hasError('required')">Minimum Price is required</span>
                <span *ngIf="arrivalDataForm.get('min_price').hasError('pattern')">Minimum Price should be greater than
                    or equal to 1</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="max_price" placeholder="Max Price" matInput type='number' required>
            <mat-error>

                <span *ngIf="arrivalDataForm.get('max_price').hasError('minGtmax')">Maximum Price should be greater than
                    or equal to minimum price</span>
                <span *ngIf="arrivalDataForm.get('max_price').hasError('pattern')">Maximum Price should be greater than
                    or equal to 1</span>
            </mat-error>
        </mat-form-field>
        <span class="error" *ngIf="submitted == true && maxPriceError == true">Maximum Price is required</span>
        <mat-form-field class="example-full-width">
            <input formControlName="quantity" placeholder="Quantity" matInput type='number' required>
            <mat-error>
                <span *ngIf="arrivalDataForm.get('quantity').hasError('required')">Quantity is required</span>
                <span *ngIf="arrivalDataForm.get('quantity').hasError('pattern')">Quantity should be greater than or
                    equal to 0</span>
            </mat-error>
        </mat-form-field>
    </form>

    <mat-dialog-actions>
        <button class="mat-raised-button mr-10" (click)="close()">Close</button>
        <button class="mat-raised-button mat-primary" (click)="saveArrivalData()">Save</button>
    </mat-dialog-actions>

</mat-dialog-content>


<mat-dialog-content style="width:500px" *ngIf="rowType == '2 Row Barley'">
    <form class="example-full-width" [formGroup]="arrivalDataForm">
        <mat-form-field class="example-full-width">
            <mat-label required>Select Mandi</mat-label>
            <mat-select class="matSelect" formControlName="mandi_id" [(value)]="selectedMandi" required>
                <!-- <mat-option *ngFor="let mandi of mandiList" [value]="mandi.mandi_id">
                    {{mandi.mandiObj.mandi_name}}
                </mat-option> -->
                <mat-option *ngFor="let mandi of mandiList" [value]="mandi.mandiObj ? mandi.mandi_id : mandi.id">
                    <span *ngIf="mandi.mandiObj">{{mandi.mandiObj.mandi_name}}</span>
                    <span *ngIf="!mandi.mandiObj">{{mandi.mandi_name}}</span>
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!arrivalDataForm.get('mandi_id').valid || arrivalDataForm.get('mandi_id').touched">One
                    Mandi is
                    compulsory</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field style="width:100%" color="accent" appearance="fill">
            <mat-label [class.active]="arrivalDataForm.get('document_date').value">Document Date</mat-label>
            <input formControlName="document_date" matInput [readonly]="true" [matDatepicker]="picker2">
            <mat-datepicker #picker2 color="primary"></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="one_price" placeholder="One Price" matInput type='number' required>
            <mat-error>
                <span *ngIf="arrivalDataForm.get('one_price').hasError('required')">One Price is required</span>
                <span *ngIf="arrivalDataForm.get('one_price').hasError('pattern')">One Price should be greater than or
                    equal to 1</span>
            </mat-error>
        </mat-form-field>
       
        <mat-form-field class="example-full-width">
            <input formControlName="quantity" placeholder="Quantity" matInput type='number' 
            required>
            <mat-error>
                <span *ngIf="arrivalDataForm.get('quantity').hasError('required')">Quantity is required</span>
                <span *ngIf="arrivalDataForm.get('quantity').hasError('pattern')">Quantity should be greater than or equal to 0</span>
            </mat-error>
        </mat-form-field>
    </form>

    <mat-dialog-actions>
        <button class="mat-raised-button mr-10" (click)="close()">Close</button>
        <button class="mat-raised-button mat-primary" (click)="saveArrivalData()">Save</button>
    </mat-dialog-actions>

</mat-dialog-content>

<mat-dialog-content style="width:500px; min-height: 130px;" *ngIf="rowType == 'setCeilingPrice'">
    <form class="example-full-width" [formGroup]="arrivalDataForm">

        <mat-form-field class="example-full-width">
            <input formControlName="ceilingPrice" placeholder="Ceiling Price" matInput type='number' required>
            <mat-error>
                <span *ngIf="arrivalDataForm.get('ceilingPrice').hasError('required')">Ceiling Price Required</span>
                <span *ngIf="arrivalDataForm.get('ceilingPrice').hasError('pattern')">Ceiling Price should be greater
                    than or equal to 1</span>
            </mat-error>
        </mat-form-field>

    </form>

    <mat-dialog-actions>
        <button class="mat-raised-button mr-10" (click)="close()">Close</button>
        <button class="mat-raised-button mat-primary" (click)="saveArrivalData()">Save</button>
    </mat-dialog-actions>

</mat-dialog-content>

<mat-dialog-content style="width:500px; min-height: 140px;" *ngIf="rowType == 'setCeilingPriceCluster'">
    <form class="example-full-width" [formGroup]="arrivalDataForm">

        <div class="row">
            <div class="col-md-6">
                <mat-form-field>
                    <mat-label required>Select Cluster</mat-label>
                    <mat-select class="matSelect" formControlName="cluster_id" [(value)]="selectedCluster" required>
                        <mat-option *ngFor="let cluster of clusterList" [value]="cluster.cluster_id">
                            {{cluster.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error>
                        <span
                            *ngIf="!arrivalDataForm.get('cluster_id').valid || arrivalDataForm.get('cluster_id').touched">One
                            Mandi is compulsory</span>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-6">
                <mat-form-field>

                    <input formControlName="ceiling_for_price" placeholder="Ceiling For Price" matInput type='number'
                        required>
                    <mat-error>
                        <span *ngIf="arrivalDataForm.get('ceiling_for_price').hasError('required')">Ceiling For Price
                            Required</span>
                        <span *ngIf="arrivalDataForm.get('ceiling_for_price').hasError('pattern')">Ceiling For Price
                            should be greater than or equal to 1</span>
                    </mat-error>
                </mat-form-field>
            </div>

        </div>



    </form>

    <mat-dialog-actions>
        <button class="mat-raised-button mr-10" (click)="close()">Close</button>
        <button class="mat-raised-button mat-primary" (click)="saveArrivalData()">Save</button>
    </mat-dialog-actions>

</mat-dialog-content>