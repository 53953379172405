import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MenuService } from '../services/menu.service';
import { UbService } from '../services/ub.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogComponent } from '../widgets/dialog/dialog.component';
import { ExcelService } from '../services/excel.service';
import { Daterangepicker } from 'ng2-daterangepicker';
import { NotificationsService } from 'angular2-notifications';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { ForlineItemMasterDialogComponent } from '../widgets/forline-item-master-dialog/forline-item-master-dialog.component';


@Component({
  selector: 'app-forline-item-master',
  templateUrl: './forline-item-master.component.html',
  styleUrls: ['./forline-item-master.component.scss']
})
export class ForlineItemMasterComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  permission: any;
  documentDate = moment(new Date()).format('YYYY-MM-DD');
  loggedInUser: any;
  public notificationOptions: any = {
    position: ['bottom', 'right'],
    timeOut: 5000,
    lastOnBottom: true,
    pauseOnHover: true,
    clickToClose: true,
    animate: 'fromRight'
  };
  dataSource = new MatTableDataSource<any>();
  displayedRowColumns: any;
  loading = false;
  constructor(
    public menuService: MenuService,
    private router: Router,
    private service: UbService,
    private dialog: MatDialog,
    private excelService: ExcelService,
    private _service: NotificationsService
  ) {
   }

  ngOnInit(): void {
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    const menu = this.menuService.getMenu(this.router.url);
    this.permission = menu.actions;
    this.displayedRowColumns = ['parameter', 'group', 'data_type', 'sequence', 'base_type', 'base', 'value', 'valid_from', 'valid_To',  'action'];
    this.getAllForLine();
  }

  applyFilter(filterValue) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getAllForLine() {
    this.loading = true;
    this.service.getAllForLineItem().subscribe((response: any) => {
      for (const data of response.result) {
        data.valid_from = data.valid_from ? moment(new Date(data.valid_from)).format('YYYY-MM-DD') : '';
        data.valid_to = data.valid_to ? moment(new Date(data.valid_to)).format('YYYY-MM-DD') : '';
      }
      response.result = response.result.sort((a, b ) => b.id - a.id);
      this.dataSource = new MatTableDataSource(response.result);
      this.dataSource.filterPredicate = (data: any, filter: string) => {
        return data.parameter?.toLocaleLowerCase().includes(filter) ||
          data.group?.toLocaleLowerCase().includes(filter) ||
          data.data_type?.toLocaleLowerCase().includes(filter) ||
          data.sequence?.toString().toLocaleLowerCase().includes(filter) ||
          data.base?.toLocaleLowerCase().includes(filter) ||
          data.value?.toLocaleLowerCase().includes(filter) ||
          data.valid_from?.toLocaleLowerCase().includes(filter) ||
          data.valid_to?.toLocaleLowerCase().includes(filter) ||
          data.base_type.toLocaleLowerCase().includes(filter);
      };
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loading = false;
    }, error => {
      this._service.error('Error', error.error.message);
      this.loading = false;
    });
  }

  checkPermission(access: string): boolean {
    return this.permission.includes(access);
  }

  editforLineItem(rowData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Add For Line Item',
      height: '800px',
      width: '300px',
      panelClass: 'addnew-custom-dialog',
      type: 'edit',
      forlineRowItem: rowData
    };
    const dialogRef = this.dialog.open(ForlineItemMasterDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.getAllForLine();
    });
  }

  addforLineItem() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Update For Line Item',
      height: '800px',
      width: '300px',
      panelClass: 'addnew-custom-dialog',
      type: 'add'
    };
    const dialogRef = this.dialog.open(ForlineItemMasterDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.getAllForLine();
    });
  }

  exportAsXLSX(): void {
    const exportData = this.dataSource.data.map((val) => (
      {
        'Id': val.id,
        'Parameter': val.parameter,
        'Group': val.group,
        'Data Type': val.data_type,
        'Sequence': val.sequence,
        'Base Type': val.base_type,
        'Base': val.base,
        'Value': val.value,
        'Valid From': val.valid_from,
        'Valid To': val.valid_to
      }
    ));
    this.excelService.exportAsExcelFile(exportData, 'for-line-item');
  }

}
