import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MenuService } from '../services/menu.service';
import { UbService } from '../services/ub.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ExcelService } from '../services/excel.service';
import { NotificationsService } from 'angular2-notifications';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import * as moment from 'moment';
import { UserMasterDialogComponent } from '../widgets/user-master-dialog/user-master-dialog.component';

@Component({
  selector: 'app-user-master',
  templateUrl: './user-master.component.html',
  styleUrls: ['./user-master.component.scss']
})
export class UserMasterComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  permission: any;
  documentDate = moment(new Date()).format('YYYY-MM-DD');
  public daterange: any = {
    start: this.documentDate,
    end: this.documentDate
  };
  loggedInUser: any;
  public options: any = {
    locale: { format: 'D MMMM YYYY' },
    alwaysShowCalendars: false,
  };
  public notificationOptions: any = {
    position: ['bottom', 'right'],
    timeOut: 5000,
    lastOnBottom: true,
    pauseOnHover: true,
    clickToClose: true,
    animate: 'fromRight'
  };
  dataSource = new MatTableDataSource<any>();

  displayRowColums: any;
  loading = false;
  constructor(
    public menuService: MenuService,
    private router: Router,
    private service: UbService,
    private dialog: MatDialog,
    private excelService: ExcelService,
    private _service: NotificationsService
  ) { }

  applyFilter(filterValue) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    const menu = this.menuService.getMenu(this.router.url);
    this.permission = menu.actions;
    this.getUserDetails();
    this.displayRowColums = ['user_id', 'first_name', 'last_name', 'sap_vendor_code', 'email_1', 'mobile_1', 'pincode', 'gstin', 'identity', 'role', 'active' , 'action'];
    // this.dataSource.filterPredicate = (data: any, filter: string) => {
    //   return data.user_id.toLocaleLowerCase().includes(filter) ||
    //     data.first_name.toLocaleLowerCase().includes(filter) ||
    //     data.last_name.toLocaleLowerCase().includes(filter) ||
    //     data.sap_vendor_code.toLocaleLowerCase().includes(filter) ||
    //     data.email_1.toLocaleLowerCase().includes(filter) ||
    //     data.mobile_1.toString().includes(filter) ||
    //     data.pincode.toString().includes(filter) ||
    //     data.gstin.toLocaleLowerCase().includes(filter) ||
    //     data.roles.toLocaleLowerCase().includes(filter) ||
    //     data.role?.role.toLocaleLowerCase().includes(filter)
    // };
  }

  getUserDetails() {
    this.loading = true
    this.service.getUserDetails().subscribe((response: any) => {
      if (response.status) {
        this.dataSource = new MatTableDataSource(response.result);
        this.dataSource.filterPredicate = (data: any, filter: string) => {
          if(data.active == '1') {
            data['status'] == 'active'
          } else {
            data['status'] == 'inactive'
          }
          // console.log('Data---->', data)
          // console.log('filter---->', filter)
          return data.user_id?.toLocaleLowerCase().includes(filter) ||
            data.first_name?.toLocaleLowerCase().includes(filter) ||
            data.last_name?.toLocaleLowerCase().includes(filter) ||
            data.sap_vendor_code?.toLocaleLowerCase().includes(filter) ||
            data.email_1?.toLocaleLowerCase().includes(filter) ||
            data.mobile_1?.toString().includes(filter) ||
            data.pincode?.toString().includes(filter) ||
            data.gstin?.toLocaleLowerCase().includes(filter) ||
            data.roles?.toLocaleLowerCase().includes(filter) ||
            data.role?.role.toLocaleLowerCase().includes(filter) ||
            data.status.toLocaleLowerCase().includes(filter)
        };
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (item, property) => {
          switch(property) {
            case 'role': return item.role?.role;
            default: return item[property];
          }
        }
        this.dataSource.sort = this.sort;
      }
      this.loading = false;
    }, error => {
      this._service.error('Error', error.error.message);
      this.loading = false
    });
  }

  checkPermission(access: string): boolean {
    return this.permission.includes(access);
  }

  addUser() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Add User',
      height: '900px',
      width: '300px',
      panelClass: 'addnew-custom-dialog',
      type: 'add'
    };
    const dialogRef = this.dialog.open(UserMasterDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.getUserDetails();
    });
  }

  editRow(row) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Add User',
      height: '800px',
      width: '300px',
      panelClass: 'addnew-custom-dialog',
      type: 'edit',
      userRowData: row
    };
    const dialogRef = this.dialog.open(UserMasterDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.getUserDetails();
    });
  }

  exportAsXLSX(): void {

    const exportData = this.dataSource.data.map((val) => (
      {
        'Id': val.id,
        'User Id': val.user_id,
        'First Name': val.first_name,
        'Last Name': val.last_name,
        'Sap Vendor Code': val.sap_vendor_code,
        'Email 1': val.email_1,
        'Mobile 1': val.mobile_1,
        'Pincode': val.pincode,
        'Gstin': val.Gstin,
        'Identity':val.roles,
        'Role': val.role?.role,
        'Status': val.active ? (val.active == 1 ? 'Active' : 'Inactive') : ''
      }
    ));
    console.log(this.dataSource.data);
    this.excelService.exportAsExcelFile(exportData, 'user-master');
  }

}
