import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuService } from '../services/menu.service';
import { UbService } from '../services/ub.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ExcelService } from '../services/excel.service';
import { ceilingTabText, UserRole, ViewceilingAction } from '../models/schema';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-viewceiling',
  templateUrl: './viewceiling.component.html',
  styleUrls: ['./viewceiling.component.scss']
})

export class ViewceilingComponent implements OnInit {
  ceilingTabText = ceilingTabText;
  viewceilingAction = ViewceilingAction;
  permission;
  documentDate = new Date().toISOString().slice(0, 10).replace('T', ' ');
  date = new Date().toISOString().slice(0, 16);
  animal: string;
  name: string;
  mandiList: any;
  startDate = moment().subtract(1, "days").format("YYYY-MM-DD");
  endDate = moment().format("YYYY-MM-DD");
  loading = false;
  public daterange: any = {
    start: this.startDate,
    end: this.endDate,
  };

  loggedInUser: any;

  sixRowofferCount = 0;
  sixRowAuctionCount = 0;
  twoRowAuctionCount = 0
  todayDate = new Date();

  public options: any = {
    locale: { format: 'D MMMM YYYY' },
    alwaysShowCalendars: false,
    maxDate: new Date(),
    startDate: moment().subtract(1, "days").format("D MMMM YYYY"),
    endDate: moment().format("D MMMM YYYY"),
  };
  displayedColumns_2RowBarley = ['mandi_name', 'state', 'one_price', 'msp', 'premium', 'suggested_price', 'ceiling_price'];
  displayedColumns_6RowBarley = ['mandi_name', 'quantity', 'min_price', 'max_price', 'avg_buying_price', 'modal_price', 'suggested_price', 'ceiling_price'];
  displayedColumns_For = ['cluster_name', 'destination', 'for_freight', 'storage', 'interest', 'avg_buying_price', 'modal_price', 'suggested_for_price', 'ceiling_for_price'];
  dataSource = new MatTableDataSource<any>();
  backupData: any;
  links = [];
  activeLink = '';
  forFreightData = [];
  seasonType = 'Season';

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public menuService: MenuService,
    private service: UbService,
    private excelService: ExcelService,
    private router: Router,
  ) {
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      if (this.activeLink === ceilingTabText.Barley_6_Row_Auction) {
        return data.mandiObj.mandi_name.toLocaleLowerCase().includes(filter) ||
          data.quantity.toString().includes(filter) ||
          data.max_price.toString().includes(filter) ||
          data.suggested_price.toString().includes(filter) ||
          data.min_price.toString().includes(filter) ||
          data.avg_buying_price.toString().includes(filter) ||
          data.ceiling_price.toString().includes(filter)
      }
      if (this.activeLink === ceilingTabText.Barley_6_Row_Offer) {
        return data.clusterObj.name.toLocaleLowerCase().includes(filter) ||
          data.destinationObj.delivery_name.toLocaleLowerCase().includes(filter) ||
          data.avg_buying_price.toString().includes(filter) ||
          data.for_freight.toString().includes(filter) ||
          data.avg_price.toString().includes(filter) ||
          data.suggested_for_price.toString().includes(filter) ||
          data.ceiling_for_price.toString().includes(filter)
      }

      if (this.activeLink === ceilingTabText.Barley_2_Row_Auction) {
        return data.mandiObj.mandi_name.toLocaleLowerCase().includes(filter) ||
          data.stateObj.state_name.toLocaleLowerCase().includes(filter) ||
          data.quantity.toString().includes(filter) ||
          data.modal_price.toString().includes(filter) ||
          data.one_price.toString().includes(filter) ||
          data.suggested_price.toString().includes(filter) ||
          data.avg_buying_price.toString().includes(filter) ||
          data.ceiling_price.toString().includes(filter)
      }

    };
  }

  ngOnInit(): void {
    this.service.currentNotified.subscribe(status => {
      console.log('Current notified status--->', status)
    });
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    const menu = this.menuService.getMenu(this.router.url);
    this.permission = menu.actions;

    if (this.loggedInUser.role == UserRole.UBP) {
      this.displayedColumns_2RowBarley = ['mandi_name', 'state', 'one_price', 'msp', 'premium', 'suggested_price', 'ceiling_price', 'document_date', 'notified_date'];
      this.displayedColumns_6RowBarley = ['mandi_name', 'quantity', 'min_price', 'max_price', 'avg_buying_price', 'modal_price', 'suggested_price', 'ceiling_price', 'document_date', 'notified_date'];
      this.displayedColumns_For = ['cluster_name', 'destination', 'for_freight', 'document_date', 'storage', 'interest', 'avg_buying_price', 'suggested_for_price', 'ceiling_for_price', 'notified_date'];
    } else {
      this.displayedColumns_For = ['cluster_name', 'destination', 'notified_date', 'document_date', 'ceiling_for_price'];
      this.displayedColumns_2RowBarley = ['mandi_name', 'state', 'one_price', 'document_date', 'ceiling_price', 'notified_date'];
      this.displayedColumns_6RowBarley = ['mandi_name', 'quantity', 'min_price', 'max_price', 'document_date', 'ceiling_price', 'notified_date'];
    }

    this.service.getUserMandi(this.loggedInUser.id).subscribe((response: any) => {
      this.mandiList = response.result.map(val => val.mandi_id);
    });

    this.getAllForFreightData();

    if (this.checkPermission1(ViewceilingAction.view_6_row)) {
      this.links.push(ceilingTabText.Barley_6_Row_Auction);
    }

    if (this.checkPermission1(ViewceilingAction.view_6_row_offer)) {
      this.links.push(ceilingTabText.Barley_6_Row_Offer);
    }

    if (this.checkPermission1(ViewceilingAction.view_2_row)) {
      this.links.push(ceilingTabText.Barley_2_Row_Auction);
      this.activeLink = ceilingTabText.Barley_2_Row_Auction;
    }

    if (this.checkPermission1(ViewceilingAction.view_6_row_offer)) {
      this.activeLink = ceilingTabText.Barley_6_Row_Offer
    }

    if (this.checkPermission1(ViewceilingAction.view_6_row)) {
      this.activeLink = ceilingTabText.Barley_6_Row_Auction
    }

  }

  getAllForFreightData() {
    this.service.getAllForFreightData().subscribe((result) => {
      if (result['status']) {
        this.forFreightData = result['result'];
        this.getAllTableData();
        this.getTableData();
      }
    }, err => { })
  }

  getAllTableData() {
    const document_date = {
      start: this.startDate,
      end: this.endDate
    }

    let filter;
    if (this.loggedInUser.role === UserRole.PukkaArthiya || this.loggedInUser.role === UserRole.Facilitator) {
      filter = 'is_ceiling_set=true&status=Approved&mandi_id=' + this.mandiList.join(',') + '&from_date=' + this.daterange.start + '&to_date=' + this.daterange.end;
    } else {
      filter = 'is_ceiling_set=true&status=Approved&from_date=' + this.daterange.start + '&to_date=' + this.daterange.end;
    }

    this.service.getArrivalDataViewCeiling(filter).subscribe((response: any) => {
      if (response['status']) {
        this.sixRowAuctionCount = response.result.length;
      }
    }, err => { });

    let dashboardfilter = '';
    if (this.loggedInUser.role === UserRole.PukkaArthiya || this.loggedInUser.role === UserRole.Facilitator) {
      dashboardfilter = 'mandi_id=' + this.mandiList.join(',') + '&status=Approved&season=' + (this.seasonType === 'Season' ? true : false) + '&from_date=' + this.daterange.start + '&to_date=' + this.daterange.end
    } else {
      dashboardfilter = 'is_ceiling_set=true&status=Approved&season=' + (this.seasonType === 'Season' ? true : false) + '&from_date=' + this.daterange.start + '&to_date=' + this.daterange.end
    }
    this.service.getForDashboardViewCeiling(dashboardfilter).subscribe((response: any) => {
      if (response['status']) {
        this.sixRowofferCount = response.result.length;
        console.log(this.sixRowofferCount);
      }
    }, err => { });

    this.service.get2RowViewCeiling(filter).subscribe((response: any) => {
      if (response['status']) {
        this.twoRowAuctionCount = response.result.length;
      }
    }, err => { });
  }

  getTableData() {
    this.dataSource.data = [];
    const document_date = {
      start: this.startDate,
      end: this.endDate
    }
    let filter;
    if (this.loggedInUser.role === UserRole.PukkaArthiya || this.loggedInUser.role === UserRole.Facilitator) {
      filter = 'is_ceiling_set=true&status=Approved&mandi_id=' + this.mandiList.join(',') + '&from_date=' + this.daterange.start + '&to_date=' + this.daterange.end;
    } else {
      filter = 'is_ceiling_set=true&status=Approved&from_date=' + this.daterange.start + '&to_date=' + this.daterange.end;
    }

    if (this.activeLink === '6 Row Barley Auction') {
      this.loading = true;
      this.service.getArrivalDataViewCeiling(filter).subscribe((response: any) => {
        if (response['status']) {
          this.dataSource.data = response.result;
          this.sixRowAuctionCount = response.result.length;
        }
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loading = false;
      }, err => {
        this.loading = false;
      });
    } else if (this.activeLink === '6 Row Barley Offer') {
      if (this.loggedInUser.role === UserRole.PukkaArthiya || this.loggedInUser.role === UserRole.Facilitator) {
        filter = 'mandi_id=' + this.mandiList.join(',') + '&status=Approved&season=' + (this.seasonType === 'Season' ? true : false) + '&from_date=' + this.daterange.start + '&to_date=' + this.daterange.end
      } else {
        filter = 'is_ceiling_set=true&status=Approved&season=' + (this.seasonType === 'Season' ? true : false) + '&from_date=' + this.daterange.start + '&to_date=' + this.daterange.end
      }
      this.loading = true;
      this.service.getForDashboardViewCeiling(filter).subscribe((response: any) => {
        if (response['status']) {
          this.dataSource.data = response.result;
          this.sixRowofferCount = response.result.length;
        }
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loading = false;
      }, err => {
        this.loading = false;
      });
    } else if (this.activeLink === '2 Row Barley Auction') {
      this.loading = true;
      this.service.get2RowViewCeiling(filter).subscribe((response: any) => {
        if (response['status']) {
          this.dataSource.data = response.result;
          this.twoRowAuctionCount = response.result.length;
        }
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loading = false;
      }, err => {
        this.loading = false;
      });
    }
  }

  seasonTypeChange() {
    if (this.seasonType === 'Season') {
      this.seasonType = 'Offseason';
    } else if (this.seasonType === 'Offseason') {
      this.seasonType = 'Season';
    }
    let filter = 'is_ceiling_set=true&season=' + (this.seasonType === 'Season' ? true : false) + '&from_date=' + this.daterange.start + '&to_date=' + this.daterange.end;
    this.service.getForDashboardData(filter).subscribe((response: any) => {
      if (response['status']) {
        this.dataSource.data = response.result;
        this.sixRowofferCount = response.result.length;
      }
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, err => { });
  }

  public selectedDate(value: any, datepicker?: any) {
    this.startDate = new Date(value.start).toDateString();
    this.endDate = new Date(value.end).toDateString();
    datepicker.start = value.start;
    datepicker.end = value.end;
    this.daterange.start = moment(value.start).format('YYYY-MM-DD');
    this.daterange.end = moment(value.end).format('YYYY-MM-DD');

    this.getTableData()
  }

  applyFilter(filterValue) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getData(link) {
    this.activeLink = link;
    this.getTableData();
  }

  exportAsXLSX(): void {
    if (this.activeLink === '6 Row Barley Auction') {
      const exportData = this.dataSource.data.map((val) => ({
        id: val.id,
        "Mandi Name": val.mandiObj.mandi_name,
        "Document Date": val.document_date,
        "Quantity": val.quantity,
        "Min price": val.min_price,
        "Max Price": val.max_price,
        "Avg Buying Price": val.avg_buying_price,
        "Modal Price": val.modal_price,
        "Suggested Price": val.suggested_price,
        "Ceiling Price": val.ceiling_price,
        "Submitted": val.is_submitted
      }));
      this.excelService.exportAsExcelFile(exportData, '6-row-barley-auction-data');
    }
    else if (this.activeLink === '6 Row Barley Offer') {
      const exportData = this.dataSource.data.map((val) => (
        {
          id: val.id,
          "Cluster": val.clusterObj.name,
          "Document Date": val.document_date,
          "Delivery Location": val.destinationObj.delivery_name,
          "Avg price": val.avg_price,
          "For Freight": val.for_freight,
          "Avg Buying Price": val.avg_buying_price,
          "Modal Price": val.modal_price,
          "Suggested For Price": val.suggested_for_price,
          "Ceiling For Price": val.ceiling_for_price,
          "Submitted": val.is_submitted
        }));
      this.excelService.exportAsExcelFile(exportData, '6-row-barley-offer-data');
    }
    else if (this.activeLink === '2 Row Barley Auction') {
      const exportData = this.dataSource.data.map((val) => (
        {
          id: val.id,
          "Mandi Name": val.mandiObj.mandi_name,
          "State": val.state,
          "One Price": val.one_price,
          "MSP": val.msp,
          "Premium": val.premium,
          "Suggested Price": val.suggested_price,
          "Ceiling Price": val.ceiling_price,
          "Submitted": val.is_submitted
        }));
      this.excelService.exportAsExcelFile(exportData, '2-row-barley-auction-data');
    }
  }

  checkPermission1(access: string): boolean {
    return this.permission.includes(access);
  }

  checkPermission() {
    if (UserRole.UBP == this.loggedInUser.role) {
      return true;
    } else {
      return false;
    }
  }
}
