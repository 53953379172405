import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationsService } from 'angular2-notifications';
import { UbService } from 'src/app/services/ub.service';

@Component({
  selector: 'app-plant-master-dialog',
  templateUrl: './plant-master-dialog.component.html',
  styleUrls: ['./plant-master-dialog.component.scss']
})
export class PlantMasterDialogComponent implements OnInit {
  title: string;
  plantForm: FormGroup; 
  loggedInUser;
  plant_id;
  type;

  constructor(
    private service: UbService,
    private fb: FormBuilder,
    private notificationService: NotificationsService,
    private dialogRef: MatDialogRef<PlantMasterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) { 
    this.title = data.title;
    this.type = data.type;
    this.plant_id=data.plant_id;
  }

  ngOnInit(){
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    this.plantForm = this.fb.group({      
      name: ['', [Validators.required]],
      code: ['', [Validators.required]],
      description: ['', [Validators.required]]
    });
    if(this.plant_id){
    this.getPlantData(this.plant_id);
    }
    }
  

  getPlantData(id) {
    this.service.getPlantByID(id).subscribe((res) => {
      if (res['status']) {
        const data = res['result'];
        this.plantForm.controls.name.setValue(data.name);
        this.plantForm.controls.code.setValue(data.code);
        this.plantForm.controls.description.setValue(data.description);
      }
    } , err => {})
  }


  close() {
    this.dialogRef.close();
  }

  addPlant() {
    for (const c in this.plantForm.controls) {
      if (this.plantForm.controls.hasOwnProperty(c)) {
        this.plantForm.controls[c].markAsTouched();
      }
    }
    console.log(this.plantForm.valid);
    if (this.plantForm.valid) {
      const payload = {
        "name": this.plantForm.value.name,
        "code": this.plantForm.value.code,
        "description": this.plantForm.value.description
      }
        this.service.addPlant(payload).subscribe((res) => {
          this.notificationService.success('Success', 'Plant Added Successfully');
          this.dialogRef.close();
        }, error => {
          this.notificationService.error('Error', error.error.message);
          this.dialogRef.close();
        });  
    }  
  }

  editPlant() {
    for (const c in this.plantForm.controls) {
      if (this.plantForm.controls.hasOwnProperty(c)) {
        this.plantForm.controls[c].markAsTouched();
      }
    }   
    if (this.plantForm.valid) {
      const payload = {
        "name": this.plantForm.value.name,
        "code": this.plantForm.value.code,
        "description": this.plantForm.value.description
      }
        this.service.updatePlant(this.plant_id , payload).subscribe((res) => {
          this.notificationService.success('Success', 'Plant Udapted Successfully');
          this.dialogRef.close();
        }, error => {
          this.notificationService.error('Error', error.error.message);
          this.dialogRef.close();
        });
      }
  }

}
