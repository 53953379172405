import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationsService } from 'angular2-notifications';
import { UbService } from 'src/app/services/ub.service';
import * as moment from 'moment';

@Component({
  selector: 'app-for-freight-dialog',
  templateUrl: './for-freight-dialog.component.html',
  styleUrls: ['./for-freight-dialog.component.scss']
})
export class ForFreightDialogComponent implements OnInit {
  title: string;
  forFreightForm: FormGroup;
  mandiList = [];
  locationList = [];
  deliveryLocationList = [];
  loggedInUser;
  forfreight_id;
  type;

  constructor(
    private service: UbService,
    private fb: FormBuilder,
    private notificationService: NotificationsService,
    private dialogRef: MatDialogRef<ForFreightDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.title = data.title;
    this.forfreight_id = data.forfreight_id;
    this.type = data.type;
   }

   ngOnInit(){
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    this.forFreightForm = this.fb.group({
      mandi_id: ['', [Validators.required]],
      delivery_id: ['', [Validators.required]],
      group_id :  ['', [Validators.required]],
      freight_value :  ['', [Validators.required , Validators.pattern('^[1-9]+[0-9]*(\.?[0-9]{1,2}$)?')]],
      valid_from: ['', [Validators.required]],
      valid_to: ['', [Validators.required]]
    }); 
    this.getAllMandis();
    this.forFreightForm.controls.mandi_id.valueChanges.subscribe((result) => {
      this.getAllDeliveryLocation(result);
    })
  }

  getAllMandis() {
    this.service.getAllMandi().subscribe((res) => {
      if (res['status']) {
        this.mandiList = res['result'];
        if (this.forfreight_id) {
          this.getFormData(this.forfreight_id);
        }
      }
    } , error => {
      this.notificationService.error('Error', error.error.message);
    })
  }

  getAllDeliveryLocation(result) {
    this.deliveryLocationList = [];
    this.service.getMandiDestination(result).subscribe((res) => {
      if (res['status']) {
        this.deliveryLocationList = res['result'];
      }
    }, error => {
      this.notificationService.error('Error', error.error.message);
    });
  }

  getFormData(id) {
    this.service.getForFreighData(id).subscribe((res) => {
      if (res['status']) {
        if (res['result']) {
          const data = res['result'];
          this.forFreightForm.controls.mandi_id.setValue(data.mandi_id);
          this.forFreightForm.controls.delivery_id.setValue(data.destination_id);
          this.forFreightForm.controls.group_id.setValue(data.gid);
          this.forFreightForm.controls.valid_to.setValue(data.valid_to);
          this.forFreightForm.controls.freight_value.setValue(data.freight_value);
          this.forFreightForm.controls.valid_from.setValue(data.valid_from);
        }
      }
    } , error => {
      this.notificationService.error('Error', error.error.message);
    })
  }


  close() {
    this.dialogRef.close();
  }

  addForFreight() {
    for (const c in this.forFreightForm.controls) {
      if (this.forFreightForm.controls.hasOwnProperty(c)) {
        this.forFreightForm.controls[c].markAsTouched();
      }
    }
    if (this.forFreightForm.valid) {
      const payload = {
        mandi_id : this.forFreightForm.value.mandi_id,
        destination_id : this.forFreightForm.value.delivery_id,
        freight_value : this.forFreightForm.value.freight_value,
        gid : this.forFreightForm.value.group_id,
        valid_from : moment(new Date(this.forFreightForm.value.valid_from)).format('YYYY-MM-DD'),
        valid_to : moment(new Date(this.forFreightForm.value.valid_to)).format('YYYY-MM-DD')
      }
      this.service.addForFreight(payload).subscribe((res) => {
        if (res['status']) {
          this.notificationService.success('Success', 'For Freight Added Successfully');
          this.dialogRef.close();
        }
      } , error => {
        this.notificationService.error('Error', error.error.message);
      })
    }
  }

  editForFreight() {
    for (const c in this.forFreightForm.controls) {
      if (this.forFreightForm.controls.hasOwnProperty(c)) {
        this.forFreightForm.controls[c].markAsTouched();
      }
    }
    if (this.forFreightForm.valid) {
      const payload = {
        mandi_id : this.forFreightForm.value.mandi_id,
        destination_id : this.forFreightForm.value.delivery_id,
        freight_value : this.forFreightForm.value.freight_value,
        gid : this.forFreightForm.value.group_id,
        valid_from : moment(new Date(this.forFreightForm.value.valid_from)).format('YYYY-MM-DD'),
        valid_to : moment(new Date(this.forFreightForm.value.valid_to)).format('YYYY-MM-DD')
      }
      this.service.updateForFreight(payload, this.forfreight_id).subscribe((res) => {
        if (res['status']) {
          this.notificationService.success('Success', 'For Freight Added Successfully');
          this.dialogRef.close();
        }
      } , error => {
        this.notificationService.error('Error', error.error.message);
      })
    }
  }

}
