<h2 class="dialog-heading" *ngIf="title" mat-dialog-title>{{title}}</h2>
<mat-dialog-content style="width:500px">
    <form class="example-full-width" [formGroup]="agmarkForm">
        <mat-form-field class="example-full-width">
            <mat-label required>Location ID</mat-label>
            <mat-select class="matSelect" formControlName="location_id" required>
                <mat-option *ngFor="let place of locations" [value]="place.id">
                    {{place.town_city}} | {{place.district}} | {{place.state}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!agmarkForm.get('location_id').valid || agmarkForm.get('location_id').touched">Location ID is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="market_name" placeholder="Market Name" matInput type='text' required>
            <mat-error>
                <span *ngIf="agmarkForm.get('market_name').hasError('required')">Market Name is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label required>Active</mat-label>
            <mat-select class="matSelect" formControlName="active" required>
                <mat-option *ngFor="let stat of status" [value]="stat.id">
                    {{stat.name}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!agmarkForm.get('location_id').valid || agmarkForm.get('location_id').touched">Active field is required</span>
            </mat-error>
        </mat-form-field>       
    </form>
    <mat-dialog-actions>
        <button class="mat-raised-button mr-10" (click)="close()">Close</button>
        <button class="mat-raised-button mat-primary" (click)="saveAgmark()">Save</button>
    </mat-dialog-actions>
</mat-dialog-content>