import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MenuService } from '../services/menu.service';
import { UbService } from '../services/ub.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ExcelService } from '../services/excel.service';
import { NotificationsService } from 'angular2-notifications';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import * as moment from 'moment';
import { AgmarkMasterDialogComponent } from '../widgets/agmark-master-dialog/agmark-master-dialog.component';


@Component({
  selector: 'app-agmark-master',
  templateUrl: './agmark-master.component.html',
  styleUrls: ['./agmark-master.component.scss']
})
export class AgmarkMasterComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  permission: any;
  documentDate = moment(new Date()).format('YYYY-MM-DD');
  loggedInUser: any;
  public notificationOptions: any = {
    position: ['bottom', 'right'],
    timeOut: 5000,
    lastOnBottom: true,
    pauseOnHover: true,
    clickToClose: true,
    animate: 'fromRight'
  };
  dataSource = new MatTableDataSource<any>();
  displayedRowColumns: any;
  loading = false;
  constructor(
    public menuService: MenuService,
    private router: Router,
    private service: UbService,
    private dialog: MatDialog,
    private excelService: ExcelService,
    private _service: NotificationsService
  ) {
  }

  applyFilter(filterValue) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnInit(): void {
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    const menu = this.menuService.getMenu(this.router.url);
    this.permission = menu.actions;
    this.displayedRowColumns = ['location', 'market_name', 'status', 'action'];
    this.getAgmarkDetails();
  }


  getAgmarkDetails() {
    this.loading = true;
    this.service.getAllAgmarkDetails().subscribe((response: any) => {
      this.dataSource.data = response.result;
      for(const data of this.dataSource.data) {
        if(data.active == 1) {
          data['status'] = 'active'
        } else {
          data['status'] = 'inactive'
        }
      }
      this.dataSource.filterPredicate = (data: any, filter: string) => {
        return data.locationObj?.state.toLocaleLowerCase().includes(filter) ||
        data.status?.toLocaleLowerCase().includes(filter) ||
        data.locationObj?.district?.toLocaleLowerCase().includes(filter) ||
        data.locationObj?.town_city?.toLocaleLowerCase().includes(filter) ||
        data.market_name?.toLocaleLowerCase().includes(filter);
      };
      this.dataSource.paginator = this.paginator;
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch(property) {
          case 'location': return item.locationObj?.town_city;
          default: return item[property];
        }
      }
      this.dataSource.sort = this.sort;
      this.loading = false;
    }, error => {
      this._service.error('Error', error.error.message);
      this.loading = false;
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  edit(rowData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Update Agmark',
      height: '800px',
      width: '300px',
      panelClass: 'addnew-custom-dialog',
      type: 'edit',
      agmarkData: rowData
    };
    const dialogRef = this.dialog.open(AgmarkMasterDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.getAgmarkDetails();
    });
  }

  addAgmark() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Add Agmark',
      height: '800px',
      width: '300px',
      panelClass: 'addnew-custom-dialog',
      type: 'add'
    };
    const dialogRef = this.dialog.open(AgmarkMasterDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.getAgmarkDetails();
    });
  }
  exportAsXLSX(): void {
    const exportData = this.dataSource.data.map((val) => (
      {
        'Location': val.locationObj.town_city + '|' + val.locationObj.district + '|' + val.locationObj.state,
        'Market Name': val.market_name,
        'Status': (val.active === '1') ? 'Active' : 'InActive',
      }
    ));
    this.excelService.exportAsExcelFile(exportData, 'arrival-data');
  }

}
