<h2 class="dialog-heading" *ngIf="title" mat-dialog-title>{{title}}</h2>
<mat-dialog-content style="width:500px">
    <form class="example-full-width" [formGroup]="clusterForm"  *ngIf="type === 'add'">
        <mat-form-field class="example-full-width">
            <mat-label>Name</mat-label>
            <input formControlName="name"  matInput type='text' required>
            <mat-error>
                <span *ngIf="!clusterForm.get('name').valid || clusterForm.get('name').touched">Name is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
                <mat-label>Description</mat-label>
            <input formControlName="description" matInput type='text' required>
            <mat-error>
                <span *ngIf="!clusterForm.get('description').valid || clusterForm.get('description').touched">Description is required</span>
            </mat-error>
        </mat-form-field>
    </form>
    <mat-dialog-actions  *ngIf="type === 'add'">
            <button class="mat-raised-button mr-10" (click)="close()">Close</button>
            <button class="mat-raised-button mat-primary" (click)="addCluster()">Add</button>
    </mat-dialog-actions>

    <form class="example-full-width" [formGroup]="clusterForm"  *ngIf="type === 'edit'">
        <mat-form-field class="example-full-width">
            <mat-label>Name</mat-label>
            <input formControlName="name"  matInput type='text' required>
            <mat-error>
                <span *ngIf="!clusterForm.get('name').valid || clusterForm.get('name').touched">Name is required</span>
            </mat-error>
            </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label>Description</mat-label>
                <input formControlName="description" matInput type='text' required>
                <mat-error>
                    <span *ngIf="!clusterForm.get('description').valid || clusterForm.get('description').touched">Description is required</span>
                </mat-error>
            </mat-form-field>
    </form>
    <mat-dialog-actions  *ngIf="type === 'edit'">
        <button class="mat-raised-button mr-10" (click)="close()">Close</button>
        <button class="mat-raised-button mat-primary" (click)="editCluster()">Update</button>
    </mat-dialog-actions>
</mat-dialog-content>