import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  dialogData: any;

  constructor(
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) { 
    this.dialogData = data;
  }

  ngOnInit(): void {
  }

  confirm() {
    let obj ={
      content : this.dialogData.content,
      value : 'confirm'
    }
    this.dialogRef.close(obj);
  }
  close() {
    this.dialogRef.close();
  }

}
