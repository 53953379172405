import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NotificationsService } from 'angular2-notifications';
import { UbService } from 'src/app/services/ub.service';
import { UserRole } from 'src/app/models/schema';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { ExcelService } from 'src/app/services/excel.service';


const uniqueDistrictArr = (arrayData) => {
  return arrayData.reduce(function (a, d) {
    if (!a.find(val => val.district === d.district)) {
      a.push(d);
    }
    return a;
  }, []);
}

const uniqueStateArr = (arrayData) => {
  return arrayData.reduce(function (a, d) {
    if (!a.find(val => val.state_id === d.state_id)) {
      a.push(d);
    }
    return a;
  }, []);
}

@Component({
  selector: 'app-order-report',
  templateUrl: './order-report.component.html',
  styleUrls: ['./order-report.component.scss']
})
export class OrderReportComponent implements OnInit {

  @ViewChild('matRefState') matRefState;
  @ViewChild('matRefDistrict') matRefDistrict;
  @ViewChild('matRefCity') matRefCity;
  @ViewChild('matRefMandi') matRefMandi;
  @ViewChild('matRefDelivery') matRefDelivery;
  @ViewChild('matRefAratiya') matRefAratiya;
  @ViewChild('matRefOrderType') matRefOrderType;
  @ViewChild('matRefOrderStatus') matRefOrderStatus;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('uploadExcel') uploadExcel;

  states = new FormControl();
  filterForm: FormGroup;
  stateList = [];
  districtList = [];
  cityList = [];
  mandiList = [];
  stateListBack = [];
  districtListBack = [];
  cityListBack = [];
  mandiListBack = [];
  loading = false;
  loggedInUser: any;
  dataSource = new MatTableDataSource<any>();
  backupData: any;
  displayedColumns: any;
  deliveryList: any;
  userList: any;
  statusList = [{ title: 'All', value: 'All' }, { title: 'Submitted', value: 1 }, { title: 'Pending', value: 0 }];

  district = new FormControl();
  city = new FormControl();
  mandi = new FormControl();

  min_price_from = new FormControl();
  min_price_to = new FormControl();

  max_price_from = new FormControl();
  max_price_to = new FormControl();
  status = new FormControl();
  all = 'All'
  document_date = new FormControl();
  documentDate = moment(new Date()).format('YYYY-MM-DD');
  public daterange: any = {
    start: this.documentDate,
    end: this.documentDate
  };

  public options: any = {
    locale: { format: 'D MMMM YYYY' },
    alwaysShowCalendars: false,
  };
  // mandi = new FormControl();

  constructor(
    private service: UbService,
    private fb: FormBuilder,
    private _service: NotificationsService,
    private excelService: ExcelService
  ) {
    this.filterForm = this.fb.group({
      state: ['', [Validators.required]],
      district: ['', [Validators.required]],
      city: ['', [Validators.required]],
      mandi: ['', [Validators.required]],
      delivery: ['', [Validators.required]],
      arthiya_id: ['', [Validators.required]],
      order_type: [''],
      order_status: [''],
      document_date: [''],
      order_number_from: [''],
      order_number_to: ['']
    });
    this.displayedColumns = ['state', 'district', 'city', 'order_number', 'order_type', 'order_date', 'mandi_name', 'pakka_arthiya', 'reference_number', 'delivery_location',
      'base_price', 'quantity', 'for_price', 'counter_offer_price',
      'created_by', 'order_creation_date', 'offer_change_state', 'is_submitted'];
  }

  ngOnInit(): void {
    this.service.getLocations().subscribe((response: any) => {
      this.cityList = response.result;
      this.cityListBack = response.result;

      let state = uniqueStateArr(response.result)
      this.stateList = state;
      this.stateListBack = state;

      let district = uniqueDistrictArr(response.result)
      this.districtList = district;
      this.districtListBack = district;

    });

    this.service.getAllMandi().subscribe((response: any) => {
      this.mandiList = response.result;
      this.mandiListBack = response.result;
    });

    this.service.getAllDeliveryLocation().subscribe((response: any) => {
      console.log(response);
      this.deliveryList = response.result;
    });

    this.service.getUserDetails().subscribe((response: any) => {
      console.log(response);
      this.userList = response.result;
      this.userList = this.userList.filter(user => user.role_id == 2);
      console.log(this.userList);
    });

    this.filterForm.get('state').setValue([this.all]);
    this.filterForm.get('district').setValue([this.all]);
    this.filterForm.get('city').setValue([this.all]);
    this.filterForm.get('mandi').setValue([this.all]);
    this.filterForm.get('delivery').setValue([this.all]);
    this.filterForm.get('arthiya_id').setValue([this.all]);
    this.filterForm.get('order_type').setValue(this.all);
    this.filterForm.get('order_status').setValue(this.all);
  }


  stateChange(element) {

    this.matRefDistrict.options.forEach((data) => data.deselect());
    this.matRefCity.options.forEach((data) => data.deselect());
    this.matRefMandi.options.forEach((data) => data.deselect());

    this.filterForm.get('district').setValue([this.all]);
    this.filterForm.get('city').setValue([this.all]);
    this.filterForm.get('mandi').setValue([this.all]);
    this.filterForm.get('delivery').setValue([this.all]);
    this.filterForm.get('arthiya_id').setValue([this.all]);

    const locationIds = this.filterForm.value.state;
    if (locationIds && locationIds[0] != 'All') {
      this.districtList = this.districtListBack.filter((location) => locationIds.includes(location.state_id));
    }

    console.log('this.districtList -------->', locationIds, this.districtList)
  }

  districtChange(element) {
    this.matRefCity.options.forEach((data) => data.deselect());
    this.matRefMandi.options.forEach((data) => data.deselect());

    this.filterForm.get('city').setValue([this.all]);
    this.filterForm.get('mandi').setValue([this.all]);
    this.filterForm.get('delivery').setValue([this.all]);
    this.filterForm.get('arthiya_id').setValue([this.all]);

    let locationIds: any = this.filterForm.value.district;
    if (locationIds && locationIds[0] != 'All') {
      this.cityList = this.cityListBack.filter((location) => locationIds.includes(location.district));
    }
    console.log('this.cityList -------->', locationIds, this.cityList)
  }

  cityChange(element) {
    let locationIds: any = this.filterForm.value.city;

    this.filterForm.get('mandi').setValue([this.all]);
    this.filterForm.get('delivery').setValue([this.all]);
    this.filterForm.get('arthiya_id').setValue([this.all]);

    if (locationIds && locationIds[0] != 'All') {
      this.mandiList = this.mandiListBack.filter((location) => locationIds.includes(location.location_id));
    }

    console.log('this.mandiList -------->', locationIds, this.mandiList)
  }

  mandiChange(element) {
    console.log('elemnt-->', element);
    console.log('topings-->', this.filterForm.value.mandi);
  }

  deliveryChange(element) {
    console.log('element-->', element);
    console.log(this.filterForm.value.delivery);
  }

  arthiyaChange(element) {
    console.log('element-->', element);
    console.log(this.filterForm.value.delivery);
  }

  get2RowArrivalData(id, date) {
    let filter;
    if (this.loggedInUser.role === UserRole.Facilitator) {
      filter = 'user_id=' + id + '&from_date=' + date.start + '&to_date=' + date.end;
    } else {
      filter = '&from_date=' + date.start + '&to_date=' + date.end;
    }
    this.loading = true;
    this.service.get2RowArrivalDatas(filter).subscribe((response: any) => {
      this.dataSource.data = response.result;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loading = false;
    }, error => {
      this._service.error('Error', error.error.message);
      this.loading = false;
    });
  }

  stateSelChange(element) {
  }

  districtSelChange(elemnt) {
  }

  citySelChange(elemnt) {
  }

  mandiSelChange(elemnt) {
  }


  public selectedDate(value: any, datepicker?: any) {

    datepicker.start = value.start;
    datepicker.end = value.end;

    this.daterange.start = moment(value.start).format('YYYY-MM-DD');
    this.daterange.end = moment(value.end).format('YYYY-MM-DD');
    this.daterange.label = value.label;
  }

  reset() {
    this.matRefState.options.forEach((data) => data.deselect());
    this.matRefDistrict.options.forEach((data) => data.deselect());
    this.matRefCity.options.forEach((data) => data.deselect());
    this.matRefMandi.options.forEach((data) => data.deselect());
    this.matRefDelivery.options.forEach((data) => data.deselect());
    this.matRefAratiya.options.forEach((data) => data.deselect());
    this.matRefOrderType.options.forEach((data) => data.deselect());
    this.matRefOrderStatus.options.forEach((data) => data.deselect());


    this.filterForm.get('state').setValue([this.all]);
    this.filterForm.get('district').setValue([this.all]);
    this.filterForm.get('city').setValue([this.all]);
    this.filterForm.get('mandi').setValue([this.all]);
    this.filterForm.get('delivery').setValue([this.all]);
    this.filterForm.get('arthiya_id').setValue([this.all]);
    this.filterForm.get('order_type').setValue(this.all);
    this.filterForm.get('order_status').setValue(this.all);

  }

  execute() {
    if (this.filterForm.valid) {
      const payload = {
        state: this.filterForm.value.state,
        district: this.filterForm.value.district,
        city: this.filterForm.value.city,
        mandi: this.filterForm.value.mandi,
        arthiya_id: this.filterForm.value.arthiya_id,
        destination_id: this.filterForm.value.destination_id,
        status: this.filterForm.value.order_status,
        type: this.filterForm.value.order_type,
        order_number_from: this.filterForm.value.order_number_from,
        order_number_to: this.filterForm.value.order_number_to,
        from_date: this.daterange.start,
        to_date: this.daterange.end,
        product_id: 1
      };
      this.loading = true;
      this.service.getOrderReport(payload).subscribe((response: any) => {

        if (response['result']) {
          this.dataSource.data = response.result;
        } else {
          this._service.error('Error', 'Data Fetiching Failed');
        }
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loading = false;

      }, error => {
        this._service.error('Error', error.error.message);
        this.loading = false;
      });
    }
  }

  exportAsXLSX() {
    console.log(this.dataSource.data);
    const exportData = this.dataSource.data.map((val) => (
      {
        id: val.id,
        'State': val.mandiObj.state,
        'District': val.mandiObj.district,
        'City': val.mandiObj.city,
        'Order Number': val.order_number,
        'Order Type': val.type,
        'Order Date': val.document_date,
        'Mandi Name': val.mandiObj.mandi_name,
        'Pakka Arthiya': val.user_id,
        'Reference Number': val.ref_num,
        'Delivery Location': val.destination_id,
        'Base Price': val.base_price,
        'Quantity': val.quantity,
        'For Price': val.order_for_price,
        'Counter Offer Price': val.counter_offer_price,
        'Order Created By': val.created_by,
        'Order Creation Date': val.created_at,
        'Offer Accept/Reject Date': (val.is_counter_offer_set == true) ? 'Accepted' : 'Rejected',
        'Status': val.status
      }
    ));
    this.excelService.exportAsExcelFile(exportData, 'Order-Report');
  }


}
