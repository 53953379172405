<h2 class="dialog-heading" *ngIf="title" mat-dialog-title>{{title}}</h2>
<mat-dialog-content style="width:500px">
    <form class="example-full-width" [formGroup]="offerForm" *ngIf="type !== 'update'">
        <mat-form-field class="example-full-width">
            <mat-label required>Select Mandi</mat-label>
            <mat-select class="matSelect" formControlName="mandi_id" required [disabled]="type === 'edit'">
                <!-- <mat-option *ngFor="let mandi of mandiList" [value]="mandi.mandi_id">
                    {{mandi.mandiObj.mandi_name}}
                </mat-option> -->
                <mat-option *ngFor="let mandi of mandiList" [value]="mandi.mandiObj ? mandi.mandi_id : mandi.id">
                    <span *ngIf="mandi.mandiObj">{{mandi.mandiObj.mandi_name}}</span>
                    <span *ngIf="!mandi.mandiObj">{{mandi.mandi_name}}</span>
                </mat-option>

            </mat-select>
            
            <mat-error>
                <span *ngIf="!offerForm.get('mandi_id').valid || offerForm.get('mandi_id').touched">Mandi is required</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="loggedInUser.role == UserRole.PukkaArthiya" class="example-full-width">
            <input formControlName="pakka_arthiya" placeholder="Pakka Arthiya" [readonly]="true" matInput type='text'
            required>
        </mat-form-field>

        <mat-form-field *ngIf="loggedInUser.role != UserRole.PukkaArthiya" class="example-full-width">
            <mat-label required>Select Pakka Arthiya</mat-label>
            <mat-select class="matSelect" formControlName="pakka_arthiya" required>
                <mat-option *ngFor="let user of pakkaArthiyaList" [value]="user.userObj.id">
                    {{user.userObj.user_id}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!offerForm.get('pakka_arthiya').valid || offerForm.get('pakka_arthiya').touched">Pakka Arthiya is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="ref_num" placeholder="Reference Number" matInput type='text'>
            
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label [class.active]="offerForm.get('document_date').value">Document Date</mat-label>
            <input formControlName="document_date" matInput  [matDatepicker]="picker1" [min]="minDate" [max]="maxDate" (dateChange)="changeDocumentDate('change', $event)">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1 color="primary"></mat-datepicker>
            <mat-error>
                <span *ngIf="offerForm.get('document_date').hasError('required')">Document Date is required</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <mat-label required>Delivery Location</mat-label>
            <mat-select class="matSelect" formControlName="delivery_id" required (selectionChange)="getCeilingPrice()">
                <mat-option *ngFor="let location of deliveryLocationList" [value]="location.destination_id">
                    {{location.destinationObj.delivery_name}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!offerForm.get('delivery_id').valid || offerForm.get('delivery_id').touched">Delivery Location is required</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <input formControlName="ceiling_price" placeholder="Ceiling For Price" matInput type='number' required readonly>
            <mat-error>
                <span *ngIf="offerForm.get('ceiling_price').hasError('required')">Ceiling Price is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="order_for_price" placeholder="For Price" matInput type='number' required>
            <mat-error>
                <span *ngIf="offerForm.get('order_for_price').hasError('required')">For Price is required</span>
                <span *ngIf="offerForm.get('order_for_price').hasError('baseLsMax')">For Price should be less than or equal to minimum price</span>
                <span *ngIf="offerForm.get('order_for_price').hasError('pattern')">For Price should be greater than or equal to 1</span>    
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="quantity" placeholder="Quantity" matInput type='number' required>
            <mat-error>
                <span *ngIf="offerForm.get('quantity').hasError('required')">Quantity is Required</span>
                <span *ngIf="offerForm.get('quantity').hasError('pattern')">Quantity should be greater than or equal to 0</span> 
            </mat-error>
        </mat-form-field>
        
    </form>
    <form class="example-full-width" [formGroup]="offerForm" *ngIf="type === 'update'">
        <mat-form-field class="example-full-width">
            <mat-label required>Select Mandi</mat-label>
            <mat-select class="matSelect" formControlName="mandi_id" required disabled>
                <mat-option *ngFor="let mandi of mandiList" [value]="mandi.mandiObj ? mandi.mandi_id : mandi.id">
                    <span *ngIf="mandi.mandiObj">{{mandi.mandiObj.mandi_name}}</span>
                    <span *ngIf="!mandi.mandiObj">{{mandi.mandi_name}}</span>
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!offerForm.get('mandi_id').valid || offerForm.get('mandi_id').touched">Mandi is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label required>Select Pakka Arthiya</mat-label>
            <mat-select class="matSelect" formControlName="pakka_arthiya" required disabled>
                <mat-option *ngFor="let user of pakkaArthiyaList" [value]="user.userObj.id">
                    {{user.userObj.user_id}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!offerForm.get('pakka_arthiya').valid || offerForm.get('pakka_arthiya').touched">Pakka Arthiya is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="ref_num" placeholder="Reference Number" matInput type='text' required readonly>
            <mat-error>
                <span *ngIf="offerForm.get('ref_num').hasError('required')">Reference Number is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field style="width:100%" color="accent" appearance="fill">
            <mat-label [class.active]="offerForm.get('document_date').value">Document Date</mat-label>
            <input formControlName="document_date" matInput [readonly]="true" [matDatepicker]="picker2">
            <!-- <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle> -->
            <mat-datepicker #picker2 color="primary"></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <mat-label required>Delivery Location</mat-label>
            <mat-select class="matSelect" formControlName="delivery_id" required>
                <mat-option *ngFor="let location of deliveryLocationList" [value]="location.destination_id">
                    {{location.destinationObj.delivery_name}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!offerForm.get('delivery_id').valid || offerForm.get('delivery_id').touched">Delivery Location is required</span>
            </mat-error>
        </mat-form-field>
        
        <mat-form-field class="example-full-width">
            <input formControlName="ceiling_price" placeholder="Ceiling Price" matInput type='number' required readonly>
            <mat-error>
                <span *ngIf="offerForm.get('ceiling_price').hasError('required')">Ceiling Price is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="counter_offer_price" placeholder="Counter Offer Price" matInput type='number'>
            <mat-error>
                <span *ngIf="offerForm.get('counter_offer_price').hasError('required')">Counter Offer Price is required</span>
                <span *ngIf="offerForm.get('counter_offer_price').hasError('pattern')">Counter Offer should be greater than or equal to 1</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="order_for_price" placeholder="Base Price" matInput type='number' required readonly>
            <mat-error>
                <span *ngIf="offerForm.get('order_for_price').hasError('required')">Base Price is required</span>
                <span *ngIf="offerForm.get('order_for_price').hasError('BaseLsMax')">Base Price should be less than or equal to minimum price</span>
                <span *ngIf="offerForm.get('order_for_price').hasError('pattern')">Base Price should be greater than or equal to 1</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="quantity" placeholder="Quantity" matInput type='number' required>
            <mat-error>
                <span *ngIf="offerForm.get('quantity').hasError('required')">Quantity is Required</span>
                <span *ngIf="offerForm.get('quantity').hasError('pattern')">Quantity should be greater than or equal to 0</span>
            </mat-error>
        </mat-form-field>
        
        <mat-form-field class="example-full-width">
            <mat-label required>Plant Code</mat-label>
            <mat-select class="matSelect" formControlName="plant_master_id" required>
                <mat-option *ngFor="let plantcode of plantCodeList" [value]="plantcode.id">
                    {{plantcode.name}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!offerForm.get('plant_master_id').valid || offerForm.get('plant_master_id').touched">Plant Code is required</span>
            </mat-error>
        </mat-form-field>
    </form>
    <mat-dialog-actions  *ngIf="type === 'add' && status != 'APPROVED'">
        <button class="mat-raised-button mr-10" (click)="close()">Close</button>
        <button class="mat-raised-button mat-primary" (click)="addOffer()">Add</button>
    </mat-dialog-actions>
    <mat-dialog-actions  *ngIf="type === 'edit' && status != 'APPROVED'">
        <button class="mat-raised-button mr-10" (click)="close()">Close</button>
        <button class="mat-raised-button mat-primary" (click)="updateOffer()">Save</button>
    </mat-dialog-actions>
    <mat-dialog-actions  *ngIf="type === 'update' && status != 'APPROVED'">
        <button class="mat-raised-button mr-10" (click)="close()">Close</button>
        <button class="mat-raised-button mat-primary" (click)="updateOffer()">update</button>
    </mat-dialog-actions>
    <mat-dialog-actions  *ngIf="type === 'update' && status == 'APPROVED'">
        <button class="mat-raised-button mr-10" (click)="close()">Close</button>
        <button class="mat-raised-button mat-primary" (click)="approveOffer()">Approve</button>
    </mat-dialog-actions>
</mat-dialog-content>
