import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationsService } from 'angular2-notifications';
import { UbService } from 'src/app/services/ub.service';
@Component({
  selector: 'app-states-master-dialog',
  templateUrl: './states-master-dialog.component.html',
  styleUrls: ['./states-master-dialog.component.scss']
})
export class StatesMasterDialogComponent implements OnInit {

  title: string;
  stateForm: FormGroup; 
  loggedInUser;
  state_id;
  type;
  states;
  isActive=[
    { value:'1', name:'Active'},
    { value:'0', name:'Inactive'}
  ];

  constructor(
    private service: UbService,
    private fb: FormBuilder,
    private notificationService: NotificationsService,
    private dialogRef: MatDialogRef<StatesMasterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) { 
    this.title = data.title;
    this.type = data.type;
    this.state_id=data.state_id;
  }

  ngOnInit(){
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    this.stateForm = this.fb.group({      
      state_name: ['', [Validators.required]],
      sort_order: ['', [Validators.required, Validators.pattern('^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$')]],
      short_name: ['', [Validators.required]],
      active:['',[Validators.required]]
    });
    if(this.state_id){
      this.getStateData(this.state_id);     
    }
    }
  

  getStateData(id) {
    this.service.getStatesById(id).subscribe((res) => {
      if (res['status']) {
        const data = res['result'];
        this.stateForm.controls.state_name.setValue(data.state_name);
        this.stateForm.controls.sort_order.setValue(data.sort_order);
        this.stateForm.controls.short_name.setValue(data.short_name);
        this.stateForm.controls.active.setValue(data.status);
      }
    } , err => {})
  }

  close() {
    this.dialogRef.close();
  }

  addState() {
    for (const c in this.stateForm.controls) {
      if (this.stateForm.controls.hasOwnProperty(c)) {
        this.stateForm.controls[c].markAsTouched();
      }
    }   
    if (this.stateForm.valid) {
      const payload = {
        "state_name": this.stateForm.value.state_name,
        "sort_order": this.stateForm.value.sort_order,
        "short_name": this.stateForm.value.short_name,
        "status":this.stateForm.value.active,
        "created_by":this.loggedInUser.id,
      }
      this.service.addState(payload).subscribe((res) => {
        this.notificationService.success('Success', 'State Added Successfully');
        this.dialogRef.close();
      }, error => {
        this.notificationService.error('Error', error.error.message);
        this.dialogRef.close();
      });  
    }  
  }

  editState() {
    for (const c in this.stateForm.controls) {
      if (this.stateForm.controls.hasOwnProperty(c)) {
        this.stateForm.controls[c].markAsTouched();
      }
    }   
    if (this.stateForm.valid) {
      const payload = {
        "state_name": this.stateForm.value.state_name,
        "sort_order": this.stateForm.value.sort_order,
        "short_name": this.stateForm.value.short_name,
        "status":this.stateForm.value.active,
      }
        this.service.updateState(this.state_id , payload).subscribe((res) => {
          this.notificationService.success('Success', 'State Udapted Successfully');
          this.dialogRef.close();
        }, error => {
          this.notificationService.error('Error', error.error.message);
          this.dialogRef.close();
        });
      }
  }


}
