<h2 class="dialog-heading" *ngIf="dialogData" mat-dialog-title>{{dialogData.title}}</h2>
<mat-dialog-content style="width:500px">
    <form class="example-full-width" [formGroup]="manageOrderForm">
        <mat-form-field class="example-full-width">
            <mat-label required>Select Mandi</mat-label>
            <!-- <mat-select *ngIf="!isUbLoggedIn" class="matSelect" formControlName="mandi_id" [disabled]="status ? true : false"
                (selectionChange)="getDeliveryLocation($event.value)" [(value)]="selectedMandi" required>
                <mat-option *ngFor="let mandi of mandiList" [value]="mandi.mandiObj ? mandi.mandi_id : mandi.id">
                    <span *ngIf="mandi.mandiObj">{{mandi.mandiObj.mandi_name}}</span>
                </mat-option>
            </mat-select> -->

            <mat-select class="matSelect" formControlName="mandi_id" [disabled]="status ? true : false"
                (selectionChange)="getDeliveryLocation($event.value)" [(value)]="selectedMandi" required>
                <mat-option *ngFor="let mandi of mandiList" [value]="mandi.mandiObj ? mandi.mandi_id : mandi.id">
                    <span *ngIf="mandi.mandiObj">{{mandi.mandiObj.mandi_name}}</span>
                    <span *ngIf="!mandi.mandiObj">{{mandi.mandi_name}}</span>
                </mat-option>
            </mat-select>

            <mat-error>
                <span *ngIf="!manageOrderForm.get('mandi_id').valid || manageOrderForm.get('mandi_id').touched">One
                    Mandi is
                    compulsory</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="dialogData.user.role == UserRole.PukkaArthiya" class="example-full-width">
            <input formControlName="pakka_arthiya" placeholder="Pakka Arthiya" [readonly]="true" matInput type='text'
            required>
        </mat-form-field>
        <mat-form-field *ngIf="dialogData.user.role != UserRole.PukkaArthiya" class="example-full-width">
            <mat-label required>Pakka Arthiya</mat-label>
            <mat-select formControlName="pakka_arthiya"  class="matSelect" 
                (selectionChange)="getUserDetails($event.value)" [(value)]="selectedUser" required>
                <mat-option *ngFor="let user of userDetails" [value]="user.userObj.id">
                    {{user.userObj.user_id}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!manageOrderForm.get('pakka_arthiya').valid || manageOrderForm.get('pakka_arthiya').touched">One
                    User is
                    compulsory</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="referenceNo" placeholder="Reference No"  matInput type='text'>
        </mat-form-field>
        <mat-form-field class="example-full-width" color="accent">
            <mat-label [class.active]="manageOrderForm.get('document_date').value">Document Date</mat-label>
            <input formControlName="document_date" (dateChange)="getCeilingPrice()" matInput [matDatepicker]="picker2" [readonly]="status ? true : false">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2 color="primary"></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label required>Product Type</mat-label>
            <mat-select (selectionChange)="getCeilingPrice()" class="matSelect" formControlName="product_id" [disabled]="status ? true : false"
                [(value)]="selectedProduct" required>
                <mat-option *ngFor="let product of dialogData.products" [value]="product.id">
                    {{product.description}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!manageOrderForm.get('product_id').valid || manageOrderForm.get('product_id').touched">
                    Product is
                    compulsory</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label required>Delivery Location</mat-label>
            <mat-select class="matSelect" formControlName="delivery_location" [(value)]="selectedLocation" required>
                <mat-option *ngFor="let item of deliveryLocation" [value]="item.destinationObj.id">
                    {{item.destinationObj.short_name}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span
                    *ngIf="!manageOrderForm.get('delivery_location').valid || manageOrderForm.get('delivery_location').touched">
                    Delivery Location is
                    compulsory</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="ceiling_price" placeholder="Celling Price" [readonly]="true" matInput type='number'
                required>
            <mat-error>
                <span *ngIf="!manageOrderForm.get('ceiling_price').valid || manageOrderForm.get('ceiling_price').touched">Ceiling Price is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="base_price" placeholder="Base Price" (keyup)="onKeyUpEvent($event)" matInput
                type='number' required>
            <mat-error>
                <span *ngIf="higherBasePrice">Base Price should be less than Ceiling Price</span>
                <span *ngIf="!manageOrderForm.get('base_price').valid || manageOrderForm.get('base_price').touched">
                    Base Price is
                    compulsory</span>
                <span *ngIf="manageOrderForm.get('base_price').hasError('pattern')">Base Price should be greater than or equal to 1</span> 
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="quantity" placeholder="Quantity" matInput type='number'>
            <mat-error>
                <span *ngIf="manageOrderForm.get('quantity').hasError('pattern')">Quantity should be greater than or equal to 0</span> 
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width" *ngIf="isShowPlantCode">
            <mat-label required>Plant Code</mat-label>
            <mat-select class="matSelect" formControlName="plant_master_id" required>
                <mat-option *ngFor="let plantcode of plantCodeList" [value]="plantcode.id">
                    {{plantcode.name}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!manageOrderForm.get('plant_master_id').valid || manageOrderForm.get('plant_master_id').touched">Plant Code is required</span>
            </mat-error>
        </mat-form-field>
    </form>
    <mat-dialog-actions *ngIf="!dialogData.editItem && !status">
        <button class="mat-raised-button mr-10" (click)="close()">Close</button>
        <button  class="mat-raised-button mat-primary" (click)="submitOrder()">Add</button>
    </mat-dialog-actions>
    <mat-dialog-actions *ngIf="dialogData.editItem && !status">
        <button class="mat-raised-button mr-10" (click)="close()">Close</button>
        <button class="mat-raised-button mat-primary" (click)="editOrder()">Save</button>
    </mat-dialog-actions>
    <mat-dialog-actions *ngIf="dialogData.editItem && status">
        <button class="mat-raised-button mr-10" (click)="close()">Close</button>
        <button class="mat-raised-button mat-primary" (click)="approveOrder()">Approve</button>
    </mat-dialog-actions>
    <simple-notifications [options]="notificationOptions"></simple-notifications>
</mat-dialog-content>