import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, ActivatedRoute, Params } from '@angular/router';
import jwt_decode from "jwt-decode";
import { map } from 'rxjs/operators';
import { UbService } from './ub.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(public router: Router, public ubService: UbService, private activatedRoute: ActivatedRoute) { }
  canActivate(route: ActivatedRouteSnapshot): any {
    const authToken = this.ubService.getToken();
    console.log(authToken)
    // setTimeout(() => {
    //   this.activatedRoute.queryParams.subscribe(
    //     (params: Params) => {
    //       console.log(params);
    //     });
    //   this.validateToken(authToken);
    //   if (authToken) {
    return true;
    //   } else {
    //     this.router.navigate(['/login']);
    //     return false;
    //   }
    // } , 2000);
  }

  validateToken(authToken) {
    return true;
    try {
      const decodedToken: any = jwt_decode(authToken);
      if (decodedToken.exp === undefined) {
        return false;
      }

      const expTime = decodedToken.exp;
      if (expTime.valueOf() < Date.now() / 1000 ) {
        return false;
      }

      return true;

    } catch (err) {
      console.log('toekn err: ', err);
      return true;
    }
  }
}
