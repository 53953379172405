import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationsService } from 'angular2-notifications';
import { UbService } from 'src/app/services/ub.service';
import * as moment from 'moment';
import { MinValidation } from '../dialog/minValidation';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { UserRole } from 'src/app/models/schema';

@Component({
  selector: 'app-manage-offer-dialog',
  templateUrl: './manage-offer-dialog.component.html',
  styleUrls: ['./manage-offer-dialog.component.scss']
})
export class ManageOfferDialogComponent implements OnInit {
  UserRole = UserRole;
  title: string;
  offerForm: FormGroup;
  pakkaArthiyaList = [];
  deliveryLocationList = [];
  loggedInUser;
  offer_id;
  type;
  mandiList = [];
  refPattern = '^(?=.*[A-Za-z])(?=.*[0-9])[A-Za-z0-9]+$';
  documentDate = moment(new Date()).format('YYYY-MM-DD');

  maxDate = moment(new Date()).toDate()
  minDate = moment(new Date()).subtract(1, 'days').toDate();
  plantCodeList = [];
  status;
  isSeason: boolean;
  changeDocumentDate(type: string, event: MatDatepickerInputEvent<Date>) {
    this.documentDate = moment(new Date(event.value)).format('YYYY-MM-DD');
    this.getCeilingPrice();
  }

  constructor(
    private service: UbService,
    private fb: FormBuilder,
    private notificationService: NotificationsService,
    private dialogRef: MatDialogRef<ManageOfferDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.title = data.title;
    this.offer_id = data.offer_id;
    this.type = data.type;
    this.status = data.status;
    this.isSeason = data.season
    console.log('data--------->', data)
  }

  ngOnInit() {
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    if (this.type === 'update') {
      this.offerForm = this.fb.group({
        mandi_id: ['', [Validators.required]],
        pakka_arthiya: ['', [Validators.required]],
        ref_num: [''],
        document_date: ['', [Validators.required]],
        quantity: ['', [Validators.required, Validators.pattern('^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$')]],
        order_for_price: ['', [Validators.required, Validators.pattern('^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$')]],
        delivery_id: ['', [Validators.required]],
        ceiling_price: ['', [Validators.required]],
        counter_offer_price: ['', Validators.pattern('^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$')],
        plant_master_id: ['', [Validators.required]]
      }, {
        validator: MinValidation.BaseLsMax
      });
    } else {
      this.offerForm = this.fb.group({
        mandi_id: ['', [Validators.required]],
        pakka_arthiya: ['', [Validators.required]],
        ref_num: [''],
        document_date: [this.documentDate, [Validators.required]],
        quantity: ['', [Validators.required, Validators.pattern('^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$')]],
        order_for_price: ['', [Validators.required, Validators.pattern('^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$')]],
        delivery_id: ['', [Validators.required]],
        ceiling_price: ['', [Validators.required]],
        counter_offer_price: [''],
        plant_master_id: ['']
      }, {
        validator: MinValidation.BaseLsMax
      });
    }
    if (this.loggedInUser.role == UserRole.PukkaArthiya) {
      this.pakkaArthiyaList = [{ userObj: this.loggedInUser }];
      this.offerForm.controls.pakka_arthiya.setValue(this.loggedInUser.id);
    }
    this.offerForm.controls.mandi_id.valueChanges.subscribe((result) => {
      this.getAllDeliveryLocation(result);
      this.getAllPakkaArthiya(result);
      this.getplantCode();
    });

    this.getMandiList(this.loggedInUser.id);
    if (this.offer_id) {
      this.service.getOfferById(this.offer_id).subscribe((result) => {
        this.offerForm.controls['mandi_id'].setValue(result['result'].mandi_id);
        this.offerForm.controls['pakka_arthiya'].setValue(result['result'].user_id);
        this.offerForm.controls['ref_num'].setValue(result['result'].ref_num);
        this.offerForm.controls['quantity'].setValue(result['result'].quantity);
        this.offerForm.controls['document_date'].setValue(result['result'].document_date);
        this.offerForm.controls['delivery_id'].setValue(result['result'].destination_id);
        this.offerForm.controls['ceiling_price'].setValue(result['result'].ceiling_price);
        this.offerForm.controls['order_for_price'].setValue(result['result'].order_for_price);
        this.offerForm.controls['counter_offer_price'].setValue(result['result'].counter_offer_price);
        this.offerForm.controls['plant_master_id'].setValue(result['result'].plant_master_id);
      }, error => {
        this.notificationService.error('Error', error.error.message);
      });
    }
  }

  getplantCode() {
    this.plantCodeList = [];
    this.service.getAllPlantCode().subscribe((res) => {
      if (res['status']) {
        this.plantCodeList = res['result'];
      }
    }, error => {
      this.notificationService.error('Error', error.error.message);
    })
  }

  generateReferenceNo() {
    let referenceNo = Math.floor(Math.random() * (999 - 100 + 1) + 100);
    if (referenceNo) {
      this.offerForm.controls.ref_num.setValue(referenceNo);
    }
  }

  getMandiList(userId) {
    if (this.loggedInUser.role != UserRole.Facilitator && this.loggedInUser.role != UserRole.PukkaArthiya) {
      this.service.getAllMandi().subscribe(
        (mandiResponse: any) => {
          if (mandiResponse.status === true) {
            this.mandiList = mandiResponse.result;
          }
          else {
            console.log('error')
          }
        }, (err) => {
          console.log(err)
        }
      );
    } else {
      this.service.getUserMandi(userId).subscribe(
        (mandiResponse: any) => {
          if (mandiResponse.status === true) {
            this.mandiList = mandiResponse.result;
          }
          else {
            console.log('error')
          }
        }, (err) => {
          console.log(err)
        }
      );
    }
  }
  // getMandiList() {
  //   this.service.getUserMandi(this.loggedInUser.id).subscribe((res) => {
  //     if (res['status']) {
  //       this.mandiList = res['result'];
  //       if (this.mandiList.length === 0) {
  //         this.service.getAllMandi().subscribe((res) => {
  //           if (res['status']) {
  //             for (const data of res['result']) {
  //               this.mandiList.push({ mandi_id: data.id, mandiObj: data });
  //             }
              
  //           }
  //         })
  //       } else {
  //         if (this.offer_id) {
  //           this.service.getOfferById(this.offer_id).subscribe((result) => {
  //             console.log('result------------------->', result)
  //             this.offerForm.controls['mandi_id'].setValue(result['result'].mandi_id);
  //             this.offerForm.controls['pakka_arthiya'].setValue(result['result'].user_id);
  //             this.offerForm.controls['ref_num'].setValue(result['result'].ref_num);
  //             this.offerForm.controls['quantity'].setValue(result['result'].quantity);
  //             this.offerForm.controls['document_date'].setValue(result['result'].document_date);
  //             this.offerForm.controls['delivery_id'].setValue(result['result'].destination_id);
  //             this.offerForm.controls['ceiling_price'].setValue(result['result'].ceiling_price);
  //             this.offerForm.controls['order_for_price'].setValue(result['result'].order_for_price);
  //             this.offerForm.controls['counter_offer_price'].setValue(result['result'].counter_offer_price);
  //             this.offerForm.controls['plant_master_id'].setValue(result['result'].plant_master_id);
  //           }, error => {
  //             this.notificationService.error('Error', error.error.message);
  //           });
  //         }
  //       }
  //     }
  //   }, error => {
  //     this.service.getAllMandi().subscribe((res) => {
  //       if (res['status']) {
  //         for (const data of res['result']) {
  //           this.mandiList.push({ mandi_id: data.id, mandiObj: data });
  //         }
  //         if (this.offer_id) {
  //           this.service.getOfferById(this.offer_id).subscribe((result) => {
  //             this.offerForm.controls['mandi_id'].setValue(result['result'].mandi_id);
  //             this.offerForm.controls['pakka_arthiya'].setValue(result['result'].user_id);
  //             this.offerForm.controls['ref_num'].setValue(result['result'].ref_num);
  //             this.offerForm.controls['quantity'].setValue(result['result'].quantity);
  //             this.offerForm.controls['document_date'].setValue(result['result'].document_date);
  //             this.offerForm.controls['delivery_id'].setValue(result['result'].destination_id);
  //             this.offerForm.controls['ceiling_price'].setValue(result['result'].ceiling_price);
  //             this.offerForm.controls['order_for_price'].setValue(result['result'].order_for_price);
  //             this.offerForm.controls['counter_offer_price'].setValue(result['result'].counter_offer_price);
  //             this.offerForm.controls['plant_master_id'].setValue(result['result'].plant_master_id);
  //           }, error => {
  //             this.notificationService.error('Error', error.error.message);
  //           });
  //         }
  //       }
  //     })
  //     this.notificationService.error('Error', error.error.message);
  //   })
  // }

  getAllDeliveryLocation(result) {
    this.deliveryLocationList = [];
    this.service.getMandiDestination(result).subscribe((res) => {
      if (res['status']) {
        this.deliveryLocationList = res['result'];
        if (this.offerForm.value.mandi_id && this.documentDate && !this.offer_id) {
          this.getCeilingPrice();
        }
      }
    }, error => {
      this.notificationService.error('Error', error.error.message);
    });
  }

  getAllPakkaArthiya(result) {
    this.pakkaArthiyaList = [];
    this.service.getMandiFilter(result).subscribe(
      (mandiResponse: any) => {
        if (mandiResponse.status === true) {
          if (mandiResponse.result.length > 0) {
            this.pakkaArthiyaList = mandiResponse.result;
            this.pakkaArthiyaList = this.pakkaArthiyaList.filter((itm) => itm.userObj.role_id === 2);
          }
        }
      }, error => {
        this.notificationService.error('Error', error.error.message);
      }
    );
  }

  close() {
    this.dialogRef.close();
  }

  getCeilingPrice() {
    if (this.offerForm.value.document_date) {
      this.documentDate = moment(new Date(this.offerForm.value.document_date)).format('YYYY-MM-DD');
    }

    if (this.offerForm.value.mandi_id && this.documentDate && this.offerForm.value.delivery_id) {
      console.log('')
      const mandiDetails = this.mandiList.find(val=>val.mandi_id == this.offerForm.value.mandi_id);
      if(!mandiDetails || !mandiDetails.mandiObj || !mandiDetails.mandiObj?.cluster_id) {
        this.notificationService.error('Error', 'Mandi is not mapped to cluster');
        return false;
      }
      const clusterId = mandiDetails.mandiObj.cluster_id;
      this.service.getForCeilingPrice(clusterId, this.offerForm.value.delivery_id,  this.documentDate, this.isSeason).subscribe(
        (response: any) => {
          if (response.result) {
            this.offerForm.controls['ceiling_price'].setValue(response.result.ceiling_for_price);
          } else {
            this.notificationService.error('Error', 'Ceiling FOR Price not set');
          }

        }, (error) => {
          this.notificationService.error('Error', error.error.message);
        }
      );
    }
  }

  addOffer() {
    
    for (const c in this.offerForm.controls) {
      if (this.offerForm.controls.hasOwnProperty(c)) {
        this.offerForm.controls[c].markAsTouched();
      }
    }

    if (this.offerForm.valid) {
      const payload = {
        mandi_id: this.offerForm.value.mandi_id,
        document_date: moment(new Date(this.offerForm.value.document_date)).format('YYYY-MM-DD'),
        user_id: this.offerForm.value.pakka_arthiya,
        created_by: this.loggedInUser.id,
        product_id: 1,
        quantity: this.offerForm.value.quantity,
        ref_num: this.offerForm.value.ref_num.toString(),
        destination_id: this.offerForm.value.delivery_id,
        order_for_price: this.offerForm.value.order_for_price,
        ceiling_price: this.offerForm.value.ceiling_price,
        suggested_price: this.offerForm.value.ceiling_price,
        type: "FOR",
        status: "SAVED"
      }
      this.service.createOrder(payload).subscribe((res) => {
        if (res['status']) {
          this.notificationService.success('Success', 'Offer Created Successfully');
        }
        this.close();
      }, error => {
        this.notificationService.error('Error', error.error.message);
      })
    }
  }

  updateOffer() {
    for (const c in this.offerForm.controls) {
      if (this.offerForm.controls.hasOwnProperty(c)) {
        this.offerForm.controls[c].markAsTouched();
      }
    }

    if (this.offerForm.valid) {
      const payload = {
        mandi_id: this.offerForm.value.mandi_id,
        document_date: moment(new Date(this.offerForm.value.document_date)).format('YYYY-MM-DD'),
        user_id: this.offerForm.value.pakka_arthiya,
        // created_by: this.loggedInUser.id,
        product_id: 1,
        quantity: this.offerForm.value.quantity,
        ref_num: this.offerForm.value.ref_num.toString(),
        destination_id: this.offerForm.value.delivery_id,
        order_for_price: this.offerForm.value.order_for_price,
        type: "FOR",
        // status: "SAVED",
        plant_master_id: this.offerForm.value.plant_master_id,
        updated_by: this.loggedInUser.id
      }
      if (this.loggedInUser.role.toLowerCase() == 'facilitator' || this.loggedInUser.role.toLowerCase() == 'pakka arthiya') {
        payload['status'] = 'SAVED';
      }

      if (this.type === 'update' && this.offerForm.value.counter_offer_price) {
        const payload_1 = {
          quantity: this.offerForm.value.quantity,
          counter_offer_price: this.offerForm.value.counter_offer_price,
          id: this.offer_id
        }
        this.service.updatePriceOrder(payload_1).subscribe((res) => {
          if (res['status']) {
            this.notificationService.success('Success', 'Updated Successfully');
          }
          this.close();
        }, error => {
          this.notificationService.error('Error', error.error.message);
        })
      }
      this.service.updateOrder(payload, this.offer_id).subscribe((res) => {
        console.log(res);
        if (res['status']) {
          this.notificationService.success('Success', 'Offer Updated Successfully');
        }
        this.close();
      }, error => {
        this.notificationService.error('Error', error.error.message);
      })
    }
  }

  approveOffer() {
    for (const c in this.offerForm.controls) {
      if (this.offerForm.controls.hasOwnProperty(c)) {
        this.offerForm.controls[c].markAsTouched();
      }
    }

    if (this.offerForm.valid) {
      const payload = {
        mandi_id: this.offerForm.value.mandi_id,
        document_date: moment(new Date(this.offerForm.value.document_date)).format('YYYY-MM-DD'),
        user_id: this.offerForm.value.pakka_arthiya,
        product_id: 1,
        quantity: this.offerForm.value.quantity,
        ref_num: this.offerForm.value.ref_num.toString(),
        destination_id: this.offerForm.value.delivery_id,
        order_for_price: this.offerForm.value.order_for_price,
        type: "FOR",
        plant_master_id: this.offerForm.value.plant_master_id,
        reviewed_by: this.loggedInUser.id,
        updated_by: this.loggedInUser.id
      }
      if (this.loggedInUser.role.toLowerCase() == 'facilitator' || this.loggedInUser.role.toLowerCase() == 'pakka arthiya') {
        payload['status'] = 'SAVED';
      }
      if (this.type === 'update' && this.offerForm.value.counter_offer_price) {
        const payload_1 = {
          quantity: this.offerForm.value.quantity,
          counter_offer_price: this.offerForm.value.counter_offer_price,
          id: this.offer_id
        }
        this.service.updatePriceOrder(payload_1).subscribe((res) => {
          if (res['status']) {
            // this.notificationService.success('Success', 'Updated Successfully');
          }
          this.close();
        }, error => {
          this.notificationService.error('Error', error.error.message);
        })
      }
      this.service.updateOrder(payload, this.offer_id).subscribe((res) => {
        if (res['status']) {
          const payload = {
            ids: [this.offer_id],
            status: 'APPROVED'
          }
          this.service.reviewOrder(payload).subscribe(
            (response: any) => {
              if (response.status === true) {
                this.dialogRef.close();
                this.notificationService.success('Success', 'Offer Approved Successfully');
              }
            }, (err) => {
              console.log(err)
            }
          )
          // this.notificationService.success('Success', 'Offer Updated Successfully');
        }
        this.close();
      }, error => {
        this.notificationService.error('Error', error.error.message);
      })
    }
  }

}
