<div class="container-fluid">
    <div class="pageHeader">
        For Dashboard - 6 Row Barley({{seasonType}})
    </div>

    <div class="dateRangeBlock">
        <div class="col-md-6">
            <span class="minMandiContent"> <span class="minMandiCount">{{minimumOfMandis}}</span> Minimum of all Cluster
            </span>
        </div>
        <div class="col-md-6 buttonAlignRight">
            <span [ngClass]="seasonType === ForDashToggleText.Season ? 'activeType' : 'inactiveType'">Season</span>
            <mat-slide-toggle (change)="seasonTypeChange($event)" color="'#3f51b5'"
                [checked]="seasonType != activeSeason" [disabled]="!isBothSeasonAllowed"></mat-slide-toggle>
            <span [ngClass]="seasonType != ForDashToggleText.Season ? 'activeType' : 'inactiveType'">Offseason</span>
        </div>
    </div>


    <div class="tabsBlock">
        <div class="tabContentBlock">
            <div class="row">
                <div class="col-md-4 pl-0">
                    <mat-form-field class="cusWidth">
                        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
                        <button mat-icon-button matPrefix>
                            <mat-icon class="primColor">search</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
                <div class="col-md-8 buttonAlignRight tabBtnAlign"
                    *ngIf="seasonType == ForDashToggleText.Season && checkPermission(ForDashAction.view_season_for)">

                    <button class="mat-raised-button mat-primary freezeAllBtn mr-10" (click)="freezeAll('freeze')"
                        *ngIf="checkPermission(ForDashAction.freeze_season_for) && (isFrozen == false || isFrozen == null)">Freeze
                        All</button>

                    <button class="mat-raised-button mat-primary unfreezeAllBtn mr-10" (click)="freezeAll('unfreeze')"
                        *ngIf="checkPermission(ForDashAction.freeze_season_for) && isFrozen == true">UnFreeze
                        All</button>

                    <button class="mat-raised-button mat-primary  mr-10" (click)="openCeilingPriceDialog()"
                        *ngIf="checkPermission(ForDashAction.set_ceiling_season_for)">Set All</button>

                    <button class="mat-raised-button mat-primary  mr-10" (click)="openCeilingPriceClusterDialog()"
                        *ngIf="checkPermission(ForDashAction.set_ceiling_season_for)">Set Per Cluster</button>

                    <button class="mat-raised-button mat-primary  mr-10" (click)="submitForApproval()"
                        *ngIf="checkPermission(ForDashAction.send_review_season_for)">Submit for Approval</button>

                    <button class="mat-raised-button mat-primary notifyAllBtn mr-10" (click)="notifyAll()"
                        *ngIf="checkPermission(ForDashAction.notify_season_for)">Notify All</button>

                    <button class="mat-raised-button mat-primary rejectAllBtn mr-10" (click)="rejectAll();"
                        *ngIf="checkPermission(ForDashAction.review_season_for)">Reject All</button>

                    <button class="mat-raised-button mat-primary approveAllBtn mr-10" (click)="approveAll();"
                        *ngIf="checkPermission(ForDashAction.review_season_for)">Approve All</button>

                    <button class="mat-raised-button mat-primary exportBtn" (click)="exportAsXLSX()">
                        <fa-icon [icon]="['fas', 'download']"></fa-icon> Export Excel
                    </button>
                </div>

                <div class="col-md-8 buttonAlignRight tabBtnAlign"
                    *ngIf="seasonType == ForDashToggleText.Offseason && checkPermission(ForDashAction.view_offseason_for)">

                    <button class="mat-raised-button mat-primary freezeAllBtn mr-10" (click)="freezeAll('freeze')"
                        *ngIf="checkPermission(ForDashAction.freeze_offseason_for) && (isFrozen == false || isFrozen == null)">Freeze
                        All</button>

                    <button class="mat-raised-button mat-primary unfreezeAllBtn mr-10" (click)="freezeAll('unfreeze')"
                        *ngIf="checkPermission(ForDashAction.freeze_offseason_for) && isFrozen == true">UnFreeze
                        All</button>

                    <button class="mat-raised-button mat-primary  mr-10" (click)="openCeilingPriceDialog()"
                        *ngIf="checkPermission(ForDashAction.set_ceiling_offseason_for)">Set All</button>

                    <button class="mat-raised-button mat-primary  mr-10" (click)="openCeilingPriceClusterDialog()"
                        *ngIf="checkPermission(ForDashAction.set_ceiling_offseason_for)">Set Per Cluster</button>

                    <button class="mat-raised-button mat-primary  mr-10" (click)="submitForApproval()"
                        *ngIf="checkPermission(ForDashAction.send_review_offseason_for)">Submit for Approval</button>

                    <button class="mat-raised-button mat-primary notifyAllBtn mr-10" (click)="notifyAll()"
                        *ngIf="checkPermission(ForDashAction.notify_offseason_for)">Notify All</button>

                    <button class="mat-raised-button mat-primary rejectAllBtn mr-10" (click)="rejectAll();"
                        *ngIf="checkPermission(ForDashAction.review_offseason_for)">Reject All</button>

                    <button class="mat-raised-button mat-primary approveAllBtn mr-10" (click)="approveAll();"
                        *ngIf="checkPermission(ForDashAction.review_offseason_for)">Approve All</button>

                    <button class="mat-raised-button mat-primary exportBtn" (click)="exportAsXLSX()">
                        <fa-icon [icon]="['fas', 'download']"></fa-icon> Export Excel
                    </button>
                </div>
            </div>
            <div class="row" style="padding: 20px 0px 0px;">
                <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
                <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="$event ? masterToggle() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="clister_id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Cluster </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.clusterObj.name}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="delivery_location">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Delivery Location </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.destinationObj.short_name}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="notified_date">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Notified Date & time </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.notified_at | date: 'dd/MM/yyyy h:mm:ss' }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="avg_price">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Arrival Avg Price <br> (&#x20b9;)
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.avg_price}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="for_freight" *ngIf="seasonType == ForDashToggleText.Season">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> For Freight<br> (&#x20b9;)
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.for_freight}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="expenses" *ngIf="seasonType == ForDashToggleText.Season">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Mandi Expenses <br> (&#x20b9;)
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.expenses}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="storage" *ngIf="seasonType == ForDashToggleText.Offseason">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Storage Cost<br> (&#x20b9;)
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.storage}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="interest" *ngIf="seasonType == ForDashToggleText.Offseason">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Interest Rate<br> (&#x20b9;)
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.interest}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="avg_buying_price">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Avg Buying Price <br> (&#x20b9;)
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.avg_buying_price}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="suggested_for_price">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Suggested For Price <br> (&#x20b9;)
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.suggested_for_price}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="ceiling_for_price">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Ceiling For Price <br> (&#x20b9;)
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element  let i = index">
                            <div *ngIf="seasonType == ForDashToggleText.Season">
                                <input matInput [disabled]="!isFrozen" [(ngModel)]="element.ceiling_for_price"
                                    class="ceilingInput">
                                <button mat-raised-button matSuffix [disabled]="!isFrozen" 
                                [ngClass]="element.is_ceiling_set ?'ceilingPriceBtn mr-10':'ceilingPriceBtnSet mr-10'"
                                    (click)="setCeilingPrice(element)" *ngIf="checkPermission(ForDashAction.set_ceiling_season_for)">
                                    set
                                </button>
                                
                            </div>

                            <div *ngIf="seasonType == ForDashToggleText.Offseason">
                                <input matInput [disabled]="!isFrozen" [(ngModel)]="element.ceiling_for_price"
                                    class="ceilingInput">
                                <button mat-raised-button matSuffix [disabled]="!isFrozen" 
                                [ngClass]="element.is_ceiling_set ?'ceilingPriceBtn mr-10':'ceilingPriceBtnSet mr-10'"
                                    (click)="setCeilingPrice(element)" *ngIf="checkPermission(ForDashAction.set_ceiling_offseason_for)">
                                    set
                                </button>
                                
                            </div>

                        </mat-cell>
                    </ng-container>


                    <ng-container matColumnDef="approval">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Approval </mat-header-cell>
                        <mat-cell *matCellDef="let element">

                            <span class="submittedRow"
                                *ngIf="element.approvermasterObj && element.approvermasterObj.status == 'Approved'"><span *ngIf="!element.is_approval_triggered">Approved</span> <span *ngIf="element.is_approval_triggered" style="color:rgb(231, 114, 68)">Re-submitted</span></span>
                            <span class="notSubmittedRow"
                                *ngIf="element.approvermasterObj && element.approvermasterObj.status == 'Rejected'"><span *ngIf="!element.is_approval_triggered">Rejected</span> <span *ngIf="element.is_approval_triggered" style="color:rgb(231, 114, 68)">Re-submitted</span></span>

                            <div class="pendingApprovals"
                                *ngIf="element.approvermasterObj && element.approvermasterObj.status == 'Pending'">
                                <div class="approvalStatusBlock"
                                    *ngIf="element.approvermasterObj && element.approvermasterObj.status != 'Approved' && element.approvermasterObj.status !='Rejected'">

                                    <span class="approvedCountIcon"
                                        *ngIf="element.approvermasterObj.approvedApprovers > 0">
                                        <fa-icon [icon]="['fas', 'user-circle']"
                                            matTooltip="{{element.approvermasterObj.approvedName}}"></fa-icon>
                                        {{element.approvermasterObj.approvedApprovers}}
                                    </span>
                                    <span class="rejectedCountIcon">
                                        <fa-icon [icon]="['fas', 'user-circle']"
                                            matTooltip="{{element.approvermasterObj.pendingName}}"></fa-icon>
                                        {{element.approvermasterObj.pendintApprovers}}
                                    </span>

                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="notify" *ngIf="seasonType == ForDashToggleText.Offseason">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Notify </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <img *ngIf="!checkPermission(ForDashAction.notify_offseason_for) || element.is_approval_triggered" class="notApprovedAlarm"
                                src="assets/images/alarm.svg">

                            <img *ngIf="checkPermission(ForDashAction.notify_offseason_for) && !element.is_approval_triggered"
                                [ngClass]="element && element.approvermasterObj && element.approvermasterObj.status == 'Approved' ? element.notified ? 'notifiedAlarm' : 'approvedAlarm' : 'notApprovedAlarm' "
                                src="assets/images/alarm.svg" (click)="notifyRow(element)">
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="notify" *ngIf="seasonType == ForDashToggleText.Season">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Notify </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <img *ngIf="!checkPermission(ForDashAction.notify_season_for)" class="notApprovedAlarm"
                                src="assets/images/alarm.svg">

                            <img *ngIf="checkPermission(ForDashAction.notify_season_for)"
                                [ngClass]="element && element.approvermasterObj && element.approvermasterObj.status == 'Approved' ? element.notified ? 'notifiedAlarm' : 'approvedAlarm' : 'notApprovedAlarm' "
                                src="assets/images/alarm.svg" (click)="notifyRow(element)">
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Action </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <div class="actionRowBlock"
                                *ngIf="showActions(element) && checkPermission(ForDashAction.review_season_for) && seasonType == ForDashToggleText.Season">
                                <a class="approvedRow mr-10" (click)="updateCeilingPriceStatus('Approved',element)"
                                    *ngIf="element.ceiling_for_price != null && element.ceiling_for_price > 0 && element.approvermasterObj && element.approvermasterObj.status == 'Pending'">
                                    Approve
                                </a>
                                <a class="rejectedRow mr-10" (click)="updateCeilingPriceStatus('Rejected',element)"
                                    *ngIf="element.ceiling_for_price != null && element.ceiling_for_price > 0 && element.approvermasterObj && element.approvermasterObj.status == 'Pending'">
                                    Reject
                                </a>
                            </div>

                            <div class="actionRowBlock"
                                *ngIf="showActions(element) && checkPermission(ForDashAction.review_offseason_for) && seasonType == ForDashToggleText.Offseason">
                                <a class="approvedRow mr-10" (click)="updateCeilingPriceStatus('Approved',element)"
                                    *ngIf="element.ceiling_for_price != null && element.ceiling_for_price > 0 && element.approvermasterObj && element.approvermasterObj.status == 'Pending'">
                                    Approve
                                </a>
                                <a class="rejectedRow mr-10" (click)="updateCeilingPriceStatus('Rejected',element)"
                                    *ngIf="element.ceiling_for_price != null && element.ceiling_for_price > 0 && element.approvermasterObj && element.approvermasterObj.status == 'Pending'">
                                    Reject
                                </a>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="forDashboardSeasonColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: forDashboardSeasonColumns;"></mat-row>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No Data Found on {{documentDate}}</td>
                    </tr>
                </mat-table>
                <mat-paginator
                    [pageSizeOptions]="dataSource.data.length > 50 ? [10, 20, 50, dataSource.data.length] : [10, 20, 50]"
                    showFirstLastButtons></mat-paginator>
            </div>
        </div>

    </div>
    <simple-notifications [options]="notificationOptions"></simple-notifications>
</div>