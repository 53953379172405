import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationsService } from 'angular2-notifications';
import { UbService } from 'src/app/services/ub.service';
import { HIGH_CONTRAST_MODE_ACTIVE_CSS_CLASS } from '@angular/cdk/a11y/high-contrast-mode/high-contrast-mode-detector';
@Component({
  selector: 'app-product-master-dialog',
  templateUrl: './product-master-dialog.component.html',
  styleUrls: ['./product-master-dialog.component.scss']
})
export class ProductMasterDialogComponent implements OnInit {
 // dialogData: any;
 productForm = this.fb.group({
  name: ['', [Validators.required]],
  description: ['' , [Validators.required]],
  sap_material_no: ['' , [Validators.required]]
});
  loggedInUser;
  title;
  product_id;
  type;
  constructor(
    private services : UbService,
    private fb: FormBuilder,
    private notificationService: NotificationsService,
    private dialogRef: MatDialogRef<ProductMasterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) { 
      this.title = data.title;
      this.product_id = data.product_id;
      this.type = data.type;
    }

  ngOnInit(): void {
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    if(this.product_id){
    this.getProductData(this.product_id);
    }
  }
  close() {
    this.dialogRef.close();
  }

  getProductData(id) {
    this.services.getProductByID(id).subscribe((res) => {
      if (res['status']) {
        const data = res['result'];
        this.productForm.controls.name.setValue(data.name);
        this.productForm.controls.description.setValue(data.description);
        this.productForm.controls.sap_material_no.setValue(data.sap_material_no);
      }
    } , err => {})
  }

  addProduct() {
    for (const c in this.productForm.controls) {
      if (this.productForm.controls.hasOwnProperty(c)) {
        this.productForm.controls[c].markAsTouched();
      }
    }
    if (this.productForm.valid) {
      const payload = {
        "name": this.productForm.value.name,
        "description": this.productForm.value.description,
        "sap_material_no": this.productForm.value.sap_material_no,        
      }
      this.services.addProduct(payload).subscribe((res) => {
        this.notificationService.success('Success', 'Product Added Successfully');
        this.dialogRef.close();
      }, error => {
        this.notificationService.error('Error', error.error.message);
        // this.dialogRef.close();
      });
    }
  }

  editProduct() {
    for (const c in this.productForm.controls) {
      if (this.productForm.controls.hasOwnProperty(c)) {
        this.productForm.controls[c].markAsTouched();
      }
    }
    if (this.productForm.valid) {     
      const payload = {
        "name": this.productForm.value.name,
        "description": this.productForm.value.description,
        "sap_material_no": this.productForm.value.sap_material_no,        
      }
      this.services.updateProduct(this.product_id , payload).subscribe((res) => {
        this.notificationService.success('Success', 'Product Updated Successfully');
        this.dialogRef.close();
      }, error => {
        this.notificationService.error('Error', error.error.message);
        this.dialogRef.close();
      });
    }
  }



}
