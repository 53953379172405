<h2 class="dialog-heading" *ngIf="title" mat-dialog-title>{{title}}</h2>
<mat-dialog-content style="width:500px">
    <form class="example-full-width" [formGroup]="hoLimitForm" *ngIf="type === 'add'">
        <mat-form-field class="example-full-width">
            <input formControlName="upper_limit" placeholder="Upper Limit" matInput type='text' required>
            <mat-error>
                <span *ngIf="hoLimitForm.get('upper_limit').hasError('required')">Upper Limit is required</span>
            </mat-error>
            <mat-error>
                <span *ngIf="hoLimitForm.get('upper_limit').hasError('pattern')">Upper Limit should be a number</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label required>Type</mat-label>
            <mat-select class="matSelect" formControlName="type" required>
                <mat-option *ngFor="let type of types" [value]="type">
                    {{type}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!hoLimitForm.get('type').valid || hoLimitForm.get('type').touched">Type is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="year" placeholder="year" matInput type='text' required>
            <mat-error>
                <span *ngIf="hoLimitForm.get('year').hasError('required')"> Year is required</span>
            </mat-error>
            <mat-error>
                <span *ngIf="hoLimitForm.get('year').hasError('pattern')"> Year should be a number</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label>Month</mat-label>
            <mat-select class="matSelect" formControlName="month" required>
                <mat-option *ngFor="let month of months" [value]="month">
                    {{month}}
                </mat-option>
            </mat-select>
            <mat-error>
            <span *ngIf="!hoLimitForm.get('month').valid || hoLimitForm.get('month').touched">month is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label>Active</mat-label>
            <mat-select class="matSelect" formControlName="active" required>
                <mat-option *ngFor="let active of isActive" [value]="active.value">
                    {{active.name}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!hoLimitForm.get('active').valid || hoLimitForm.get('active').touched">Active is required</span>
            </mat-error>
        </mat-form-field>
    </form>
    <mat-dialog-actions  *ngIf="type === 'add'">
        <button class="mat-raised-button mr-10" (click)="close()">Close</button>
        <button class="mat-raised-button mat-primary" (click)="addHoLimit()">Add</button>
    </mat-dialog-actions>

    
        <form class="example-full-width" [formGroup]="hoLimitForm" *ngIf="type === 'edit'">
            <mat-form-field class="example-full-width">
                <input formControlName="upper_limit" placeholder="Upper Limit" matInput type='text' required>
                <mat-error>
                    <span *ngIf="hoLimitForm.get('upper_limit').hasError('required')">Upper Limit Name is required</span>
                </mat-error>
                <mat-error>
                    <span *ngIf="hoLimitForm.get('upper_limit').hasError('pattern')">Upper Limit should be a number</span>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label required>Type</mat-label>
                <mat-select class="matSelect" formControlName="type" required>
                    <mat-option *ngFor="let type of types" [value]="type">
                        {{type}}
                    </mat-option>
                </mat-select>
                <mat-error>
                    <span *ngIf="!hoLimitForm.get('type').valid || hoLimitForm.get('type').touched">Type is required</span>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <input formControlName="year" placeholder="Year" matInput type='text' required>
                <mat-error>
                    <span *ngIf="hoLimitForm.get('year').hasError('required')">Year is required</span>
                </mat-error>
                <mat-error>
                    <span *ngIf="hoLimitForm.get('year').hasError('pattern')"> Year should be a number</span>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width">
                    <mat-label>Month</mat-label>
                    <mat-select class="matSelect" formControlName="month" required>
                        <mat-option *ngFor="let month of months" [value]="month">
                            {{month}}
                        </mat-option>
                    </mat-select>
                    <mat-error>
                    <span *ngIf="!hoLimitForm.get('month').valid || hoLimitForm.get('month').touched">month is required</span>
                    </mat-error>
                </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>Active</mat-label>
                <mat-select class="matSelect" formControlName="active" required>
                    <mat-option *ngFor="let active of isActive" [value]="active.value" placeholder="active">
                        {{active.name}}
                    </mat-option>
                </mat-select>
                <mat-error>
                    <span *ngIf="!hoLimitForm.get('active').valid || hoLimitForm.get('active').touched">Active is required</span>
                </mat-error>
            </mat-form-field>
        </form>
    <mat-dialog-actions  *ngIf="type === 'edit'">
        <button class="mat-raised-button mr-10" (click)="close()">Close</button>
        <button class="mat-raised-button mat-primary" (click)="editHoLimit()">Save</button>
    </mat-dialog-actions>
</mat-dialog-content>
