import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { ExcelService } from '../services/excel.service';
import { MenuService } from '../services/menu.service';
import { UbService } from '../services/ub.service';
import { UpperLimitOfCeilingPriceDialogComponent } from '../widgets/upper-limit-of-ceiling-price-dialog/upper-limit-of-ceiling-price-dialog.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-upper-limit-of-ceiling-price',
  templateUrl: './upper-limit-of-ceiling-price.component.html',
  styleUrls: ['./upper-limit-of-ceiling-price.component.scss']
})
export class UpperLimitOfCeilingPriceComponent implements OnInit {

  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayColumns = ['upper_limit' , 'type' , 'year' , 'month' , 'is_active','action'];
  currentPage = 0;
  isActive=[
    { value:true, name:'Active'},
    { value:false, name:'InActive'}
  ];
  public notificationOptions: any = {
    position: ['bottom', 'right'],
    timeOut: 5000,
    lastOnBottom: true,
    pauseOnHover: true,
    clickToClose: true,
    animate: 'fromRight'
  };
  permission;
  loading = false;
  data;

  constructor(
    private service: UbService,
    private _service: NotificationsService,
    private excelService: ExcelService,
    private dialog: MatDialog,
    private menuService: MenuService,
    private router: Router
  ) {
  }

  ngOnInit(){
    const menu = this.menuService.getMenu(this.router.url);
    this.permission = menu.actions;
    this.loading = true;
    this.service.getHoLimit().subscribe((response: any) => {     
        this.dataSource.data = response.result;
        this.dataSource.filterPredicate = (data: any, filter: string) => {
          return data.type?.toLocaleLowerCase().includes(filter) ||
            data.upper_limit?.toString().includes(filter) ||
            data.year?.toString().includes(filter) ||
            data.month?.toString().includes(filter) ||
            data.is_active?.toLocaleLowerCase().includes(filter)
        };
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loading = false;
        this.isDataActive();
    }, error => {
      this._service.error('Error', error.error.message);
      this.loading = false;
    });
  }

 isDataActive(){
   for(const data of this.dataSource.data){
     if(data.is_active){
       data.is_active='Active';
     }
     else{
      data.is_active='Inactive';
     }
   }
 }

  checkPermission(access: string): boolean {
    return this.permission.includes(access);
  }

  applyFilter(filterValue) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  exportAsXLSX(): void {
    const exportData = this.dataSource.data.map((val) => (
      {
        'Id': val.id,
        'Upper Limit': val.upper_limit,
        'Type' : val.type,
        'Year' : val.year,
        'Month' : val.month,
        'Active':val.is_active
      }
    ));
    this.excelService.exportAsExcelFile(exportData, 'Upper-limit');
  }

  addHoLimit() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Add HO Limit',
      height: '800px',
      width: '300px',
      panelClass: 'addnew-custom-dialog',
      type : 'add'
    };
    const dialogRef = this.dialog.open(UpperLimitOfCeilingPriceDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.ngOnInit();
    })
  }

  editHoLimit(data) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Update HO Limit',
      height: '800px',
      width: '300px',
      panelClass: 'addnew-custom-dialog',
      type : 'edit',
      hoLimit_id : data.id
    };
    const dialogRef = this.dialog.open(UpperLimitOfCeilingPriceDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.ngOnInit();
    })
  }

  onPageChange(currentPage) {
    console.log(currentPage);
  }
}
