<div class="container-fluid">
    <div class="tabContentBlock">
        <div class="row pageHeader">
        Role Manager Details
    </div>

    <div class="row" style="margin-top: 12px;">
            <div class="col-md-6 pl-0">
                <mat-form-field class="cusWidth">
                    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
                    <button mat-icon-button matPrefix>
                        <mat-icon class="primColor">search</mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <div class="col-md-6 buttonAlignRight tabBtnAlign">
                <button class="mat-raised-button mat-button-color mr-10 exportBtn" (click)="exportAsXLSX()">
                        <fa-icon [icon]="['fas', 'download']"  style="margin-right: 10px;"></fa-icon> Export
                    Excel</button>
                <button class="mat-raised-button mat-primary exportBtn" (click)="addRoleManager()">Add Record</button>
            </div>
        </div>
        <div class="row" style="padding: 20px 0px 20px;">

            <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
                <ng-container matColumnDef="role_name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Role Name </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.role_name}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="menu_name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Menu Name </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.menu_name}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="device_type">
                    <mat-header-cell *matHeaderCellDef mat-sort-header style="max-width: 120px;"> Device Type </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="max-width: 120px;"> 
                        <span *ngIf ="element.device_type == 'W'">Web</span>
                        <span *ngIf ="element.device_type == 'M'">Mobile</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef mat-sort-header > Actions </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.actions}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef mat-sort-header style="max-width: 120px;"> Status </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="max-width: 120px;"> {{element.is_active}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="action">
                        <mat-header-cell *matHeaderCellDef mat-sort-header style="max-width: 100px;">Action</mat-header-cell>
                        <mat-cell *matCellDef="let element" style="max-width: 100px;">
                            <div class="actionRowBlock">
                                <a class="mr-10" (click)="editRoleManager(element)">
                                    Edit
                                </a>
                            </div>
                        </mat-cell>
                    </ng-container>

                <mat-header-row *matHeaderRowDef="displayedRowColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedRowColumns;"></mat-row>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No Data Found on {{documentDate}}</td>
                </tr>
            </mat-table>
            <mat-paginator [pageSizeOptions]="dataSource.data.length > 50 ? [10, 20, 50, dataSource.data.length] : [10, 20, 50]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
    <simple-notifications [options]="notificationOptions"></simple-notifications>
</div>