<div class="container-fluid">

    <div class="tabContentBlock">
        <div class="row pageHeader">
            User Details
        </div>
        <div class="row" style="margin-top: 12px;">
            <div class="col-md-6 pl-0">
                <mat-form-field class="cusWidth">
                    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
                    <button mat-icon-button matPrefix> <mat-icon class="primColor">search</mat-icon> </button>
                </mat-form-field>
            </div>
            <div class="col-md-6 buttonAlignRight tabBtnAlign">
                <button class="mat-raised-button mat-button-color mr-10 exportBtn"  (click)="exportAsXLSX()"> 
                    <fa-icon [icon]="['fas', 'download']" style="margin-right: 10px;"></fa-icon>Export Excel</button>
                <button class="mat-raised-button mat-primary mr-10 exportBtn" (click)="addUser()" > Add Record</button>
            </div>
        </div>

        <div class="row" style="padding: 20px 0px 20px;">
            <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
            <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

                <ng-container matColumnDef="user_id">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="wd-150"> User Id </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="wd-150"> {{element.user_id}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="first_name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="wd-150"> First Name</mat-header-cell>
                    <mat-cell *matCellDef="let element" class="wd-150"> {{element.first_name}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="last_name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="wd-150">Last Name</mat-header-cell>
                    <mat-cell *matCellDef="let element" class="wd-150"> {{element.last_name}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="sap_vendor_code">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="wd-120"> SAP Vendor Code </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="wd-120"> {{element.sap_vendor_code}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="email_1">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="wd-200"> Email</mat-header-cell>
                    <mat-cell *matCellDef="let element" class="wd-200"> {{element.email_1}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="mobile_1">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="wd-150"> Mobile </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="wd-150"> {{element.mobile_1}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="pincode">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="wd-120"> Pincode</mat-header-cell>
                    <mat-cell *matCellDef="let element" class="wd-120"> {{element.pincode}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="gstin" >
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="wd-120"> GSTIN </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="wd-120"> {{element.gstin}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="identity">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="wd-120"> Identity </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="wd-120"> {{element.roles}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="role">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="wd-150"> Role </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="wd-150"> {{element.role?.role}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="active">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="wd-120"> Status </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="wd-120">
                        <span *ngIf="element?.active == 1">
                            Active
                        </span>
                        <span *ngIf="element?.active != 1">
                            Inactive
                        </span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="action">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="wd-80">Action</mat-header-cell>
                    <mat-cell *matCellDef="let element"  class="wd-80">
                        <div class="actionRowBlock">
                            <a class="mr-10" (click)="editRow(element)">
                                Edit
                            </a>
                        </div>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayRowColums"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayRowColums;"></mat-row>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No Data Found on {{documentDate}}</td>
                </tr>
            </mat-table>
            <mat-paginator [pageSizeOptions]="dataSource.data.length > 50 ? [10, 20, 50, dataSource.data.length] : [10, 20, 50]" showFirstLastButtons></mat-paginator>
        </div>
        <simple-notifications [options]="notificationOptions"></simple-notifications>
    </div>
</div>