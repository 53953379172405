<h2 class="dialog-heading" *ngIf="title" mat-dialog-title>{{title}}</h2>
<mat-dialog-content style="width:500px">
    <form class="example-full-width" [formGroup]="userForm">

        <mat-form-field class="example-full-width">
            <input formControlName="user_id" placeholder="User Id" matInput type='text' required>
            <mat-error>
                <span *ngIf="userForm.get('user_id').hasError('required')">User Id is required</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <input formControlName="first_name" placeholder="First Name" matInput type='text' required>
            <mat-error>
                <span *ngIf="userForm.get('first_name').hasError('required')">First Name is required</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <input formControlName="last_name" placeholder="Last Name" matInput type='text' required>
            <mat-error>
                <span *ngIf="userForm.get('last_name').hasError('required')">Last Name is required</span>
            </mat-error>
        </mat-form-field>

        <!-- <mat-form-field class="example-full-width">
            <input formControlName="sap_vendor_code" placeholder="SAP Code" matInput type='text'>
            <mat-error>
                <span *ngIf="userForm.get('sap_vendor_code').hasError('required')">Sap Code is required</span>
            </mat-error>
        </mat-form-field> -->

        <mat-form-field class="example-full-width">
            <mat-label required>Sap Code</mat-label>
            <mat-select class="identity" formControlName="sap_vendor_code">
                <mat-option *ngFor="let code of sapVendorCode" [value]="code.code">
                    {{code.name}} - {{code.code}}
                </mat-option>
            </mat-select>
            
        </mat-form-field>

        <mat-form-field class="example-full-width" *ngIf="actionType == 'add' ">
            <input formControlName="password" placeholder="Password" matInput type='text' required>
            <mat-error>
                <span *ngIf="userForm.get('password').hasError('required')">Password is required</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <input formControlName="mobile_1" placeholder="Mobile 1" matInput type='text' required>
            <mat-error>
                <span *ngIf="userForm.get('mobile_1').hasError('required')">Mobile 1 is required</span>
            </mat-error>
            <mat-error>
                <span *ngIf="userForm.get('mobile_1').hasError('pattern')">Invalid Mobile Number</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <input formControlName="mobile_2" placeholder="Mobile 2" matInput type='text'>
            <mat-error>
                <span *ngIf="userForm.get('mobile_2').hasError('pattern')">Invalid Mobile Number</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <input formControlName="address_1" placeholder="Address 1" matInput type='text' required>
            <mat-error>
                <span *ngIf="userForm.get('address_1').hasError('required')">Address 1 is required</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <input formControlName="address_2" placeholder="Address 2" matInput type='text'>
            
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <input formControlName="pincode" placeholder="pincode" matInput type='text'>
            <mat-error>
                <span *ngIf="userForm.get('pincode').hasError('required')">Pincode is required</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <input formControlName="gstin" placeholder="GSTIN" matInput type='text'>
            <mat-error>
                <span *ngIf="userForm.get('gstin').hasError('required')">GSTIN is required</span>
            </mat-error>
        </mat-form-field>

        <!-- <mat-form-field class="example-full-width">
            <mat-label required>Select Identity</mat-label>
            <mat-select class="role" formControlName="roles" required>
                <mat-option *ngFor="let iden of identity" [value]="iden">
                    {{iden}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!userForm.get('roles').valid || userForm.get('roles').touched">Location ID is
                    required</span>
            </mat-error>
        </mat-form-field> -->

        <mat-form-field class="example-full-width">
            <mat-label required>Role</mat-label>
            <mat-select class="identity" formControlName="role_id" required>
                <mat-option *ngFor="let rol of role" [value]="rol.id">
                    {{rol.role}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!userForm.get('role_id').valid || userForm.get('role_id').touched">Role is
                    required</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <input formControlName="email_1" placeholder="Email 1" matInput type='text' required>
            <mat-error>
                <span *ngIf="userForm.get('email_1').hasError('required')">Email 1 is required</span>
                <span *ngIf="userForm.get('email_1').hasError('pattern')">Email 1 is Invalid</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <input formControlName="email_2" placeholder="Email 2" matInput type='text'>
            <mat-error>
                <span *ngIf="userForm.get('email_2').hasError('required')">Market Name is required</span>
                <span *ngIf="userForm.get('email_2').hasError('pattern')">Email 2 is Invalid</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <mat-label required>Active</mat-label>
            <mat-select class="matSelect" formControlName="active" required>
                <mat-option *ngFor="let stat of status" [value]="stat.id">
                    {{stat.name}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!userForm.get('active').valid || userForm.get('active').touched">Active is required</span>
            </mat-error>
        </mat-form-field>

        <mat-radio-group aria-label="Select an option" formControlName ="is_ub_user">
            <mat-radio-button value=true>UB USER</mat-radio-button>
            <mat-radio-button value=false class="ml-10">NONUB USER</mat-radio-button>
        </mat-radio-group>

    </form>
    <mat-dialog-actions>
        <button class="mat-raised-button mr-10" (click)="close()">Close</button>
        <button class="mat-raised-button mat-primary" (click)="saveUser()">Save</button>
    </mat-dialog-actions>
</mat-dialog-content>
<simple-notifications [options]="notificationOptions"></simple-notifications>