<h2 class="dialog-heading" *ngIf="title" mat-dialog-title>{{title}}</h2>
<mat-dialog-content style="width:500px">
    <form class="example-full-width" [formGroup]="mandiForm">
        <mat-form-field class="example-full-width">
            <mat-label required>Mandi Name</mat-label>
            <mat-select class="matSelect" formControlName="mandi_id" required>
                <mat-option *ngFor="let man of mandi" [value]="man.id">
                    {{man.mandi_name}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!mandiForm.get('mandi_id').valid || mandiForm.get('mandi_id').touched">Mandi Name is required</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <mat-label required>User Name</mat-label>
            <mat-select class="matSelect" formControlName="user_id" required>
                <mat-option *ngFor="let use of userList" [value]="use.id">
                    {{use.first_name}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!mandiForm.get('user_id').valid || mandiForm.get('user_id').touched">User Name is required</span>
            </mat-error>
        </mat-form-field>
        
        <mat-form-field class="example-full-width">
            <mat-label required>Active</mat-label>
            <mat-select class="matSelect" formControlName="active" required>
                <mat-option *ngFor="let stat of status" [value]="stat.id">
                    {{stat.name}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!mandiForm.get('active').valid || mandiForm.get('active').touched">Activity State is required</span>
            </mat-error>
        </mat-form-field>       
    </form>
    <mat-dialog-actions>
        <button class="mat-raised-button mr-10" (click)="close()">Close</button>
        <button class="mat-raised-button mat-primary" (click)="saveAgmark()">Save</button>
    </mat-dialog-actions>
</mat-dialog-content>