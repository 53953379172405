import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor() { }
  getMenu(route) {
    let menu = JSON.parse(localStorage.getItem('menuItems'));
    let obj = menu.find(m => '/'+m.menu.navigation_url == route);
    obj.actions = obj.actions.replace(/\s+/g, '');
    obj.actions = obj.actions.split(',');
    return obj;
   
  }
}
