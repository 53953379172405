import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './header/header.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
// import { MatSelectModule } from '@angular/material/select';
import { MatSelectModule } from '@angular/material/select'
import { MatListModule } from '@angular/material/list'
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { HomeComponent } from './home/home.component';
import { ArrivaldataComponent } from './arrivaldata/arrivaldata.component';
import { AuctionDashboardComponent } from './auction-dashboard/auction-dashboard.component';
import { RoleMasterComponent } from './role-master/role-master.component';
import { MandimasterComponent } from './mandimaster/mandimaster.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TableComponent } from './table/table.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { from } from 'rxjs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './services/auth.interceptor';
import { AuthGuardService } from './services/auth-guard.service';
import { Daterangepicker } from 'ng2-daterangepicker';
import { DialogComponent } from './widgets/dialog/dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ExcelService } from './services/excel.service';
import { ViewceilingComponent } from './viewceiling/viewceiling.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatMenuModule } from '@angular/material/menu';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { MatBadgeModule } from '@angular/material/badge';
import { MatRadioModule } from '@angular/material/radio';
import { ManageOrderComponent } from './manage-order/manage-order.component';
import { ManageOrderDialogComponent } from './widgets/manage-order-dialog/manage-order-dialog.component';
import { ConfirmationDialogComponent } from './widgets/confirmation-dialog/confirmation-dialog.component';
import { AuctionPriceViewComponent } from './auction-price-view/auction-price-view.component';
import { LocationsMasterComponent } from './locations-master/locations-master.component';
import { UserMasterComponent } from './user-master/user-master.component';
import { MandiMasterDialogComponent } from './widgets/mandi-master-dialog/mandi-master-dialog.component';
import { MandiDestinationMasterComponent } from './mandi-destination-master/mandi-destination-master.component';
import { MandiDestinationDialogComponent } from './widgets/mandi-destination-dialog/mandi-destination-dialog.component';
import { StatesMasterComponent } from './states-master/states-master.component';
import { AgmarkMasterComponent } from './agmark-master/agmark-master.component';
import { AgmarkMasterDialogComponent } from './widgets/agmark-master-dialog/agmark-master-dialog.component';
import { ForFreightMasterComponent } from './for-freight-master/for-freight-master.component';
import { ForFreightDialogComponent } from './widgets/for-freight-dialog/for-freight-dialog.component';
import { RoleMasterDialogComponent } from './widgets/role-master-dialog/role-master-dialog.component';
import { ProductMasterComponent } from './product-master/product-master.component';
import { StorageInterestMasterComponent } from './storage-interest-master/storage-interest-master.component';
import { StorageInterestMasterDialogComponent } from './widgets/storage-interest-master-dialog/storage-interest-master-dialog.component';
import { ClusterMasterComponent } from './cluster-master/cluster-master.component';
import { PremiumMasterComponent } from './premium-master/premium-master.component';
import { ClusterMasterDialogComponent } from './widgets/cluster-master-dialog/cluster-master-dialog.component';
import { PremiumMasterDialogComponent } from './widgets/premium-master-dialog/premium-master-dialog.component';
import { ProductMasterDialogComponent } from './widgets/product-master-dialog/product-master-dialog.component';
import { ManageOfferComponent } from './manage-offer/manage-offer.component';
import { ManageOfferDialogComponent } from './widgets/manage-offer-dialog/manage-offer-dialog.component';
import { ForDashboardComponent } from './for-dashboard/for-dashboard.component';
import { LocationMasterDialogComponent } from './widgets/location-master-dialog/location-master-dialog.component';
import { UserMasterDialogComponent } from './widgets/user-master-dialog/user-master-dialog.component';
import { ClusterFreightMasterComponent } from './cluster-freight-master/cluster-freight-master.component';
import { ClusterFreightMasterDialogComponent } from './widgets/cluster-freight-master-dialog/cluster-freight-master-dialog.component';
import { RoleManagerMasterComponent } from './role-manager-master/role-manager-master.component';
import { ForlineItemMasterComponent } from './forline-item-master/forline-item-master.component';
import { ForlineItemMasterDialogComponent } from './widgets/forline-item-master-dialog/forline-item-master-dialog.component';
import { MandiUserMappingMasterComponent } from './mandi-user-mapping-master/mandi-user-mapping-master.component';
import { MandiUserMappingMasterDialogComponent } from './widgets/mandi-user-mapping-master-dialog/mandi-user-mapping-master-dialog.component';
import { DeliveryMasterComponent } from './delivery-master/delivery-master.component';
import { DeliveryMasterDialogComponent } from './widgets/delivery-master-dialog/delivery-master-dialog.component';
import { MSPMasterComponent } from './msp-master/msp-master.component';
import { MSPMasterDialogComponent } from './widgets/msp-master-dialog/msp-master-dialog.component';
import { PlantMasterComponent } from './plant-master/plant-master.component';
import { PlantMasterDialogComponent } from './widgets/plant-master-dialog/plant-master-dialog.component';
import { UpperLimitOfCeilingPriceComponent } from './upper-limit-of-ceiling-price/upper-limit-of-ceiling-price.component';
import { UpperLimitOfCeilingPriceDialogComponent } from './widgets/upper-limit-of-ceiling-price-dialog/upper-limit-of-ceiling-price-dialog.component';
import { AgmarknetUploadComponent } from './agmarknet-upload/agmarknet-upload.component';
import { StatesMasterDialogComponent } from './widgets/states-master-dialog/states-master-dialog.component';
import { RoleManagerMasterDialogComponent } from './widgets/role-manager-master-dialog/role-manager-master-dialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { CreateNewPasswordComponent } from './create-new-password/create-new-password.component';
import { ZeroOrderDialogComponent } from './widgets/zero-order-dialog/zero-order-dialog.component';
import { AgmarkReportComponent } from './reports/agmark-report/agmark-report.component';
import { MisReportComponent } from './reports/mis-report/mis-report.component';
import { ArrivalReportComponent } from './reports/arrival-report/arrival-report.component';
import { OrderReportComponent } from './reports/order-report/order-report.component';
import { OfferReportComponent } from './reports/offer-report/offer-report.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    LandingpageComponent,
    HomeComponent,
    ArrivaldataComponent,
    AuctionDashboardComponent,
    RoleMasterComponent,
    MandimasterComponent,
    TableComponent,
    DashboardComponent,
    DialogComponent,
    ViewceilingComponent,
    ManageOrderComponent,
    ManageOrderDialogComponent,
    DialogComponent,
    ConfirmationDialogComponent,
    ManageOfferComponent,
    ManageOfferDialogComponent,
    AuctionPriceViewComponent,
    LocationsMasterComponent,
    UserMasterComponent,
    MandiMasterDialogComponent,
    MandiDestinationMasterComponent,
    MandiDestinationDialogComponent,
    StatesMasterComponent,
    AgmarkMasterComponent,
    AgmarkMasterDialogComponent,
    ForFreightMasterComponent,
    ForFreightDialogComponent,
    RoleMasterDialogComponent,
    ProductMasterComponent,
    StorageInterestMasterComponent,
    StorageInterestMasterDialogComponent,
    ClusterMasterComponent,
    PremiumMasterComponent,
    ClusterMasterDialogComponent,
    PremiumMasterDialogComponent,
    ProductMasterDialogComponent,
    ForDashboardComponent,
    LocationMasterDialogComponent,
    UserMasterDialogComponent,
    ClusterFreightMasterComponent,
    ClusterFreightMasterDialogComponent,
    RoleManagerMasterComponent,
    ForlineItemMasterComponent,
    ForlineItemMasterDialogComponent,
    MandiUserMappingMasterComponent,
    MandiUserMappingMasterDialogComponent,
    DeliveryMasterComponent,
    DeliveryMasterDialogComponent,
    MSPMasterComponent,
    MSPMasterDialogComponent,
    PlantMasterComponent,
    PlantMasterDialogComponent,
    UpperLimitOfCeilingPriceComponent,
    UpperLimitOfCeilingPriceDialogComponent,
    AgmarknetUploadComponent,
    StatesMasterDialogComponent,
    RoleManagerMasterDialogComponent,
    ResetPasswordComponent,
    CreateNewPasswordComponent,
    ZeroOrderDialogComponent,
    AgmarkReportComponent,
    MisReportComponent,
    ArrivalReportComponent,
    OrderReportComponent,
    OfferReportComponent
  ],
  imports: [
    MatNativeDateModule,
    MatDatepickerModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatCardModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatTabsModule,
    MatProgressBarModule,
    MatTableModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatButtonToggleModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatPaginatorModule,
    MatSortModule,
    MatSnackBarModule,
    MatCheckboxModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    Daterangepicker,
    MatFormFieldModule,
    NgbModule,
    MatMenuModule,
    FontAwesomeModule,
    SimpleNotificationsModule.forRoot(),
    MatBadgeModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatTooltipModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  },
    AuthGuardService,
    ExcelService
  ],
  bootstrap: [AppComponent],
  entryComponents: []
})
export class AppModule { }
