import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationsService } from 'angular2-notifications';
import { UbService } from 'src/app/services/ub.service';

@Component({
  selector: 'app-zero-order-dialog',
  templateUrl: './zero-order-dialog.component.html',
  styleUrls: ['./zero-order-dialog.component.scss']
})
export class ZeroOrderDialogComponent implements OnInit {

  dialogData: any;
  arrivalData = [];
  message = '';
  loggedInUser;
  productId;
  public notificationOptions: any = {
    position: ['bottom', 'right'],
    timeOut: 5000,
    lastOnBottom: true,
    pauseOnHover: true,
    clickToClose: true,
    animate: 'fromRight'
  };

  constructor(
    private ubbpsService: UbService,
    private notificationService: NotificationsService,
    private dialogRef: MatDialogRef<ZeroOrderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.arrivalData = data.notifyData;
    this.productId = data.product_id;

    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    this.message = this.arrivalData.length > 0 ? this.arrivalData[0].message : '';
  }

  ngOnInit(): void {
  }

  confirm() {
    const payload = [];
    for (const data of this.arrivalData) {
      payload.push({
        "mandi_id": data.data.mandi_id,
        "document_date": data.data.document_date,
        "user_id": this.loggedInUser.id,
        "created_by": this.loggedInUser.id,
        "product_id": this.productId
      })
    }
    this.ubbpsService.postZeroOrder(payload).subscribe((response: any) => {
      if (response.status) {
        const data = response.result.filter((itm) => itm.status === false);
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            this.notificationService.error('Error', data[i].message);
          }
        } else {
          this.notificationService.success('Success', response.message);
        }
        this.dialogRef.close();
      } else {
        this.notificationService.error('Error', response?.message);
      }
    }, error => {
      this.notificationService.error('Error', error.error.message);
    })
  }

  close() {
    this.dialogRef.close();
  }

}
