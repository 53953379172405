<div class="container-fluid">
  <div class="pageHeader">
    Ceilings Details
  </div>
  <div class="row dateRangeBlock" style="padding: 0px 20px 0px;">
    <div class="col-md-6">
      <div class="input-wrapper">
        <input type="text" class="daterangeInput input-field" name="daterangeInput" daterangepicker [options]="options"
          (selected)="selectedDate($event, daterange)" /> &nbsp;
        <fa-icon [icon]="['fas', 'calendar']" class="icon-favIcon primColor"></fa-icon>
      </div>
    </div>
    <div class="col-md-6 buttonAlignRight" *ngIf="activeLink == '6 Row Barley Offer'">
      <span [ngClass]="seasonType === 'Season' ? 'activeType' : 'inactiveType'">Season</span>
      <mat-slide-toggle (click)="seasonTypeChange()" color="'#3f51b5'"></mat-slide-toggle>
      <span [ngClass]="seasonType === 'Offseason' ? 'activeType' : 'inactiveType'">Offseason</span>
    </div>
  </div>
  <div class="tabsBlock">
    <nav mat-tab-nav-bar>
      <a mat-tab-link *ngFor="let link of links" (click)="getData(link)" [active]="activeLink == link"> {{link}}
        <label class="count" *ngIf="link == '6 Row Barley Auction'">{{sixRowAuctionCount}}</label>
        <label class="count" *ngIf="link == '6 Row Barley Offer'">{{sixRowofferCount}}</label>
        <label class="count" *ngIf="link == '2 Row Barley Auction'">{{twoRowAuctionCount}}</label>
      </a>
    </nav>
    <div *ngIf="activeLink == '6 Row Barley Auction'" class="tabContentBlock">
      <div class="row">
        <div class="col-md-6 pl-0">
          <mat-form-field class="cusWidth">
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
            <button mat-icon-button matPrefix>
              <mat-icon class="primColor">search</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="col-md-6 buttonAlignRight tabBtnAlign">
          <button class="mat-raised-button mat-primary mr-10 exportBtn" (click)="exportAsXLSX()">
            <fa-icon [icon]="['fas', 'download']"></fa-icon> Export Excel
          </button>
        </div>
      </div>
      <div class="row" style="padding: 20px 0px 0px;">
        <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
        <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
          <ng-container matColumnDef="mandi_name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Mandi Name</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.mandiObj.mandi_name}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="quantity">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Quantity (in Quintal)</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.quantity}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="min_price">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Minimum Price (₹)</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.min_price}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="max_price">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Maximum Price (₹)</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.max_price}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="document_date">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Document Date</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.document_date}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="notified_date">
            <mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 200px;"> Notified Date & time
            </mat-header-cell>
            <mat-cell *matCellDef="let element" style="min-width: 200px;"> {{element.notified_at | date: 'dd/MM/yyyy h:mm:ss'}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="avg_buying_price" *ngIf="checkPermission()">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Avg. Buying Price (₹)</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.avg_buying_price}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="modal_price" *ngIf="checkPermission()">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Modal Price (₹)</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.modal_price}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="suggested_price" *ngIf="checkPermission()">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Suggested Price (₹)</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.suggested_price}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="ceiling_price">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Ceiling Price (₹)</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.ceiling_price}} </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns_6RowBarley"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns_6RowBarley;"></mat-row>
        </mat-table>
        <mat-paginator
          [pageSizeOptions]="dataSource.data.length > 50 ? [10, 20, 50, dataSource.data.length] : [10, 20, 50]"
          showFirstLastButtons></mat-paginator>
      </div>
    </div>
    <div *ngIf="activeLink == '6 Row Barley Offer'" class="tabContentBlock">
      <div class="row">
        <div class="col-md-6 pl-0">
          <mat-form-field class="cusWidth">
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
            <button mat-icon-button matPrefix>
              <mat-icon class="primColor">search</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="col-md-6 buttonAlignRight tabBtnAlign">
          <button class="mat-raised-button mat-primary mr-10 exportBtn" (click)="exportAsXLSX()">
            <fa-icon [icon]="['fas', 'download']"></fa-icon> Export Excel
          </button>
        </div>
      </div>
      <div class="row" style="padding: 20px 0px 0px;">
        <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
        <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
          <ng-container matColumnDef="cluster_name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Cluster</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span *ngIf="element.clusterObj">
                {{element.clusterObj.name}}
              </span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="destination">
            <mat-header-cell *matHeaderCellDef mat-sort-header style="min-width:250px;">Delivery Location
            </mat-header-cell>
            <mat-cell *matCellDef="let element" style="min-width:250px;">
              <span *ngIf="element.destinationObj">
                {{element.destinationObj.delivery_name}}
              </span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="document_date">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Document Date</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.document_date}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="avg_price" *ngIf="checkPermission()">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Avg Price <br> (&#x20b9;)
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.avg_price}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="for_freight" *ngIf="checkPermission()">
            <mat-header-cell *matHeaderCellDef mat-sort-header> For Freight<br> (&#x20b9;)
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.for_freight}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="storage" *ngIf="checkPermission()">
            <mat-header-cell *matHeaderCellDef mat-sort-header> For Freight<br> (&#x20b9;)
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.for_freight}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="notified_date">
            <mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 200px;"> Notified Date & time
            </mat-header-cell>
            <mat-cell *matCellDef="let element" style="min-width: 200px;"> {{element.notified_at | date: 'dd/MM/yyyy h:mm:ss'}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="interest" *ngIf="checkPermission()">
            <mat-header-cell *matHeaderCellDef mat-sort-header> For Freight<br> (&#x20b9;)
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.for_freight}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="avg_buying_price" *ngIf="checkPermission()">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Avg Buying Price <br> (&#x20b9;)
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.avg_buying_price}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="suggested_for_price" *ngIf="checkPermission()">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Suggested For Price <br> (&#x20b9;)
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.suggested_for_price}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="ceiling_for_price" >
            <mat-header-cell *matHeaderCellDef mat-sort-header> Ceiling For Price <br> (&#x20b9;)
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.ceiling_for_price}} </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns_For"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns_For;"></mat-row>
        </mat-table>
        <mat-paginator
          [pageSizeOptions]="dataSource.data.length > 50 ? [10, 20, 50, dataSource.data.length] : [10, 20, 50]"
          showFirstLastButtons></mat-paginator>
      </div>
    </div>
    <div *ngIf="activeLink == '2 Row Barley Auction'" class="tabContentBlock">
      <div class="row">
        <div class="col-md-6 pl-0">
          <mat-form-field class="cusWidth">
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
            <button mat-icon-button matPrefix>
              <mat-icon class="primColor">search</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="col-md-6 buttonAlignRight tabBtnAlign">
          <button class="mat-raised-button mat-primary mr-10 exportBtn" (click)="exportAsXLSX()">
            <fa-icon [icon]="['fas', 'download']"></fa-icon> Export Excel
          </button>
        </div>
      </div>
      <div class="row" style="padding: 20px 0px 0px;">
        <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
        <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
          <ng-container matColumnDef="mandi_name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Mandi Name</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.mandiObj.mandi_name}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="state">
            <mat-header-cell *matHeaderCellDef mat-sort-header>State</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.mandiObj.state}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="one_price">
            <mat-header-cell *matHeaderCellDef mat-sort-header>One Price (₹)</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.one_price}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="document_date">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Document Date</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.document_date}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="notified_date">
            <mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 200px;"> Notified Date & time
            </mat-header-cell>
            <mat-cell *matCellDef="let element" style="min-width: 200px;"> {{element.notified_at | date: 'dd/MM/yyyy h:mm:ss'}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="msp" *ngIf="checkPermission()">
            <mat-header-cell *matHeaderCellDef mat-sort-header>MSP (₹)</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.msp}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="premium" *ngIf="checkPermission()">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Premium(₹)</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.premium}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="suggested_price" *ngIf="checkPermission()">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Suggested Price(₹)</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.suggested_price}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="ceiling_price" >
            <mat-header-cell *matHeaderCellDef mat-sort-header>Ceiling Price(₹)</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.ceiling_price}}</mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns_2RowBarley"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns_2RowBarley;"></mat-row>
        </mat-table>
        <mat-paginator
          [pageSizeOptions]="dataSource.data.length > 50 ? [10, 20, 50, dataSource.data.length] : [10, 20, 50]"
          showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </div>
</div>