<h2 class="dialog-heading" *ngIf="title" mat-dialog-title>{{title}}</h2>
<mat-dialog-content style="width:500px">
    <form class="example-full-width" [formGroup]="roleManagerForm">
        <mat-form-field class="example-full-width">
            <mat-label required>Role</mat-label>
            <mat-select class="matSelect" formControlName="role" required>
                <mat-option *ngFor="let role of roleList" [value]="role.id">
                    {{role.role}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!roleManagerForm.get('role').valid || roleManagerForm.get('role').touched">Role is required</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <mat-label required>Menu</mat-label>
            <mat-select class="matSelect" formControlName="menu" (selectionChange)="changeMenu($event.value)" required >
                <mat-option *ngFor="let menu of menuList" [value]="menu.id">
                    {{menu.display_name}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!roleManagerForm.get('menu').valid || roleManagerForm.get('menu').touched">Menu is required</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <mat-label required>Device Type</mat-label>
            <mat-select class="matSelect" formControlName="device_type" required>
                <mat-option *ngFor="let device of device" [value]="device.value">
                    {{device.name}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!roleManagerForm.get('device_type').valid || roleManagerForm.get('device_type').touched">Device Type is required</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <mat-label>Actions</mat-label>
            <mat-select #matRefAction formControlName="actions" (openedChange)="actionChange($event)" multiple>
                <mat-option (selectionChange)="actionSelChange($event)" *ngFor="let action of actionList"
                    [value]="action">
                    {{action}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <mat-label>Status</mat-label>
            <mat-select class="matSelect" formControlName="is_active" required>
                <mat-option *ngFor="let active of isActive" [value]="active.id">
                    {{active.name}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!roleManagerForm.get('is_active').valid || roleManagerForm.get('is_active').touched">Status is required</span>
            </mat-error>
        </mat-form-field>
    </form>
    <mat-dialog-actions>
        <button class="mat-raised-button mr-10" (click)="close()">Close</button>
        <button class="mat-raised-button mat-primary" (click)="saveLocations()">Save</button>
    </mat-dialog-actions>
</mat-dialog-content>