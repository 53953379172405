<div class="container-fluid">
  <div class="pageHeader">
    Auction Price View
  </div>
  <div class="row dateRangeBlock" style="padding: 0px 20px 0px;">
    <div class="col-md-4">
      <div class="input-wrapper">
        <input type="text" class="daterangeInput input-field" name="daterangeInput" daterangepicker [options]="options"
          (selected)="selectedDate($event, daterange)" /> &nbsp;
        <fa-icon [icon]="['fas', 'calendar']" class="icon-favIcon primColor"></fa-icon>
      </div>
    </div>
    <div class="col-md-4 form-select">
      <mat-form-field appearance="fill">
        <mat-label>Product Type</mat-label>
        <mat-select [(ngModel)]="isProductType" name="product" class="form-select-1">
          <mat-option *ngFor="let product of productType" [value]="product" (click)="getData(product)">
            {{product}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-4 form-select">
      <mat-form-field appearance="fill">
        <mat-label>Mandi</mat-label>
        <mat-select [formControl]="mandis" multiple class="form-select-2" (selectionChange)="changeMandi($event)">
          <mat-option *ngFor="let mandi of mandiList" [value]="mandi.id">{{mandi.mandi_name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="tabContentBlock">
    <div class="row" style="margin-left:0px;margin-right: 0px;">
      <div class="col-md-6 pl-0">
        <mat-form-field class="cusWidth">
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
          <button mat-icon-button matPrefix>
            <mat-icon class="primColor">search</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="col-md-6 buttonAlignRight tabBtnAlign">
        <button class="mat-raised-button mat-primary mr-10 exportBtn" (click)="exportAsXLSX()">
          <fa-icon [icon]="['fas', 'download']"></fa-icon> Export Excel
        </button>
      </div>
    </div>
    <div class="row" style="margin-left:0px;margin-right: 0px;margin-top: 20px;">
      <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
      <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
        <ng-container matColumnDef="mandi_name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Mandi Name</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.mandiObj.mandi_name}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="one_price">
          <mat-header-cell *matHeaderCellDef mat-sort-header>One Price (₹)</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.one_price}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="state">
          <mat-header-cell *matHeaderCellDef mat-sort-header>State</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.stateObj?.state_name}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="min_price">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Minimum Arrival Price (₹)</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.min_price}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="max_price">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Maximum Arrival Price (₹)</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.max_price}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="quantity">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Arrival Quantity(In quintal)</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.quantity}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="avg_buying_price">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Avg. Buying Price (₹)</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.avg_buying_price}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="modal_price">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Modal Price (₹)</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.modal_price}} </mat-cell>
        </ng-container>
        <!-- <ng-container matColumnDef="quantity">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Minimum Price Of All Mandi's (₹)</mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.quantity}} </mat-cell>
                </ng-container>-->
        <ng-container matColumnDef="suggested_price">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Suggested Price (₹)</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.suggested_price}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="ceiling_price">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Ceiling Price (₹)</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.ceiling_price}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="created_at">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Created On</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.document_date}} </mat-cell>
        </ng-container>
        <!-- <ng-container matColumnDef="date">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Date</mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.date}} </mat-cell>
                </ng-container> -->
        <ng-container matColumnDef="created_by">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Created By</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span *ngIf="element.createdByObj">{{element.createdByObj.user_id}}</span>
            <span *ngIf="!element.createdByObj">{{element.createdByObj?.first_name}} </span>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns_2and6RowBarley"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns_2and6RowBarley;"></mat-row>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No Data Found</td>
        </tr>
      </mat-table>
      <mat-paginator
        [pageSizeOptions]="dataSource.data.length > 50 ? [10, 20, 50, dataSource.data.length] : [10, 20, 50]"
        showFirstLastButtons></mat-paginator>
    </div>
  </div>
</div>