import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationsService } from 'angular2-notifications';
import { monthList, yearList } from 'src/app/models/schema';
import { UbService } from 'src/app/services/ub.service';



@Component({
  selector: 'app-storage-interest-master-dialog',
  templateUrl: './storage-interest-master-dialog.component.html',
  styleUrls: ['./storage-interest-master-dialog.component.scss']
})
export class StorageInterestMasterDialogComponent implements OnInit {

  dialogData: any;
  title: string;
  storageForm: FormGroup;
  storageData: any;
  loggedInUser: any;
  season: any = [true, false];
  storageType = ['storage', 'interest'];
  type: any;
  months = monthList;
  years = yearList();
  selectedOption = this.storageType[0];
  constructor(
    private service: UbService,
    private fb: FormBuilder,
    private notificationService: NotificationsService,
    private dialogRef: MatDialogRef<StorageInterestMasterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.title = data.title;
    this.storageData = data;
    this.type = data.type;
    console.log(this.storageData);
  }

  ngOnInit(): void {
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    this.storageForm = this.fb.group({
      type: ['', [Validators.required]],
      month: ['', [Validators.required]],
      year: ['', [Validators.required]],
      cost: ['' ,[Validators.pattern('^[1-9]+[0-9]*(\.?[0-9]{1,2}$)?')]],
      rate: ['' , [Validators.pattern('^[1-9]+[0-9]*(\.?[0-9]{1,2}$)?')]],
      season: ['', [Validators.required]],
    });
    this.getSeasons();
  }

  getSeasons() {
    this.service.getSeasons().subscribe((res) => {
      if (res['status']) {
        // this.season = res['result'];
        if (this.storageData.storageInterestData) {
          this.storageForm.controls['type'].setValue(this.storageData.storageInterestData.type);
          this.storageForm.controls['month'].setValue(this.storageData.storageInterestData.month);
          this.storageForm.controls['year'].setValue(this.storageData.storageInterestData.year);
          this.storageForm.controls['cost'].setValue(this.storageData.storageInterestData.cost);
          this.storageForm.controls['rate'].setValue(this.storageData.storageInterestData.rate);
          this.storageForm.controls['season'].setValue(this.storageData.storageInterestData.season);
          this.selectedOption = this.storageData.storageInterestData.type;
        }
      }
    }, error => {
      this.notificationService.error('Error', error.error.message);
    });
  }

  close() {
    this.dialogRef.close();
  }

  saveStorage() {
    for (const c in this.storageForm.controls) {
      if (this.storageForm.controls.hasOwnProperty(c)) {
        this.storageForm.controls[c].markAsTouched();
      }
    }

    if (this.storageForm.valid) {
      if (this.storageForm.value.type == this.storageType[0]) {
        if (this.storageForm.value.cost === '' || this.storageForm.value.cost === null) {
          this.notificationService.error('Error', 'Please add cost for storage');
          return false
        }

      } else if (this.storageForm.value.type == this.storageType[1]) {
        if (this.storageForm.value.rate === '' || this.storageForm.value.rate === null) {
          this.notificationService.error('Error', 'Please add rate for interest');
          return false
        }
      }
      const payload: any = {
        type: this.storageForm.value.type,
        month: this.storageForm.value.month,
        year: this.storageForm.value.year,
        cost: this.selectedOption == this.storageType[0] ? this.storageForm.value.cost : null,
        rate: this.selectedOption == this.storageType[1] ? this.storageForm.value.rate : null,
        season: this.storageForm.value.season,
      };
      if (this.type === 'add') {
        payload.created_by = this.loggedInUser.id;
        payload.updated_by = this.loggedInUser.id;
        this.service.addStorageInterest(payload).subscribe((res) => {
          if (res['status']) {
            this.notificationService.success('Success', res['message']);
            this.dialogRef.close();
          }
        }, error => {
          this.notificationService.error('Error', error.error.message);
        });
      } else if (this.type === 'edit') {
        payload.updated_by = this.loggedInUser.id
        this.service.updateStorageInterest(this.storageData.storageInterestData.id, payload).subscribe((res) => {
          if (res['status']) {
            this.notificationService.success('Success', res['message']);
            this.dialogRef.close();
          }
        }, error => {
          this.notificationService.error('Error', error.error.message);
        });

      }

    }
  }

  typeChange(type) {
    this.selectedOption = type
  }
}
