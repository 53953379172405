import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationsService } from 'angular2-notifications';
import { UbService } from 'src/app/services/ub.service';

@Component({
  selector: 'app-role-master-dialog',
  templateUrl: './role-master-dialog.component.html',
  styleUrls: ['./role-master-dialog.component.scss']
})
export class RoleMasterDialogComponent implements OnInit {

  title: string;
  roleForm: FormGroup;
  loggedInUser;
  role_id;
  type;
  activeList = [{ id : '1' , name : 'Active'} , {id : '0' , name : 'Inactive'}];

  constructor(
    private service: UbService,
    private fb: FormBuilder,
    private notificationService: NotificationsService,
    private dialogRef: MatDialogRef<RoleMasterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) { 
    this.title = data.title;
    this.role_id = data.role_id;
    this.type = data.type;
  }

  ngOnInit(){
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    this.roleForm = this.fb.group({
      role: ['', [Validators.required]],
      active: ['', [Validators.required]]
    }); 
    if (this.role_id) {
      this.getFormData();
    }
  }

  getFormData() {
    this.service.getRoleBy(this.role_id).subscribe((res) => {
      if (res['status']) {
        if (res['result']) {
          const data = res['result'];
          this.roleForm.controls.role.setValue(data.role);
          this.roleForm.controls.active.setValue(data.active); 
        }
      }
    } , error => {
      this.notificationService.error('Error', error.error.message);
    })
  }


  close() {
    this.dialogRef.close();
  }

  addRole() {
    for (const c in this.roleForm.controls) {
      if (this.roleForm.controls.hasOwnProperty(c)) {
        this.roleForm.controls[c].markAsTouched();
      }
    }
    if (this.roleForm.valid) {
      const payload = {
        created_by : this.loggedInUser.id,
        role : this.roleForm.value.role,
        active: this.roleForm.value.active
      }
      this.service.addRole(payload).subscribe((res) => {
        if (res['status']) {
          this.notificationService.success('Success', 'Role Added Successfully');
          this.dialogRef.close();
        }
      } , error => {
        this.notificationService.error('Error', error.error.message);
      });
    }
  }

  editRole() {
    for (const c in this.roleForm.controls) {
      if (this.roleForm.controls.hasOwnProperty(c)) {
        this.roleForm.controls[c].markAsTouched();
      }
    }
    if (this.roleForm.valid) {
      const payload = {
        created_by : this.loggedInUser.id,
        role : this.roleForm.value.role,
        active: this.roleForm.value.active
      }
      this.service.updateRole(payload, this.role_id).subscribe((res) => {
        if (res['status']) {
          this.notificationService.success('Success', 'Role Edited Successfully');
          this.dialogRef.close();
        }
      } , error => {
        this.notificationService.error('Error', error.error.message);
      });
    }
  }

}
