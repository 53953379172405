<h2 class="dialog-heading" *ngIf="title" mat-dialog-title>{{title}}</h2>
<mat-dialog-content style="width:500px">
    <form class="example-full-width" [formGroup]="mandiForm" *ngIf="type === 'add'">
        <mat-form-field class="example-full-width">
            <input formControlName="mandi_name" placeholder="Mandi Name" matInput type='text' required>
            <mat-error>
                <span *ngIf="mandiForm.get('mandi_name').hasError('required')">Mandi Name is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="short_name" placeholder="Short Name" matInput type='text'>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label required>Select Agmark</mat-label>
            <mat-select class="matSelect" formControlName="agmark_id" required>
                <mat-option *ngFor="let agmark of agmarkList" [value]="agmark.id">
                    {{agmark.market_name}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!mandiForm.get('agmark_id').valid || mandiForm.get('agmark_id').touched">Agmark is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label required>Location</mat-label>
            <mat-select class="matSelect" formControlName="location_id" required>
                <mat-option *ngFor="let location of locationList" [value]="location.id">
                    {{location.state}} | {{location.district}} | {{location.town_city}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!mandiForm.get('location_id').valid || mandiForm.get('location_id').touched">Location is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label required>Delivery Location</mat-label>
            <mat-select class="matSelect" formControlName="delivery_id" required>
                <mat-option *ngFor="let location of deliveryLocationList" [value]="location.id">
                    {{location.delivery_name}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!mandiForm.get('delivery_id').valid || mandiForm.get('delivery_id').touched">Delivery Location is required</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <mat-label required>Cluster</mat-label>
            <mat-select class="matSelect" formControlName="cluster_id" required>
                <mat-option *ngFor="let cluster of clusterList" [value]="cluster.id">
                    {{cluster.name}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!mandiForm.get('cluster_id').valid || mandiForm.get('cluster_id').touched">Cluster is required</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <input formControlName="address_1" placeholder="Address 1" matInput type='text' required>
            <mat-error>
                <span *ngIf="mandiForm.get('address_1').hasError('required')">Address is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="address_2" placeholder="Address 2" matInput type='text'>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="pincode" placeholder="Pincode" matInput type='number' required>
            <mat-error>
                <span *ngIf="mandiForm.get('pincode').hasError('required')">Pincode is required</span>
            </mat-error>
        </mat-form-field>
    </form>
    <mat-dialog-actions  *ngIf="type === 'add'">
        <button class="mat-raised-button mr-10" (click)="close()">Close</button>
        <button class="mat-raised-button mat-primary" (click)="addMandi()">Add</button>
    </mat-dialog-actions>


    <form class="example-full-width" [formGroup]="mandiForm" *ngIf="type === 'edit'">
        <mat-form-field class="example-full-width">
            <input formControlName="mandi_name" placeholder="Mandi Name" matInput type='text' required>
            <mat-error>
                <span *ngIf="mandiForm.get('mandi_name').hasError('required')">Mandi Name is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="short_name" placeholder="Short Name" matInput type='text'>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label required>Select Agmark</mat-label>
            <mat-select class="matSelect" formControlName="agmark_id" required>
                <mat-option *ngFor="let agmark of agmarkList" [value]="agmark.id">
                    {{agmark.market_name}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!mandiForm.get('agmark_id').valid || mandiForm.get('agmark_id').touched">Agmark is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label required>Location</mat-label>
            <mat-select class="matSelect" formControlName="location_id" required>
                <mat-option *ngFor="let location of locationList" [value]="location.id">
                    {{location.state}} | {{location.district}} | {{location.town_city}} | {{location.pincode}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!mandiForm.get('location_id').valid || mandiForm.get('location_id').touched">Location is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label required>Delivery Location</mat-label>
            <mat-select class="matSelect" formControlName="delivery_id" required>
                <mat-option *ngFor="let location of deliveryLocationList" [value]="location.id">
                    {{location.delivery_name}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!mandiForm.get('delivery_id').valid || mandiForm.get('delivery_id').touched">Delivery Location is required</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <mat-label required>Cluster</mat-label>
            <mat-select class="matSelect" formControlName="cluster_id" required>
                <mat-option *ngFor="let cluster of clusterList" [value]="cluster.id">
                    {{cluster.name}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!mandiForm.get('cluster_id').valid || mandiForm.get('cluster_id').touched">Cluster is required</span>
            </mat-error>
        </mat-form-field>
        
        <mat-form-field class="example-full-width">
            <input formControlName="address_1" placeholder="Address 1" matInput type='text' required>
            <mat-error>
                <span *ngIf="mandiForm.get('address_1').hasError('required')">Address is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="address_2" placeholder="Address 2" matInput type='text'>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <input formControlName="pincode" placeholder="Pincode" matInput type='number' required>
            <mat-error>
                <span *ngIf="mandiForm.get('pincode').hasError('required')">Pincode is required</span>
            </mat-error>
        </mat-form-field>
    </form>
    <mat-dialog-actions  *ngIf="type === 'edit'">
        <button class="mat-raised-button mr-10" (click)="close()">Close</button>
        <button class="mat-raised-button mat-primary" (click)="editMandi()">Save</button>
    </mat-dialog-actions>
</mat-dialog-content>
