import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { ExcelService } from '../services/excel.service';
import { MenuService } from '../services/menu.service';
import { UbService } from '../services/ub.service';
import { MandiDestinationDialogComponent } from '../widgets/mandi-destination-dialog/mandi-destination-dialog.component';


@Component({
  selector: 'app-mandi-destination-master',
  templateUrl: './mandi-destination-master.component.html',
  styleUrls: ['./mandi-destination-master.component.scss']
})
export class MandiDestinationMasterComponent implements OnInit {


  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayColumns = ['mandi_name', 'delivery_name', 'active', 'action'];
  currentPage = 0;
  public notificationOptions: any = {
    position: ['bottom', 'right'],
    timeOut: 5000,
    lastOnBottom: true,
    pauseOnHover: true,
    clickToClose: true,
    animate: 'fromRight'
  };
  permission;
  loading = false;
  constructor(
    private service: UbService,
    private _service: NotificationsService,
    private excelService: ExcelService,
    private dialog: MatDialog,
    private menuService: MenuService,
    private router: Router
  ) {
  }

  ngOnInit() {
    const menu = this.menuService.getMenu(this.router.url);
    this.permission = menu.actions;
    this.loading = true;
    this.service.getAllMandiDestination().subscribe((response: any) => {
      if (response['status']) {
        for (const data of response.result) {
          data.mandi_name = data.mandiObj.mandi_name;
          data.delivery_name = data.destinationObj.delivery_name;
          data.active = data.active == 1 ? 'Active' : 'Inactive';
        }
        this.dataSource.filterPredicate = (data: any, filter: string) => {
          return data.mandi_name?.toLocaleLowerCase().includes(filter) ||
            data.active?.toLocaleLowerCase().includes(filter) ||
            data.delivery_name?.toLocaleLowerCase().includes(filter)
        };
        response.result = response.result.sort((a, b) => b.id - a.id);
        this.dataSource.data = response.result;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      this.loading = false;
    }, error => {
      this._service.error('Error', error.error.message);
      this.loading = false;
    });
  }

  checkPermission(access: string): boolean {
    return this.permission.includes(access);
  }

  applyFilter(filterValue) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  exportAsXLSX(): void {
    const exportData = this.dataSource.data.map((val) => (
      {
        'Id': val.id,
        'Mandi Name': val.mandi_name,
        'Delivery Location': val.delivery_name,
        'Active': val.active
      }
    ));
    this.excelService.exportAsExcelFile(exportData, 'mandi-destination-master');
  }

  mapMandiDestination() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Mandi Destination Mapping',
      height: '800px',
      width: '300px',
      panelClass: 'addnew-custom-dialog',
      type: 'add'
    };
    const dialogRef = this.dialog.open(MandiDestinationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.ngOnInit();
    })
  }

  editMandi(data) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Mandi Destination Mapping',
      height: '800px',
      width: '300px',
      panelClass: 'addnew-custom-dialog',
      type: 'edit',
      mandi_destination_id: data.id
    };
    const dialogRef = this.dialog.open(MandiDestinationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.ngOnInit();
    })
  }

  onPageChange(currentPage) {
    console.log(currentPage);
  }

}
