import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MenuService } from '../services/menu.service';
import { UbService } from '../services/ub.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogComponent } from '../widgets/dialog/dialog.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ExcelService } from '../services/excel.service';
import { Daterangepicker } from 'ng2-daterangepicker';
import { NotificationsService } from 'angular2-notifications';
import { SelectionModel } from '@angular/cdk/collections';

import * as moment from 'moment';
import { ZeroOrderDialogComponent } from '../widgets/zero-order-dialog/zero-order-dialog.component';
import { AuctionAction, AuctiontabText } from '../models/schema';

const initialSelection = [];
const allowMultiSelect = true;

enum Products {
  product_6_Row = '1',
  product_2_Row = '2'
}

@Component({
  selector: 'app-auction-dashboard',
  templateUrl: './auction-dashboard.component.html',
  styleUrls: ['./auction-dashboard.component.scss']
})
export class AuctionDashboardComponent implements OnInit, AfterViewInit {
  AuctionAction = AuctionAction;
  AuctiontabText = AuctiontabText;
  permission: any;
  documentDate = moment(new Date()).format('YYYY-MM-DD');
  animal: string;
  name: string;
  mandiList: any;
  startDate = moment().subtract(1, "days").format("YYYY-MM-DD");
  endDate = moment().format("YYYY-MM-DD");
  loading = false;
  public daterange: any = {
    start: this.startDate,
    end: this.endDate,
  };
  loggedInUser: any;
  public options: any = {
    locale: { format: 'D MMMM YYYY' },
    alwaysShowCalendars: false,
    maxDate: new Date(),
    startDate: moment().subtract(1, "days").format("D MMMM YYYY"),
    endDate: moment().format("D MMMM YYYY"),
  };
  public notificationOptions: any = {
    position: ['bottom', 'right'],
    timeOut: 5000,
    lastOnBottom: true,
    pauseOnHover: true,
    clickToClose: true,
    animate: 'fromRight'
  };
  displayed6RowColumns: any;
  displayed2RowColumns: any;
  dataSource = new MatTableDataSource<any>();
  backupData: any;
  links = [];
  activeLink = '';
  stateList: any;
  editRow: any;
  selection: any;
  isFrozen = false;
  isPreviousDate = false;
  approversList = [];
  minimumOfMandis = 0;
  alarmClass = 'notApprovedAlarm'
  alarm2RowClass = 'notApprovedAlarm'
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public selectedDate(value: any, datepicker?: any) {
    // this is the date  selected
    // console.log(value);

    // any object can be passed to the selected event and it will be passed back here
    datepicker.start = value.start;
    datepicker.end = value.end;

    // use passed valuable to update state
    // this.daterange.start = value.start.toISOString().slice(0, 10).replace('T', ' ');
    // this.daterange.end = value.end.toISOString().slice(0, 10).replace('T', ' ');
    this.daterange.start = moment(value.start).format('YYYY-MM-DD');
    this.daterange.end = moment(value.end).format('YYYY-MM-DD');
    this.daterange.label = value.label;
    this.getTableData(this.activeLink);
  }

  constructor(
    public menuService: MenuService,
    private router: Router,
    private service: UbService,
    private dialog: MatDialog,
    private excelService: ExcelService,
    private _service: NotificationsService
  ) {
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      return data.min_price.toString().includes(filter) ||
        data.mandiObj.mandi_name.toLocaleLowerCase().includes(filter) ||
        data.max_price.toString().includes(filter) ||
        data.is_submitted.toString().includes(filter) ||
        data.document_date.includes(filter);
    };
    this.selection = new SelectionModel<any>(allowMultiSelect, initialSelection);
  }

  openCeilingPriceDialog() {

    if (this.selection.selected == 0) {
      const reqIds = this.dataSource.data.filter(val => (this.isFrozen === true) && (val.is_submitted === true)
        && ((val.approvermasterObj && val.approvermasterObj.status != "Approved") || !val.approvermasterObj));

      console.log('reqIds------>', reqIds)
      reqIds.forEach(row => this.selection.select(row));
      console.log('this.selection.selected------>', this.selection.selected)
    }

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      title: 'Set Ceiling Price',
      rowType: 'setCeilingPrice',
      rowData: null,
      buttonText: {
        ok: 'Add',
        cancel: 'Cancel',
      },
      height: '600px',
      width: '300px',
      panelClass: 'addnew-custom-dialog'
    };

    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.setAllCeilingPrice(data);
      }
    });
  }

  applyFilter(filterValue) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  ngOnInit(): void {
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    const menu = this.menuService.getMenu(this.router.url);
    this.permission = menu.actions;
    this.displayed6RowColumns = ['select', 'mandi_name', 'notified_date', 'quantity', 'min_price', 'max_price', 'previous_year_price', 'avg_buying', 'modal_price', 'suggested_price', 'ceiling_price', 'approval', 'notify'];
    this.displayed2RowColumns = ['select', 'mandi_name', 'notified_date', 'quantity', 'state', 'one_price', 'previous_year_price', 'MSP', 'premium', 'modal_price', 'suggested_price', 'ceiling_price', 'approval', 'notify'];

    if (this.checkPermission(AuctionAction.review_2_row)) {
      this.displayed2RowColumns.push('action');
    }

    if (this.checkPermission(AuctionAction.review_6_row)) {
      this.displayed6RowColumns.push('action');
    }

    this.get6RowAuctionData(this.loggedInUser.id, this.documentDate);
    this.service.getUserMandi(this.loggedInUser.id).subscribe((response: any) => {
      this.mandiList = response.result;
    });
    this.service.getState().subscribe((response: any) => {
      this.stateList = response.result;
    });

    if (this.checkPermission(AuctionAction.view_6_row)) {
      this.links.push(AuctiontabText.Barley_6_Row);
    }

    if (this.checkPermission(AuctionAction.view_2_row)) {
      this.links.push(AuctiontabText.Barley_2_Row);
      this.activeLink = AuctiontabText.Barley_2_Row;
    }

    if (this.checkPermission(AuctionAction.view_6_row)) {
      this.activeLink = AuctiontabText.Barley_6_Row;
    }

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getCurrentDate() {
    this.documentDate = moment(new Date()).format('YYYY-MM-DD');
    this.isPreviousDate = false;
    this.getTableData(this.activeLink);
  }

  getPreviousDate() {
    this.documentDate = moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD');
    this.isPreviousDate = true;
    this.getTableData(this.activeLink);
  }


  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected == numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  getTableData(link) {
    this.dataSource = new MatTableDataSource([]);
    this.selection = new SelectionModel<any>(allowMultiSelect, initialSelection);
    if (link === AuctiontabText.Barley_2_Row) {
      this.get2RowAuctionData(this.loggedInUser.id, this.documentDate);
    } else if (link === AuctiontabText.Barley_6_Row) {
      this.get6RowAuctionData(this.loggedInUser.id, this.documentDate);
    }
  }

  get6RowAuctionData(id, date) {
    this.loading = true;
    this.service.getAuctionData(id, date).subscribe((response: any) => {
      console.log('this.selection.selected------------>', this.selection.selected)
      this.selection.clear()
      if (response && response.result && response.result.length > 0) {
        this.isFrozen = response.result[0].is_frozen;
      } else {
        this.isFrozen = false;
      }
      this.dataSource.data = response.result;
      for (const data of this.dataSource.data) {
        data.notified_at = data.notified_at ? moment(new Date(data.notified_at)).format('YYYY-MM-DD HH:mm ZZ') : ''
      }
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      const obj = {
        userId: id,
        product_id: Products.product_6_Row,
        documentDate: this.documentDate
      };
      if (this.dataSource.data && this.dataSource.data.length > 0) {
        this.minimumOfMandis = Math.min.apply(Math, this.dataSource.data.map(function (o) {
          return o.min_price;
        }));
      } else {
        this.minimumOfMandis = 0;
      }

      this.getDataSourceStatus(obj);
      this.loading = false;
    }, error => {
      this.loading = false;
      this._service.error('Error', error.error.message);
    });
  }

  get2RowAuctionData(id, date) {
    this.loading = true
    this.service.get2RowAuctionData(id, date).subscribe((response: any) => {
      this.selection.clear();
      if (response && response.result && response.result.length > 0) {
        this.isFrozen = response.result[0].is_frozen;
      } else {
        this.isFrozen = false;
      }
      this.dataSource.data = response.result;
      for (const data of this.dataSource.data) {
        data.notified_at = data.notified_at ? moment(new Date(data.notified_at)).format('YYYY-MM-DD HH:mm ZZ') : ''
      }
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      const obj = {
        userId: id,
        product_id: Products.product_2_Row,
        documentDate: this.documentDate
      };
      if (this.dataSource.data && this.dataSource.data.length > 0) {
        this.minimumOfMandis = Math.min.apply(Math, this.dataSource.data.map(function (o) {
          return o.one_price;
        }));
      } else {
        this.minimumOfMandis = 0;
      }
      
      this.getDataSourceStatus(obj);
      this.loading = false
    }, error => {
      this.loading = false
      this._service.error('Error', error.error.message);
    });
  }

  checkPermission(access: string): boolean {
    return this.permission.includes(access);
  }

  setCeilingPrice(rowData) {
    const payload = {
      ceiling_price: rowData.ceiling_price,
      ids: [rowData.id]
    };
    this.updateCeilingPrice(payload);
  }

  setAllCeilingPrice(price) {

    const ids = this.selection.selected.map(val => val.id);
    const payload = {
      ceiling_price: price.toString(),
      ids: ids
    };


    if (ids.length > 0) {
      this.updateCeilingPrice(payload);
    } else {
      this._service.warn('Warning', '0 Mandi selected');
    }
  }

  submitForApproval() {
    // if (this.selection.selected == 0) {
    //   const reqIds = this.dataSource.data.filter(val => (this.isFrozen === true) && (val.is_submitted === true)
    //     && val.is_ceiling_set === true);

    //   console.log('reqIds------>', reqIds)
    //   reqIds.forEach(row => this.selection.select(row));
    //   console.log('this.selection.selected------>', this.selection.selected)
    // }

    console.log('this.selection.selected------------>', this.selection.selected)
    const ids = this.selection.selected.map(val => val.id);
    if (!ids || ids.length <= 0) {
      this._service.error('Error', 'Select at least one record for approval');
      return false;
    }
    const payload = {
      ids: ids
    };
    this.triggerApproval(payload);

  }

  triggerApproval(payload) {
    let link = 'arrivaldata';
    if (this.activeLink === AuctiontabText.Barley_2_Row) {
      link = 'arrivaldata2row';
    }

    this.service.triggerApprovalFlow(payload, link).subscribe((response: any) => {

      if (response) {
        const triggerRes = response.result.filter(val => val.status == false);
        if (triggerRes.length == 0) {
          this._service.success('Success', response.message);
        } else {
          for (let i = 0; i < triggerRes.length; i++) {
            this._service.error('Error', triggerRes[i].message);
          }
        }

        if (this.activeLink === AuctiontabText.Barley_2_Row) {
          this.get2RowAuctionData(this.loggedInUser.id, this.documentDate);
        } else if (this.activeLink === AuctiontabText.Barley_6_Row) {
          this.get6RowAuctionData(this.loggedInUser.id, this.documentDate);
        }

      } else {
        this._service.error('Error', 'Approval Failed');
      }
    }, error => {
      this._service.error('Error', error.error.message);
    });
  }


  updateCeilingPrice(payload) {

    if (this.activeLink === AuctiontabText.Barley_6_Row) {
      this.service.set6RowCeilinPrices(payload).subscribe((response: any) => {
        if (response) {
          const updateCeilingRes = response.result.filter(val => val.status == false);
          if (updateCeilingRes.length == 0) {
            this._service.success('Success', response.message);
          } else {
            for (let i = 0; i < updateCeilingRes.length; i++) {
              this._service.error('Error', updateCeilingRes[i].message);
            }
          }
          this.getTableData(this.activeLink);
        }
      }, error => {
        this._service.error('Error', error.error.message);
      });
    } else if (this.activeLink === AuctiontabText.Barley_2_Row) {
      this.service.set2RowCeilinPrices(payload).subscribe((response: any) => {
        if (response) {
          const updateCeilingRes = response.result.filter(val => val.status == false);
          if (updateCeilingRes.length == 0) {
            this._service.success('Success', response.message);
          } else {
            for (let i = 0; i < updateCeilingRes.length; i++) {
              this._service.error('Error', updateCeilingRes[i].message);
            }
          }
          this.getTableData(this.activeLink);
        }
      }, error => {
        this._service.error('Error', error.error.message);
      });
    }
  }

  freezeAll(status) {
    let sendData = {
      product_id: 1,
      document_date: this.documentDate,
      is_frozen: 1
    };
    if (status === 'unfreeze') {
      sendData.is_frozen = 0;
    }
    if (this.activeLink === AuctiontabText.Barley_2_Row) {
      sendData.product_id = 2;
    }

    this.service.freezeAuctionData(sendData).subscribe((response: any) => {
      if (response) {
        this._service.success('Success', response.message);
        this.getTableData(this.activeLink);
      }
    }, error => {
      this._service.error('Error', error.error.message);
    });
  }

  getDataSourceStatus(data) {
    this.service.getFreezeState(data).subscribe((response: any) => {
      if (response) {
        if (response && response.result && response.result.length > 0) {
          this.isFrozen = response.result[0].is_frozen;
        } else {
          this.isFrozen = false;
        }
      }
    }, error => {
      this._service.error('Error', error.error.message);
    });

    if (this.dataSource.data) {
      let aprsList = [];
      let numApproved;
      let currentApprover;
      let totalApprovers;
      let pendingApprovers;
      for (let i = 0; i < this.dataSource.data.length; i++) {
        if (this.dataSource.data[i].approvermasterObj && this.dataSource.data[i].approvermasterObj.approvers) {
          currentApprover = this.dataSource.data[i].approvermasterObj.current_approver;
          aprsList = this.dataSource.data[i].approvermasterObj.approvers.split(',');
          totalApprovers = aprsList.length;
          numApproved = aprsList.indexOf(currentApprover);

          let reviewedBy = [];
          if (this.dataSource.data[i].approvermasterObj && this.dataSource.data[i].approvermasterObj.updated_by) {
            reviewedBy = this.dataSource.data[i].approvermasterObj.updated_by.map(val => val.reviewerObj.first_name);
          }

          if (totalApprovers > 0) {
            this.dataSource.data[i].approvermasterObj.totalApprovers = totalApprovers;
            if (numApproved > 0) {

              pendingApprovers = totalApprovers - (numApproved);
            } else {
              pendingApprovers = totalApprovers;
              numApproved = 0;
            }
            this.dataSource.data[i].approvermasterObj.pendintApprovers = pendingApprovers;
            this.dataSource.data[i].approvermasterObj.pendingName = aprsList.splice(numApproved).join(',');
            this.dataSource.data[i].approvermasterObj.approvedApprovers = numApproved;
            this.dataSource.data[i].approvermasterObj.approvedName = reviewedBy.join(',');
          }
        }
      }
    }
  }

  updateCeilingPriceStatus(status, rowData) {
    let payload = {
      status: status,
      product_id: rowData.product_id,
      reviewed_by: this.loggedInUser.id,
      ids: [rowData.id]
    };
    if (this.selection.selected.length > 0) {
      const ids = this.selection.selected.map(val => val.id);
      payload.ids = ids;
    }
    this.service.updateAuctionDataStatus(payload).subscribe((response: any) => {
      if (response) {
        const updateAuctionRes = response.result.filter(val => val.status == false);
        if (updateAuctionRes.length == 0) {
          this._service.success('Success', response.message);
        } else {
          for (let i = 0; i < updateAuctionRes.length; i++) {
            this._service.error('Error', updateAuctionRes[i].message);
          }
        }
        this.getTableData(this.activeLink);
      }
    }, error => {
      this._service.error('Error', error.error.message);
    });
  }

  rejectAll() {
    const isAllowed = this.selection.selected.
      filter(val => (!val.approvermasterObj || (val.approvermasterObj.current_approver.trim() != this.loggedInUser.role)));

    for (let i = 0; i < isAllowed.length; i++) {
      const element = isAllowed[i];
      this._service.error('Error', `Not allowed to review for ${element.mandiObj
      .mandi_name}`);
    }
    if (isAllowed.length > 0) {
      return false
    }

    const selections = this.selection.selected.map(val => val.id);
    if (!selections || selections.length <= 0) {
      this._service.error('Error', 'Select at least one record');
      return false;
    }
    
    const payload = {
      status: 'Rejected',
      reviewed_by: this.loggedInUser.id,
      ids: selections,
      product_id: 1
    };
    if (this.activeLink === AuctiontabText.Barley_2_Row) {
      payload.product_id = 2;
    }
    if (selections.length > 0) {
      this.service.updateAuctionDataStatus(payload).subscribe((response: any) => {
        if (response) {
          const rejectAllRes = response.result.filter(val => val.status == false);
          if (rejectAllRes.length == 0) {
            this._service.success('Success', response.message);
          } else {
            for (let i = 0; i < rejectAllRes.length; i++) {
              this._service.error('Error', rejectAllRes[i].message);
            }
          }
          this.getTableData(this.activeLink);
        }
      }, error => {
        this._service.error('Error', error.error.message);
      });
    } else {
      this._service.error('Error', 'No data found for approval');
    }
  }

  approveAll() {
    
    const isAllowed = this.selection.selected.
      filter(val => (!val.approvermasterObj || (val.approvermasterObj.current_approver.trim() != this.loggedInUser.role)));

    for (let i = 0; i < isAllowed.length; i++) {
      const element = isAllowed[i];
      this._service.error('Error', `Not allowed to review for ${element.mandiObj
      .mandi_name}`);
    }
    if (isAllowed.length > 0) {
      return false
    }

    const selections = this.selection.selected.map(val => val.id);
    if (!selections || selections.length <= 0) {
      this._service.error('Error', 'Select at least one record');
      return false;
    }

    const payload = {
      status: 'Approved',
      reviewed_by: this.loggedInUser.id,
      ids: selections,
      product_id: 1
    };
    if (this.activeLink === AuctiontabText.Barley_2_Row) {
      payload.product_id = 2;
    }
    if (isAllowed.length == 0) {
      this.service.updateAuctionDataStatus(payload).subscribe((response: any) => {
        if (response) {
          const approveAllRes = response.result.filter(val => val.status == false);
          if (approveAllRes.length == 0) {
            this._service.success('Success', response.message);
          } else {
            for (let i = 0; i < approveAllRes.length; i++) {
              this._service.error('Error', approveAllRes[i].message);
            }
          }
          this.getTableData(this.activeLink);
        }
      }, error => {
        this._service.error('Error', error.error.message);
      });
    } else {
      this._service.error('Error', 'No data found for approval');
    }
  }

  notifyAll() {
    let idsList = [];//this.dataSource.data.filter(val => (val.ceiling_price && val.ceiling_price > 0)).map(val => val.id);
    if (this.selection.selected.length > 0) {
      const ids = this.selection.selected.filter(val => (val.ceiling_price && val.ceiling_price > 0)).map(val => val.id);
      idsList = ids;
    }
    if (idsList.length === 0) {
      this._service.error('Error', 'No Data selected')
      return;
    }
    const sendData = {
      ids: idsList,
      notified_by: this.loggedInUser.id,
      document_date: this.documentDate
    };
    this.submitNotification(sendData);
  }

  notifyRow(rowData) {
    let sendData = {
      ids: [rowData.id],
      notified_by: this.loggedInUser.id,
      document_date: this.documentDate
    };

    this.submitNotification(sendData);
  }

  submitNotification(sendData) {
    if (this.activeLink === AuctiontabText.Barley_2_Row) {
      this.service.auctionData2RowNotify(sendData).subscribe((response: any) => {
        if (response.status) {
          if (response.result && response.result.length > 0) {
            const data = response.result.filter((itm) => itm.status === false && itm.type === 'zero-order');
            if (data.length > 0) {
              if (data.length > 0) {
                const dialogConfig = new MatDialogConfig();
                dialogConfig.data = { notifyData: data, product_id: (this.activeLink === AuctiontabText.Barley_2_Row ? 2 : 1) };
                const dialogRef = this.dialog.open(ZeroOrderDialogComponent, dialogConfig);
                dialogRef.afterClosed().subscribe(data => {
                  if (data) {
                    this.getTableData(this.activeLink);
                  }
                });
              }
              // this._service.error('Error', data[0].message);
            } else {
              const data = response.result.filter((itm) => itm.status === false);
              if (data.length > 0) {
                for (let i = 0; i < data.length; i++) {
                  this._service.error('Error', data[i].message);
                }
              } else {
                this._service.success('Success', response.message);
              }

              this.getTableData(this.activeLink);
            }
          } else {
            this._service.success('Success', response.message);
            this.getTableData(this.activeLink);
          }
        }
      }, error => {
        this._service.error('Error', error.error.message);
      });

    } else if (this.activeLink === AuctiontabText.Barley_6_Row) {
      this.service.auctionData6RowNotify(sendData).subscribe((response: any) => {
        if (response.status) {
          if (response.result && response.result.length > 0) {
            const data = response.result.filter((itm) => itm.status === false && itm.type === 'zero-order');
            if (data.length > 0) {
              if (data.length > 0) {
                const dialogConfig = new MatDialogConfig();
                dialogConfig.data = { notifyData: data, product_id: (this.activeLink === AuctiontabText.Barley_2_Row ? 2 : 1) };
                const dialogRef = this.dialog.open(ZeroOrderDialogComponent, dialogConfig);
                dialogRef.afterClosed().subscribe(data => {
                  if (data) {
                    this.getTableData(this.activeLink);
                  }
                });
              }
            } else {
              const data = response.result.filter((itm) => itm.status === false);
              if (data.length > 0) {
                for (let i = 0; i < data.length; i++) {
                  this._service.error('Error', data[i].message);
                }
              } else {
                this._service.success('Success', response.message);
              }
              this.getTableData(this.activeLink);
            }
          } else {
            this._service.success('Success', response.message);
            this.getTableData(this.activeLink);
          }
        }
      }, error => {
        this._service.error('Error', error.error.message);
      });
    }
  }

  exportAsXLSX(): void {

    if (this.activeLink === AuctiontabText.Barley_6_Row) {
      const exportData = this.dataSource.data.map((val) => (
        {
          id: val.id,
          'Mandi Name': val.mandiObj.mandi_name,
          'Document Date': val.document_date,
          'Quantity': val.quantity,
          'min price': val.min_price,
          'Max Price': val.max_price,
          'Avg Buying Price': val.avg_buying_price,
          'Modal Price': val.modal_price,
          'Suggested Price': val.suggested_price,
          'Ceiling Price': val.ceiling_price
        }
      ));
      this.excelService.exportAsExcelFile(exportData, 'arrival-data');

    } else if (this.activeLink === AuctiontabText.Barley_2_Row) {
      const exportData = this.dataSource.data.map((val) => (
        {
          id: val.id,
          'Mandi Name': val.mandiObj.mandi_name,
          'Document Date': val.document_date,
          'Quantity': val.quantity,
          'State': val.stateObj?.state_name,
          'One price': val.one_price,
          'MSP': val.msp,
          'Premium': val.premium,
          'Modal Price': val.modal_price,
          'Suggested Price': val.suggested_price,
          'Ceiling Price': val.ceiling_price
        }
      ));
      this.excelService.exportAsExcelFile(exportData, 'arrival-data');
    }
  }

  showActions(element: any) {
    if (element.approvermasterObj && element.approvermasterObj.current_approver) {
      if (element.approvermasterObj.current_approver.trim() == this.loggedInUser.role) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  checkNotify(element: any) {
    if (element && element.approvermasterObj && element.approvermasterObj.approvers) {
      if (element.approvermasterObj.approvers.includes(this.loggedInUser.role)) {
        const currentApprover = element.approvermasterObj.current_approver;
        const aprsList = element.approvermasterObj.approvers.split(',');
        const currentApprIndex = aprsList.indexOf(currentApprover);
        const userIndex = aprsList.indexOf(this.loggedInUser.role);
        if (userIndex > currentApprIndex) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }

  getAlarmCLass(element) {
    if (element && element.approvermasterObj && element.approvermasterObj.status == 'Approved') {
      if (element.notified) {
        this.alarmClass = 'notifiedAlarm';
      } else {
        this.alarmClass = 'approvedAlarm';
      }
    } else {
    }
    return this.alarmClass
  }
  getAlarm2RowCLass(element) {
    if (element && element.approvermasterObj && element.approvermasterObj.status == 'Approved') {
      if (element.notified) {
        this.alarm2RowClass = 'notifiedAlarm';
      } else {
        this.alarm2RowClass = 'approvedAlarm';
      }
    } else {
    }
    return this.alarm2RowClass
  }
}

