<div class="container-fluid h100">
    <!-- <app-header></app-header> -->
    <div class="login-container">
        <div class="loginInputBlock h100">
            <div class="loginHeader">
                <img src="assets/images/main_logo.svg">
            </div>
            <div class="loginHeaderlogoText m-t10">
                Barley Procurement
            </div>
            <div class="loginHeaderText m-t10 m-b20">
                sign in
            </div>
            <div class="loginForm">
                <form [formGroup]="loginForm">
                    <div class="form-group">
                        <mat-form-field appearance="fill">
                            <mat-label> User Name </mat-label>
                            <input matInput type="text" class="example-right-align" formControlName="name" required>
                            <img matPrefix class="input-icon" src="assets/images/icons/person.svg">
                            <mat-error *ngIf="loginForm.get('name').hasError('required')">User Name Required</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field appearance="fill">
                            <mat-label> Password </mat-label>
                            <input matInput type="password" class="example-right-align" formControlName="password"
                                required>
                            <img matPrefix class="input-icon" src="assets/images/icons/lock.svg">
                            <mat-error *ngIf="loginForm.get('password').hasError('required')">Password Required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <div class="rememberBlock">
                            <div>
                                <section class="loginRememberMe">
                                    <mat-checkbox class="rememberMe-margin">Remember Me</mat-checkbox>
                                </section>
                            </div>
                            <div>
                                <a (click)="resetPassword()">Forgot Password?</a>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <button mat-raised-button color="primary" (click)="login()" class="w-100">
                            <span class="spinner-border spinner-border-sm mr-1" *ngIf = "loginUser"></span>
                            Login
                        </button>
                    </div>
                </form>
            </div>
            <div class="alternateBtnBlock m-b20">
                <div class="horizontalLine">
                    <span>OR</span>
                </div>
                <div>
                    <a [href]="UBLoginUrl">
                    <button mat-raised-button color="primary" class="w-100">
                        <span class="spinner-border spinner-border-sm mr-1" *ngIf = "loginUb"></span>
                       Sign In as a UB User
                    </button>
                    </a>
                </div>
            </div>
            <div class="loginFooter m-t10 m-b20">
                <div class="loginFooterCompany">
                    UNITED BREWERIES LIMITED
                </div>
                <div>
                    &#169; 2020 | All rights reserved
                </div>
            </div>

        </div>
        <div class="loginCarasoleBlock">
            <ngb-carousel [showNavigationArrows]="true" [showNavigationIndicators]="true" interval="12000"
                [keyboard]="true" [pauseOnHover]="false" [wrap]="true" [activeId]="'secondSlide'">
                <ng-template ngbSlide *ngFor="let slide of slides">
                    <div class="picsum-img-wrapper">
                        <img src="{{slide.image}}" alt="Carousel" class="mh-100" style="width: 100%; height: 100vh">
                    </div>
                    <div class="carousel-caption">
                        <h3>{{slide.title}}</h3>
                        <p>{{slide.text}}</p>
                    </div>
                </ng-template>
            </ngb-carousel>
        </div>

    </div>
    <simple-notifications [options]="notificationOptions"></simple-notifications>
</div>