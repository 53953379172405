import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationsService } from 'angular2-notifications';
import { UbService } from 'src/app/services/ub.service';

@Component({
  selector: 'app-agmark-master-dialog',
  templateUrl: './agmark-master-dialog.component.html',
  styleUrls: ['./agmark-master-dialog.component.scss']
})
export class AgmarkMasterDialogComponent implements OnInit {

  dialogData: any;
  title: string;
  agmarkForm: FormGroup;
  locations: any;
  agmarkData: any;
  loggedInUser;
  status = [
    {
      name: 'Active',
      id: '1'
    },
    {
      name: 'InActive',
      id: '0'
    }
  ];

  constructor(
    private service: UbService,
    private fb: FormBuilder,
    private notificationService: NotificationsService,
    private dialogRef: MatDialogRef<AgmarkMasterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.title = data.title;
    this.agmarkData = data;
  }

  ngOnInit(): void {
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    this.agmarkForm = this.fb.group({
      market_name: ['', [Validators.required]],
      location_id: ['', [Validators.required]],
      active: ['', [Validators.required]],
    });
    this.agmarkForm.controls.active.setValue('1');
    this.getLocation();
  }

  getLocation() {
    this.service.getAllLocation().subscribe((res) => {
      if (res['status']) {
        this.locations = res['result'];
        this.locations = this.locations.filter(val => val.is_active == true || val.is_active == 1)

        if (this.agmarkData.agmarkData) {
          console.log(this.agmarkData.agmarkData);
          this.agmarkForm.controls['market_name'].setValue(this.agmarkData.agmarkData.market_name);
          this.agmarkForm.controls['location_id'].setValue(this.agmarkData.agmarkData.location_id);
          this.agmarkForm.controls['active'].setValue(this.agmarkData.agmarkData.active);
        }
      }
    }, error => {
      this.notificationService.error('Error', error.error.message);
    });
  }

  close() {
    this.dialogRef.close();
  }

  saveAgmark() {
    for (const c in this.agmarkForm.controls) {
      if (this.agmarkForm.controls.hasOwnProperty(c)) {
        this.agmarkForm.controls[c].markAsTouched();
      }
    }
    if (this.agmarkForm.valid) {
      const payload = {
        location_id: this.agmarkForm.value.location_id,
        active: this.agmarkForm.value.active,
        market_name: this.agmarkForm.value.market_name,
        updated_by: this.loggedInUser.id
      };
      if (this.agmarkData.type === 'add') {
        payload['created_by'] = this.loggedInUser.id,
          this.service.createAgmark(payload).subscribe((res) => {
            if (res['status']) {
              this.notificationService.success('Success', res['message']);
              this.dialogRef.close();
            }
          }, error => {
            this.notificationService.error('Error', error.error.message);
          });
      } else if (this.agmarkData.type === 'edit') {
        this.service.updateAgmark(this.agmarkData.agmarkData.id, payload).subscribe((res) => {
          if (res['status']) {
            this.notificationService.success('Success', res['message']);
            this.dialogRef.close();
          }
        }, error => {
          this.notificationService.error('Error', error.error.message);
        });

      }
    }
  }

}
