<div class="container-fluid">
    <div class="pageHeader">
        Manage Offer - 6 Row Barley
    </div>
    <div class="dateRangeBlock">
        <div class="col-md-6">
            <div class="input-wrapper">
                <input type="text" class="daterangeInput input-field" name="daterangeInput" daterangepicker
                    [options]="options" (selected)="selectedDate($event, daterange)" /> &nbsp;
                <fa-icon [icon]="['fas', 'calendar']" class="icon-favIcon primColor"></fa-icon>
            </div>
        </div>
        <div class="col-md-6 buttonAlignRight">
            <span [ngClass]="seasonType === OfferToggleText.Season ? 'activeType' : 'inactiveType'">Season</span>
            <mat-slide-toggle (change)="seasonTypeChange($event)"
            color="'#3f51b5'" [checked]="seasonType != activeSeason"
            ></mat-slide-toggle>
            <span [ngClass]="seasonType === OfferToggleText.Offseason ? 'activeType' : 'inactiveType'">Offseason</span>
        </div>
    </div>
    <div class="row" style="padding: 20px;margin-left: 0px;margin-right: 0px;margin-top:25px">
        <div class="col-md-6 pl-0">
            <mat-form-field class="cusWidth">
                <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
                <button mat-icon-button matPrefix>
                    <mat-icon class="primColor">search</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <div class="col-md-1 pl-0">
            <mat-form-field class="example-full-width">
                <mat-label required>Status</mat-label>
                <mat-select class="matSelect" (selectionChange)="changeStatus($event)" [(value)]="statusSelected">
                    <mat-option *ngFor="let status of avilableStatus" [value]="status">{{status}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="seasonType === OfferToggleText.Season" class="col-md-5 buttonAlignRight tabBtnAlign">
            <button class="mat-raised-button mat-primary mr-10 ml-10" style="bottom: 10px;" (click)="addNewOffer()"
             *ngIf="checkPermission(OfferAction.create_season_offer)">
                <fa-icon [icon]="['fas', 'plus']"></fa-icon> Add New Offer
            </button>
            <button class="mat-raised-button mat-primary mr-10"  style="bottom: 10px;" (click)="openDialogConfirmation('all', 'Submit Offer')"
            *ngIf="checkPermission(OfferAction.submit_season_offer)">Submit All</button>
            
            <button class="mat-raised-button mat-primary rejectBtn"  style="bottom: 10px;right:10px" (click)="rejectAll('Reject Offer' , 'all')" 
            *ngIf="checkPermission(OfferAction.review_season_offer) && !checkPermission(OfferAction.review_season_counter_offer)">
                Reject All
            </button>
            <button class="mat-raised-button mat-primary acceptBtn"  style="bottom: 10px;" (click)="acceptAll('Approve Offer' , 'all')"
            *ngIf="checkPermission(OfferAction.review_season_offer) && !checkPermission(OfferAction.review_season_counter_offer)" >
                Accept All
            </button>
            <button class="mat-raised-button mat-primary exportBtn"  style="bottom: 10px;" (click)="exportAsXLSX()">
                <fa-icon [icon]="['fas', 'download']" *ngIf="checkPermission('export')"></fa-icon> Export Excel
            </button>
        </div>

        <div *ngIf="seasonType === OfferToggleText.Offseason" class="col-md-5 buttonAlignRight tabBtnAlign">
            <button class="mat-raised-button mat-primary mr-10 ml-10" style="bottom: 10px;" (click)="addNewOffer()"
             *ngIf="checkPermission(OfferAction.create_offseason_offer)">
                <fa-icon [icon]="['fas', 'plus']"></fa-icon> Add New Offer
            </button>
            <button class="mat-raised-button mat-primary mr-10"  style="bottom: 10px;" (click)="openDialogConfirmation('all', 'Submit Offer')"
            *ngIf="checkPermission(OfferAction.submit_offseason_offer)">Submit All</button>
            
            <button class="mat-raised-button mat-primary rejectBtn"  style="bottom: 10px;right:10px" (click)="rejectAll('Reject Offer' , 'all')" 
            *ngIf="checkPermission(OfferAction.review_offseason_offer) && !checkPermission(OfferAction.review_season_counter_offer)">
                Reject All
            </button>
            <button class="mat-raised-button mat-primary acceptBtn"  style="bottom: 10px;" (click)="acceptAll('Approve Offer' , 'all')"
            *ngIf="checkPermission(OfferAction.review_offseason_offer) && !checkPermission(OfferAction.review_season_counter_offer)">
                Accept All
            </button>
            <button class="mat-raised-button mat-primary exportBtn"  style="bottom: 10px;" (click)="exportAsXLSX()">
                <fa-icon [icon]="['fas', 'download']" *ngIf="checkPermission('export')"></fa-icon> Export Excel
            </button>
        </div>

    </div>
    <div class="row" style="padding: 20px;margin-left: 0px;margin-right: 0px;">
        <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
        <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="mandi_name">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Mandi Name </mat-header-cell>
                <mat-cell *matCellDef="let element" class="wc-200"> {{element.mandiObj.mandi_name}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="delivery_location">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Delivery Location </mat-header-cell>
                <mat-cell *matCellDef="let element" class="wc-200"> {{element.destinationObj.delivery_name}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="pakka_arthiya">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Pakka Arthiya </mat-header-cell>
                <mat-cell *matCellDef="let element" class="wc-200"> {{element.userObj.user_id}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="reference_no">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Reference No</mat-header-cell>
                <mat-cell *matCellDef="let element" class="wc-200"> {{element.ref_num}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="order_number">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200">Order No</mat-header-cell>
                <mat-cell *matCellDef="let element" class="wc-200"> {{element.order_number}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="offer_reference_no">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200">Offer No</mat-header-cell>
                <mat-cell *matCellDef="let element" class="wc-200"> {{element.offer_number}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="document_date">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Document Date <br> (&#x20b9;)
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="wc-200"> {{element.document_date}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="plant_code">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Plant Code </mat-header-cell>
                <mat-cell *matCellDef="let element" style="width: 100px; margin-right: 50px">
                    <mat-form-field>

                        <mat-select (selectionChange)="changePlaneCode($event.value, element)"
                            [(value)]="element.plant_master_id">
                            <mat-option>Select Plant Code</mat-option>
                            <mat-option *ngFor="let option of plantCodeList" [value]="option.id"
                                selected="element.plant_master_id == option.id">{{option.code}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="base_price">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Base Price <br> (&#x20b9;)
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="wc-200"> {{element.base_price}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="quantity">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Quantity <br> (In Quintal)
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="wc-200"> {{element.quantity}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="previous_year_price">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Previous Year Price </mat-header-cell>
                <mat-cell *matCellDef="let element" class="wc-200"> {{element.previous_year_price}} </mat-cell>
              </ng-container>

            <ng-container matColumnDef="for_price">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200">For Price</mat-header-cell>
                <mat-cell *matCellDef="let element" class="wc-200"> {{element.order_for_price}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="suggested_price">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Suggested Price <br> (&#x20b9;)
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="wc-200"> {{element.suggested_price}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="ceiling_price">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Ceiling Price <br>(&#x20b9;)
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="wc-200"> {{element.ceiling_price}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="created_by">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Created By</mat-header-cell>
                <mat-cell *matCellDef="let element" class="wc-200">
                    <span *ngIf="element.createdByObj">{{element.createdByObj.user_id}}</span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="counter_offer_price">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="w-400"> Counter Offer Price <br> (&#x20b9;)
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="wc-400">
                    <span style="margin-right: 25px;">{{element.counter_offer_price}}</span>
                    <button class="mat-raised-button mat-primary" (click)="updateCounterPrice(element , 'UPDATE')"  
                    *ngIf="checkPermission(OfferAction.review_season_offer) && element.status === 'SUBMITTED'">Update</button>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="w-400">Actions</mat-header-cell>
                <mat-cell *matCellDef="let element" class="wc-400">
                    <span *ngIf="element.status === 'SAVED' && checkPermission(OfferAction.submit_season_offer)" style="display:flex">
                        <span class="action-btn" (click)="manageOfferDialog(element)">Edit</span> |
                        <span class="action-btn submitActionBtn"
                            (click)="openDialogConfirmation(element, 'Submit Offer')">Submit</span>
                    </span>
                    <span *ngIf="element.status === 'SUBMITTED' && checkPermission(OfferAction.review_season_offer) && !element.is_counter_offer_set" style="display:flex">
                        <!-- <span class="action-btn" (click)="updateCounterPrice(element)">Edit</span> | -->
                        <span class="action-btn color-accept"
                            (click)="updateCounterPrice(element ,'APPROVED')">Approve</span> |
                        <span class="action-btn color-reject" (click)="reviewOrder(element, 'REJECTED')">Reject</span>
                    </span>
                    <span *ngIf="element.status === 'APPROVED' && checkPermission(OfferAction.review_season_offer)" class="action-btn color-accept">
                      {{element.status}}
                    </span>
                    <span *ngIf="element.status === 'REJECTED' && checkPermission(OfferAction.review_season_offer)" class="action-btn color-reject">
                        {{element.status}}
                      </span>
                    <span *ngIf="element.status === 'SUBMITTED' && !checkPermission(OfferAction.review_season_offer) && !element.is_counter_offer_set" class="action-btn color-accept">
                      {{element.status}}
                    </span>
                    <span *ngIf="element.status === 'APPROVED' && !checkPermission(OfferAction.review_season_offer)" class="action-btn color-accept">
                        {{element.status}}
                    </span>
                    <span *ngIf="element.status === 'REJECTED' && !checkPermission(OfferAction.review_season_offer)" class="action-btn color-reject">
                        <span class="action-btn" (click)="manageOfferDialog(element)">Edit</span> |
                        {{element.status}}
                    </span>
                    <span *ngIf="element.status === 'SUBMITTED' && checkPermission(OfferAction.review_season_counter_offer) && element.is_counter_offer_set" class="action-btn color-accept">
                        <span class="action-btn color-accept" (click)="reviewOrder(element ,'APPROVED')">Approve</span> |
                        <span class="action-btn color-reject"  (click)="reviewOrder(element, 'REJECTED')">Reject</span>
                    </span>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayed6RowColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayed6RowColumns;"></mat-row>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No Data Found</td>
            </tr>
        </mat-table>
        <mat-paginator
            [pageSizeOptions]="dataSource.data.length > 50 ? [10, 20, 50, dataSource.data.length] : [10, 20, 50]"
            showFirstLastButtons></mat-paginator>
    </div>
    <simple-notifications [options]="notificationOptions"></simple-notifications>
</div>