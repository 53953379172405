<h2 class="dialog-heading" *ngIf="title" mat-dialog-title>{{title}}</h2>
<mat-dialog-content style="width:500px">
    <form class="example-full-width" [formGroup]="storageForm">
        <mat-form-field class="example-full-width">
            <mat-label required>Type</mat-label>
            <mat-select class="matSelect" formControlName="type" required (selectionChange)="typeChange($event.value)">
                <mat-option *ngFor="let type of storageType" [value]="type">
                    {{type}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!storageForm.get('season').valid || storageForm.get('season').touched">Type is
                    required</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <mat-label required>Month</mat-label>
            <mat-select class="matSelect" formControlName="month" required>
                <mat-option *ngFor="let month of months" [value]="month">
                    {{month}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="storageForm.get('month').hasError('required')">Month is required</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <mat-label required>Year</mat-label>
            <mat-select class="matSelect" formControlName="year" required>
                <mat-option *ngFor="let year of years" [value]="year">
                    {{year}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="storageForm.get('year').hasError('required')">Year is required</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width" *ngIf="selectedOption == storageType[1]">
            <input formControlName="rate" placeholder="Rate*" matInput type='number' required>
            <mat-error>
                <span *ngIf="!storageForm.get('rate').valid || storageForm.get('rate').touched">Rate should 
                    be number greater than 0 and can be upto 2 decimal place</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width" *ngIf="selectedOption == storageType[0]">
            <input formControlName="cost" placeholder="Cost*" matInput type='number required'>
            <mat-error>
                <span *ngIf="!storageForm.get('cost').valid || storageForm.get('cost').touched">Cost should 
                    be number greater than 0 and can be upto 2 decimal place</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <mat-label required>Season</mat-label>
            <mat-select class="matSelect" formControlName="season" required>
                <mat-option *ngFor="let sea of season" [value]="sea">
                    <span *ngIf="sea == true">Season</span>
                    <span *ngIf="sea == false">Off Season</span>
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="!storageForm.get('season').valid || storageForm.get('season').touched">Season is
                    required</span>
            </mat-error>
        </mat-form-field>
    </form>
    <mat-dialog-actions>
        <button class="mat-raised-button mr-10" (click)="close()">Close</button>
        <button class="mat-raised-button mat-primary" (click)="saveStorage()">Save</button>
    </mat-dialog-actions>
</mat-dialog-content>