import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationsService } from 'angular2-notifications';
import { UbService } from 'src/app/services/ub.service';


@Component({
  selector: 'app-location-master-dialog',
  templateUrl: './location-master-dialog.component.html',
  styleUrls: ['./location-master-dialog.component.scss']
})
export class LocationMasterDialogComponent implements OnInit {

  dialogData: any;
  title: string;
  locationForm: FormGroup;
  locationData: any;
  loggedInUser: any;
  stateList: any;
  type: any;
  status = [
    {
      name: 'Active',
      id: 1
    },
    {
      name: 'InActive',
      id: 0
    }
  ];

  constructor(
    private service: UbService,
    private fb: FormBuilder,
    private notificationService: NotificationsService,
    private dialogRef: MatDialogRef<LocationMasterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.title = data.title;
    this.locationData = data.locationData;
    this.type = data.type;
  }

  ngOnInit(): void {
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    this.locationForm = this.fb.group({
      state: ['', [Validators.required]],
      district: ['', [Validators.required]],
      town_city: ['', [Validators.required]],
      pincode: ['', [Validators.required]],
      activity: ['', [Validators.required]]
    });
    this.locationForm.controls.activity.setValue(1);
    this.getState();
  }

  close() {
    this.dialogRef.close();
  }

  getState(){
    this.service.getState().subscribe((res) => {
      if (res['status']) {
        this.stateList = res['result'];
        this.stateList = this.stateList.filter(val => val.status == '1')
        if (this.locationData) {
          this.locationForm.controls['state'].setValue(this.locationData.state_id);
          this.locationForm.controls['district'].setValue(this.locationData.district);
          this.locationForm.controls['town_city'].setValue(this.locationData.town_city);
          this.locationForm.controls['pincode'].setValue(this.locationData.pincode);
          this.locationForm.controls['activity'].setValue(this.locationData.is_active);
        }
      }
    }, error => {
      this.notificationService.error('Error', error.error.message);
    });
  }

  saveLocations() {
    for (const c in this.locationForm.controls) {
      if (this.locationForm.controls.hasOwnProperty(c)) {
        this.locationForm.controls[c].markAsTouched();
      }
    }
    if (this.locationForm.valid) {
      const state = this.stateList.find( state => state.id === this.locationForm.value.state);
      const payload = {
        state: state.state_name,
        state_id: this.locationForm.value.state,
        district: this.locationForm.value.district,
        town_city: this.locationForm.value.town_city,
        pincode: this.locationForm.value.pincode,
        is_active: this.locationForm.value.activity,
      };

      if (this.type === 'add') {
        this.service.createLocations(payload).subscribe((res) => {
          if (res['status']) {
            this.notificationService.success('Success', res['message']);
            this.dialogRef.close();
          }
        }, error => {
          this.notificationService.error('Error', error.error.message);
        });
      } else if (this.type === 'edit') {

        this.service.updateLocations(this.locationData.id, payload).subscribe((res) => {
          if (res['status']) {
            this.notificationService.success('Success', res['message']);
            this.dialogRef.close();
          }
        }, error => {
          this.notificationService.error('Error', error.error.message);
        });

      }
    }
  }

}
