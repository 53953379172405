<h2 class="dialog-heading" *ngIf="title" mat-dialog-title>{{title}}</h2>
<mat-dialog-content style="width:500px">
    <form class="example-full-width" [formGroup]="productForm"  *ngIf="type === 'add'">
        <mat-form-field class="example-full-width">
            <mat-label>Name</mat-label>
            <input formControlName="name"  matInput type='text' required>
            <mat-error>
                <span *ngIf="!productForm.get('name').valid || productForm.get('name').touched">Product Name is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label>Description</mat-label>
            <input formControlName="description" matInput type='text' required>
            <mat-error>
                <span *ngIf="!productForm.get('description').valid || productForm.get('description').touched">Description is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label>Sap Material No</mat-label>
            <input formControlName="sap_material_no" matInput type='text' required > 
            <mat-error>
                <span *ngIf="!productForm.get('sap_material_no').valid || productForm.get('sap_material_no').touched">Sap Material No is required</span>
            </mat-error>
        </mat-form-field>
    </form>
    <mat-dialog-actions  *ngIf="type === 'add'">
            <button class="mat-raised-button mr-10" (click)="close()">Close</button>
            <button class="mat-raised-button mat-primary" (click)="addProduct()">Add</button>
    </mat-dialog-actions>

    <form class="example-full-width" [formGroup]="productForm"  *ngIf="type === 'edit'">
        <mat-form-field class="example-full-width">
            <mat-label>Name</mat-label>
            <input formControlName="name"  matInput type='text' required>
            <mat-error>
                <span *ngIf="!productForm.get('name').valid || productForm.get('name').touched">Product Name is required</span>
            </mat-error>
            </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label>Description</mat-label>
            <input formControlName="description" matInput type='text' required>
            <mat-error>
                <span *ngIf="!productForm.get('description').valid || productForm.get('description').touched">Description is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
        <mat-label>Sap Material No</mat-label>
            <input formControlName="sap_material_no" matInput type='text' required>
            <mat-error>
                <span *ngIf="!productForm.get('sap_material_no').valid || productForm.get('sap_material_no').touched">Sap Material No is required</span>
            </mat-error>
        </mat-form-field>
    </form>
    <mat-dialog-actions  *ngIf="type === 'edit'">
        <button class="mat-raised-button mr-10" (click)="close()">Close</button>
        <button class="mat-raised-button mat-primary" (click)="editProduct()">Update</button>
    </mat-dialog-actions>
</mat-dialog-content>