<h2 class="dialog-heading" *ngIf="title" mat-dialog-title>{{title}}</h2>
<mat-dialog-content style="width:500px">
    <form class="example-full-width" [formGroup]="mspForm"  *ngIf="type === 'add'">
        <mat-form-field class="example-full-width">
            <mat-label>Product</mat-label>
            <mat-select class="matSelect" formControlName="product" required>
                <mat-option *ngFor="let product of products" [value]="product.id">
                    {{product.name}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="mspForm.get('product').hasError('required')">Product is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label>State</mat-label>
            <mat-select class="matSelect" formControlName="state" required>
                <mat-option *ngFor="let state of states" [value]="state.id">
                    {{state.state_name}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="mspForm.get('state').hasError('required')">State is required</span>
            </mat-error>
    </mat-form-field>
        <mat-form-field class="example-full-width">
                <mat-label>MSP</mat-label>
            <input formControlName="msp" matInput type='text' required > 
            <mat-error>
                    <span *ngIf="mspForm.get('msp').hasError('required')">MSP is required</span>
                </mat-error>
                <mat-error>
                    <span *ngIf="mspForm.get('msp').hasError('pattern')">MSP should be a greater than 0 and should be upto 2 decimal place</span>
                </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label>Month</mat-label>
            <mat-select name="month" formControlName="month" required>
                <mat-option *ngFor="let month of months" [value]="month.month">
                  {{month.month}}
                </mat-option>
              </mat-select> 
              <mat-error>
                <span *ngIf="mspForm.get('month').hasError('required')">Month is required</span>
            </mat-error>
    </mat-form-field>
    <mat-form-field class="example-full-width">
        <mat-label>Year</mat-label>
    <input formControlName="year" matInput type='text' required> 
    <mat-error>
        <span *ngIf="mspForm.get('year').hasError('required')">Year is required</span>
    </mat-error>
    <mat-error>
        <span *ngIf="mspForm.get('year').hasError('pattern')">Year not valid</span>
    </mat-error>
</mat-form-field>
    </form>
    <mat-dialog-actions  *ngIf="type === 'add'">
            <button class="mat-raised-button mr-10" (click)="close()">Close</button>
            <button class="mat-raised-button mat-primary" (click)="addMspMaster()">Add</button>
    </mat-dialog-actions>

    <form class="example-full-width" [formGroup]="mspForm"  *ngIf="type === 'edit'">
        <mat-form-field class="example-full-width">
            <mat-label>Product</mat-label>
            <mat-select class="matSelect" formControlName="product" required>
                <mat-option *ngFor="let product of products" [value]="product.id">
                    {{product.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="example-full-width">
                <mat-label>State</mat-label>
                <mat-select class="matSelect" formControlName="state" required>
                    <mat-option *ngFor="let state of states" [value]="state.id">
                        {{state.state_name}}
                    </mat-option>
                </mat-select>
        </mat-form-field>
        <mat-form-field class="example-full-width">
                <mat-label>MSP</mat-label>
            <input formControlName="msp" matInput type='text' required > 
            <mat-error>
                <span *ngIf="mspForm.get('msp').hasError('required')">MSP is required</span>
            </mat-error>
            <mat-error>
                <span *ngIf="mspForm.get('msp').hasError('pattern')">MSP should be a greater than 0 and should be upto 2 decimal place</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label>Month</mat-label>
            <mat-select name="month" formControlName="month" required>
                <mat-option  *ngFor="let month of months" [value]="month.month">
                  {{month.month}}
                </mat-option>
              </mat-select> 
              <mat-error>
                <span *ngIf="mspForm.get('month').hasError('required')">Month is required</span>
            </mat-error>
    </mat-form-field>
    <mat-form-field class="example-full-width">
        <mat-label>Year</mat-label>
    <input formControlName="year" matInput type='text' required> 
    <mat-error>
        <span *ngIf="mspForm.get('year').hasError('required')">Year is required</span>
    </mat-error>
    <mat-error>
        <span *ngIf="mspForm.get('year').hasError('pattern')">Year not valid</span>
    </mat-error>
</mat-form-field>
    </form>
    <mat-dialog-actions  *ngIf="type === 'edit'">
        <button class="mat-raised-button mr-10" (click)="close()">Close</button>
        <button class="mat-raised-button mat-primary" (click)="editMspMaster()">Update</button>
    </mat-dialog-actions>
</mat-dialog-content>