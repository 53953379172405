import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NotificationsService } from 'angular2-notifications';
import { UbService } from 'src/app/services/ub.service';
import { UserRole } from 'src/app/models/schema';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { ExcelService } from 'src/app/services/excel.service';

const uniqueDistrictArr = (arrayData) => {
  return arrayData.reduce(function (a, d) {
    if (!a.find(val => val.District === d.District)) {
      a.push(d);
    }
    return a;
  }, []);
}

const uniqueStateArr = (arrayData) => {
  return arrayData.reduce(function (a, d) {
    if (!a.find(val => val.state_id === d.state_id)) {
      a.push(d);
    }
    return a;
  }, []);
}


@Component({
  selector: 'app-agmark-report',
  templateUrl: './agmark-report.component.html',
  styleUrls: ['./agmark-report.component.scss']
})
export class AgmarkReportComponent implements OnInit {
  @ViewChild('matRefState') matRefState;
  @ViewChild('matRefDistrict') matRefDistrict;
  @ViewChild('matRefCity') matRefCity;
  @ViewChild('matRefMandi') matRefMandi;
  @ViewChild('matRefDelivery') matRefDelivery;
  @ViewChild('matRefAratiya') matRefAratiya;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('uploadExcel') uploadExcel;

  states = new FormControl();
  filterForm: FormGroup;
  stateList = [];
  districtList = [];
  cityList = [];
  mandiList = [];
  stateListBack = [];
  districtListBack = [];
  cityListBack = [];
  mandiListBack = [];
  loading = false;
  loggedInUser: any;
  dataSource = new MatTableDataSource<any>();
  backupData: any;
  displayedColumns: any;
  deliveryList: any;
  userList: any;
  statusList = [{ title: 'All', value: 2 }, { title: 'Submitted', value: 1 }, { title: 'Pending', value: 0 }];

  district = new FormControl();
  city = new FormControl();
  mandi = new FormControl();

  min_price_from = new FormControl();
  min_price_to = new FormControl();

  max_price_from = new FormControl();
  max_price_to = new FormControl();
  status = new FormControl();

  document_date = new FormControl();
  documentDate = moment(new Date()).format('YYYY-MM-DD');
  all = 'All'
  public daterange: any = {
    start: this.documentDate,
    end: this.documentDate
  };

  public offerDateRange: any = {
    start: this.documentDate,
    end: this.documentDate
  };

  public options: any = {
    locale: { format: 'D MMMM YYYY' },
    alwaysShowCalendars: false,
  };
  // mandi = new FormControl();

  constructor(
    private service: UbService,
    private fb: FormBuilder,
    private _service: NotificationsService,
    private excelService: ExcelService
  ) {
    this.filterForm = this.fb.group({
      state: ['', [Validators.required]],
      district: ['', [Validators.required]],
      city: ['', [Validators.required]],
      mandi: ['', [Validators.required]],
      min_price_from: [''],
      min_price_to: [''],
      max_price_from: [''],
      max_price_to: [''],
      modal_price_from: [''],
      modal_price_to: [''],
      price_date: ['']
    });
    this.displayedColumns = ['state', 'district', 'city', 'market_name', 'min_price', 'max_price', 'modal_price',
      'price_date', 'created_by', 'creation_date'];
  }

  ngOnInit(): void {
    this.service.getLocations().subscribe((response: any) => {
      this.cityList = response.result;
      this.cityListBack = response.result;

      let state = uniqueStateArr(response.result)
      this.stateList = state;
      this.stateListBack = state;

      let district = uniqueDistrictArr(response.result)
      this.districtList = district;
      this.districtListBack = district;

    });

    this.service.getAllMandi().subscribe((response: any) => {
      this.mandiList = response.result;
      this.mandiListBack = response.result;
    });

    this.service.getAllDeliveryLocation().subscribe((response: any) => {
      console.log(response);
      this.deliveryList = response.result;
    });

    this.service.getUserDetails().subscribe((response: any) => {
      console.log(response);
      this.userList = response.result;
      this.userList = this.userList.filter(user => user.role_id == 2);
      console.log(this.userList);
    });

    this.filterForm.get('state').setValue([this.all]);
    this.filterForm.get('district').setValue([this.all]);
    this.filterForm.get('city').setValue([this.all]);
    this.filterForm.get('mandi').setValue([this.all]);
  }

  stateChange(element) {

    this.matRefDistrict.options.forEach((data) => data.deselect());
    this.matRefCity.options.forEach((data) => data.deselect());
    this.matRefMandi.options.forEach((data) => data.deselect());
    const locationIds = this.filterForm.value.state;
    if (locationIds && locationIds[0] != 'All') {
      this.districtList = this.districtListBack.filter((location) => locationIds.includes(location.state_id));
    }

    console.log('this.districtList -------->', locationIds, this.districtList)
  }

  districtChange(element) {
    this.matRefCity.options.forEach((data) => data.deselect());
    this.matRefMandi.options.forEach((data) => data.deselect());
    let locationIds: any = this.filterForm.value.district;
    if (locationIds && locationIds[0] != 'All') {
      this.cityList = this.cityListBack.filter((location) => locationIds.includes(location.District));
    }
    console.log('this.cityList -------->', locationIds, this.cityList)
  }

  cityChange(element) {
    let locationIds: any = this.filterForm.value.city;
    if (locationIds && locationIds[0] != 'All') {
      this.mandiList = this.mandiListBack.filter((location) => locationIds.includes(location.location_id));
    }

    console.log('this.mandiList -------->', locationIds, this.mandiList)
  }

  mandiChange(element) {
    console.log('elemnt-->', element);
    console.log('topings-->', this.filterForm.value.mandi);
  }

  stateSelChange(element) {
  }

  districtSelChange(elemnt) {
  }

  citySelChange(elemnt) {
  }

  mandiSelChange(elemnt) {
  }

  public selectOfferDate(value: any, datepicker?: any) {
    datepicker.start = value.start;
    datepicker.end = value.end;

    this.offerDateRange.start = moment(value.start).format('YYYY-MM-DD');
    this.offerDateRange.end = moment(value.end).format('YYYY-MM-DD');
    this.offerDateRange.label = value.label;
  }

  public selectedDate(value: any, datepicker?: any) {

    datepicker.start = value.start;
    datepicker.end = value.end;

    this.daterange.start = moment(value.start).format('YYYY-MM-DD');
    this.daterange.end = moment(value.end).format('YYYY-MM-DD');
    this.daterange.label = value.label;
  }

  reset() {
    this.matRefState.options.forEach((data) => data.deselect());
    this.matRefDistrict.options.forEach((data) => data.deselect());
    this.matRefCity.options.forEach((data) => data.deselect());
    this.matRefMandi.options.forEach((data) => data.deselect());
  }

  execute() {
    if (this.filterForm.valid) {
      const payload = {

        state: this.filterForm.value.state,
        district: this.filterForm.value.district,
        city: this.filterForm.value.city,
        market: this.filterForm.value.mandi,
        min_price_from: this.filterForm.value.min_price_from,
        min_price_to: this.filterForm.value.min_price_to,
        max_price_from: this.filterForm.value.max_price_from,
        max_price_to: this.filterForm.value.max_price_to,
        modal_price_from: this.filterForm.value.modal_price_from,
        modal_price_to: this.filterForm.value.modal_price_to,
        from_date: moment(new Date(this.daterange.start)).format('YYYY-MM-DD'),
        to_date: moment(new Date(this.daterange.end)).format('YYYY-MM-DD'),
      };

      this.loading = true;
      this.service.getAgmarkReport(payload).subscribe((response: any) => {

        if (response['result']) {
          this.dataSource.data = response.result;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        } else {
          this._service.error('Error', 'Data Fetiching Failed');
        }
        this.loading = false;

      }, error => {
        this._service.error('Error', error.error.message);
        this.loading = false;
      });
    }
  }

  exportAsXLSX() {
    const exportData = this.dataSource.data.map((val) => (
      {
        id: val.id,
        'State': val.state_name,
        'District': val.district_name,
        'City': val.city_name,
        'Market Name': val.market_center_name,
        'Min Price': val.ag_min,
        'Max Price': val.ag_max,
        'Modal Price': val.modal,
        'Price Date': val.price_date,
        'Created By': val.created_by,
        'Creation Date': val.createdAt
      }
    ));
    this.excelService.exportAsExcelFile(exportData, 'Agmark-Report');
  }

}
