import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuService } from '../services/menu.service';
import { UbService } from '../services/ub.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ExcelService } from '../services/excel.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ClusterMasterDialogComponent } from '../widgets/cluster-master-dialog/cluster-master-dialog.component';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-cluster-master',
  templateUrl: './cluster-master.component.html',
  styleUrls: ['./cluster-master.component.scss']
})
export class ClusterMasterComponent implements OnInit {
  loggedInUser;
  public notificationOptions: any = {
    position: ['bottom', 'right'],
    timeOut: 5000,
    lastOnBottom: true,
    pauseOnHover: true,
    clickToClose: true,
    animate: 'fromRight'
  };
  permission;
  loading = false;
  displayedColumns_data = ['name', 'description', 'action'];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(public menuService: MenuService,
    private service: UbService,
    private excelService: ExcelService,
    private router: Router,
    private notificationService: NotificationsService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    const menu = this.menuService.getMenu(this.router.url);
    this.permission = menu.actions;
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    this.getTableData();
  }

  checkPermission(access: string): boolean {
    return this.permission.includes(access);
  }

  addCluster() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Add Cluster',
      height: '800px',
      width: '300px',
      panelClass: 'addnew-custom-dialog',
      type: 'add'
    };
    const dialogRef = this.dialog.open(ClusterMasterDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.ngOnInit();
    })
  }

  editCluster(data) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Edit Cluster',
      height: '800px',
      width: '300px',
      panelClass: 'addnew-custom-dialog',
      type: 'edit',
      cluster_id: data.id
    };
    const dialogRef = this.dialog.open(ClusterMasterDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.ngOnInit();
    })
  }
  getTableData() {
    this.loading = true;
    this.service.getCluster().subscribe((response: any) => {
      if (response['status']) {
        this.dataSource.data = response.result;
      }
      this.dataSource.filterPredicate = (data: any, filter: string) => {
        return data.name?.toLocaleLowerCase().includes(filter) ||
        data.description.toLocaleLowerCase().includes(filter);
      };
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loading = false;
    }, err => {
      this.notificationService.error('Error', err.error.message);
    });
  }

  applyFilter(filterValue) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  exportAsXLSX(): void {
    const exportData = this.dataSource.data.map((val) => ({
      'Id': val.id,
      "Name": val.name,
      "Description": val.description,
    }));
    this.excelService.exportAsExcelFile(exportData, 'Cluster-Master-Data');
  }

}
