import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationsService } from 'angular2-notifications';
import { UbService } from 'src/app/services/ub.service';

@Component({
  selector: 'app-user-master-dialog',
  templateUrl: './user-master-dialog.component.html',
  styleUrls: ['./user-master-dialog.component.scss']
})
export class UserMasterDialogComponent implements OnInit {

  dialogData: any;
  title: string;
  userForm: FormGroup;
  role: any;
  userData: any;
  loggedInUser;
  is_ub_user: any;
  actionType: any;
  sapVendorCode: any;
  status = [
    {
      name: 'Active',
      id: '1'
    },
    {
      name: 'InActive',
      id: '0'
    }
  ];

  identity = [
    'Admin', 'Facilitator', 'Pukka Arthiya', 'UBP', 'Viewer'
  ];

  public notificationOptions: any = {
    position: ['bottom', 'right'],
    timeOut: 5000,
    lastOnBottom: true,
    pauseOnHover: true,
    clickToClose: true,
    animate: 'fromRight'
  };

  constructor(
    private service: UbService,
    private fb: FormBuilder,
    private notificationService: NotificationsService,
    private dialogRef: MatDialogRef<UserMasterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.title = data.title;
    this.userData = data;
    this.actionType = data.type;
  }

  ngOnInit(): void {
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    this.userForm = this.fb.group({
      user_id: ['', [Validators.required]],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      sap_vendor_code: [''],
      password: ['', [Validators.required]],
      mobile_1: ['', [Validators.required , Validators.pattern('[0-9]{10}')]],
      mobile_2: ['' , [Validators.pattern('[0-9]{10}')]],
      address_1: ['', [Validators.required]],
      address_2: [''],
      pincode: ['', []],
      gstin: ['', []],
      // roles: ['', [Validators.required]],
      role_id: ['', [Validators.required]],
      email_1: ['', [Validators.required , 
        Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      email_2: ['' ,[Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      active: ['', [Validators.required]],
      is_ub_user: ['false']
    });
    this.userForm.controls['active'].setValue('1');
    this.getSapVendorCode();
  }

  close() {
    this.dialogRef.close();
  }

  getSapVendorCode() {
    this.service.getAllSapVendorCode().subscribe((res) => {
      if (res['status'] == true) {
        this.sapVendorCode = res['result'];
        this.getRole();
      } else {
        this.notificationService.error('Error', res['message']);
      }
    }, error => {
      this.notificationService.error('Error', error.error.message);
    });
  }

  getRole() {
    this.service.getAllRoles().subscribe((res) => {
      if (res['status']) {
        this.role = res['result'];
        if (this.userData.userRowData) {
          this.userForm.controls['user_id'].setValue(this.userData.userRowData.user_id);
          this.userForm.controls['first_name'].setValue(this.userData.userRowData.first_name);
          this.userForm.controls['last_name'].setValue(this.userData.userRowData.last_name);
          this.userForm.controls['sap_vendor_code'].setValue(this.userData.userRowData.sap_vendor_code);
          this.userForm.controls['password'].setValue(this.userData.userRowData.password);
          this.userForm.controls['mobile_1'].setValue(this.userData.userRowData.mobile_1);
          this.userForm.controls['mobile_2'].setValue(this.userData.userRowData.mobile_2);
          this.userForm.controls['address_1'].setValue(this.userData.userRowData.address_1);
          this.userForm.controls['address_2'].setValue(this.userData.userRowData.address_2);
          this.userForm.controls['pincode'].setValue(this.userData.userRowData.pincode);
          this.userForm.controls['gstin'].setValue(this.userData.userRowData.gstin);
          // this.userForm.controls['roles'].setValue(this.userData.userRowData.roles);
          this.userForm.controls['role_id'].setValue(this.userData.userRowData.role_id);
          this.userForm.controls['email_1'].setValue(this.userData.userRowData.email_1);
          this.userForm.controls['email_2'].setValue(this.userData.userRowData.email_2);
          this.userForm.controls['is_ub_user'].setValue(this.userData.userRowData.is_ub_user || 'false');
          this.userForm.controls['active'].setValue(this.userData.userRowData.active);
        }
      }
    }, error => {
      this.notificationService.error('Error', error.error.message);
    });
  }

  saveUser() {
    for (const c in this.userForm.controls) {
      if (this.userForm.controls.hasOwnProperty(c)) {
        this.userForm.controls[c].markAsTouched();
      }
    }

    console.log('this.userForm------------>', this.userForm)
    if (this.userForm.valid) {
      const payload = {
        user_id: this.userForm.value.user_id,
        first_name: this.userForm.value.first_name,
        last_name: this.userForm.value.last_name,
        sap_vendor_code: this.userForm.value.sap_vendor_code || '',
        password: this.userForm.value.password,
        mobile_1: this.userForm.value.mobile_1,
        mobile_2: this.userForm.value.mobile_2,
        address_1: this.userForm.value.address_1,
        address_2: this.userForm.value.address_2,
        pincode: this.userForm.value.pincode,
        gstin: this.userForm.value.gstin,
        // roles: this.userForm.value.roles,
        role_id: this.userForm.value.role_id,
        email_1: this.userForm.value.email_1,
        email_2: this.userForm.value.email_2,
        active: this.userForm.value.active,
        is_ub_user: this.userForm.value.is_ub_user
      };

      if (this.userData.type === 'add') {
        this.service.createUser(payload).subscribe((res) => {
          if (res['status'] == true) {
            this.notificationService.success('Success', res['message']);
            this.dialogRef.close();
          } else {
            this.notificationService.error('Error', res['message']);
          }
        }, error => {
          this.notificationService.error('Error', error.error.message);
        });
      } else if (this.userData.type === 'edit') {
        delete payload.password;
        delete payload.user_id;
        this.service.updateUser(this.userData.userRowData.id, payload).subscribe((res) => {
          if (res['status'] == true) {
            this.notificationService.success('Success', res['message']);
            this.dialogRef.close();
          } else {
            this.notificationService.error('Error', res['message']);
          }
        }, error => {
          this.notificationService.error('Error', error.error.message);
        });

      }

    }
  }

}
