import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationsService } from 'angular2-notifications';
import { UbService } from 'src/app/services/ub.service';

@Component({
  selector: 'app-mandi-destination-dialog',
  templateUrl: './mandi-destination-dialog.component.html',
  styleUrls: ['./mandi-destination-dialog.component.scss']
})
export class MandiDestinationDialogComponent implements OnInit {
  title: string;
  mandiForm: FormGroup;
  mandiList = [];
  locationList = [];
  deliveryLocationList = [];
  loggedInUser;
  mandi_destination_id;
  type;
  activeList = [{ id : '1' , name : 'Active'} , {id : '0' , name : 'Inactive'}];

  constructor(
    private service: UbService,
    private fb: FormBuilder,
    private notificationService: NotificationsService,
    private dialogRef: MatDialogRef<MandiDestinationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) { 
    this.title = data.title;
    this.mandi_destination_id = data.mandi_destination_id;
    this.type = data.type;
  }

  ngOnInit(){
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    this.mandiForm = this.fb.group({
      mandi_id: ['', [Validators.required]],
      delivery_id: ['', [Validators.required]],
      active :  ['', [Validators.required]],
    }); 
    this.getAllMandis();
  }

  getAllMandis() {
    this.service.getAllMandi().subscribe((res) => {
      if (res['status']) {
        this.mandiList = res['result'];
        this.getAllDeliveryLocation()
      }
    } , error => {
      this.notificationService.error('Error', error.error.message);
    })
  }

  getAllDeliveryLocation() {
    this.deliveryLocationList = [];
    this.service.getAllDeliveryLocation().subscribe((res) => {
      if (res['status']) {
        this.deliveryLocationList = res['result'];
        if (this.mandi_destination_id) {
          this.getFormData(this.mandi_destination_id);
        }
      }
    }, error => {
      this.notificationService.error('Error', error.error.message);
    });
  }

  getFormData(id) {
    this.service.getMappedMandiDestination(id).subscribe((res) => {
      if (res['status']) {
        if (res['result']) {
          const data = res['result'];
          this.mandiForm.controls.mandi_id.setValue(data.mandi_id);
          this.mandiForm.controls.delivery_id.setValue(data.destination_id);
          this.mandiForm.controls.active.setValue(data.active); 
        }
      }
    } , error => {
      this.notificationService.error('Error', error.error.message);
    })
  }


  close() {
    this.dialogRef.close();
  }

  addMandiDestination() {
    for (const c in this.mandiForm.controls) {
      if (this.mandiForm.controls.hasOwnProperty(c)) {
        this.mandiForm.controls[c].markAsTouched();
      }
    }
    if (this.mandiForm.valid) {
      const payload = {
        created_by : this.loggedInUser.id,
        mandi_id : this.mandiForm.value.mandi_id,
        destination_id: this.mandiForm.value.delivery_id,
        active : this.mandiForm.value.active
      }
      this.service.mapMandiDestination(payload).subscribe((res) => {
        if (res['status']) {
          this.notificationService.success('Success', 'Mandi Destination Mapped Successfully');
          this.dialogRef.close();
        }
      } , error => {
        this.notificationService.error('Error', error.error.message);
      });
    }
  }

  editMandiDestination() {
    for (const c in this.mandiForm.controls) {
      if (this.mandiForm.controls.hasOwnProperty(c)) {
        this.mandiForm.controls[c].markAsTouched();
      }
    }
    if (this.mandiForm.valid) {
      const payload = {
        created_by : this.loggedInUser.id,
        mandi_id : this.mandiForm.value.mandi_id,
        destination_id: this.mandiForm.value.delivery_id,
        active : this.mandiForm.value.active
      }
      this.service.updateMandiDestination(payload, this.mandi_destination_id).subscribe((res) => {
        if (res['status']) {
          this.notificationService.success('Success', 'Mandi Destination Mapping Updated Successfully');
          this.dialogRef.close();
        }
      } , error => {
        this.notificationService.error('Error', error.error.message);
      });
    }
  }

}
