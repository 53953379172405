import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationsService } from 'angular2-notifications';
import { UbService } from 'src/app/services/ub.service';

@Component({
  selector: 'app-upper-limit-of-ceiling-price-dialog',
  templateUrl: './upper-limit-of-ceiling-price-dialog.component.html',
  styleUrls: ['./upper-limit-of-ceiling-price-dialog.component.scss']
})
export class UpperLimitOfCeilingPriceDialogComponent implements OnInit {

  title: string;
  hoLimitForm: FormGroup;
  hoLimit_id;
  type;
  loggedInUser;
  types=['Six_Row_Offer','Six_Row','Two_Row'];
  isActive=[
    { value:true, name:'Active'},
    { value:false, name:'InActive'}
  ];
  months=[1,2,3,4,5,6,7,8,9,10,11,12];

  constructor(
    private service: UbService,
    private fb: FormBuilder,
    private notificationService: NotificationsService,
    private dialogRef: MatDialogRef<UpperLimitOfCeilingPriceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) { 
    this.title = data.title;
    this.hoLimit_id = data.hoLimit_id;
    this.type = data.type;
  }

  ngOnInit(){
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    this.hoLimitForm = this.fb.group({
      upper_limit: ['',[Validators.required,Validators.pattern('^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$')]],
      type: ['', [Validators.required]],
      year: ['', [Validators.required,Validators.pattern('^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$')]],
      month: ['', [Validators.required,Validators.pattern('^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$')]],
       active :  ['', [Validators.required]]
    });
    this.hoLimitForm.controls.active.setValue(true);
    if(this.hoLimit_id){
      this.getHoLimitData(this.hoLimit_id);
    }
  }
  

  getHoLimitData(id) {
    this.service.getHoLimitByID(id).subscribe((res) => {
      if (res['status']) {
        const data = res['result'];
        this.hoLimitForm.controls.upper_limit.setValue(data.upper_limit);
        this.hoLimitForm.controls.type.setValue(data.type);
        this.hoLimitForm.controls.year.setValue(data.year);
        this.hoLimitForm.controls.month.setValue(data.month);
        this.hoLimitForm.controls.active.setValue(data.is_active);
      }
    } , err => {})
  }


  close() {
    this.dialogRef.close();
  }

  addHoLimit() {
    for (const c in this.hoLimitForm.controls) {
      if (this.hoLimitForm.controls.hasOwnProperty(c)) {
        this.hoLimitForm.controls[c].markAsTouched();
      }
    }
    if (this.hoLimitForm.valid) {
      const payload = {
        "upper_limit": this.hoLimitForm.value.upper_limit,
        "type": this.hoLimitForm.value.type,
        "year": this.hoLimitForm.value.year,
        "month": this.hoLimitForm.value.month,
        "is_active": this.hoLimitForm.value.active,
      }
      this.service.addHoLimit(payload).subscribe((res) => {
        this.notificationService.success('Success', 'HO Limigt Added Successfully');
        this.dialogRef.close();
      }, error => {
        this.notificationService.error('Error', error.error.message);
        // this.dialogRef.close();
      });
    }
  }

  editHoLimit() {
    for (const c in this.hoLimitForm.controls) {
      if (this.hoLimitForm.controls.hasOwnProperty(c)) {
        this.hoLimitForm.controls[c].markAsTouched();
      }
    }   
    if (this.hoLimitForm.valid) {
      const payload = {
        "upper_limit": this.hoLimitForm.value.upper_limit,
        "type": this.hoLimitForm.value.type,
        "year": this.hoLimitForm.value.year,
        "month": this.hoLimitForm.value.month,
        "is_active": this.hoLimitForm.value.active,
      }
      this.service.updateHoLimit(this.hoLimit_id, payload).subscribe((res) => {
        this.notificationService.success('Success', 'HO Limit Udapted Successfully');
        this.dialogRef.close();
      }, error => {
        this.notificationService.error('Error', error.error.message);
        // this.dialogRef.close();
      });
    }
  }

}
