import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationsService } from 'angular2-notifications';
import { UbService } from 'src/app/services/ub.service';
import * as moment from 'moment';

@Component({
  selector: 'app-cluster-freight-master-dialog',
  templateUrl: './cluster-freight-master-dialog.component.html',
  styleUrls: ['./cluster-freight-master-dialog.component.scss']
})
export class ClusterFreightMasterDialogComponent implements OnInit {

  title: string;
  forFreightForm: FormGroup;
  clusterList = [];
  locationList = [];
  deliveryLocationList = [];
  loggedInUser: any;
  clusterFreight: any;
  type: any;

  constructor(
    private service: UbService,
    private fb: FormBuilder,
    private notificationService: NotificationsService,
    private dialogRef: MatDialogRef<ClusterFreightMasterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.title = data.title;
    this.clusterFreight = data;
    this.type = data.type;
    if (this.clusterFreight.clusterRowData) {
      this.getAllDeliveryLocation(this.clusterFreight.clusterRowData.cluster_id);
    }
  }

  ngOnInit(): void {
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    this.forFreightForm = this.fb.group({
      cluster_id: ['', [Validators.required]],
      delivery_id: ['', [Validators.required]],
      group_id :  ['', [Validators.required]],
      freight_value :  ['', [Validators.required , Validators.pattern('^[1-9]+[0-9]*(\.?[0-9]{1,2}$)?')]],
      valid_from: ['', [Validators.required]],
      valid_to: ['', [Validators.required]]
    });
    this.getAllMandis();
    this.forFreightForm.controls.cluster_id.valueChanges.subscribe((result) => {
      this.getAllDeliveryLocation(result);
    });
  }

  getAllMandis() {
    this.service.getAllCluster().subscribe((res) => {
      if (res['status']) {
        this.clusterList = res['result'];
        console.log('this.clusterFreight.clusterRowData------------>', this.clusterFreight.clusterRowData)
        if (this.clusterFreight.clusterRowData) {
          this.forFreightForm.controls.cluster_id.setValue(this.clusterFreight.clusterRowData.cluster_id);
          this.forFreightForm.controls.delivery_id.setValue(this.clusterFreight.clusterRowData.destination_id);
          this.forFreightForm.controls.group_id.setValue(this.clusterFreight.clusterRowData.gid);
          this.forFreightForm.controls.valid_to.setValue(this.clusterFreight.clusterRowData.valid_to);
          this.forFreightForm.controls.freight_value.setValue(this.clusterFreight.clusterRowData.freight_value);
          this.forFreightForm.controls.valid_from.setValue(this.clusterFreight.clusterRowData.valid_from);
        }
      }
    } , error => {
      this.notificationService.error('Error', error.error.message);
    });
  }

  getAllDeliveryLocation(result) {
    this.deliveryLocationList = [];
    this.service.getDeliveryDetails().subscribe((res) => {
      if (res['status']) {
        this.deliveryLocationList = res['result'];
      }
    }, error => {
      this.notificationService.error('Error', error.error.message);
    });
  }


  close() {
    this.dialogRef.close();
  }


  saveForFreight() {
    for (const c in this.forFreightForm.controls) {
      if (this.forFreightForm.controls.hasOwnProperty(c)) {
        this.forFreightForm.controls[c].markAsTouched();
      }
    }
    if (this.forFreightForm.valid) {
      const payload = {
        cluster_id : this.forFreightForm.value.cluster_id,
        destination_id : this.forFreightForm.value.delivery_id,
        freight_value : this.forFreightForm.value.freight_value,
        gid : this.forFreightForm.value.group_id,
        valid_from : moment(new Date(this.forFreightForm.value.valid_from)).format('YYYY-MM-DD'),
        valid_to : moment(new Date(this.forFreightForm.value.valid_to)).format('YYYY-MM-DD')
      };

      if (this.type === 'add') {
        this.service.addClusterFreight(payload).subscribe((res) => {
          if (res['status']) {
            this.notificationService.success('Success', res['message']);
            this.dialogRef.close();
          }
        } , error => {
          this.notificationService.error('Error', error.error.message);
        });
      } else if (this.type === 'edit') {

        this.service.updateClusterFreight(this.clusterFreight.clusterRowData.id, payload).subscribe((res) => {
          if (res['status']) {
            this.notificationService.success('Success', res['message']);
            this.dialogRef.close();
          }
        } , error => {
          this.notificationService.error('Error', error.error.message);
        });

      }
    }
  }


}
