<div class="container-fluid">
    <div class="tabContentBlock">
        <div class="row pageHeader">
            Delivery Master Details
        </div>

        <div class="row"  style="margin-top: 12px;">
            <div class="col-md-6 pl-0">
                <mat-form-field class="cusWidth">
                    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
                    <button mat-icon-button matPrefix>
                        <mat-icon class="primColor">search</mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <div class="col-md-6 buttonAlignRight tabBtnAlign">
                <button class="mat-raised-button mat-button-color mr-10 exportBtn" (click)="exportAsXLSX()"> 
                    <fa-icon [icon]="['fas', 'download']" style="margin-right: 10px;"></fa-icon>Export Excel</button>
                <button class="mat-raised-button mat-primary exportBtn" (click)="addDelivery()"> Add Record</button>
            </div>
        </div>
        <div class="row" style="padding: 20px 0px 20px;">
            <!-- <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar> -->
            <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

                <ng-container matColumnDef="delivery_type">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="wd-100"> Delivery Type </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="wd-100"> {{element.delivery_type}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="delivery_name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="wd-100"> Delivery Name</mat-header-cell>
                    <mat-cell *matCellDef="let element" class="wd-100"> {{element.delivery_name}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="short_name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="wd-100"> Short Name</mat-header-cell>
                    <mat-cell *matCellDef="let element" class="wd-100"> {{element.short_name}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="address_1">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="wd-200">Address 1</mat-header-cell>
                    <mat-cell *matCellDef="let element" class="wd-200"> {{element.address_1}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="address_2">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="wd-200"> Address 2 </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="wd-200"> {{element.address_2}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="pincode">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="wd-100"> Pincode</mat-header-cell>
                    <mat-cell *matCellDef="let element" class="wd-100"> {{element.pincode}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="city">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="wd-100"> City </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="wd-100"> {{element.city}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="district">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="wd-100"> District </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="wd-100"> {{element.district}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="state">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="wd-100"> State </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="wd-100"> {{element.state}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="mobile_1">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="wd-100"> Mobile 1 </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="wd-100"> {{element.mobile_1}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="mobile_2">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="wd-100"> Mobile 2 </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="wd-100"> {{element.mobile_2}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="email_1">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="wd-100"> Email 1 </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="wd-100"> {{element.email_1}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="email_2">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="wd-100"> Email 2 </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="wd-100"> {{element.email_2}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="gstin">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="wd-200"> GSTIN </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="wd-200"> {{element.gstin}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="action">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="wd-90">Action</mat-header-cell>
                    <mat-cell *matCellDef="let element" class="wd-90">
                        <div class="actionRowBlock">
                            <a class="mr-10" (click)="editRow(element)">
                                Edit
                            </a>
                        </div>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayRowColums"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayRowColums;"></mat-row>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No Data Found on {{documentDate}}</td>
                </tr>
            </mat-table>
            <mat-paginator [pageSizeOptions]="dataSource.data.length > 50 ? [10, 20, 50, dataSource.data.length] : [10, 20, 50]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
    <simple-notifications [options]="notificationOptions"></simple-notifications>
</div>