import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { UbService } from '../services/ub.service';
import { NotificationsService } from 'angular2-notifications';
// import {WindowService } from '../window.service';
// import * as firebase from 'firebase';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  phno = 7795652826;
  loginForm: FormGroup;
  phnoEntered;
  error = false;
  showOtp = false;
  windowRef: any;
  submitted = false;
  loginUb = false;
  loginUser = false;
  user;
  slides = [
    {
      image: 'assets/images/banners/barley-banner1.jpg',
      title: 'caption',
      text: 'text test1'
    },
    {
      image: 'assets/images/banners/barley-banner2.jpg',
      title: 'caption2',
      text: 'text test2'
    },
    {
      image: 'assets/images/banners/barley-banner3.jpg',
      title: 'caption3',
      text: 'text test3'
    }
  ];
  public settings = {
    length: 4,
    numbersOnly: true,
    timer: 12,
    btnClass: 'mt-5'
  };

  public notificationOptions: any = {
    position: ['bottom', 'right'],
    timeOut: 5000,
    lastOnBottom: true,
    pauseOnHover: true,
    clickToClose: true,
    animate: 'fromRight'
  };

  UBLoginUrl;

  constructor(private router: Router, private formBuilder: FormBuilder, private http: HttpClient, private service: UbService,
    private _service: NotificationsService
    //  private windowService: WindowService
  ) {
    // this.windowRef = this.windowService.windowRef;
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      name: [null, Validators.required],
      password: [null, [Validators.required]],
    });
    this.UBLoginUrl = this.service.BASEURL + 'login';
  }

  checkPassword(control) {
    const enteredPassword = control.value;
    const passwordCheck = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    return (!passwordCheck.test(enteredPassword) && enteredPassword) ? { requirements: true } : null;
  }

  get f() { return this.loginForm.controls; }

  login() {
    this.submitted = true;
    if (this.loginForm.valid) {
      this.loginUser = true;
      const sendData = {
        user_id: this.loginForm.value.name,
        password: this.loginForm.value.password
      };
      this.service.loginUser(sendData).subscribe(response => {
        const result = <any>response;
        if (result && result.user) {
          let permissions = result.user.permissions;
          if (permissions.length > 0) {
            permissions = permissions.filter((elem) => elem.device_type.toLowerCase() === 'w');
          }
          localStorage.setItem('menuItems', JSON.stringify(permissions));

          localStorage.setItem('ubtoken', JSON.stringify(result.token));
          const name = result.user.first_name + result.user.last_name;
          this.service.loginEvent(name);
          const user = {
            id: result.user.id,
            user_id: result.user.user_id,
            email_1: result.user.email_1,
            roles_id: result.user.roles_id,
            role: result.user.role.role
          };
          localStorage.setItem('ubuser', JSON.stringify(user));
          this.router.navigate(['/dashboard']);
        } else {
          this.loginUser = false;
          this._service.error('Login Failed', result.message );
        }
      }, error => {
        console.log(error);
        this.loginUser = false;
        this._service.error('Login Failed', '' );
      });
    }
  }

  resetPassword() {
    this.router.navigate(['resetpassword']);
  }

  loginUBUser() {
    this.service.login().subscribe((res) => {
      console.log(res);
      window.open(res.url , "_self");
    })
  }
}
