<h2 class="dialog-heading" *ngIf="title" mat-dialog-title>{{title}}</h2>
<mat-dialog-content style="width:500px">
    <form class="example-full-width" [formGroup]="premiumForm"  *ngIf="type === 'add'">
        <mat-form-field class="example-full-width">
            <mat-label>Product</mat-label>
            <mat-select class="matSelect" formControlName="product_name" required>
                <mat-option *ngFor="let product of products" [value]="product.id">
                    {{product.name}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="premiumForm.get('product_name').hasError('required')">Product is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label>State</mat-label>
            <mat-select class="matSelect" formControlName="state_name" required>
                <mat-option *ngFor="let state of states" [value]="state.id">
                    {{state.state_name}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="premiumForm.get('state_name').hasError('required')">State is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label>Premium</mat-label>
            <input formControlName="premium" matInput type='text' required>
            <mat-error>
                <span *ngIf="premiumForm.get('premium').hasError('required')">Premium is required</span>
            </mat-error>
            <mat-error>
                <span *ngIf="premiumForm.get('premium').hasError('pattern')">Premium should be a number greater than 0 and should be upto 2 decimal place</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label>Year</mat-label>
            <input formControlName="year" matInput type='text' required> 
            <mat-error>
                <span *ngIf="premiumForm.get('year').hasError('required')">Year is required</span>
            </mat-error>
            <mat-error>
                <span *ngIf="premiumForm.get('year').hasError('pattern')">Year not valid</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label>Month</mat-label>
            <mat-select name="month" formControlName="month" required>
                <mat-option *ngFor="let month of months" [value]="month.month">
                  {{month.month}}
                </mat-option>
            </mat-select> 
        </mat-form-field>
    </form>
    <mat-dialog-actions  *ngIf="type === 'add'">
            <button class="mat-raised-button mr-10" (click)="close()">Close</button>
            <button class="mat-raised-button mat-primary" (click)="addProduct()">Add</button>
    </mat-dialog-actions>

    <form class="example-full-width" [formGroup]="premiumForm"  *ngIf="type === 'edit'">
        <mat-form-field class="example-full-width">
            <mat-label>Product</mat-label>
            <mat-select class="matSelect" formControlName="product_name" required>
                <mat-option *ngFor="let product of products" [value]="product.id">
                    {{product.name}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="premiumForm.get('product_name').hasError('required')">Product is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label>State</mat-label>
            <mat-select class="matSelect" formControlName="state_name" required>
                <mat-option *ngFor="let state of states" [value]="state.id">
                    {{state.state_name}}
                </mat-option>
            </mat-select>
            <mat-error>
                <span *ngIf="premiumForm.get('product_name').hasError('required')">Product is required</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label>Premium</mat-label>
            <input formControlName="premium" matInput type='text' required>
            <mat-error>
                <span *ngIf="premiumForm.get('premium').hasError('required')">Premium is required</span>
            </mat-error>
            <mat-error>
                <span *ngIf="premiumForm.get('premium').hasError('pattern')">Premium should be a number greater than 0 and should be upto 2 decimal place</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label>Year</mat-label>
        <input formControlName="year" matInput type='text' required> 
        <mat-error>
            <span *ngIf="premiumForm.get('year').hasError('required')">Year is required</span>
        </mat-error>
        <mat-error>
            <span *ngIf="premiumForm.get('year').hasError('pattern')">Year not valid</span>
        </mat-error>
    </mat-form-field>
    <mat-form-field class="example-full-width">
            <mat-label>Month</mat-label>
            <mat-select name="month" formControlName="month" required>
                <mat-option *ngFor="let month of months" [value]="month.month">
                  {{month.month}}
                </mat-option>
              </mat-select> 
    </mat-form-field>
    </form>
    <mat-dialog-actions  *ngIf="type === 'edit'">
        <button class="mat-raised-button mr-10" (click)="close()">Close</button>
        <button class="mat-raised-button mat-primary" (click)="editProduct()">Update</button>
    </mat-dialog-actions>
</mat-dialog-content>