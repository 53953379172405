import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuService } from '../services/menu.service';
import { UbService } from '../services/ub.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ExcelService } from '../services/excel.service';
import { FormControl } from '@angular/forms';
import { NotificationsService } from 'angular2-notifications';
import { AuctionPriceViewText, UserRole, AuctionPriceViewAction } from '../models/schema';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-auction-price-view',
  templateUrl: './auction-price-view.component.html',
  styleUrls: ['./auction-price-view.component.scss']
})
export class AuctionPriceViewComponent implements OnInit {
  AuctionPriceViewText = AuctionPriceViewText;
  documentDate = new Date().toISOString().slice(0, 10).replace('T', ' ');
  date = new Date().toISOString().slice(0, 16);
  startDate = moment().subtract(1, "days").format("YYYY-MM-DD");
  endDate = moment().format("YYYY-MM-DD");

  public daterange: any = {
    start: this.startDate,
    end: this.endDate,
  };
  todayDate = new Date();
  public options: any = {
    locale: { format: 'D MMMM YYYY' },
    alwaysShowCalendars: false,
    maxDate: new Date(),
    startDate: moment().subtract(1, "days").format("D MMMM YYYY"),
    endDate: moment().format("D MMMM YYYY"),
  };
  displayedColumns_6RowBarley = ['mandi_name', 'min_price', 'max_price', 'quantity', 'avg_buying_price', 'modal_price', 'suggested_price', 'ceiling_price', 'created_at', 'created_by'];
  displayedColumns_2and6RowBarley: any; //['mandi_name', 'min_price', 'max_price', 'quantity', 'avg_buying_price', 'modal_price', 'suggested_price', 'ceiling_price', 'created_at', 'created_by'];
  displayedColumns_2RowBarley = ['mandi_name', 'state', 'one_price', 'quantity', 'avg_buying_price', 'modal_price', 'suggested_price', 'ceiling_price', 'created_at', 'created_by'];
  dataSource = new MatTableDataSource<any>();
  loggedInUser: any;
  backupData: any;
  productType = [];
  isProductType: string = '';
  mandiList;
  mandisName: any;
  permission: any;
  loading = false;
  mandis = new FormControl();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  mandi_id;
  dataCopy = [];

  constructor(public menuService: MenuService,
    private service: UbService,
    private router: Router,
    private notification: NotificationsService,
    private excelService: ExcelService) {
      this.dataSource.filterPredicate = (data: any, filter: string) => {
          return data.min_price?.toString().includes(filter) ||
            data.mandiObj.mandi_name.toLocaleLowerCase().includes(filter) ||
            data.max_price?.toString().includes(filter) ||
            data.one_price?.toString().includes(filter) ||
            data.stateObj?.state_name.toLocaleLowerCase().includes(filter) ||
            data.is_submitted.toString().includes(filter) ||
            data.document_date.includes(filter);
        
      };
     }

  ngOnInit(): void {
    const user: any = localStorage.getItem('ubuser');
    this.loggedInUser = JSON.parse(user);
    const menu = this.menuService.getMenu(this.router.url);
    this.permission = menu.actions;

    
    if (this.checkPermission(AuctionPriceViewAction.view_6_row)) {
      this.productType.push(AuctionPriceViewText.Barley_6_Row)
    }

    if (this.checkPermission(AuctionPriceViewAction.view_2_row)) {
      this.isProductType = AuctionPriceViewText.Barley_2_Row
      this.productType.push(AuctionPriceViewText.Barley_2_Row);
    }

    if (this.checkPermission(AuctionPriceViewAction.view_6_row)) {
      this.isProductType = AuctionPriceViewText.Barley_6_Row;
    }

    this.getTableData();
    this.service.getAllMandi().subscribe((response: any) => {
      this.mandiList = response.result;
      // this.getMandiName(); 
    }, error => {
      this.notification.error('Error', error.error.message);
    });


  }

  checkPermission(access: string): boolean {
    return this.permission.includes(access);
  }

  getMandiName() {
    for (let i = 0; i < this.mandiList.size; i++) {
      let array1 = this.mandiList[i].mandi_name;
      let count = 0;
      this.mandisName[count++] = array1;
    }
    //   console.log(this.mandisName); 
  }

  getTableData() {
    this.dataSource.data = [];
    let filter = '';
    if (this.loggedInUser.role === UserRole.Facilitator) {
      filter = 'user_id=' + this.loggedInUser.id + 'from_date=' + this.startDate + '&to_date=' + this.endDate;
    } else {
      filter = '&from_date=' + this.startDate + '&to_date=' + this.endDate;
    }
    if (this.isProductType === AuctionPriceViewText.Barley_6_Row) {
      this.displayedColumns_2and6RowBarley = this.displayedColumns_6RowBarley
      this.loading = true
      this.service.getArrivalData(filter).subscribe((response: any) => {
        if (response['status']) {
          this.dataSource.data = response.result;
          this.dataCopy = [...this.dataSource.data];
        }
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loading = false
      }, error => {
        this.loading = false
        this.notification.error('Error', error.error.message);
      });
    } else if (this.isProductType === AuctionPriceViewText.Barley_2_Row) {
      this.displayedColumns_2and6RowBarley = this.displayedColumns_2RowBarley
      this.loading = true
      this.service.get2RowArrivalData(filter).subscribe((response: any) => {
        if (response['status']) {
          this.dataSource.data = response.result;
          this.dataCopy = [...this.dataSource.data];
        }
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loading = false
      }, error => {
        this.loading = false
        this.notification.error('Error', error.error.message);
      });
    }
  }

  public selectedDate(value: any, datepicker?: any) {
    this.startDate = new Date(value.start).toDateString();
    this.endDate = new Date(value.end).toDateString();
    this.getTableData()
  }

  applyFilter(filterValue) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getData(product) {
    this.isProductType = product;
    this.getTableData();
  }

  changeMandi(event) {
    this.dataSource.data = [];
    for (const data of event.value) {
      const arrivalData = this.dataCopy.filter((itm) => itm.mandi_id === data);
      if (arrivalData.length > 0) {
        this.dataSource.data = [...this.dataSource.data, arrivalData[0]];
      }
    }
    if (event.value.length === 0) {
      this.dataSource.data = [...this.dataCopy];
    }
  }

  exportAsXLSX(): void {
    if (this.isProductType === AuctionPriceViewText.Barley_6_Row) {
      const exportData = this.dataSource.data.map((val) => ({
        id: val.id,
        "Mandi Name": val.mandiObj.mandi_name,
        "Minimum Arrival Price": val.min_price,
        "Maximum Arrival Price": val.max_price,
        "Arrival Quantity": val.quantity,
        "Avg Buying Price": val.avg_buying_price,
        "Modal Price": val.modal_price,
        "Suggested Price": val.suggested_price,
        "Ceiling Price": val.ceiling_price,
        "Created at": val.created_at,
        "Date": val.date,
        "Created By": val.created_by,
        "Submitted": val.is_submitted
      }));
      this.excelService.exportAsExcelFile(exportData, '6-row-barley-auction-data');
    }
    else if (this.isProductType === AuctionPriceViewText.Barley_2_Row) {
      const exportData = this.dataSource.data.map((val) => (
        {
          id: val.id,
          "Mandi Name": val.mandiObj.mandi_name,
          "Document Date": val.document_date,
          "Minimum Arrival Price": val.min_price,
          "Maximum Arrival Price": val.max_price,
          "Arrival Quantity": val.quantity,
          "Avg Buying Price": val.avg_buying_price,
          "Modal Price": val.modal_price,
          "Suggested Price": val.suggested_price,
          "Ceiling Price": val.ceiling_price,
          "Created at": val.created_at,
          "Date": val.date,
          "Created By": val.created_by,
          "Submitted": val.is_submitted
        }));
      this.excelService.exportAsExcelFile(exportData, '2-row-barley-auction-data');
    }
  }
}
