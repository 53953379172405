import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { ExcelService } from '../services/excel.service';
import { MenuService } from '../services/menu.service';
import { UbService } from '../services/ub.service';
import * as moment from 'moment';
import { ForFreightDialogComponent } from '../widgets/for-freight-dialog/for-freight-dialog.component';

@Component({
  selector: 'app-for-freight-master',
  templateUrl: './for-freight-master.component.html',
  styleUrls: ['./for-freight-master.component.scss']
})

export class ForFreightMasterComponent implements OnInit {
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayColumns = ['mandi_name', 'delivery_name' , 'group_id' , 'freight_value' ,  'valid_from' , 'valid_to'  , 'action'];
  currentPage = 0;
  public notificationOptions: any = {
    position: ['bottom', 'right'],
    timeOut: 5000,
    lastOnBottom: true,
    pauseOnHover: true,
    clickToClose: true,
    animate: 'fromRight'
  };
  permission;
  loading = false;
  constructor(
    private service: UbService,
    private _service: NotificationsService,
    private excelService: ExcelService,
    private dialog: MatDialog,
    private menuService: MenuService,
    private router: Router
  ) { 
  }

  ngOnInit(){
    const menu = this.menuService.getMenu(this.router.url);
    this.permission = menu.actions;
    this.loading = true;
    this.service.getAllForFreightData().subscribe((response: any) => {
      this.loading = false;
      if (response['status']) {
        for (const data of response.result) {
          data.mandi_name = data.mandiObj?.mandi_name;
          data.delivery_name = data.destinationObj?.delivery_name;
          data.valid_from = data.valid_from ? moment(new Date(data.valid_from)).format('YYYY-MM-DD') : '';
          data.valid_to = data.valid_to ? moment(new Date(data.valid_to)).format('YYYY-MM-DD') : '';
        }
        response.result = response.result.sort((a, b ) => b.id - a.id);
        this.dataSource.data = response.result;
        this.dataSource.filterPredicate = (data: any, filter: string) => {
          return  data.mandi_name?.toLocaleLowerCase().includes(filter) ||
            data.delivery_name?.toLocaleLowerCase().includes(filter) ||
            data.gid?.toLocaleLowerCase().includes(filter) ||
            data.freight_value?.toString().toLocaleLowerCase().includes(filter) ||
            data.valid_from?.toLocaleLowerCase().includes(filter) ||
            data.valid_to?.toLocaleLowerCase().includes(filter)
        };
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      
    }, error => {
      this._service.error('Error', error.error.message);
      this.loading = false;
    });
  }

  checkPermission(access: string): boolean {
    return this.permission.includes(access);
  }

  applyFilter(filterValue) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  exportAsXLSX(): void {
    const exportData = this.dataSource.data.map((val) => (
      {
        'Id': val.id,
        'Mandi Name': val.mandi_name,
        'Delivery Location' : val.delivery_name,
        'Group ID' : val.group_id,
        'Freight Value' : val.freight_value,
        'Valid From' : val.valid_from,
        'Valid To' : val.valid_to
      }
    ));
    this.excelService.exportAsExcelFile(exportData, 'For-Freight');
  }

  mapMandiDestination() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Add For Freight Details',
      height: '800px',
      width: '300px',
      panelClass: 'addnew-custom-dialog',
      type : 'add'
    };
    const dialogRef = this.dialog.open(ForFreightDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.ngOnInit();
    })
  }

  editMandi(data) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Update For Freight Details',
      height: '800px',
      width: '300px',
      panelClass: 'addnew-custom-dialog',
      type : 'edit',
      forfreight_id : data.id
    };
    const dialogRef = this.dialog.open(ForFreightDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      this.ngOnInit();
    })
  }

  onPageChange(currentPage) {
    console.log(currentPage);
  }

}
