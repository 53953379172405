<div class="container-fluid">
    <div class="tabContentBlock">
        <div class="row pageHeader">
            Mandi Details
        </div>

        <div class="row" style="margin-top: 12px;">
            <div class="col-md-6 pl-0">
                <mat-form-field class="cusWidth">
                    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
                    <button mat-icon-button matPrefix>
                        <mat-icon class="primColor">search</mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <div class="col-md-6 buttonAlignRight tabBtnAlign">
                <button class="mat-raised-button mat-button-color mr-10 exportBtn" (click)="exportAsXLSX()"> 
                  <fa-icon [icon]="['fas', 'download']" style="margin-right: 10px;"></fa-icon>Export Excel</button>
                <button class="mat-raised-button mat-primary exportBtn" (click)="addMandi()"> Add Record</button>
            </div>
        </div>
        <div class="row" style="padding: 20px 0px 20px;">
          <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
            <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
              <ng-container matColumnDef="short_name">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Short Name </mat-header-cell>
                <mat-cell *matCellDef="let element" class="w-200"> {{element.short_name}}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="mandi_name">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Mandi Name </mat-header-cell>
                <mat-cell *matCellDef="let element" class="w-200"> {{element.mandi_name}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="market_name">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="w-200"> Agmark ID </mat-header-cell>
                <mat-cell *matCellDef="let element" class="w-200"> {{element.agmarkObj.market_name}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="location">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="w-p"> Location  </mat-header-cell>
                <mat-cell *matCellDef="let element" class="w-p" style="display: block;"> 
                  <label *ngIf="element.locationObj && element.locationObj.state"> {{element.locationObj.state}} </label>
                  <label *ngIf="element.locationObj && element.locationObj.district">&nbsp;|&nbsp;{{element.locationObj.district}} </label>
                  <label *ngIf="element.locationObj && element.locationObj.town_city">&nbsp;|&nbsp;{{element.locationObj.town_city}}</label>
                  <!-- <label *ngIf="element.locationObj && element.locationObj.pincode">&nbsp;|&nbsp;{{element.locationObj.pincode}}</label> -->
                  <!-- {{element.locationObj.state}} |  {{element.locationObj.district}} | {{element.locationObj.town_city}} | {{element.locationObj.pincode}} -->
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="delivery_location">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="w-p"> Delivery Location  </mat-header-cell>
                <mat-cell *matCellDef="let element" class="w-p"> {{element.destinationObj.delivery_name}}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="cluster">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="w-p"> Cluster  </mat-header-cell>
                <mat-cell *matCellDef="let element" class="w-p"> {{element.clusterObj?.name}}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="address_1">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="w-p"> Address 1 </mat-header-cell>
                <mat-cell *matCellDef="let element" class="w-p"> {{element.address_1}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="address_2">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="w-p"> Address 2 </mat-header-cell>
                <mat-cell *matCellDef="let element" class="w-p"> {{element.address_2}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="pincode">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="w-120"> Pincode </mat-header-cell>
                <mat-cell *matCellDef="let element" class="w-120"> {{element.pincode}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="action">
                  <mat-header-cell *matHeaderCellDef mat-sort-header style="max-width: 70px;">Action</mat-header-cell>
                  <mat-cell *matCellDef="let element" style="max-width: 70px;">
                      <div class="actionRowBlock">
                          <a class="mr-10" (click)="editMandi(element)">
                              Edit
                          </a>
                      </div>
                  </mat-cell>
              </ng-container>
              <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayColumns;"></mat-row>
            </mat-table>
            <mat-paginator [pageSizeOptions]="dataSource.data.length > 50 ? [10, 20, 50, dataSource.data.length] : [10, 20, 50]" (pageChange)="onPageChange(currentPage)" [(pageIndex)]="currentPage"  showFirstLastButtons></mat-paginator>
        </div>
        <div *ngIf="dataSource.data.length == 0" class="noData">
          No Data Found
        </div>
    </div>
    <simple-notifications [options]="notificationOptions"></simple-notifications>
</div>